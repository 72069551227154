import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import makeHSL from 'styles/makeHSL';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReminderTypes } from 'core/EventTypes';
import ReminderStyles from 'styles/ReminderStyles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  ...ReminderStyles,
  newReminderButton: {
    marginLeft: theme.spacing(3),
    color: makeHSL({ hue: 0, saturation: 0, lightness: 1 }),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonIcon: {
    marginRight: 12,
  },
  actionSmallScreenButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const NewReminderPicker = ({ onItemSelected = () => {} }, context) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);
  const { t } = useTranslation();

  const newReminderItems = [
    {
      icon: 'bells',
      title: t('NewReminderPicker.GeneralReminderTitle'),
      text: t('NewReminderPicker.GeneralReminderText'),
      event: ReminderTypes.GENERAL_REMINDER,
    },
    {
      icon: 'prescription-bottle-alt',
      title: t('NewReminderPicker.MedicineTitle'),
      text: t('NewReminderPicker.MedicineText'),
      event: ReminderTypes.MEDICINE_REMINDER,
    },
    {
      icon: 'map-marked-alt',
      title: t('NewReminderPicker.DestinationCheckTitle'),
      text: t('NewReminderPicker.DestinationCheckText'),
      event: ReminderTypes.DESTINATION_CHECK,
    },
    {
      icon: 'spa',
      title: t('NewReminderPicker.WellnessCheckTitle'),
      text: t('NewReminderPicker.WellnessCheckText'),
      event: ReminderTypes.WELLNESS_REMINDER,
    },
  ];

  function handleClick(event) {
    setAnchor(event.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  const getPermissionForRemindersAddEvent = (eventType) => {
    if (!context.hasPermission) {
      return true;
    }
    switch (eventType) {
      case 'DESTINATION_CHECK':
        return context.hasPermission('LocationService.addEvent');
      case 'MEDICINE':
        return context.hasPermission('MedicineService.addEvent');
      case 'GENERAL_REMINDER':
        return context.hasPermission('CalenderService.addGeneralEvent');
      case 'WELLNESS_REMINDER':
        return context.hasPermission('WellnessService.addEvent');
      default:
        return true;
    }
  };

  function handleReminderItemClick(reminderItem) {
    if (getPermissionForRemindersAddEvent(reminderItem.event)) {
      onItemSelected(reminderItem.event);
      handleClose();
    }
  }

  const id = 'newReminderPicker';

  return (
    <React.Fragment>
      <Button
        aria-owns={anchor ? id : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
        variant="contained"
        color="primary"
        size="small"
        className={classes.newReminderButton}
      >
        <FontAwesomeIcon className={classes.buttonIcon} icon="plus" />
        {t('NewReminderPicker.OpenNewReminderPicker')}
      </Button>

      <IconButton
        className={classes.actionSmallScreenButton}
        onClick={(e) => handleClick(e)}
      >
        <FontAwesomeIcon icon="plus" />
      </IconButton>

      <Menu
        id={id}
        anchorEl={anchor}
        getContentAnchorEl={null}
        variant="menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        <List disablePadding>
          {newReminderItems.map((reminderItem, index, array) => {
            const divider = index !== array.length - 1;

            return (
              <ListItem
                key={index}
                dense
                divider={divider}
                button
                onClick={() => handleReminderItemClick(reminderItem)}
              >
                <ListItemAvatar>
                  <Avatar className={classes[reminderItem.event]}>
                    <FontAwesomeIcon icon={['fal', reminderItem.icon]} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={reminderItem.title}
                  secondary={reminderItem.text}
                />
              </ListItem>
            );
          })}
        </List>
      </Menu>
    </React.Fragment>
  );
};

NewReminderPicker.propTypes = {
  onItemSelected: PropTypes.func,
};

NewReminderPicker.contextTypes = {
  hasPermission: PropTypes.func,
};

export { NewReminderPicker };
