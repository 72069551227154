import React, { useState } from 'react';
import {
  MenuItem,
  TextField,
  Button,
  Grid,
  FormControl,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import { searchProducts } from 'actions/medicine';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginTop: '1em',
    opacity: 1,
    overflowY: 'scroll',
    height: '10em',
    Width: '100%',
    border: '1px solid #ebeef2',
    borderRadius: 4,
  },
  centeredGridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  formButton: {
    width: '50%',
  },
}));

function ChooseMedication({ onChosenMedication = () => {} }, context) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchState = {
    search: '',
    results: [],
    searching: false,
    loaded: false,
    chosenMedication: {},
  };

  const initialFormState = {
    DrugName: '',
    Form: '',
    Strength: '',
    ActiveIngredient: '',
    ApplNo: '',
    ProductNo: '',
    ReferenceDrug: '',
    ReferenceStandard: '',
  };
  const [medicineData, setSearchState] = useState(searchState);
  const [values, setState] = useState(initialFormState);

  function formatMedicineData(medData) {
    const searchResultLabelKeys = [];
    if (isEmpty(medData) === false) {
      const searchResultKeys = Object.keys(medData);

      for (let a = 0; a < searchResultKeys.length; a += 1) {
        const key = searchResultKeys[a];
        const item = medData[key][0];

        let label = `${item.DrugName} (${item.ActiveIngredient})`;
        searchResultLabelKeys.push({ label, value: key });
      }
    }
    return searchResultLabelKeys;
  }

  function searchMedicineProducts(event) {
    event.preventDefault();
    setSearchState({ searching: true });
    searchProducts(dispatch, context.apiClient, medicineData.search)
      .then((res) => {
        setSearchState({
          results: res.data.reduce((obj, product) => {
            if (!obj[product.DrugName]) {
              obj[product.DrugName] = [];
            }
            obj[product.DrugName].push(product);
            return obj;
          }, {}),
          searching: false,
          loaded: true,
        });
      })
      .catch(() => setSearchState({ searching: false }));
    return false;
  }

  const handleSearchInput = (event) => {
    const target = event.target;
    setSearchState({ ...values, [target.name]: target.value });
  };

  function handleChoosenMedicine(value, where) {
    var placeInValues = where;
    setState({ ...values, [placeInValues]: value });
  }

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  function formatFormulationStrengthData(medData, choice) {
    if (values.DrugName !== '' && choice === 'formulation') {
      const medicineForms = (medData[values.DrugName] || []).map(
        (result) => result.Form
      );
      return medicineForms.filter(
        (form, index) => medicineForms.indexOf(form) === index
      );
    } else if (values.DrugName !== '' && choice === 'strength') {
      const medicineStrength = (medData[values.DrugName] || []).map(
        (result) => result.Strength
      );
      return medicineStrength.filter(
        (strength, index) => medicineStrength.indexOf(strength) === index
      );
    }
  }

  function onNextClick(values) {
    const chosenMedicine = getChosenMedicine();
    const updatedValues = {
      ...values,
      ...chosenMedicine,
    };

    setState(updatedValues);
    onChosenMedication(updatedValues);
  }

  function getChosenMedicine() {
    const meds = medicineData.results;
    for (var medItem in meds) {
      if (medItem === values.DrugName) {
        for (let i = 0; i < meds[medItem].length; i++) {
          if (
            meds[medItem][i].Form === values.Form &&
            meds[medItem][i].Strength === values.Strength
          ) {
            return meds[medItem][i];
          }
        }
      }
    }
  }

  return (
    <div>
      <form onSubmit={(event) => searchMedicineProducts(event)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <p align="center">
              Search for a medication or "skip" to fill in one in the next step.
            </p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              required
              value={medicineData.search}
              onChange={handleSearchInput}
              name={'search'}
              type={'text'}
              placeholder={'Search with minimum 2 characters..'}
              variant={'outlined'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {medicineData.searching && <CircularProgress />}
                    {!medicineData.searching && (
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Search"
                        onClick={(event) => searchMedicineProducts(event)}
                      >
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {medicineData.loaded && (
              <FormControl
                variant="outlined"
                fullWidth
                style={{ paddingTop: '2em' }}
              >
                <label> Please select the proper medication: </label>
                <div className={classes.dropdown}>
                  {formatMedicineData(medicineData.results).map((o) => (
                    <MenuItem
                      key={o.label}
                      value={o.value}
                      selected={values.DrugName === o.value}
                      onClick={(value) =>
                        handleChoosenMedicine(o.value, 'DrugName')
                      }
                    >
                      {o.label}
                    </MenuItem>
                  ))}
                </div>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            {medicineData.loaded && values.DrugName !== '' && (
              <FormControl variant="outlined" fullWidth>
                <label> Select the form: </label>
                <div className={classes.dropdown}>
                  {formatFormulationStrengthData(
                    medicineData.results,
                    'formulation'
                  ).map((o) => (
                    <MenuItem
                      key={o}
                      value={o}
                      selected={values.Form === o}
                      onClick={(value) => handleChoosenMedicine(o, 'Form')}
                    >
                      {o}
                    </MenuItem>
                  ))}
                </div>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            {medicineData.loaded && values.DrugName !== '' && (
              <FormControl variant="outlined" fullWidth>
                <label>Choose medicine strength:</label>
                <div className={classes.dropdown}>
                  {formatFormulationStrengthData(
                    medicineData.results,
                    'strength'
                  ).map((o) => (
                    <MenuItem
                      key={o}
                      value={o}
                      selected={values.Strength === o}
                      onClick={(value) => handleChoosenMedicine(o, 'Strength')}
                    >
                      {o}
                    </MenuItem>
                  ))}
                </div>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} className={classes.centeredGridItem}>
            <Button
              className={classes.formButton}
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => onNextClick(values)}
            >
              {values.DrugName !== '' ? 'NEXT' : 'SKIP'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

ChooseMedication.contextTypes = {
  apiClient: PropTypes.object.isRequired,
};

export default ChooseMedication;
