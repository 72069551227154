/* eslint-disable */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiSelect from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import selectStyle from './selectStyle';

const Select = props => (
  <MuiSelect
    {...props}
    disableUnderline
    input={<Input classes={{ root: props.classes.inputRoot }} />}
    classes={{ root: props.classes.selectRoot, select: props.classes.select }}
  >
    {props.children}
  </MuiSelect>
);

export default withStyles(selectStyle)(Select);
