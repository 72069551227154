export const getAddressByCoords = (dispatch, apiClient, lat, lng) => {
  dispatch({
    type: 'GEOCODE_SET_COORDS_ADDRESS',
    payload: { lat, lng, address: 'Loading...' },
  });

  return apiClient
    .call({
      endpoint: 'geocode/lookupGeometry',
      data: {
        type: 'Point',
        coordinates: [lng, lat],
      },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      const result = res.data.address;
      dispatch({
        type: 'GEOCODE_SET_COORDS_ADDRESS',
        payload: { lat, lng, address: result },
      });
      return result;
    })
    .catch((err) => {
      console.log('ERROR', err);
      return 'Locations service currently unavailable';
    });
};

export const getCoordsByAddress = (dispatch, apiClient, address) => {
  return apiClient
    .call({
      endpoint: 'geocode/lookupAddress',
      data: {
        address,
      },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      const coordinates = res.data.geometry.coordinates;
      res.data.geometry.location = {
        lng: coordinates[0],
        lat: coordinates[1],
      };
      return res.data;
    })
    .catch((err) => {
      console.log('ERROR', err);
      return 'Locations service currently unavailable';
    });
};

export const getLocationsPaginated = (
  dispatch,
  apiClient,
  userId,
  from,
  next
) =>
  apiClient
    .call({
      endpoint: 'locations/getLocationsPaginated',
      data: {
        userId,
        from,
        next,
      },
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'GEOCODE_SET_LOCATIONS', payload: res.data.items });

      return res;
    });

const getLastLocationPromises = {};
export const getLastLocation = (dispatch, apiClient, userId) => {
  if (getLastLocationPromises[userId]) {
    return getLastLocationPromises[userId];
  }

  getLastLocationPromises[userId] = apiClient
    .call({ endpoint: 'locations/getLastLocation', data: { userId } })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'GEOCODE_SET_LOCATIONS', payload: res.data });

      getLastLocationPromises[userId] = null;
      delete getLastLocationPromises[userId];

      return res;
    })
    .catch((err) => {
      getLastLocationPromises[userId] = null;
      delete getLastLocationPromises[userId];
      throw err;
    });

  return getLastLocationPromises[userId];
};

export const hello = 'hello';
