var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _socket = require('socket.io-client');

var _socket2 = _interopRequireDefault(_socket);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

module.exports = function () {
  function Api(params) {
    _classCallCheck(this, Api);

    this.uri = 'http://localhost:5050';
    this.fetchPollyfill = null;
    this.protocol = 'http';
    this.session = null;
    this.userId = null;
    this.castCb = null;
    this.ws = {
      socket: null,
      callIter: 0,
      calls: [],
      upgradePromise: null
    };
    this.rawDoc = {};
    this.listeners = [];
    this.configure(params);
  }

  _createClass(Api, [{
    key: 'configure',
    value: function configure(params) {
      switch (typeof params === 'undefined' ? 'undefined' : _typeof(params)) {
        case 'string':
          this.uri = String(params);
          break;
        case 'object':
          this.uri = String(params.uri || this.uri);
          this.fetchPollyfill = params.fetch || this.fetchPollyfill;
          this.session = String(params.session || this.session);
          break;
        default:
          break;
      }
    }
  }, {
    key: 'handshake',
    value: function handshake(credentials) {
      var _this = this;

      return this.call({
        endpoint: 'authenticate',
        data: credentials
      }).then(function (r) {
        if (r.status === 200) {
          _this.userId = r.data.userId;
          _this.session = r.data.session;
        }
        return r;
      });
    }
  }, {
    key: 'setProtocol',
    value: function setProtocol(protocol) {
      switch (protocol) {
        case 'ws':
          return this.protocol === 'ws' ? Promise.resolve() : this._wsStart();
        default:
          // Fetch http
          this.ws.socket && this.ws.socket.disconnect();
          this.protocol = 'http';
          return Promise.resolve();
      }
    }
  }, {
    key: 'setCastHandler',
    value: function setCastHandler(cb) {
      this.castCb = cb;
    }
  }, {
    key: 'listenerBind',
    value: function listenerBind(f) {
      this.listeners.push(f);
    }
  }, {
    key: 'listenerUnbind',
    value: function listenerUnbind(f) {
      for (var i = this.listeners.length - 1; i >= 0; i--) {
        let func = this.listeners[i];
        if (func === f) {
          this.listeners[i] = null;
        }
      }
    }
  }, {
    key: 'listenerClear',
    value: function listenerClear() {
      this.listeners = [];
    }
  }, {
    key: '_listenersCall',
    value: function _listenersCall(type, payload) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.listeners[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var f = _step.value;

          if (typeof f === 'function') {
            f(type, payload);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
  }, {
    key: 'call',
    value: function call(params) {
      var _this2 = this;

      switch (this.protocol) {

        case 'ws':
          var callId = this.ws.callIter++;
          var endpoint = params.endpoint.split('/');
          var objSocket = {
            category: endpoint[0],
            method: endpoint[1],
            data: params.data,
            callId: callId,
            command: params.command || 'api'
          };
          this.ws.socket.emit('command', objSocket);
          var promise = new Promise(function (res, rej) {
            _this2.ws.calls[callId] = { resolve: res, reject: rej };
            return null;
          });
          return promise;

        default:
          // Fetch http
          var url = this.uri + '/' + (params.command || 'api') + '/' + params.endpoint;
          var objFetch = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'authorization': this.session
            },
            body: JSON.stringify(params.data)
          };
          return (this.fetchPollyfill ? this.fetchPollyfill : fetch)(url, objFetch).then(function (response) {
            return response.json();
          });
      }
    }
  }, {
    key: 'reset',
    value: function reset() {
      var _this3 = this;

      return this.setProtocol('http').then(function () {
        _this3.session = null;
        _this3.userId = null;
      });
    }
  }, {
    key: '_wsStart',
    value: function _wsStart() {
      var _this4 = this;

      var loginObj = {
        query: {
          session: this.session
        },
        jsonp: false,
        transports: ['websocket']
      };
      this.ws.socket = (0, _socket2.default)(this.uri, loginObj);
      this.ws.socket.on('connect', function () {
        _this4._listenersCall('connect', {});
      });
      this.ws.socket.on('reconnect', function (retryCount) {
        _this4._listenersCall('reconnect', retryCount);
      });
      this.ws.socket.on('reconnecting', function (retryCount) {
        _this4._listenersCall('reconnecting', retryCount);
      });

      this.ws.socket.on('command', this._wsResponse.bind(this));

      this.ws.socket.on('disconnect', function () {
        _this4._listenersCall('disconnect', {});
        _this4._wsCleanup.bind(_this4);
      });

      this.ws.socket.on('error', function (e) {
        _this4._listenersCall('error', e);
        _this4.ws.upgradePromise.reject(e);
      });
      return new Promise(function (res, rej) {
        return _this4.ws.upgradePromise = { resolve: res, reject: rej };
      });
    }
  }, {
    key: '_wsResponse',
    value: function _wsResponse(command, resp) {
      switch (command) {
        case 'connected':
          if (resp.status === 200) {
            this.protocol = 'ws';
            return this.ws.upgradePromise.resolve(resp);
          }
          this.ws.upgradePromise.reject(resp);
          break;
        case 'api':
          if (this.ws.calls[resp.callId]) {
            this.ws.calls[resp.callId].resolve(resp);
          }
          break;
        case 'error':
          console.error('error', resp);
          break;
        case 'cast':
          if (this.castCb) {
            this.castCb(resp);
          }

          break;
        default:
          console.info('Unhandled commmand', command, ' with resp', resp);
          break;
      }
    }
  }, {
    key: '_wsCleanup',
    value: function _wsCleanup() {
      this.protocol = 'http';
    }

    /* _updateRawdoc(cb) {
      return this._sendCommand();
      //this._doCall('rawdoc', null, {}, cb);
    }*/

    /* _generateApi(data) {
      const api = {};
      for (let i = data.length - 1; i >= 0; i--) {
        const category = data[i];
        api[category.name] = {};
        for (let b = category.methods.length - 1; b >= 0; b--) {
          const method = category.methods[b];
          const obj = {};
          obj.category = category.name;
          obj.methodInfo = method;
          api[category.name][method.name] = ((funkData) => {
            return (args, cb) => {
              const methodInfo = funkData.methodInfo;
              const category = funkData.category;
              const sendParams = {};
              for (let c = methodInfo.indata.length - 1; c >= 0; c--) {
                const indata = methodInfo.indata[c];
                sendParams[indata.name] = args[indata.name];
              }
              this._doCall(category, methodInfo.name, sendParams, cb);
            };
          })(obj);
        }
      }
      return (api);
    }*/

  }]);

  return Api;
}();
