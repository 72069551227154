import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  TextField,
  MenuItem,
  Switch,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { TimePicker, DatePicker } from 'material-ui-pickers';
import Frequency from 'enums/Frequency';
import ReminderAlert from 'enums/ReminderAlert';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme) => ({
  buttonElement: {
    width: '100%',
  },
  switch: {
    marginBottom: theme.spacing(1),
  },
  errorText: {
    textAlign: 'center',
    color: red[300],
  },
}));

function TimeSelectionComponent({
  recurrence,
  formState,
  handleChange,
  getDefaultDateTime,
}) {
  let repeatTimes = Frequency.toNumber(recurrence);
  const timeSelection = [];

  for (let i = 1; i <= repeatTimes; i += 1) {
    const time = `time_${i}`;
    const label = i === 1 ? 'Time' : `Time ${i}`;
    const timeValue = formState[time] ? formState[time] : getDefaultDateTime();
    if (!formState[time]) {
      handleChange(time, timeValue);
    }

    timeSelection.push(
      <Grid item xs={12} key={`${label}-${i}`}>
        <TimePicker
          fullWidth
          keyboard
          clearable
          label={label}
          name={time}
          onChange={(value) => handleChange(time, value.toDate())}
          value={timeValue}
          mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
          variant="outlined"
        />
      </Grid>
    );
  }

  return timeSelection;
}

const destinationReminderFrequencies = [
  Frequency.NEVER,
  Frequency.EVERY_DAY.ONE,
  Frequency.EVERY_WEEK.ONE,
  Frequency.EVERY_MONTH.ONE,
];

const destinationReminderAlerts = [
  ReminderAlert.ON_TIME,
  ReminderAlert.MIN_5,
  ReminderAlert.MIN_15,
  ReminderAlert.MIN_30,
  ReminderAlert.HOUR_1,
  ReminderAlert.HOUR_2,
  ReminderAlert.DAY_1,
];

function DestinationReminderForm({
  timezone,
  reminder,
  onReminderSubmitted = () => {},
  onReminderBack = () => {},
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [values, setValues] = useState(reminder);
  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (name === 'ongoing') {
      setValues({
        ...values,
        ongoing: value,
        endDate: values.ongoing ? null : values.endDate,
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleDateChange = (name, date) => {
    setValues({ ...values, [name]: date });
  };

  function handleOnSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    onReminderSubmitted(values);
  }

  function handleBack(event) {
    event.stopPropagation();
    event.preventDefault();

    onReminderBack(values);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          select
          label="Frequency"
          value={values.recurrence}
          name="recurrence"
          onChange={handleChange}
          variant="outlined"
          fullWidth
        >
          {destinationReminderFrequencies.map((frequency) => (
            <MenuItem key={frequency} value={frequency}>
              {t(frequency)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          format="MM/DD/YYYY"
          label="Start date"
          value={values.startDate}
          minDate={today}
          name="startDate"
          onChange={(value) => handleDateChange('startDate', value.toDate())}
          variant="outlined"
          fullWidth
          mask={(v) =>
            v ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
          }
          keyboard
        />
      </Grid>
      {!values.ongoing && (
        <Grid item xs={12}>
          <DatePicker
            format="MM/DD/YYYY"
            label="End date"
            minDate={tomorrow}
            value={values.endDate}
            name="endDate"
            disabled={values.recurrence === Frequency.NEVER && !values.ongoing}
            onChange={(value) => handleDateChange('endDate', value.toDate())}
            variant="outlined"
            fullWidth
            mask={(v) =>
              v
                ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                : []
            }
            keyboard
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              checked={values.ongoing}
              disabled={values.recurrence === Frequency.NEVER}
              name="ongoing"
              onChange={handleChange}
              value={String(initialDestinationReminderFormValues.ongoing)}
              color="primary"
            />
          }
          label="Ongoing reminder"
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <TimeSelectionComponent
            timezone={timezone}
            recurrence={values.recurrence}
            classes={classes}
            formState={values}
            handleChange={(key, value) => {
              handleDateChange(key, value);
            }}
            getDefaultDateTime={() => {
              return DateTime.local().plus({ hours: 1 }).toJSDate();
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              className={classes.switch}
              checked={values.showReminderField}
              onChange={handleChange}
              name="showReminderField"
              color="primary"
              value="reminderCheck"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="Remind Care Recipient to be at this destination?"
        />
        {values.showReminderField === true && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                label="Reminder"
                value={values.reminderTime}
                name="reminderTime"
                onChange={handleChange}
                variant="outlined"
                fullWidth
              >
                {destinationReminderAlerts.map((reminder) => (
                  <MenuItem key={reminder} value={reminder}>
                    {t(reminder)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rowsMax="4"
                label="Additional Reminder Information"
                value={values.additionalInformation}
                name="additionalInformation"
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={6}>
        <Button
          className={classes.buttonElement}
          variant="contained"
          onClick={handleBack}
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          className={classes.buttonElement}
          disabled={
            (!values.ongoing && values.recurrence === Frequency.NEVER) ||
            (!values.ongoing &&
              values.recurrence !== Frequency.NEVER &&
              !values.endDate)
          }
          variant="contained"
          color="primary"
          onClick={handleOnSubmit}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
}

const today = DateTime.local().startOf('day').toJSDate();

const tomorrow = DateTime.local().plus({ days: 1 }).toJSDate();

const initialDestinationReminderFormValues = Object.freeze({
  recurrence: Frequency.NEVER,
  reminderTime: ReminderAlert.ON_TIME,
  startDate: today,
  ongoing: true,
  endDate: null,
  showReminderField: false,
  time_1: today,
  additionalInformation: '',
});

export { initialDestinationReminderFormValues, DestinationReminderForm };
