/* eslint-disable */

export const getContacts = (dispatch, apiClient, userId) =>
  apiClient.call({ endpoint: 'Contacts/getContacts', data: { userId } }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'CONTACTS_SET', payload: res.data, userId });
    return res;
  });

export const addContact = (dispatch, apiClient, data) =>
  apiClient.call({ endpoint: 'Contacts/addContact', data }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    return res;
  });

export const editContact = (dispatch, apiClient, data) =>
  apiClient.call({ endpoint: 'Contacts/editContact', data }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    return res;
  });

export const removeContact = (dispatch, apiClient, data) =>
  apiClient.call({ endpoint: 'Contacts/removeContact', data }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    return res;
  });

export const getUploadToken = (apiClient, CRId, contactId, fileName) => {
  const data = {
    CRId,
    contactId,
    fileName
  };

  return apiClient
    .call({ endpoint: 'Contacts/getUploadToken', data })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }

      return res;
    })
    .catch((err) => {
      throw err;
    });
};
