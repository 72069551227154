import React from 'react';
import { Route as DefaultRoute, Switch } from 'react-router-dom';
import LoadingPage from 'pages/LoadingPage';
import { Route } from 'routes/Route';
const SessionPage = React.lazy(() => import('SessionPage'));

const CalendarPage = React.lazy(() => import('pages/CalendarPage'));
const CaregiverCalendarPage = React.lazy(() =>
  import('pages/CaregiverCalendarPage')
);
const Caregivers = React.lazy(() => import('pages/Caregivers'));
const Contacts = React.lazy(() => import('pages/Contacts'));
const CRSettings = React.lazy(() => import('pages/CRSettings'));
const CGSettings = React.lazy(() => import('pages/CGSettings'));
const Dashboard = React.lazy(() => import('pages/Dashboard'));
const Login = React.lazy(() => import('pages/Login'));
const Signup = React.lazy(() => import('pages/Signup'));
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/ResetPassword'));
const VerifyEmail = React.lazy(() => import('pages/VerifyEmail'));
const VerifyPhone = React.lazy(() => import('pages/VerifyPhone'));
const VerifyChanges = React.lazy(() => import('pages/VerifyChanges'));
const Index = React.lazy(() => import('pages/Index'));
const LocationsPage = React.lazy(() => import('pages/LocationsPage'));
const TimelinePage = React.lazy(() => import('pages/Timeline'));
const MedicalProfile = React.lazy(() => import('pages/MedicalProfile'));
const VideoRoom = React.lazy(() => import('pages/VideoRoom'));
const WellnessChecks = React.lazy(() => import('pages/WellnessChecks'));
const Inbox = React.lazy(() => import('pages/Inbox'));
const Admin = React.lazy(() => import('pages/Admin'));
const DeviceLogsPage = React.lazy(() => import('pages/DeviceLogs'));
const Adherence = React.lazy(() => import('pages/Adherence'));
const Reminders = React.lazy(() => import('pages/RemindersPage'));
const KitchenSinkPage = React.lazy(() => import('pages/KitchenSinkPage'));
const TimelineV2Page = React.lazy(() => import('pages/TimelineV2Page'));
const ChatPage = React.lazy(() => import('pages/ChatPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const MedicationPage = React.lazy(() => import('pages/MedicationPage'));
const NotificationSettingsPage = React.lazy(() =>
  import('pages/NotificationSettings/NotificationSettingsPage')
);
const ActivityPage = React.lazy(() => import('pages/ActivityPage'));
const HeartRatePage = React.lazy(() => import('pages/HeartRatePage'));
const TermsConditionsPage = React.lazy(() =>
  import('pages/TermsConditionsPage')
);
const PrivacyPolicyPage = React.lazy(() => import('pages/PrivacyPolicyPage'));
const page404 = React.lazy(() => import('pages/PageNotFound'));

const addRoutesForDevelopment = () => {
  if (process.env.NODE_ENV !== 'production') {
    return (
      <DefaultRoute path="/kitchen-sink" exact component={KitchenSinkPage} />
    );
  }

  return null;
};

export default (
  <React.Suspense fallback={<LoadingPage />}>
    <Switch>
      {addRoutesForDevelopment()}

      <DefaultRoute
        path="/session"
        exact
        render={(props) => <SessionPage {...props} />}
      />

      <Route.Insecure path="/login" exact component={Login} />

      <Route.Insecure path="/signup/:invitationId?" exact component={Signup} />

      <Route.Insecure
        path="/forgot-password"
        exact
        component={ForgotPassword}
      />

      <Route.Insecure
        path="/reset-password/:token"
        exact
        component={ResetPassword}
      />

      <Route.Insecure path="/verify/:token" exact component={VerifyEmail} />

      <Route.Insecure
        path="/verifyPhone/:token/:userId"
        exact
        component={VerifyPhone}
      />

      <Route.Insecure
        path="/verifychanges/:id"
        exact
        component={VerifyChanges}
      />

      <Route.App path="/" exact requiresCareRecipient component={Index} />

      <Route.App
        path="/dashboard"
        requiresCareRecipient
        exact
        component={Dashboard}
      />

      <Route.App
        path="/locations/:locationId?/:date?"
        requiresCareRecipient
        exact
        component={LocationsPage}
      />

      <Route.App
        path="/timeline"
        requiresCareRecipient
        exact
        component={TimelinePage}
      />

      <Route.App
        path="/timeline_v2"
        requiresCareRecipient
        exact
        component={TimelineV2Page}
      />

      <Route.App
        path="/medical-profile/:medicineId?/:date?"
        requiresCareRecipient
        exact
        component={MedicalProfile}
      />

      <Route.App path="/video-room" exact component={VideoRoom} />

      <Route.App
        path="/wellness-checks/:checkinId?/:date?"
        requiresCareRecipient
        exact
        component={WellnessChecks}
      />

      <Route.App
        path="/cr-settings/:settingItem?"
        requiresCareRecipient={true}
        exact
        component={CRSettings}
      />

      <Route.App path="/settings" exact component={CGSettings} />

      <Route.App requiresCareRecipient path="/inbox" exact component={Inbox} />

      <Route.App
        requiresCareRecipient
        path="/inbox/:id"
        exact
        component={Inbox}
      />

      <Route.App
        requiresCareRecipient
        path="/adherence"
        exact
        component={Adherence}
      />

      <Route.App
        requiresCareRecipient
        path="/adherence/:type"
        exact
        component={Adherence}
      />

      <Route.App
        requiresCareRecipient
        path="/reminders"
        exact
        component={Reminders}
      />

      <Route.App
        requiresCareRecipient
        path="/contacts"
        exact
        component={Contacts}
      />

      <Route.App path="/caregivers" exact component={Caregivers} />

      <Route.App path="/admin" exact component={Admin} />

      <Route.App
        requiresCareRecipient
        path="/devicelogs"
        exact
        component={DeviceLogsPage}
      />

      <Route.App
        requiresCareRecipient
        path="/new-dashboard"
        exact
        component={DashboardPage}
      />

      <Route.App
        requiresCareRecipient
        path="/chat"
        exact
        component={ChatPage}
      />

      <Route.App
        requiresCareRecipient
        path="/medications"
        exact
        component={MedicationPage}
      />

      <Route.App
        requiresCareRecipient
        path="/calendar"
        exact
        component={CalendarPage}
      />

      <Route.App
        path="/caregiver-calendar"
        exact
        component={CaregiverCalendarPage}
      />

      <Route.App
        requiresCareRecipient
        path="/notification-settings/:settingItem?"
        exact
        component={NotificationSettingsPage}
      />

      <Route.App
        requiresCareRecipient={true}
        path="/activity"
        exact
        component={ActivityPage}
      />

      <Route.App
        requiresCareRecipient={true}
        path="/heart-rate"
        exact
        component={HeartRatePage}
      />

      <DefaultRoute
        path="/terms-and-conditions"
        exact
        component={TermsConditionsPage}
      />

      <DefaultRoute
        path="/privacy-policy"
        exact
        component={PrivacyPolicyPage}
      />

      <DefaultRoute path="*" component={page404} status="404" />
    </Switch>
  </React.Suspense>
);
