import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

const styles = () => ({
  textFieldRoot: {
    display: 'block',
  },
  inputRoot: {
    height: 36,
    backgroundColor: 'white',
    padding: '8px 0 8px 8px',
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: 4,
  },
  inputFocused: {},
  input: {
    padding: 0,
  },
  iconRoot: {
    color: 'white',
  },
  iconButtonRoot: {
    borderRadius: '0 4px 4px 0',
    backgroundColor: '#4a90e2',
    height: 36,
    width: 36,
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#4a90e2',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#4a90e2',
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: '#4a90e2',
    },
  },
});

const SearchField = ({ classes, ...props }) => (
  <TextField
    {...props}
    classes={{ root: classes.textFieldRoot }}
    InputProps={{
      disableUnderline: true,
      classes: {
        root: classes.inputRoot,
        focused: classes.inputFocused,
        input: classes.input,
      },
      endAdornment: (
        <InputAdornment position="end">
          <IconButton classes={{ root: classes.iconButtonRoot }} type="submit">
            <Icon classes={{ root: classes.iconRoot }}>search</Icon>
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

export default withStyles(styles)(SearchField);
