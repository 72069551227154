/* eslint-disable */

export const create =
  (dispatch, apiClient, toUserId = [], careRecipientId, subject, content) =>
    apiClient.call({
      endpoint: 'message/create',
      data: {
        toUserId,
        careRecipientId,
        subject,
        content,
      }
    })
      .then((res) => {
        if (res.status >= 400) {
          throw res;
        }
        dispatch({ type: 'MESSAGES_SET', payload: res.data });
        return res;
      });

export const list = (dispatch, apiClient, data = {}) => apiClient
  .call({ endpoint: 'message/list', data })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'MESSAGES_SET', payload: res.data.messages });
    return res;
  });

export const markRead = (dispatch, apiClient, id) => apiClient
  .call({ endpoint: 'message/markRead', data: { id } })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'MESSAGES_SET', payload: res.data });
    return res;
  });

export const recipientsAvailable = (dispatch, apiClient) => apiClient
  .call({ endpoint: 'message/recipientsAvailable' })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'USER_SET', payload: res.data });
    return res;
  });

export const eslint = 'Stop complaining, i do what i want';
