/* eslint-disable */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import style from './style.module.scss';

const styles = () => ({
  textFieldRoot: {},
  inputRoot: {
    height: 36,
    backgroundColor: 'white',
    padding: 0,
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: 4
  },
  inputFocused: {},
  input: {
    padding: '8px 0 8px 8px'
  },
  adornment: {
    alignSelf: 'center'
  }
});

const Field = ({
  classes, type, error, isChanged, isUsed, options, value, onChange, ...props
}) => (
    <div>
      {(() => {
        if (type === 'date') {
          return (
            <DatePicker
              {...props}
              fullWidth
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                  input: classes.input
                }
              }}
              InputAdornmentProps={{
                classes: { root: classes.adornment }
              }}
              keyboard
              format="MM/DD/YYYY"
              clearable
              autoOk
              disableOpenOnEnter
              animateYearScrolling={false}
              value={value}
              onChange={onChange}
              variant="outlined"
            />
          );
        } else if (type === 'time') {
          return (
            <TimePicker
              fullWidth
              clearable
              value={value}
              onChange={onChange}
              label={props.label}
              variant="outlined"
            />
          );
        } else if (type === 'select') {
          return (
            <Select
              fullWidth
              disableUnderline
              classes={{
                root: classes.inputRoot,
                // focused: classes.inputFocused,
                select: classes.input
              }}
              value={value}
              onChange={onChange}
            >
              {options &&
                options.map(option => (
                  <MenuItem key={`select_${option.value}`} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          );
        }
        return (
          <TextField
            fullWidth
            type={type}
            value={value}
            onChange={onChange}
            variant="outlined"
            {...props}
          />
        );
      })()}
      {isChanged && isUsed && error && <span className={style.fieldError}>{error}</span>}
    </div>
  );

export default withStyles(styles)(Field);
