/* eslint-disable */

import React from 'react';
import MuiIcon from '@material-ui/core/Icon';
import LtIcon from 'components/LtIcon';
import style from './style.module.scss';

export default ({
  iconColor, iconClassName, ltIcon, small, ...props
}) => {
  const icon = ltIcon || props.icon;
  const Icon = ltIcon ? LtIcon : MuiIcon;
  return (
    <div {...props} className={`${style.title} ${props.className || ''} ${small && style.small}`}>
      {(icon || iconClassName) && (
        <Icon
          className={`${style.icon} ${iconClassName || ''}`}
          {...(iconColor ? { style: { color: iconColor } } : {})}
        >
          {icon}
        </Icon>
      )}
      {props.children}
    </div>
  );
};
