/* global FormData */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { post } from 'axios';
import set from 'lodash.set';
import { addContact, editContact, getUploadToken } from 'actions/contacts';
import NewContactForm from './NewContactForm';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
  },
  reminderTimeLabel: {
    fontSize: 12,
    paddingTop: 8,
  },
  disclaimer: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
    hasPermission: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const data = this.props.data || {};
    const { contactData } = data;
    const edit = !!contactData;
    let out = {};

    if (edit) {
      out = {
        edit: true,
        ...contactData,
      };
    }

    this.state = {
      out,
      edit,
      saving: false,
      file: null,
      isDialogOpen: true,
    };
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
    this.setState({
      isDialogOpen: false,
    });
  }

  handleSubmit = (formState) => {
    const { out, saving } = this.state;
    if (!saving) {
      this.setState({ saving: true });

      const toSave = {
        userId: this.props.activeRecipient.userId,
        ...out,
        ...formState,
      };

      if (this.state.edit) {
        editContact(this.props.dispatch, this.context.apiClient, toSave)
          .then((res) => {
            if (typeof formState.profileImage !== 'string') {
              const { contactId } = res.data;
              return this.uploadImage(contactId, formState.profileImage);
            }

            return res;
          })
          .then(() => this.setState({ saving: false }, () => this.onClose()))
          .catch((err) =>
            this.setState({ saveError: err.error, saving: false })
          );
      } else {
        addContact(this.props.dispatch, this.context.apiClient, toSave)
          .then((res) => {
            if (typeof formState.profileImage.name === 'string') {
              const { contactId } = res.data;
              return this.uploadImage(contactId, formState.profileImage);
            }

            return res;
          })
          .then(() => this.setState({ saving: false }, () => this.onClose()))
          .catch((err) =>
            this.setState({ saveError: err.error, saving: false })
          );
      }
    }
  };

  onChangeFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.setState({ file });
    }
  }

  setOutValue(key, val) {
    this.setState({ out: set(this.state.out, key, val) });
  }

  uploadImage(contactId, file) {
    if (!file || !contactId) {
      return false;
    }

    const promise = getUploadToken(
      this.context.apiClient,
      this.props.activeRecipient.userId,
      contactId,
      file
    );

    return promise.then((res) => {
      const upload = res.data;
      const uri = `${process.env.REACT_APP_URL_SERVER_BACKEND}/upload/up/${upload.fileId}`;

      const formData = new FormData();
      formData.append('file', file);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      return post(uri, formData, config);
    });
  }

  render() {
    const { classes } = this.props;
    const { edit } = this.state;

    return (
      <MuiDialog open={this.state.isDialogOpen} onClose={() => this.onClose()}>
        <DialogTitle id="alert-dialog-title">
          {!edit ? 'Create New Contact' : 'Edit Contact'}
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={() => this.onClose()}
          >
            <Icon>clear</Icon>
          </IconButton>
        </DialogTitle>
        <div className={classes.disclaimer}>
          <Typography variant="body2">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="//www.leapthru.com/knowledge-base/important-information-regarding-911-and-emergency-contacts/"
            >
              Please review this important information about setting up 911 and
              other emergency contacts.
            </a>
          </Typography>
        </div>
        <DialogContent>
          <NewContactForm
            preFilledForm={this.state.out}
            onSubmit={this.handleSubmit}
          ></NewContactForm>
        </DialogContent>
      </MuiDialog>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(
    ['carerecipients', 'activeRecipient'],
    {}
  );
  return { activeRecipient };
};

export default withStyles(styles)(connect(mapStateToProps)(Dialog));
