import clamp from 'utils/clamp';

const clampRadialNumber = clamp({ min: 0, max: 360 });
const clampDecimalNumber = clamp({ min: 0, max: 1 });

export default function makeHSL({ hue, saturation, lightness, opacity } = {}) {
  const parameters = [
    clampRadialNumber(hue || 0),
    `${clampDecimalNumber(saturation || 0) * 100}%`,
    `${clampDecimalNumber(lightness || 1) * 100}%`,
    `${clampDecimalNumber(opacity || 1)}`,
  ];

  return `hsl(${parameters.join(', ')})`;
}
