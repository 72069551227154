/* eslint-disable */

/* global ga */
import ApiClient from 'vendor/@iapi/apiclient';
import React  from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import Transition from 'react-transition-group/Transition';
import { auth, logout } from 'actions/user';
import DialogImportMembers from 'dialogs/ImportMembers';
import DialogEditMultipleUsers from 'dialogs/EditMultipleUsers';
import DialogCreateCareRecipient from 'dialogs/CreateCareRecipient';
import DialogCareGiverSettings from 'dialogs/CareGiverSettings';
import DialogCareRecipientPicker from 'dialogs/CareRecipientPicker';
import DialogConfirmation from 'dialogs/Confirmation';
import DialogGeneralReminder from 'dialogs/GeneralReminder';
import DialogInviteCaregiver from 'dialogs/InviteCareGiver';
import DialogMessage from 'dialogs/Message';
import DialogMessageCompose from 'dialogs/MessageCompose';
import DialogNewCheckin from 'dialogs/NewCheckin';
import DialogNewMedication from 'dialogs/NewMedication';
import DialogRecipientPicker from 'dialogs/RecipientPicker';
import DialogNewLocation from 'dialogs/NewLocation';
import DialogNewUserLocation from 'dialogs/NewUserLocation';
import DialogEditMedicalProfile from 'dialogs/EditMedicalProfile';
import DialogNewContact from 'dialogs/NewContact';
import DialogAudioPlayer from 'dialogs/AudioPlayer';
import DialogAdminEditUser from 'dialogs/AdminEditUser';
import DialogAdminVerificationInformation from 'dialogs/AdminVerificationInformation';
import DialogGenerateCoupon from 'dialogs/GenerateCoupon';
import DialogSignupTerms from 'dialogs/SignupTerms';
import DialogSignupPrivacyPolicy from 'dialogs/SignupPrivacyPolicy';
import DialogHelp from 'dialogs/Help';
import CreateReminder from 'dialogs/CreateReminder';
import LoginDisclaimerDialog from 'dialogs/LoginDisclaimer';
import { getPermissionsFromCareRecipient } from 'reducers/carerecipients';
import routes from 'routes';

const code = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65, 13];

const dialogs = {
  importMembers: { component: DialogImportMembers },
  editMultipleUsers: { component: DialogEditMultipleUsers },
  careGiverSettings: { component: DialogCareGiverSettings },
  careRecipientPicker: { component: DialogCareRecipientPicker },
  confirmation: { component: DialogConfirmation },
  createCareRecipient: { component: DialogCreateCareRecipient },
  generalReminder: { component: DialogGeneralReminder },
  inviteCaregiver: { component: DialogInviteCaregiver },
  message: { component: DialogMessage },
  messageCompose: { component: DialogMessageCompose },
  newCheckin: { component: DialogNewCheckin },
  newLocation: { component: DialogNewLocation },
  newUserLocation: { component: DialogNewUserLocation },
  newMedication: { component: DialogNewMedication },
  recipientPicker: { component: DialogRecipientPicker },
  editMedicalProfile: { component: DialogEditMedicalProfile },
  newContact: { component: DialogNewContact },
  audioPlayer: { component: DialogAudioPlayer },
  adminEditUser: { component: DialogAdminEditUser },
  adminVerificationInformation: { component: DialogAdminVerificationInformation },
  generateCoupon: { component: DialogGenerateCoupon },
  signupTerms: { component: DialogSignupTerms },
  signupPrivacyPolicy: { component: DialogSignupPrivacyPolicy },
  help: { component: DialogHelp },
  createReminder: { component: CreateReminder },
  loginDisclaimer: { component: LoginDisclaimerDialog }
};

class AppFrame extends React.Component {
  static childContextTypes = {
    apiClient: PropTypes.object,
    dialogPush: PropTypes.func,
    dialogPop: PropTypes.func,
    dialogClose: PropTypes.func,
    hasPermission: PropTypes.func,
    addCastListener: PropTypes.func,
    removeCastListener: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.apiClient = new ApiClient({ uri: process.env.REACT_APP_URL_SERVER_BACKEND, session: props.session });

    this.apiClient.setCastHandler(data => this.getApiCast(data));

    this.state = {
      loading: true,
      dialogs: {},
      dialogId: 0,
      dialogStack: [],
      codeIndex: 0,
      showDebug: false
    };

    this.castListeners = {};
  }

  getChildContext() {
    return {
      apiClient: this.apiClient,
      dialogPush: this.dialogPush.bind(this),
      dialogPop: this.dialogPop.bind(this),
      dialogClose: this.dialogClose.bind(this),
      hasPermission: this.hasPermission.bind(this),
      addCastListener: this.addCastListener.bind(this),
      removeCastListener: this.removeCastListener.bind(this)
    };
  }

  componentDidMount() {
    auth(this.props.dispatch,
      this.apiClient,
      { session: this.props.session },
      { activeCareRecipientId: this.props.activeCareRecipientId }
      ).catch(() => {
      return logout(this.props.dispatch, this.apiClient);
    }).then(() => this.setState({ loading: false }));

    document.addEventListener('keyup', this._handleKeyDown.bind(this));
  }

  getApiCast(event) {
    if (event) {
      if (event.type && this.castListeners[event.type]) {
        for (let a = 0; a < this.castListeners[event.type].length; a += 1) {
          try {
            const listener = this.castListeners[event.type][a];
            listener(event);
          } catch (err) {
            console.log('listener error', err);
          }
        }
      }
    }
  }

  addCastListener(event, listener) {
    if (!this.castListeners[event]) {
      this.castListeners[event] = [];
      this.castListeners[event].push(listener);
    } else if (!this.castListeners[event].includes(listener)) {
      this.castListeners[event].push(listener);
    }
  }

  removeCastListener(event, listener) {
    if (this.castListeners[event]) {
      this.castListeners[event] = this.castListeners[event].filter(item => item !== listener);
    }
  }

  _handleKeyDown(event) {
    if (this.state.showDebug) {
      this.setState({ showDebug: false });
      return;
    }

    if (event.keyCode === code[this.state.codeIndex]) {
      if (this.state.codeIndex === (code.length - 1)) {
        this.setState({ codeIndex: 0, showDebug: true });
        return;
      }
      this.setState({ codeIndex: this.state.codeIndex + 1 });
      return;
    }

    if (this.state.codeIndex !== 0) {
      this.setState({ codeIndex: 0 });
    }
  }

  dialogPush(type, data) {
    const id = `dialog_${this.state.dialogId}`;
    this.setState({
      dialogs: Object.assign({}, this.state.dialogs, {
        [id]: {
          type,
          data: typeof data === 'object' ? data : {},
        }
      }),
      dialogStack: this.state.dialogStack.concat([id]),
      dialogId: this.state.dialogId + 1,
    }, () => this.bodyDialogToggle());
    return id;
  }

  hasPermission(permission, recipient = this.props.activeRecipient) {
    const { currentUserId } = this.props;
    const careGiverRights = getPermissionsFromCareRecipient(recipient, currentUserId);

    try {
      return recipient.primaryCareGiver === currentUserId || get(careGiverRights, permission, false);
    } catch {
      return false;
    }
  }

  dialogClose(id) {
    this.setState({
      dialogStack: this.state.dialogStack.filter(i => i !== id)
    }, () => this.bodyDialogToggle());
    setTimeout(() => this.dialogCleanup(id), 1000);
  }

  dialogPop() {
    const id = this.state.dialogStack[this.state.dialogStack.length - 1];
    this.dialogClose(id);
  }

  dialogCleanup(id) {
    const newState = Object.assign({}, this.state.dialogs);
    delete newState[id];
    this.setState({ dialogs: newState });
  }

  // eslint-disable-next-line class-methods-use-this
  bodyDialogToggle() {
    const remove = this.state.dialogStack.length === 0;
    document.body.style.overflow = remove ? null : 'hidden';
    document.body.style.height = remove ? null : '100%';
    document.body.style.width = remove ? null : '100%';
    if (!!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform)) {
      document.body.style.position = remove ? null : 'fixed';
    }
  }

  render() {
    let debug = null;

    if (this.state.showDebug) {
      const debugStyle = {
        zIndex: 99999,
        background: '#ffffff',
        width: '500px',
        height: '500px',
        color: '000000',
        display: 'block',
        position: 'absolute'
      };

      debug = <div style={debugStyle}><pre>{JSON.stringify(process.env.REACT_APP_URL_SERVER_BACKEND, null, 2)}</pre></div>;
    }

    if (this.state.loading) {
      return (
        <div>{debug}</div>
      );
    }

    return (
      <div className="dashboard">
        <div>{debug}</div>
          <Router>{routes}</Router>
        {this.props.children}
        {Object.keys(this.state.dialogs).map(id => (
          <Transition
            key={id}
            in={this.state.dialogStack.includes(id)}
            timeout={200}
            mountOnEnter
            unmountOnExit
          >
            {(transition) => {
              const Dialog = dialogs[this.state.dialogs[id].type].component;
              return (
                <Dialog
                  transition={transition}
                  dialogId={id}
                  data={this.state.dialogs[id].data}
                />
              );
            }}
          </Transition>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeCareRecipientId: state.getIn(['cookie', 'activeCareRecipientId'], false),
  activeRecipient: state.getIn(['carerecipients', 'activeRecipient']),
  alerts: state.getIn(['alerts', 'alerts']),
  currentUserId: state.getIn(['user', 'self', 'userId']),
  session: state.getIn(['cookie', 'access_session'], false)
});

export default connect(mapStateToProps)(AppFrame);
