import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import LtIcon from 'components/LtIcon';
import IconButton from '@material-ui/core/IconButton';
import { useActiveCareRecipientSelector } from 'reducers/carerecipients';
import User from 'entities/user';
import { hasBreakThruCapability } from '../utils/deviceCapabilities';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 25,
  },

  buttonIconRight: {
    marginLeft: theme.spacing(1),
  },

  buttonIconLeft: {
    marginRight: theme.spacing(1),
  },

  actionBigScreenButton: {
    marginRight: 25,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  actionSmallScreenButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const CommunicationDropdown = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchor, setAnchor] = useState(null);
  const activeCareRecipient = useActiveCareRecipientSelector();
  const currentUser = useSelector(
    (state) => state.getIn(['user', 'self']),
    shallowEqual
  );
  const user = User(currentUser);
  const isRecipient = activeCareRecipient.userId === user.id;

  function handleClick(event) {
    setAnchor(event.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  const id = 'CommunicationDropdown';

  const BreakThruListItem = () => {
    if (hasBreakThruCapability(activeCareRecipient)) {
      return (
        <ListItem
          divider
          button
          component={Link}
          to="/video-room"
          onClick={() => handleClose()}
        >
          <ListItemIcon className={classes.root}>
            <LtIcon>breakthru</LtIcon>
          </ListItemIcon>
          <ListItemText
            primary={t('CommunicationDropdown.BreakThru')}
            className={classes.textAndIconColor}
          />
        </ListItem>
      );
    }

    return (
      <ListItem divider button disabled={true}>
        <ListItemIcon className={classes.root}>
          <LtIcon>breakthru</LtIcon>
        </ListItemIcon>
        <ListItemText
          primary={t(
            'CommunicationDropdown.BreakThruDisabledDueToMissingCapability'
          )}
          className={classes.textAndIconColor}
        />
      </ListItem>
    );
  };

  return (
    <React.Fragment>
      <Button
        className={classes.actionBigScreenButton}
        variant="outlined"
        size="small"
        aria-owns={anchor ? id : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
      >
        <FontAwesomeIcon
          className={classes.buttonIconLeft}
          color="red"
          icon="heart"
        />
        {t('CommunicationDropdown.Communicate')}
        <FontAwesomeIcon
          className={classes.buttonIconRight}
          size="sm"
          icon={['fal', 'chevron-down']}
        />
      </Button>

      <IconButton
        className={classes.actionSmallScreenButton}
        onClick={(e) => handleClick(e)}
      >
        <FontAwesomeIcon color="red" icon="heart" />
      </IconButton>

      <Menu
        id={id}
        anchorEl={anchor}
        getContentAnchorEl={null}
        variant="menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        <List disablePadding>
          {!isRecipient && (
            <ListItem
              divider
              button
              component={Link}
              to="/chat"
              onClick={() => handleClose()}
            >
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon="comments"
                  size="1x"
                  className={classes.textAndIconColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('CommunicationDropdown.GroupChat')}
                className={classes.textAndIconColor}
              />
            </ListItem>
          )}
          <BreakThruListItem />
          <ListItem
            divider
            button
            component={Link}
            to="/video-room"
            onClick={() => handleClose()}
          >
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon={['fas', 'phone-alt']}
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>

            <ListItemText
              primary={t('CommunicationDropdown.VideoCall')}
              className={classes.textAndIconColor}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/inbox"
            onClick={() => handleClose()}
          >
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon={['fas', 'shield-alt']}
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('CommunicationDropdown.SecureMessages')}
              className={classes.textAndIconColor}
            />
          </ListItem>
        </List>
      </Menu>
    </React.Fragment>
  );
};

export default CommunicationDropdown;
