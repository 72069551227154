/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RRule from 'rrule';
import moment from 'moment-timezone';
import set from 'lodash.set';
import get from 'lodash.get';
import debounce from 'lodash.debounce';
import { Marker } from 'react-google-maps';
import { MenuItem, Icon } from '@material-ui/core';
import { DateTime } from 'luxon';
import { getCoordsByAddress, getAddressByCoords } from 'actions/geocode';
import { addLocationEvent, editLocationEvent } from 'actions/location';
import { getMinutes, getTimeByTimezone, toToday } from 'utils/date';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import RequiredFieldIndicator from 'components/RequiredFieldIndicator';
import Title from 'components/Title';
import Select from 'components/Select';
import SearchField from 'components/DialogSearchField';
import Field from 'components/DialogField';
import Switch from 'components/Switch';
import GoogleMap from 'components/GoogleMap';
import style from './style.module.scss';

const frequencyOptions = {
  one_time: { label: 'One time', rrule: { freq: RRule.YEARLY } }, // using yearly as placeholder
  day_1: { label: 'Once a day', rrule: { freq: RRule.DAILY } },
  // day_2: { label: '2 times a day', rrule: { freq: RRule.DAILY } },
  // day_3: { label: '3 times a day', rrule: { freq: RRule.DAILY } },
  // day_4: { label: '4 times a day', rrule: { freq: RRule.DAILY } },
  week_1: { label: '1x per week', rrule: { freq: RRule.WEEKLY } },
  month_1: { label: '1x per month', rrule: { freq: RRule.MONTHLY } }
};
const frequencyKeys = Object.keys(frequencyOptions);
const remindBeforeOptions = {
  on_time: { label: 'On time', seconds: 0 },
  min_5: { label: '5 minutes before', seconds: 5 * 60 },
  min_15: { label: '15 minutes before', seconds: 15 * 60 },
  min_30: { label: '30 minutes before', seconds: 30 * 60 },
  hour_1: { label: '1 hour before', seconds: 60 * 60 },
  hour_2: { label: '2 hours before', seconds: 60 * 2 * 60 },
  day_1: { label: '1 day before', seconds: 60 * 24 * 60 }
};
const remindBeforeKeys = Object.keys(remindBeforeOptions);

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    const data = this.props.data || {};
    const { currentStep = 1, eventData } = data;
    const edit = !!eventData;
    let out = {
      frequency: 'one_time',
      remindVip: 'on_time',
      selectedLocation: null
    };

    // parsing current event data if it exists, mapping to the correct fields
    if (edit) {
      const recurrenceRRule = eventData.recurrence && RRule.fromString(eventData.recurrence);
      const foundFrequency =
        recurrenceRRule &&
        frequencyKeys.find((key) => {
          const f = frequencyOptions[key];
          return f.rrule.freq === recurrenceRRule.options.freq;
        });
      const frequency = typeof foundFrequency === 'undefined' ? 'one_time' : foundFrequency;
      const startDate = DateTime.fromJSDate(get(recurrenceRRule, 'options.dtstart')).toUTC().setZone('local', { keepLocalTime: true });
      const endDate = DateTime.fromJSDate(get(recurrenceRRule, 'options.until')).toUTC().setZone('local', { keepLocalTime: true }).toJSDate();
      const byminute = get(recurrenceRRule, 'options.byminute[0]');
      const time = typeof byminute !== 'undefined' ? startDate.plus({ minutes: byminute }).toJSDate() : null;
      const remindersEnabled = eventData.reminders && eventData.reminders.length > 0;
      const reminder = remindersEnabled && eventData.reminders[0];
      const remindVip =
        reminder
          ? remindBeforeKeys.find(key => remindBeforeOptions[key].seconds === reminder.offset)
          : 'on_time';
      out = {
        ...eventData,
        frequency,
        startDate: startDate.toJSDate(),
        endDate,
        time,
        remindVip,
        remindersEnabled
      };
    }

    this.state = {
      out,
      currentStep,
      search: '',
      searchResults: [],
      saveError: '',
      saving: false,
      newLocation: props.locationNames && props.locationNames.length === 0,
      edit
    };

    this.map = React.createRef();
  }

  componentDidMount() {
    // geocoding either coords or by the name of the location
    if (this.state.edit) {
      const lat = get(this.state.out, 'data.latitude');
      const lng = get(this.state.out, 'data.longitude');
      if (lat && lng) {
        this.geocodeCoords(lat, lng);
      } else {
        const locationName = get(this.state.out, 'data.title');
        if (locationName) {
          this.selectLocationName(locationName);
        }
      }
    }
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onNextClick() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  onBackClick() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  onSaveClick() {
    if (!this.state.saving) {
      this.setState({ saving: true });
      const { userId, timezone } = this.props.activeRecipient;

      const toSave = {
        userId,
        timezone,
        recurrence: '',
        location: {},
        ...this.state.out
      };
      const {
        remindVip, frequency, time, selectedLocation, remindersEnabled
      } = toSave;

      if (frequency) {
        const selectedFrequency = frequencyOptions[frequency];
        const { freq } = selectedFrequency.rrule;
        const rruleOpts = {
          ...selectedFrequency.rrule,
          tzid: timezone,
          freq: typeof freq === 'undefined' ? RRule.DAILY : freq,
          byminute: getMinutes(time)
        };
        if (toSave.startDate) {
          const startDate = moment(toSave.startDate).toDate();
          rruleOpts.dtstart = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
          if (frequency === 'one_time') {
            rruleOpts.until = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59));
          }
        }
        const rrule = new RRule(rruleOpts);
        toSave.recurrence = rrule.toString();
        // toSave.recurrenceDescription = rrule.toText();
      }
      if (remindersEnabled && remindVip) {
        const selectedRemindBefore = remindBeforeOptions[remindVip];
        toSave.reminders = [{ offset: selectedRemindBefore.seconds }];
      }
      if (selectedLocation) {
        const loc = get(selectedLocation, 'geometry.location', {});
        toSave.location.latitude = loc.lat;
        toSave.location.longitude = loc.lng;
      }
      if (toSave.data && toSave.data.title) {
        toSave.location.title = toSave.data.title;
      }

      if (!remindersEnabled && toSave.reminders !== undefined) {
        delete toSave.reminders;
        delete toSave.remindVip;
      }

      (this.state.edit ? editLocationEvent : addLocationEvent)(
        this.props.dispatch,
        this.context.apiClient,
        toSave
      )
        .then(() => this.setState({ saving: false }, () => this.onClose()))
        .catch(err => this.setState({ saveError: err.error, saving: false }));
    }
    this.props.onSave();
  }

  onSearchChange(event) {
    this.setState({ search: event.target.value }, this.geocodeAddress);
  }

  onLocationSelect(event) {
    const { value } = event.target;
    this.selectLocationName(value);
  }

  setOutValue(key, val) {
    this.setState({ out: set(this.state.out, key, val) });
  }

  selectLocationName(locationName) {
    const { locationNamesOptions } = this.props;
    const selected = locationNamesOptions[locationName];
    this.setOutValue('data.title', locationName);
    if (selected) {
      this.geocodeCoords(selected.latitude, selected.longitude);
    }
  }

  geocodeCoords(lat, lng) {
    getAddressByCoords(
      this.props.dispatch,
      this.context.apiClient,
      lat,
      lng
    ).then(res =>
      this.setState({ search: res }, () => {
        this.geocodeAddress();
      })
    );
  }

  geocodeAddress = debounce(() => {
    if (this.state.search) {
      getCoordsByAddress(
        this.props.dispatch,
        this.context.apiClient,
        this.state.search
      ).then(result =>
        this.setState({ searchResults: [result] }, () => {
          this.selectLocation(result);
        })
      );
    } else {
      this.setState({ searchResults: [] }, () => {
        this.selectLocation(null);
      });
    }
  }, 1000);

  selectLocation(selectedLocation) {
    this.setState({ out: { ...this.state.out, selectedLocation } }, () => {
      if (this.state.out.selectedLocation && this.map) {
        this.map.panTo(this.state.out.selectedLocation.geometry.location);
      }
    });
  }

  renderField(type, outKey, label, props, defaultValue) {
    if (type === 'text') {
      return (
        <div className={style.field} key={`field_${outKey}`}>
          <div className={style.label}>{label}</div>
          <Field
            type={get(props, 'type', 'text')}
            value={get(this.state.out, outKey, defaultValue || '')}
            onChange={event => this.setOutValue(outKey, get(event, 'target.value', event))}
            {...props}
          />
        </div>
      );
    } else if (type === 'date') {
      return (
        <div className={style.field} key={`field_${outKey}`}>
          <div className={style.label}>{label}</div>
          <Field
            type={type}
            value={get(this.state.out, outKey, defaultValue || null)}
            onChange={event => this.setOutValue(outKey, get(event, 'target.value', event))}
            {...props}
          />
        </div>
      );
    } else if (type === 'time') {
      const value = get(this.state.out, outKey, defaultValue || null);
      const time = moment(value);
      const { timezone } = this.props.activeRecipient;
      return (
        <div className={style.field} key={`field_${outKey}`} style={{ minWidth: 320 }}>
          <div className={style.label}>{label}</div>
          <div className={style.timeField}>
            <Field
              type={type}
              value={value}
              onChange={event => this.setOutValue(outKey, get(event, 'target.value', event))}
              {...props}
            />
            {time.isValid() && (
              <div className={style.time}>
                Care recipient
                {"'"}s time zone:{' '}
                <strong>{getTimeByTimezone(toToday(time), timezone).format('h:mm A')}</strong>
              </div>
            )}
          </div>
        </div>
      );
    } else if (type === 'select') {
      return (
        <div className={`${style.field} ${style.selectField}`} key={`field_${outKey}`}>
          <div className={style.label}>{label}</div>
          <Select
            value={get(this.state.out, outKey, defaultValue || '')}
            onChange={event => this.setOutValue(outKey, event.target.value)}
            className={style.select}
            {...props}
          >
            {props &&
              props.options &&
              props.options.map(option => (
                <MenuItem key={`select_${outKey}_${option.value}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </div>
      );
    } else if (type === 'switch') {
      return (
        <div className={`${style.field} ${style.switchField}`} key={`field_${outKey}`}>
          <div className={style.switch}>
            <Switch
              checked={get(this.state.out, outKey, defaultValue || false)}
              onChange={event => this.setOutValue(outKey, event.target.checked)}
            />
          </div>
          <div className={style.label}>{label}</div>
        </div>
      );
    }
    return null;
  }

  renderStep1() {
    const { searchResults, newLocation } = this.state;
    return (
      <div>
        <Title icon="alarm" iconColor="black">
          New destination check (1/2)
        </Title>
        <div>
          {this.renderField('text', 'title', 'Title')}
          {this.renderField('text', 'description', 'Additional Reminder Information')}
          <div className={style.fields}>
            <div className={`${style.field} ${style.inputGroup}`}>
              {newLocation
                ? this.renderField('text', 'data.title', 'Location name')
                : this.renderField('select', 'data.title', 'Location name', {
                    onChange: event => this.onLocationSelect(event),
                    options: this.props.locationNames.reduce((arr, loc) => {
                      if (loc.title) {
                        arr.push({ label: loc.title, value: loc.title });
                      }
                      return arr;
                    }, [])
                  })}
              <div className={style.buttonContainer}>
                <Button onClick={() => this.setState({ newLocation: !newLocation })}>
                  <Icon className={style.icon}>{newLocation ? 'clear' : 'add'}</Icon>
                </Button>
              </div>
            </div>
            <div className={style.field}>
              <div className={style.label}>
                Location Address <RequiredFieldIndicator />
              </div>
              <form onSubmit={event => event.preventDefault()}>
                <SearchField
                  value={this.state.search}
                  onChange={event => this.onSearchChange(event)}
                />
              </form>
            </div>
          </div>
          <div className={style.map}>
            <GoogleMap
              defaultZoom={1}
              getMap={(map) => {
                this.map = map;
              }}
            >
              {(() => {
                const { selectedLocation } = this.state.out;
                return selectedLocation && <Marker position={selectedLocation.geometry.location} />;
              })()}
            </GoogleMap>
          </div>
          {searchResults.length > 1 && (
            <div className={style.mapResults}>
              Other locations that match your query:
              {searchResults.map(opt => (
                <div key={opt.place_id} className={style.mapResult}>
                  {opt.address}
                  <Button
                    onClick={() =>
                      this.setState({ search: opt.address }, () =>
                        this.selectLocation(opt))
                    }
                  >
                    Select
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderStep2() {
    const { selectedLocation, remindersEnabled } = this.state.out;
    return (
      <div>
        <Title icon="alarm" iconColor="black">
          Destinations reminder (2/2)
        </Title>
        <div>
          <div className={style.selectedAddress}>
            {selectedLocation && selectedLocation.address}
          </div>
          <div className={style.fields}>
            {this.renderField('select', 'frequency', 'Frequency', {
              options: frequencyKeys.map(w => ({
                label: frequencyOptions[w].label,
                value: w
              }))
            })}
          </div>
          <div className={style.dateRangeFields}>
            {this.renderField('date', 'startDate', 'Select date', { placeholder: 'Select date' })}
            {this.renderField('time', 'time', 'Select time', { placeholder: 'Select time' })}
          </div>
          <div className={style.reminderField}>
            {this.renderField('switch', 'remindersEnabled', 'Set Reminders OFF/ON')}
          </div>
          {remindersEnabled && (
            <div>
              {this.renderField('select', 'remindVip', 'Remind VIP before', {
                options: remindBeforeKeys.map(w => ({
                  label: remindBeforeOptions[w].label,
                  value: w
                }))
              })}
            </div>
          )}
        </div>
        {this.state.saveError && (
          <div className={style.saveError}>An error occurred: {this.state.saveError}</div>
        )}
      </div>
    );
  }

  render() {
    const { currentStep } = this.state;
    return (
      <div>
        {currentStep > 1 && (
          <IconButton
            onClick={() => this.onBackClick()}
            className={style.buttonBack}
            icon="arrow_back"
            variant="fab"
            mini
          />
        )}
        <div className={style.content}>
          {currentStep === 1 && this.renderStep1()}
          {currentStep === 2 && this.renderStep2()}
        </div>
        <div>
          {currentStep !== 2 && (
            <Button className={style.nextButton} onClick={() => this.onNextClick()}>
              NEXT
            </Button>
          )}
          {currentStep === 2 && (
            <Button className={style.nextButton} onClick={() => this.onSaveClick()}>
              SAVE
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const activeRecipient = state.getIn(['carerecipients', 'activeRecipient'], {});
  const userLocations = state.getIn(['location', 'userLocations', activeRecipient.userId]);
  const locationNamesOptions =
    userLocations &&
    userLocations.reduce((obj, loc) => ({ ...obj, [get(loc, 'title', '[missing]')]: loc }), {});
  const locationNames = userLocations ? Object.values(locationNamesOptions) : [];
  const extraLocation = get(ownProps, 'data.eventData.data');
  if (extraLocation && locationNamesOptions && !locationNamesOptions[extraLocation.title]) {
    locationNamesOptions[extraLocation.title] = extraLocation;
    locationNames.push(extraLocation);
  }
  return {
    activeRecipient,
    locationNames,
    locationNamesOptions
  };
};

export default connect(mapStateToProps)(Dialog);
