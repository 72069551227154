/* eslint-disable */

import React, { Component } from 'react';
import style from './style.module.scss';

class OptionSelect extends Component {
  constructor(props) {
    super(props);
    this.componentId = Date.now();
  }

  onOptionClick(option) {
    if (this.props.onChange) {
      this.props.onChange(option.value);
    }
  }

  render() {
    return (
      <div className={`${style.container} ${this.props.className}`}>
        {this.props.label && <div className={style.label}>{this.props.label}</div>}
        <div className={style.optionSelect}>
          {(this.props.options || []).map((option, index) => {
            const selected = this.props.value === option.value;
            return (
              <div
                key={`option_${this.componentId}_${index}`}
                className={`${style.option} ${selected && style.selected}`}
                onClick={() => this.onOptionClick(option)}
                onKeyPress={() => {}}
                role="option"
                tabIndex="-1"
                aria-selected={selected}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default OptionSelect;
