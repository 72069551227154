import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 25,
  },

  buttonIconRight: {
    marginLeft: theme.spacing(1),
  },

  buttonIconLeft: {
    marginRight: theme.spacing(1),
  },

  actionBigScreenButton: {
    marginRight: 25,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  actionSmallScreenButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const OrderSomethingDropdown = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchor, setAnchor] = useState(null);

  const handleNotImplemented = (variant) => () => {
    enqueueSnackbar('This is not implemented yet!', { variant });
    handleClose();
  };

  function handleClick(event) {
    setAnchor(event.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  const id = 'OrderSomethingDropdown';

  return (
    <React.Fragment>
      <Button
        className={classes.actionBigScreenButton}
        variant="outlined"
        size="small"
        aria-owns={anchor ? id : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
      >
        <FontAwesomeIcon
          className={classes.buttonIconLeft}
          icon="concierge-bell"
        />
        {t('OrderSomethingDropdown.OrderSomething')}
        <FontAwesomeIcon
          className={classes.buttonIconRight}
          size="sm"
          icon={['fal', 'chevron-down']}
        />
      </Button>

      <IconButton
        className={classes.actionSmallScreenButton}
        onClick={(e) => handleClick(e)}
      >
        <FontAwesomeIcon icon="concierge-bell" />
      </IconButton>

      <Menu
        id={id}
        anchorEl={anchor}
        getContentAnchorEl={null}
        variant="menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        <List disablePadding>
          <ListItem divider button onClick={handleNotImplemented('warning')}>
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon={['fas', 'user-friends']}
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('OrderSomethingDropdown.CareVisit')}
              className={classes.textAndIconColor}
            />
          </ListItem>

          <ListItem divider button onClick={handleNotImplemented('warning')}>
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon={['fas', 'tshirt']}
                size="1x"
                className={classes.textAndIconColor}
              />{' '}
            </ListItemIcon>
            <ListItemText
              primary={t('OrderSomethingDropdown.LaundryCare')}
              className={classes.textAndIconColor}
            />
          </ListItem>

          <ListItem divider button onClick={handleNotImplemented('warning')}>
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon={['fas', 'utensils']}
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>

            <ListItemText
              primary={t('OrderSomethingDropdown.OrderFood')}
              className={classes.textAndIconColor}
            />
          </ListItem>

          <ListItem divider button onClick={handleNotImplemented('warning')}>
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon={['fas', 'car']}
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('OrderSomethingDropdown.OrderTransportation')}
              className={classes.textAndIconColor}
            />
          </ListItem>

          <ListItem divider button onClick={handleNotImplemented('warning')}>
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon={['fas', 'prescription-bottle']}
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('OrderSomethingDropdown.RefillPrescriptions')}
              className={classes.textAndIconColor}
            />
          </ListItem>

          <ListItem button onClick={handleNotImplemented('warning')}>
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon={['fas', 'shopping-bag']}
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('OrderSomethingDropdown.Shop')}
              className={classes.textAndIconColor}
            />
          </ListItem>
        </List>
      </Menu>
    </React.Fragment>
  );
};

export default OrderSomethingDropdown;
