/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from 'components/Button/IconButton';
import style from './style.module.scss';
import text from './text';

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func
  };

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  render() {
    const { data } = this.props;
    const content = data && text[data.page];
    return (
      <div
        className={[style.background, style[this.props.transition]].join(' ')}
        onClick={() => this.onClose()}
      >
        <div className={style.container} onClick={e => e.stopPropagation()}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            <div className={style.content}>
              {typeof content === 'function' ? content(data && data.contentProps) : content}
              {!content && <div>No information on this page.</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(['carerecipients', 'activeRecipient'], {});
  return { activeRecipient };
};

export default connect(mapStateToProps)(Dialog);
