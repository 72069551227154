import React from 'react';
import EmptyState from 'components/EmptyState';
import { Container, Card, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function NoCareRecipientEmptyState() {
  const { t } = useTranslation();

  return (
    <Container maxWidth={false}>
      <div style={{ height: '40vh' }}>
        <EmptyState
          variant={'horizontal'}
          style={{ display: 'flex', height: '100%' }}
          heading={t('NoCareRecipientEmptyState.NoActiveCareRecipientTitle')}
          icon={['fas', 'user-times']}
          body={t('NoCareRecipientEmptyState.NoActiveCareRecipientDescription')}
        />
      </div>

      <Card>
        <Box p={4}>
          <Typography variant="body1" component="p" gutterBottom>
            {t(
              'NoCareRecipientEmptyState.NoActiveCareRecipientWhatYouNeedToDo'
            )}
          </Typography>
        </Box>
      </Card>
    </Container>
  );
}
