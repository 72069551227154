import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import {
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Divider,
} from '@material-ui/core';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import RRule from 'rrule';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { addWellnessEvent, editWellnessEvent } from 'actions/wellness';
import {
  convertRecurrenceToJSDate,
  dateInRruleDateFormat,
  rruleDateToLocalTime,
} from 'utils/rruleWrapper';
import { getMinutes } from 'utils/date';
import Frequency from 'enums/Frequency';
import Question from './Question';
import InfoTooltip from '../../components/InfoTooltip';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
    borderTop: 'none',
  },
  reminderTimeLabel: {
    fontSize: 12,
    paddingTop: 8,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  boldText: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  center: {
    marginLeft: theme.spacing(18),
  },
  titlemargin: {
    marginBottom: theme.spacing(2),
  },
  infoTooltip: {
    paddingLeft: theme.spacing(2),
  },
  tooltipTitleContainer: {
    display: 'flex',
  },
});

function convertQuestionOptions(currentQuestion) {
  const isQuestionEdited = !!(
    currentQuestion.valuesForSaving !== undefined &&
    currentQuestion.optionsForSaving !== undefined
  );

  if (isQuestionEdited) {
    if (currentQuestion.type === 'options') {
      currentQuestion.alertCondition = [];
      Reflect.ownKeys(currentQuestion.options).forEach((optionKey) => {
        if (currentQuestion.options[optionKey] === true) {
          currentQuestion.alertCondition.push(optionKey);
        }
      });
      currentQuestion.options = currentQuestion.optionsForSaving;
      delete currentQuestion.optionsForSaving;
      delete currentQuestion.valuesForSaving;
      delete currentQuestion.values;
    }

    if (currentQuestion.type === 'values') {
      currentQuestion.alertCondition = [];
      Reflect.ownKeys(currentQuestion.values).forEach((valueKey) => {
        if (currentQuestion.values[valueKey] === true) {
          currentQuestion.alertCondition.push(Number(valueKey));
        }
      });
      currentQuestion.values = currentQuestion.valuesForSaving;
      delete currentQuestion.valuesForSaving;
      delete currentQuestion.optionsForSaving;
      delete currentQuestion.options;
    }
  }

  return currentQuestion;
}

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const data = this.props.data || {};
    const { currentStep = 1, eventData } = data;
    const edit = !!eventData;
    let out = {};
    let rule = {};
    let convertedRecurrence;
    const recurrenceTime = [];

    if (edit) {
      const checkin = Array.isArray(eventData.data)
        ? eventData.data
        : [eventData.data];
      rule = RRule.fromString(eventData.recurrence);
      const freqTranslations = [
        'YEARLY',
        'MONTHLY',
        'WEEKLY',
        'DAILY',
        'HOURLY',
        'MINUTELY',
        'SECONDLY',
      ];
      const originalRecurrence = freqTranslations[rule.origOptions.freq];

      const origTime = Array.isArray(rule.origOptions.byminute)
        ? rule.origOptions.byminute
        : [rule.origOptions.byminute];

      switch (originalRecurrence) {
        case 'DAILY':
        case 'WEEKLY':
        case 'MONTHLY':
          convertedRecurrence = Frequency.fromRecurrenceNumber(
            originalRecurrence,
            origTime.length
          );
          break;
        default:
      }

      const startDate = get(rule, 'options.dtstart');

      // Convert rrule time to Date
      origTime.map((time) =>
        recurrenceTime.push(convertRecurrenceToJSDate(startDate, time))
      );

      out = {
        ...eventData,
        wellnessData: checkin,
      };
    }

    const today = DateTime.local().startOf('day');
    const decideEndDate =
      typeof rule.origOptions !== 'undefined' &&
      typeof rule.origOptions.until !== 'undefined'
        ? rruleDateToLocalTime(rule.origOptions.until).toJSDate()
        : null;
    this.state = {
      currentStep,
      edit,
      endDate: decideEndDate,
      ongoing: decideEndDate ? false : true,
      numberOfQuestions:
        typeof out.wellnessData !== 'undefined' ? out.wellnessData.length : 1,
      out,
      recurrence:
        typeof convertedRecurrence !== 'undefined'
          ? convertedRecurrence
          : Frequency.NEVER,
      recurrenceTime,
      saving: false,
      startDate:
        typeof rule.origOptions !== 'undefined'
          ? rruleDateToLocalTime(rule.origOptions.dtstart).toJSDate()
          : today.toJSDate(),
      time_1: DateTime.local().toJSDate(),
      timezone: this.props.activeRecipient.timezone,
      title: out.title || '',
      sensorReadings: out.sensorReadings || {
        shouldIncludeHeartrateForCheckIn: false,
        shouldIncludeStepCountForCheckIn: false,
      },
      isDialogOpen: true,
      activeStep: currentStep === 1 ? 0 : currentStep,
    };
  }

  componentDidMount() {
    if (
      typeof this.state.recurrenceTime !== 'undefined' &&
      this.state.recurrenceTime.length > 0
    ) {
      this.state.recurrenceTime.map((timeValue, i) => {
        const time = `time_${i + 1}`;
        return this.setState({
          [time]: timeValue,
        });
      });
    }

    if (
      typeof this.state.question_1 === 'undefined' &&
      typeof this.state.out.wellnessData !== 'undefined'
    ) {
      if (this.state.out.wellnessData.length > 0) {
        this.state.out.data.map((questionValue, i) => {
          const question = `question_${i + 1}`;
          return this.setState({ [question]: questionValue });
        });
      }
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }

    this.setState({
      isDialogOpen: false,
      activeStep: 0,
    });
  }

  handleSave() {
    if (this.state.saving) {
      return;
    }
    this.setState({ saving: false });

    const questions = [];
    Reflect.ownKeys(this.state).forEach((key) => {
      if (/^question_\d$/.test(key)) {
        const currentQuestion = Object.assign({}, this.state[key]);
        const isQuestionEdited = !!(
          currentQuestion.valuesForSaving !== undefined &&
          currentQuestion.optionsForSaving !== undefined
        );

        if (isQuestionEdited) {
          if (currentQuestion.type === 'options') {
            currentQuestion.alertCondition = [];
            Reflect.ownKeys(currentQuestion.options).forEach((optionKey) => {
              if (currentQuestion.options[optionKey] === true) {
                currentQuestion.alertCondition.push(optionKey);
              }
            });
            currentQuestion.options = currentQuestion.optionsForSaving;
            delete currentQuestion.optionsForSaving;
            delete currentQuestion.valuesForSaving;
            delete currentQuestion.values;
          }

          if (currentQuestion.type === 'values') {
            currentQuestion.alertCondition = [];
            Reflect.ownKeys(currentQuestion.values).forEach((valueKey) => {
              if (currentQuestion.values[valueKey] === true) {
                currentQuestion.alertCondition.push(Number(valueKey));
              }
            });
            currentQuestion.values = currentQuestion.valuesForSaving;
            delete currentQuestion.valuesForSaving;
            delete currentQuestion.optionsForSaving;
            delete currentQuestion.options;
          }
        }
        questions.push(currentQuestion);
      }
    });

    const rule = this.getRrule(this.state);
    const recDescription = rule.toText();
    const trimmedQuestions = questions.slice(0, this.state.numberOfQuestions);
    const toSave = {
      active: true,
      recurrence: rule.toString(),
      ongoing: this.state.ongoing,
      recurrenceDescription: recDescription,
      timezone: this.state.timezone,
      title: this.state.title,
      userId: this.props.activeRecipient.userId,
      wellnessData: trimmedQuestions,
      sensorReadings: this.state.sensorReadings,
    };

    if (this.state.edit) {
      toSave.eventId = this.props.data.eventData.eventId;
      editWellnessEvent(this.props.dispatch, this.context.apiClient, toSave)
        .then(() =>
          this.setState({ saving: false }, () => {
            this.onClose();
            window.location.reload();
          })
        )
        .catch((err) => {
          console.log('err', err);
          this.setState({ saveError: err.error, saving: false });
        });
    }

    if (!this.state.edit) {
      addWellnessEvent(this.props.dispatch, this.context.apiClient, toSave)
        .then(() =>
          this.setState({ saving: false }, () => {
            this.onClose();
            window.location.reload();
          })
        )
        .catch((err) => {
          console.log('err', err);
          this.setState({ saveError: err.error, saving: false });
        });
    }
  }

  getRrule({ recurrence, startDate, endDate, ...others }) {
    let freq = null;
    if (/^(EVERY_DAY)/.test(recurrence)) {
      freq = RRule.DAILY;
    }
    if (/^(EVERY_WEEK)/.test(recurrence)) {
      freq = RRule.WEEKLY;
    }
    if (/^(EVERY_MONTH)/.test(recurrence)) {
      freq = RRule.MONTHLY;
    }

    const ongoing = this.state.ongoing;
    const dtstart = dateInRruleDateFormat(startDate);
    const until = !ongoing ? dateInRruleDateFormat(endDate, true) : false;
    const byminute = [];

    Reflect.ownKeys(others).forEach((key) => {
      if (/^time_\d$/.test(key)) {
        byminute.push(getMinutes(others[key]));
      }
    });

    const ruleData =
      freq === null
        ? {
            dtstart,
            byminute,
            count: 1,
          }
        : {
            freq,
            dtstart,
            byminute,
          };

    if (until) {
      ruleData.until = until;
    }

    ruleData.tzid = this.props.activeRecipient.timezone;
    return new RRule(ruleData);
  }

  handleSensorReadingOption(sensorReadingOptionName) {
    return (event) => {
      const state = { ...this.state };
      state.sensorReadings[sensorReadingOptionName] = event.target.checked;
      this.setState(state);
    };
  }

  questionUpdate(data, i) {
    this.setState({ [`question_${i}`]: data });
  }

  renderQuestions(repeatTimes) {
    const questions = [];
    for (let i = 1; i <= repeatTimes; i += 1) {
      let initialData =
        typeof this.state.out.wellnessData !== 'undefined'
          ? typeof this.state.out.wellnessData !== 'undefined'
            ? this.state.out.wellnessData[i - 1]
            : false
          : false;

      if (!initialData && this.state[`question_${i}`]) {
        initialData = convertQuestionOptions({
          ...this.state[`question_${i}`],
        });
      }

      questions.push(
        <Grid item xs={12} key={`question_grid_${i}`}>
          <Question
            key={`question_${i}`}
            orderNumber={i}
            questionUpdate={(data) => this.questionUpdate(data, i)}
            initialData={initialData}
          />
        </Grid>
      );
    }
    return questions;
  }

  renderTimeSelection() {
    const repeatTimes = Frequency.toNumber(this.state.recurrence);
    const timeSelection = [];

    for (let i = 1; i <= repeatTimes; i += 1) {
      const timeKey = `time_${i}`;
      const label = i === 1 ? 'Reminder time' : `Reminder time ${i}`;
      const value = this.state[timeKey]
        ? this.state[timeKey]
        : DateTime.local().toJSDate();

      timeSelection.push(
        <Grid item xs={12} key={timeKey}>
          <TimePicker
            keyboard
            fullWidth
            clearable
            label={label}
            onChange={(selectedTime) =>
              this.setState({ [timeKey]: selectedTime.toDate() })
            }
            value={value}
            mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
            variant="outlined"
          />
        </Grid>
      );
    }

    return timeSelection;
  }

  renderStep1() {
    const today = DateTime.local().startOf('day').toJSDate();
    const tomorrow = DateTime.local()
      .startOf('day')
      .plus({ days: 1 })
      .toJSDate();
    const { t } = this.props;
    const { title, recurrence, startDate, endDate, ongoing } = this.state;
    const displayEndDateField = recurrence && recurrence !== Frequency.NEVER;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Title"
            value={title}
            onChange={(event) => this.setState({ title: event.target.value })}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            label="Frequency"
            value={recurrence}
            onChange={(event) =>
              this.setState({ recurrence: event.target.value })
            }
            variant="outlined"
          >
            {Frequency.asList().map((frequency) => (
              <MenuItem key={frequency} value={frequency}>
                {t(frequency)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={12}>
          <DatePicker
            label="Start date"
            value={startDate}
            onChange={(date) =>
              this.setState({ startDate: date ? date.toDate() : null })
            }
            fullWidth
            keyboard
            format="MM/DD/YYYY"
            clearable
            autoOk
            mask={(value) =>
              value
                ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                : []
            }
            disableOpenOnEnter
            animateYearScrolling={false}
            minDate={today}
            variant="outlined"
          />
        </Grid>

        {displayEndDateField && !ongoing && (
          <Grid item xs={12} lg={12}>
            <DatePicker
              fullWidth
              keyboard
              clearable
              autoOk
              disableOpenOnEnter
              label="End date"
              value={endDate}
              minDate={tomorrow}
              onChange={(date) =>
                this.setState({ endDate: date ? date.toDate() : null })
              }
              format="MM/DD/YYYY"
              mask={(value) =>
                value
                  ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                  : []
              }
              animateYearScrolling={false}
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={ongoing}
                name="ongoing"
                disabled={recurrence === Frequency.NEVER}
                onChange={() =>
                  this.setState({
                    ongoing: ongoing ? false : true,
                    endDate: ongoing ? null : endDate,
                  })
                }
                value={String(ongoing)}
                color="primary"
              />
            }
            label="Ongoing reminder"
          />
        </Grid>
        {this.renderTimeSelection()}
      </Grid>
    );
  }

  renderStep2() {
    const { numberOfQuestions, saveError } = this.state;
    const handleNumberOfQuestionsUpdate = (event) => {
      let value = parseInt(event.target.value) || 1;
      if (value > 10) {
        value = 10;
      } else if (value < 1) {
        value = 1;
      }

      this.setState({ numberOfQuestions: value });
    };
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Number of Questions"
            type="number"
            value={numberOfQuestions}
            onChange={(event) => handleNumberOfQuestionsUpdate(event)}
            inputProps={{
              min: 1,
              max: 10,
              step: 1,
            }}
            variant="outlined"
          />
        </Grid>
        {this.renderQuestions(numberOfQuestions)}

        {saveError && <b>An error occurred: {saveError}</b>}
      </Grid>
    );
  }

  renderStep3() {
    const { sensorReadings } = this.state;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            Check any additional data you would like included with your Care
            Recipient’s responses:
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={this.handleSensorReadingOption(
                    'shouldIncludeHeartrateForCheckIn'
                  )}
                  checked={sensorReadings.shouldIncludeHeartrateForCheckIn}
                />
              }
              label="Average heart rate at check-in time"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={this.handleSensorReadingOption(
                    'shouldIncludeStepCountForCheckIn'
                  )}
                  checked={sensorReadings.shouldIncludeStepCountForCheckIn}
                />
              }
              label="Total step count at check-in time"
            />
          </FormGroup>
        </Grid>
      </Grid>
    );
  }

  getSteps() {
    return ['Timing', 'Questions', 'Additional data'];
  }

  getStepTitles() {
    return ['Timing', 'Identify the questions', 'Additional data'];
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return this.renderStep1();
      case 1:
        return this.renderStep2();
      case 2:
        return this.renderStep3();
      default:
        return 'Unkown stepIndex';
    }
  }

  isOnLastStep() {
    return this.state.activeStep === this.getSteps().length - 1;
  }

  setActiveStep(newStep) {
    this.setState({ activeStep: newStep });
  }

  handleNextStep() {
    if (this.isOnLastStep()) {
      return this.handleSave();
    }

    const { activeStep } = this.state;
    this.setActiveStep(activeStep + 1);
  }

  handleBackStep() {
    const { activeStep } = this.state;
    this.setActiveStep(activeStep - 1);
  }

  isFormValid() {
    const { title, activeStep, question_1 } = this.state;
    return (
      (activeStep === 0 && title.trim().length > 1) ||
      (activeStep === 1 && question_1 && question_1.text.trim().length > 1) ||
      activeStep === 2
    );
  }

  render() {
    const { isDialogOpen, activeStep } = this.state;
    const { classes, t } = this.props;
    const steps = this.getSteps();
    const stepTitles = this.getStepTitles();
    const dialogTitle = `Wellness Check`;

    return (
      <MuiDialog
        open={isDialogOpen}
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MuiDialogTitle disableTypography className={classes.root}>
          <div className={classes.tooltipTitleContainer}>
            <Typography variant="h6">{dialogTitle}</Typography>
            <InfoTooltip
              translationKey={'WellnessCheck.Tooltip'}
              className={classes.infoTooltip}
              translationOptions={{
                name: this.props.activeRecipient.name.first,
              }}
            />
          </div>
          <div className={classes.titlemargin}>
            <Typography variant="subtitle1">
              {t('Reminder.EventWillBeCreatedInCareRecipientTimezone')}
            </Typography>
            <Tooltip
              title={DateTime.local()
                .setZone(this.state.timezone)
                .setLocale('en-US')
                .toFormat('ZZZZZ')}
            >
              <Typography align="center" variant="caption">
                (UTC
                {DateTime.local()
                  .setZone(this.state.timezone)
                  .setLocale('en-US')
                  .toFormat('ZZ z')}{' '}
                )
              </Typography>
            </Tooltip>
          </div>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={() => this.onClose()}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Divider />
        <Typography variant="subtitle1" className={classes.boldText}>
          Step {activeStep + 1} - {stepTitles[activeStep]}
        </Typography>
        <DialogContent className={classes.content} dividers>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid container spacing={3}>
            {activeStep === steps.length ? (
              <Grid item xs={12}>
                <Typography>New reminder created!</Typography>
                <Button
                  onClick={() => this.onClose()}
                  variant="contained"
                  color="default"
                  className={classes.stepperButton}
                >
                  {t('CloseButton')}
                </Button>
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item xs={12}>
                  {this.getStepContent(activeStep)}
                </Grid>
                {activeStep !== 0 && (
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      disabled={activeStep === 0}
                      onClick={() => this.handleBackStep()}
                      variant="contained"
                      color="default"
                    >
                      {t('BackButton')}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    disabled={!this.isFormValid()}
                    onClick={() => this.handleNextStep()}
                    variant="contained"
                    className={
                      activeStep === 0 && activeStep !== steps.length - 1
                        ? classes.center
                        : ''
                    }
                    color="primary"
                  >
                    {activeStep === steps.length - 1
                      ? t('SaveButton')
                      : t('NextButton')}
                  </Button>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </DialogContent>
      </MuiDialog>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(
    ['carerecipients', 'activeRecipient'],
    {}
  );
  return { activeRecipient };
};

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(Dialog))
);
