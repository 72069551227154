export default function getOrDefault(attemptGet, defaultValue) {
  try {
    const resultingValue = attemptGet();

    if (resultingValue === undefined) {
      return defaultValue;
    }

    return resultingValue;
  } catch (e) {
    return defaultValue;
  }
}
