import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoleapthru from 'assets/brand-logos/logoleapthru.svg';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import TopAppBarLine from 'components/PageNavigation/TopAppBarLine';
import { shallowEqual, useSelector } from 'react-redux';
import getOrDefault from 'utils/safe';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginLeft: -1,
    marginRight: 20,
    color: '#333',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  leapThruLogoImage: {
    width: 140,
  },

  list: {
    width: 250,
  },
}));

const TopAppBarDrawer = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const currentUser = useSelector(
    (state) => state.getIn(['user', 'self']),
    shallowEqual
  );

  const userGroup = getOrDefault(() => currentUser.userGroup, '');
  const isIBX = userGroup.toLowerCase() === 'ibx';
  const url = isIBX ? 'https://image.leapthru.com/image/ibx' : logoleapthru;

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const drawerItems = props.menuItems.map((item, index) => (
    <ListItem key={index} button component={Link} to={item.destination}>
      <ListItemIcon>
        <FontAwesomeIcon icon={item.icon} size="lg" />
      </ListItemIcon>
      <ListItemText primary={item.name} />
    </ListItem>
  ));

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <TopAppBarLine />

      <Toolbar onClick={toggleDrawer('left', true)}>
        <img className={classes.leapThruLogoImage} src={url} alt="LeapThru" />
      </Toolbar>

      <Divider />

      <List>{drawerItems}</List>
    </div>
  );

  return (
    <div>
      <IconButton
        onClick={toggleDrawer('left', true)}
        className={classes.menuButton}
        aria-label="Menu"
      >
        <FontAwesomeIcon icon={['fal', 'bars']} size="sm" />
      </IconButton>
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        {sideList('left')}
      </Drawer>
    </div>
  );
};

export default TopAppBarDrawer;
