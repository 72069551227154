/* eslint-disable */

/* global google */
import React from 'react';
import { compose, withProps } from 'recompose';
import get from 'lodash.get';
import { withScriptjs, withGoogleMap, GoogleMap as RGoogleMap } from 'react-google-maps';

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%', flexGrow: '1' }} />,
    mapElement: <div style={{ height: '100%' }} />,
    defaultOptions: {
      streetViewControl: false,
      mapTypeControl: true,
    }
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const centerCoords = get(props, 'center', { lat: 0, lng: 0 });
  const center = new google.maps.LatLng(centerCoords.lat, centerCoords.lng);
  const extraProps = {};
  if (props.getMap) {
    extraProps.ref = (map) => {
      props.getMap(map);
    };
  }
  return (
    <RGoogleMap
      {...props}
      defaultZoom={props.defaultZoom || 16}
      defaultCenter={center}
      {...extraProps}
    >
      {props.children}
    </RGoogleMap>
  );
});
