import React from 'react';
import {
  makeStyles,
  Grid,
  DialogContent,
  Divider,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  UserLocationForm,
  initialUserLocationFormValues,
} from './UserLocationForm';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  addUserLocation,
  getUserLocations,
  updateUserLocation,
} from 'actions/location';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexWrap: 'wrap',
    display: 'flex',
  },
  stepper: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function Dialog(props, context) {
  const data = props.data || {};
  const { eventData } = data;
  const edit = !!eventData;

  const formState = {
    location: { ...initialUserLocationFormValues },
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const { apiClient } = context;
  const [open, setOpen] = React.useState(true);
  const activeCareRecipient = useSelector(
    (state) => state.getIn(['carerecipients', 'activeRecipient']),
    shallowEqual
  );

  if (open) {
    getUserLocations(dispatch, apiClient, activeCareRecipient.userId);
  }

  if (edit) {
    formState.location.locationName = eventData.title;
    formState.location.locationCoords = {
      lat: eventData.latitude,
      lng: eventData.longitude,
    };
    formState.location.locationAddress = eventData.adressTitle;
    formState.location.adressFull = eventData.adressFull;
  }

  const [userLocation, setUserLocation] = React.useState(formState);

  React.useEffect(() => {
    document.body.style.overflow = 'auto';
  });

  function handleCloseDialog() {
    setOpen(false);
    if (data.onClose) {
      data.onClose();
    }
  }

  function handleSetUserLocation(submittedUserLocation) {
    setUserLocation({
      ...userLocation,
      location: submittedUserLocation,
    });
  }

  function handleOnSaveClick() {
    const toSave = {
      title: userLocation.location.locationName,
      latitude: userLocation.location.locationCoords.lat,
      longitude: userLocation.location.locationCoords.lng,
      adressTitle: userLocation.location.locationAddress,
      adressFull: userLocation.location.locationAddressFull,
      userId: activeCareRecipient.userId,
    };

    if (edit) {
      toSave.userLocationId = eventData.userLocationId;
      updateUserLocation(dispatch, apiClient, toSave).then(() => {
        handleCloseDialog();
        window.location.reload();
      });
    } else {
      addUserLocation(dispatch, apiClient, toSave).then(() => {
        handleCloseDialog();
        window.location.reload();
      });
    }
  }

  return (
    <MuiDialog open={open} onClose={handleCloseDialog}>
      <MuiDialogTitle id="alert-dialog-title">New Location</MuiDialogTitle>
      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={handleCloseDialog}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.root}>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <UserLocationForm
            location={userLocation.location}
            onSetUserLocation={handleSetUserLocation}
            onLocationSave={handleOnSaveClick}
          />
        </Grid>
      </DialogContent>
    </MuiDialog>
  );
}

Dialog.contextTypes = {
  apiClient: PropTypes.object.isRequired,
};

export default Dialog;
