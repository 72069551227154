import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Variants = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export default function EmptyState({
  variant = Variants.HORIZONTAL,
  icon,
  heading,
  body,
  children,
}) {
  const flexDirection = variant === Variants.HORIZONTAL ? 'row' : 'column';
  const marginLeft = variant === Variants.HORIZONTAL ? 6 : 0;

  return (
    <Box
      display="flex"
      alignSelf="center"
      flexDirection={flexDirection}
      justifyContent="center"
      minHeight="100%"
      textAlign="center"
    >
      {icon ? (
        <Box
          display="flex"
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
          color="primary.dark"
          bgcolor="primary.light"
          borderRadius="100%"
          padding={4}
          style={{ height: 160, width: 160 }}
          marginBottom={4}
        >
          <FontAwesomeIcon icon={icon} size="6x" />
        </Box>
      ) : null}

      <Box
        marginLeft={marginLeft}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="100%"
        textAlign="center"
      >
        {heading ? (
          <Typography variant="h5" gutterBottom>
            {heading}
          </Typography>
        ) : null}

        {body ? (
          <Typography variant="subtitle1" gutterBottom>
            {body}
          </Typography>
        ) : null}

        <div>{children}</div>
      </Box>
    </Box>
  );
}

EmptyState.propTypes = {
  variant: PropTypes.oneOf([Variants.HORIZONTAL, Variants.VERTICAL]),
  icon: PropTypes.array,
  heading: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.node,
};
