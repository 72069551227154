import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import 'isomorphic-fetch';
import fontawesomeIcons from './icons';
import App from './App';

import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { fromJS } from 'immutable';
import cookie from 'cookie';
import { configureStore } from 'store';

library.add(fontawesomeIcons);

const cookies = cookie.parse(document.cookie);
const store = configureStore(fromJS({ cookie: cookies }));

const render = (Component) =>
  ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById('root')
  );

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
  });
}
