import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  TextField,
  InputAdornment,
  Button,
  FormControlLabel,
  Switch,
  MenuItem,
  IconButton,
  Chip,
} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import AvatarMapMarker from 'components/GoogleMap/AvatarMapMarker';
import { getCoordsByAddress } from 'actions/geocode';
import { useDebounce } from 'hooks/useDebounce';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addUserLocation } from 'actions/location';
import { useLocationsForUser } from 'reducers/location';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import User from 'entities/user';

const useStyles = makeStyles((theme) => ({
  buttonElement: {
    width: '50%',
  },
  centeredGridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  searchIcon: {
    fontSize: '1.5rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  switch: {
    marginBottom: theme.spacing(1),
  },
}));

function DestinationCheckLocationForm(
  { location, onLocationSubmitted = () => {} },
  context
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [values, setValues] = useState(location);
  const [shouldMapUpdate, setShouldMapUpdate] = useState(false); // eslint-disable-line
  const { apiClient } = context;
  const activeCareRecipient = useSelector(
    (state) => state.getIn(['carerecipients', 'activeRecipient']),
    shallowEqual
  );
  const userLocations = useLocationsForUser(activeCareRecipient);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setValues((state) => ({ ...state, [name]: value }));
  };

  const [searchedLocation, setSearchedLocation] = React.useState(
    values.locationAddress
  );
  const debouncedSearchLocation = useDebounce(searchedLocation, 1000);

  React.useEffect(() => {
    if (debouncedSearchLocation.length >= 3) {
      getCoordsByAddress(dispatch, apiClient, debouncedSearchLocation).then(
        (value) => {
          if (!value || !value.geometry) {
            return;
          }
          const { geometry } = value;

          setValues((state) => ({
            ...state,
            locationAddress: debouncedSearchLocation,
            locationAddressFull: value.address,
            locationCoords: geometry.location,
          }));
          setShouldMapUpdate((state) => ({
            ...state,
            shouldMapUpdate: true,
          }));
        }
      );
    }
  }, [debouncedSearchLocation, dispatch, apiClient]);

  React.useEffect(() => {
    if (values.savedLocations) {
      getCoordsByAddress(
        dispatch,
        apiClient,
        values.savedLocations.adressFull
      ).then((value) => {
        if (!value || !value.geometry) {
          return;
        }
        const { geometry } = value;

        setValues((state) => ({
          ...state,
          locationAddress: values.savedLocations.adressTitle,
          locationCoords: geometry.location,
        }));
        setShouldMapUpdate((state) => ({
          ...state,
          shouldMapUpdate: true,
        }));
      });
    }
  }, [dispatch, apiClient, values.savedLocations]);

  React.useEffect(() => {
    setSearchedLocation(values.locationAddress);
  }, [values.locationAddress]);

  const handleLocationChange = (event) => {
    setSearchedLocation(event.target.value);
  };

  const handleSaveLocationIconButtonClick = () => {
    if (values.locationName.length > 0 && values.locationAddress.length > 1) {
      const toSave = {
        title: values.locationName,
        latitude: values.locationCoords.lat,
        longitude: values.locationCoords.lng,
        adressTitle: values.locationAddress,
        adressFull: values.locationAddressFull,
        userId: activeCareRecipient.userId,
      };

      addUserLocation(dispatch, apiClient, toSave).then(() =>
        enqueueSnackbar('Location saved!', 'success')
      );
    }
  };

  function handleOnSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    onLocationSubmitted(values);
  }

  function handleChipDelete() {
    setValues((state) => ({
      ...state,
      savedLocations: null,
      locationAddress: '',
      locationCoords: { lat: 0, lng: 0 },
    }));
  }

  function isDestinationCheckLocationFormValid() {
    if (values.showAddLocationField) {
      return values.locationName.length > 0;
    }
    return values.title.length > 1 && values.locationAddress.length > 1;
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Title"
            value={values.title}
            name="title"
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            label="Locations"
            value={values.savedLocations}
            name="savedLocations"
            onChange={handleChange}
            helperText="Select from saved locations"
            variant="outlined"
            fullWidth
          >
            {userLocations.map((option) => (
              <MenuItem key={option.title} value={option}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {values.savedLocations && (
          <Grid item xs={12}>
            <Chip
              label={values.savedLocations.adressFull}
              onDelete={handleChipDelete}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            required
            label="Location Address"
            placeholder="Type to search..."
            value={searchedLocation}
            name="locationAddress"
            onChange={handleLocationChange}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    className={classes.searchIcon}
                    icon={['fas', 'search-location']}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {!values.savedLocations && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  className={classes.switch}
                  checked={values.showAddLocationField}
                  onChange={handleChange}
                  name="showAddLocationField"
                  color="primary"
                  value="locationCheck"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Save to locations"
            />
            {values.showAddLocationField === true && (
              <Grid item xs={12}>
                <TextField
                  required
                  label="Location name"
                  value={values.locationName}
                  name="locationName"
                  helperText="Save address to your locations"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          onClick={handleSaveLocationIconButtonClick}
                        >
                          <FontAwesomeIcon icon={['fas', 'plus']} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <div style={{ height: '200px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
              defaultZoom={13}
              center={
                values.locationCoords.lat !== 0
                  ? { ...values.locationCoords }
                  : { ...values.center }
              }
              draggable={true}
            >
              {values.locationCoords.lat !== 0 && (
                <AvatarMapMarker
                  lat={values.locationCoords.lat}
                  lng={values.locationCoords.lng}
                />
              )}
            </GoogleMapReact>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.centeredGridItem}>
          <Button
            className={classes.buttonElement}
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isDestinationCheckLocationFormValid()}
            onClick={handleOnSubmit}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

DestinationCheckLocationForm.contextTypes = {
  apiClient: PropTypes.object.isRequired,
};

const activeCareRecipient = (state) =>
  state.getIn(['carerecipients', 'activeRecipient']);
const user = User(activeCareRecipient);
let lat = 56.1822097;
let lng = 15.6033117;

if (user.location.lat.length > 0) {
  lat = user.location.lat;
  lng = user.location.lng;
}

const initialDestinationCheckLocationFormValues = Object.freeze({
  title: '',
  savedLocations: '',
  locationName: '',
  locationAddress: '',
  locationAddressFull: '',
  locationCoords: {
    lat: 0,
    lng: 0,
  },
  center: {
    lat,
    lng,
  },
  showAddLocationField: false,
});

export {
  initialDestinationCheckLocationFormValues,
  DestinationCheckLocationForm,
};
