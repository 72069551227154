/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import set from 'lodash.set';
import get from 'lodash.get';

import { create } from 'actions/coupons';

import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Title from 'components/Title';
import Field from 'components/DialogField';

import style from './style.module.scss';

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
    hasPermission: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      out: { ...props.data.user }
    };
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onSaveClick() {
    const { out, saving } = this.state;
    if (!saving) {
      this.setState({ saving: true });
      create(this.props.dispatch, this.context.apiClient, out)
        .then(() => this.setState({ saving: false }, () => this.onClose()))
        .catch(err => this.setState({ saveError: err.error, saving: false }));
    }
  }

  setOutValue(key, val) {
    this.setState({ out: set(this.state.out, key, val) });
  }

  renderField(type, outKey, label, props, defaultValue) {
    return (
      <div className={style.field} key={`field_${outKey}`}>
        <div className={style.label}>{label}</div>
        <Field
          type={type}
          value={get(this.state.out, outKey, defaultValue) || ''}
          onChange={event => this.setOutValue(outKey, get(event, 'target.value', event))}
          {...props}
        />
      </div>
    );
  }

  render() {
    const { saveError } = this.state;
    return (
      <div className={[style.background, style[this.props.transition]].join(' ')}>
        <div className={style.container} onClick={e => e.stopPropagation()}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            <div className={style.content}>
              <Title>Generate coupon</Title>
              {this.renderField('text', 'group', 'Group (company name)')}
              {this.renderField('number', 'numberOfCoupons', 'Quantitiy  of coupons')}
              {this.renderField(
                'number',
                'offerDurationDays',
                'How many days this coupon gives an account'
              )}
              {saveError && <div className={style.saveError}>An error occurred: {saveError}</div>}
            </div>
            <div>
              <Button className={style.nextButton} onClick={() => this.onSaveClick()}>
                GENERATE
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Dialog);
