import { fromJS } from 'immutable';
import moment from 'moment-timezone';
import { useSelector, shallowEqual } from 'react-redux';
import getOrDefault from 'utils/safe';

const INITIAL_STATE = fromJS({
  items: {},
  timeline: {},
  alerts: {},
  userEvents: {},
});

export function merge(state, item) {
  const arr = Array.isArray(item) ? item : [item];
  return state.mergeIn(
    ['items'],
    fromJS(arr.reduce((obj, i) => ({ ...obj, [i.id]: i }), {}))
  );
}

export function setEventItems(state, payload, userId, from, to) {
  // const data = state.getIn(['timeline', userId], []).reduce((obj, e) => {
  //   obj[e.eventId] = e; // eslint-disable-line no-param-reassign
  //   return obj;
  // }, {});
  // const newData = payload.reduce((obj, e) => {
  //   obj[e.eventId] = e; // eslint-disable-line no-param-reassign
  //   return obj;
  // }, {});

  // return state.setIn(['timeline', userId], Object.values({ ...data, ...newData }));

  const data = state.getIn(['timeline', userId], []);

  let events;
  if (data.length > 0 && (from || to)) {
    events = data.filter((event) => {
      let out = false;
      if (from && moment(event.eventTime).isBefore(from)) {
        out = true;
      } else if (to && moment(event.eventTime).isAfter(to)) {
        out = true;
      }

      return out;
    });
  } else {
    events = data;
  }

  const newEvents = events.concat(payload).sort((a, b) => {
    if (moment(a.eventTime).isBefore(moment(b.eventTime))) {
      return -1;
    }
    return 1;
  });

  return state.setIn(['timeline', userId], newEvents);
}

export function setEventItemsMulti(state, multipayload) {
  let outState = state;

  for (let a = 0; a < multipayload.length; a += 1) {
    const { payload, userId, from, to } = multipayload[a];

    outState = setEventItems(outState, payload, userId, from, to);
  }

  return outState;
}

export function setAlertItems(state, payload, userId) {
  return state.setIn(['alerts', userId], payload);
}

export function setUserEvents(state, payload, userId) {
  return state.setIn(['userEvents', userId], payload);
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'EVENT_SET':
      return merge(state, action.payload);
    case 'EVENT_SET_TIMELINE':
      return setEventItems(
        state,
        action.payload,
        action.userId,
        action.from,
        action.to
      );
    case 'EVENT_SET_TIMELINE_MULTI':
      return setEventItemsMulti(state, action.payload);
    case 'ALERT_SET':
      return setAlertItems(state, action.payload, action.userId);
    case 'USER_EVENTS_SET':
      return setUserEvents(state, action.payload, action.userId);
    default:
      return state;
  }
};

export function filterTimelineEvents(events, filter) {
  if (!filter) {
    return events;
  }
  return events.filter((item) => {
    let out = true;

    if (filter.type) {
      if (Array.isArray(filter.type)) {
        if (!filter.type.includes(item.type)) {
          out = false;
        }
      } else if (filter.type !== item.type) {
        out = false;
      }
    }

    if (out && filter.state) {
      if (Array.isArray(filter.state)) {
        if (!filter.state.includes(item.state)) {
          out = false;
        }
      } else if (filter.state !== item.state) {
        out = false;
      }
    }

    if (
      out &&
      filter.from &&
      moment(item.eventTime).isBefore(moment(filter.from))
    ) {
      out = false;
    }
    if (out && filter.to && moment(item.eventTime).isAfter(moment(filter.to))) {
      out = false;
    }
    if (out && filter.calendarId) {
      if (Array.isArray(filter.calendarId)) {
        if (!filter.calendarId.includes(item.calendarId)) {
          out = false;
        }
      } else if (filter.calendarId !== item.calendarId) {
        out = false;
      }
    }
    if (out && filter.latestCalendarId) {
      if (Array.isArray(filter.latestCalendarId)) {
        if (!filter.latestCalendarId.includes(item.latestCalendarId)) {
          out = false;
        }
      } else if (filter.latestCalendarId !== item.latestCalendarId) {
        out = false;
      }
    }

    return out;
  });
}

export function filterTimeline(state, userId, filter) {
  const outData = state.getIn(['event', 'timeline', userId], []);

  if (filter) {
    return filterTimelineEvents(outData, filter);
  }

  return outData;
}

export const useEventTimelineSelectorForUser = (user) => {
  const userId = getOrDefault(() => user.userId, null);

  return useSelector((state) => {
    const timelineEvents = state.getIn(['event', 'timeline', userId], []);

    const NEED_TO_ADD_FILTERS_LATER = [
      'general',
      'medicine',
      'location',
      'wellness',
      'refill',
    ];

    return filterTimelineEvents(timelineEvents, NEED_TO_ADD_FILTERS_LATER);
  }, shallowEqual);
};

export const filterActiveCalendarEvents = (groupedEvents) => {
  const filteredEvents = [];
  groupedEvents.forEach((groupedEvent) => {
    if (groupedEvent.calendar) {
      if (groupedEvent.calendar.active) {
        filteredEvents.push(groupedEvent);
      }
    } else if (groupedEvent.events) {
      const activeEventsInGroup = [];
      groupedEvent.events.forEach((event) => {
        if (event.calendar) {
          if (event.calendar.active) {
            activeEventsInGroup.push(event);
          }
        }
      });
      if (activeEventsInGroup.length) {
        filteredEvents.push({
          ...groupedEvent,
          events: activeEventsInGroup,
        });
      }
    }
  });
  return filteredEvents;
};
