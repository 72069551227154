import React from 'react';
import TopAppBar from 'components/PageNavigation/TopAppBar';
import BottomAppBar from 'components/PageNavigation/BottomAppBar';
import { useActiveCareRecipientSelector } from 'reducers/carerecipients';

const PageNavigationContainer = () => {
  const activeCareRecipient = useActiveCareRecipientSelector();
  const hasActiveCareRecipient = activeCareRecipient.userId !== null;

  return (
    <React.Fragment>
      <TopAppBar />
      {hasActiveCareRecipient && <BottomAppBar />}
    </React.Fragment>
  );
};

export default PageNavigationContainer;
