import { fromJS } from 'immutable';
import { shallowEqual, useSelector } from 'react-redux';

const INITIAL_STATE = fromJS({
  calendarData: {},
  newDataAvailable: false,
});

function clearCalendarUser(state, userId) {
  const calendarData = state.get('calendarData');
  const newCalendarData = calendarData.set(userId, []);
  return state.set('calendarData', newCalendarData);
}

function setCalendarData(state, inData, userId) {
  let workState = state;

  if (userId) {
    workState = clearCalendarUser(workState, userId);
  }

  const calendarData = workState.get('calendarData');
  const newData = inData.reduce((data, element) => {
    if (element && element.userId) {
      let userCalendarData = data.get(element.userId);
      if (!userCalendarData) {
        userCalendarData = [];
      }
      userCalendarData.push(element);
      return data.set(element.userId, userCalendarData);
    }
    return data;
  }, calendarData);

  return workState.set('calendarData', newData);
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CALENDAR_SET':
      return setCalendarData(state, action.payload, action.userId);
    case 'CALENDAR_CLEAR_USER':
      return clearCalendarUser(state, action.userId);
    case 'FETCH_CALENDAR_DATA':
      return state.set('newDataAvailable', !state.get('newDataAvailable'));
    default:
      return state;
  }
};

export function filterCalendar(state, userId, type, removed) {
  let outData = state.getIn(['calendar', 'calendarData', userId], null);
  if (!outData) {
    return null;
  }

  if (type) {
    outData = outData.filter((item) => item.type === type);
  }
  if (typeof removed !== 'undefined') {
    outData = outData.filter((item) => item.removed === removed);
  }

  return outData;
}

export const useCalendarFilterSelector = (userId, filterFunction = () => {}) =>
  useSelector(
    (state) =>
      state
        .getIn(['calendar', 'calendarData', userId], [])
        .filter(filterFunction),
    (current, previous) =>
      current.newDataAvailable !== previous.newDataAvailable
  );

export const useNewDataAvailableSelector = () =>
  useSelector(
    (state) => state.getIn(['calendar', 'newDataAvailable']),
    shallowEqual
  );
