/* eslint-disable */

import moment from 'moment-timezone';
import timezoneOptions from './timezoneOptionsList.json';

const now = moment();

export const MONTH_DAY_FORMAT = 'MMMM DD';
export const MONTH_YEAR_FORMAT = 'MMM YYYY';
export const HOUR_MINUTE_FORMAT = 'hh:mm A';

export const HOUR = 'Hour';
export const DAY = 'Day';
export const WEEK = 'Week';
export const MONTH = 'Month';
export const YEAR = 'Year';

export const getCurrentWeek = () => {
  moment.locale('en-US');
  return [0, 1, 2, 3, 4, 5, 6].map(d =>
    moment(now)
      .clone()
      .startOf('week')
      .add(d, 'd'));
};

export const getWeek = (date) => {
  const start = moment(date).startOf('week');
  return Array.from(Array(7), (_, index) =>
    start
      .clone()
      .startOf('day')
      .add(index, 'days'));
};

export const getMonth = (date) => {
  const start = moment(date).startOf('month');
  return Array.from(Array(start.daysInMonth()), (_, index) =>
    start
      .clone()
      .startOf('day')
      .add(index, 'days'));
};

export const getDaysInMonth = date =>
  Array.from(Array(moment(date).daysInMonth()))
    .map(Number.call, Number)
    .map(d =>
      moment(date)
        .clone()
        .startOf('month')
        .add(d, 'd'));

export const getEndOfHour = date =>
  moment(date)
    .endOf('hour');

export const getStartOfDay = date =>
  moment(date)
    .startOf('day');

export const getEndOfDay = date =>
  moment(date)
    .endOf('day');

export const getEndOfCurrentDay = () =>
  getEndOfDay(now);

export const getStartOfMonth = date =>
  moment(date)
    .startOf('month');

export const getEndOfMonth = date =>
  moment(date)
    .endOf('month');

export const getEndOfCurrentMonth = () =>
  getEndOfMonth(now);

export const getStartOfYear = date =>
  moment(date)
    .startOf('year');

export const getEndOfYear = date =>
  moment(date)
    .endOf('year');

export const getMomentOneYearAgo = date =>
  moment(date)
    .subtract(1, 'years');

export const getNow = () =>
  moment(now);

let timezones = null;
export const getTimezones = () => {
  if (!timezones) {
    timezones = timezoneOptions.map(tz => tz.value);
  }
  return timezones;
};
export const getTimezoneOptions = () => timezoneOptions;

export const getTimezoneLabel = (crTimezone) => getTimezoneOptions().filter(function (timezone) {
  return timezone.value === crTimezone;
});

export const getMinutes = (time) => {
  const midnight = moment(time).startOf('day');
  const minutes = moment(time).diff(midnight, 'minutes');
  return minutes;
};

export const getTimeByTimezone = (time, timezone) =>
  timezone ? moment(time).tz(timezone) : moment(time);

// used mainly when displaying times so we can take dst into account
export const toToday = time =>
  moment(time).set({
    year: now.year(),
    month: now.month(),
    date: now.date()
  });

export const get12HourString = (hour) => {
  const hourIn12HourClock = hour > 12 ? hour - 12 : hour;
  const suffix = hour > 12 ? 'pm' : 'am';
  return hourIn12HourClock > 9 ? `${hourIn12HourClock} ${suffix}` : `0${hourIn12HourClock} ${suffix}`;
};

export default {};
