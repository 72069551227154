export const Actions = Object.freeze({
  ACTIVE_ALERTS_SET: 'ACTIVE_ALERTS_SET',
  USER_EVENTS_SET: 'USER_EVENTS_SET',
  NEW_USER_EVENT_DATA: 'NEW_USER_EVENT_DATA',
});

export const getAlertsForUser = (dispatch, apiClient, userId) => {
  if (!userId) {
    return;
  }

  apiClient
    .call({
      endpoint: 'event/getAlerts',
      data: {
        careRecipientId: userId,
        status: ['open', 'attendingto'],
      },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      dispatch({ type: 'ACTIVE_ALERTS_SET', payload: res.data, userId });
    })
    .catch((err) => {
      console.log('Failed to get alerts: ', err);
    });
};

export const getEventsTimeline = (dispatch, apiClient, userId, from, to) => {
  if (!userId) {
    return Promise.resolve();
  }

  const data = {
    crId: userId,
    from,
    to,
  };

  return apiClient
    .call({ endpoint: 'event/listUserEvents', data })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      dispatch({ type: 'USER_EVENTS_SET', payload: res.data, userId });
    })
    .catch((err) => {
      console.log('Failed to get userEvents: ', err);
    });
};

export const resolveAlert = (
  dispatch,
  apiClient,
  alertResolveId,
  eventTime,
  resolveAction,
  note
) => {
  if (!alertResolveId || !resolveAction || !eventTime) {
    console.log('Missing input parameter to resolve an alert');
    return Promise.resolve();
  }

  return apiClient
    .call({
      endpoint: 'event/resolveAlert',
      data: { id: alertResolveId, status: resolveAction, note: note },
    })
    .then((res) => {
      if (res.status !== 200) {
        console.log('Failed to resolve alert: ', res);
        return;
      }
      dispatch({ type: Actions.NEW_USER_EVENT_DATA });
    });
};
