import { Actions } from 'actions/userevents';
import { shallowEqual, useSelector } from 'react-redux';
import { UserEventTypes } from 'core/EventTypes';
import { DateTime } from 'luxon';
import { createGrouper } from 'utils/Grouper';

const INITIAL_STATE = {
  newDataAvailable: false,
  alerts: [],
  events: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.ACTIVE_ALERTS_SET:
      return { ...state, alerts: action.payload };
    case Actions.USER_EVENTS_SET:
      return { ...state, events: action.payload };
    case Actions.NEW_USER_EVENT_DATA:
      return {
        ...state,
        newDataAvailable: !state.newDataAvailable,
      };
    default:
      return state;
  }
};

export const useUserEventSelector = () =>
  useSelector((state) => state.getIn(['userevents', 'events']), shallowEqual);

export const useAlertsSelector = () =>
  useSelector((state) => state.getIn(['userevents', 'alerts']), shallowEqual);

export const selectEventsAfterNow = (events = []) => {
  const now = DateTime.local();
  return events.filter((event) => DateTime.fromISO(event.eventTime) >= now);
};

export const sortEventsChronologically = (events = []) => {
  return []
    .concat(events)
    .sort(
      (a, b) =>
        DateTime.fromISO(a.timeOfEvent) - DateTime.fromISO(b.timeOfEvent)
    );
};

export const useGroupedUserEventSelector = () => {
  const groupingFields = (item) => [item.type, item.timeOfEvent];
  const groupByTypeAndEventTime = createGrouper(groupingFields);

  const groupEvents = groupByTypeAndEventTime((events, [type, timeOfEvent]) => {
    return {
      type,
      timeOfEvent,
      events,
    };
  });

  return useSelector(
    (state) =>
      groupEvents(
        sortEventsChronologically(
          selectEventsAfterNow(state.getIn(['userevents', 'events'], []))
        )
      ),
    shallowEqual
  );
};

export const useUserEventNewDataSelector = () =>
  useSelector((state) => state.getIn(['userevents', 'newDataAvailable']));

const medicationWidgetStatuses = ['missed', 'confirmed', 'declined'];

export const useMedicationsSelector = () =>
  useSelector(
    (state) =>
      state
        .getIn(['userevents', 'events'])
        .filter(
          (item) =>
            item.type === UserEventTypes.MEDICINE_INTAKE_REMINDER &&
            medicationWidgetStatuses.includes(item.state)
        ),
    shallowEqual
  );
