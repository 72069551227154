import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import RRule from 'rrule';
import { DateTime } from 'luxon';
import {
  convertRecurrenceToJSDate,
  dateInRruleDateFormat,
  rruleDateToLocalTime,
} from 'utils/rruleWrapper';
import { getMinutes } from 'utils/date';
import { addGeneralReminder, editGeneralReminder } from 'actions/calendar';
import Frequency from 'enums/Frequency';
import GeneralReminderForm from './GeneralReminderForm';
import { withStyles } from '@material-ui/core/styles';
import InfoTooltip from '../../components/InfoTooltip';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titlemargin: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  infoTooltip: {
    margin: theme.spacing(2, 2, 2, 0),
  },
  tooltipTitleContainer: {
    display: 'flex',
  },
});

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const data = this.props.data || {};
    const { eventData, userObject } = data;
    const edit = !!eventData;
    const isFromCareGiverCalendar = !!this.props.data.isFromCareGiverCalendar;
    let out = {};
    let rule = {};
    let convertedRecurrence;
    const recurrenceTime = [];

    if (edit) {
      const reminder = Array.isArray(eventData.data)
        ? eventData.data
        : [eventData.data];
      rule = RRule.fromString(eventData.recurrence);
      const freqTranslations = [
        'YEARLY',
        'MONTHLY',
        'WEEKLY',
        'DAILY',
        'HOURLY',
        'MINUTELY',
        'SECONDLY',
      ];
      const originalRecurrence = freqTranslations[rule.origOptions.freq];

      const origTime = Array.isArray(rule.origOptions.byminute)
        ? rule.origOptions.byminute
        : [rule.origOptions.byminute];

      switch (originalRecurrence) {
        case 'DAILY':
        case 'WEEKLY':
        case 'MONTHLY':
          convertedRecurrence = Frequency.fromRecurrenceNumber(
            originalRecurrence,
            origTime.length
          );
          break;
        default:
      }

      const startDate = get(rule, 'options.dtstart');

      // Convert rrule time to Date
      origTime.map((time) =>
        recurrenceTime.push(convertRecurrenceToJSDate(startDate, time))
      );

      out = {
        ...eventData,
        wellnessData: reminder,
      };
    }

    const today = DateTime.local().startOf('day');
    const recurrenceString = get(out, 'recurrence', false);
    const recurrenceRRule =
      recurrenceString && RRule.fromString(recurrenceString);
    const endDate = get(recurrenceRRule, 'options.until', false);

    this.state = {
      duplicateToCareGiverCalendar: false,
      edit,
      endDate:
        typeof rule.origOptions !== 'undefined' &&
        typeof rule.origOptions.until !== 'undefined'
          ? rruleDateToLocalTime(rule.origOptions.until).toJSDate()
          : null,
      isFromCareGiverCalendar,
      ongoing: !endDate ? true : false,
      out,
      reminder: [],
      recurrence:
        typeof convertedRecurrence !== 'undefined'
          ? convertedRecurrence
          : Frequency.NEVER,
      recurrenceTime,
      saving: false,
      startDate:
        typeof rule.origOptions !== 'undefined'
          ? rruleDateToLocalTime(rule.origOptions.dtstart).toJSDate()
          : today.toJSDate(),
      text: get(out.data, 'text', ''),
      time_1: DateTime.local().toJSDate(),
      timezone: userObject.timezone,
      title: out.title || '',
      userObject,
      isDialogOpen: true,
    };
  }

  componentDidMount() {
    if (
      typeof this.state.recurrenceTime !== 'undefined' &&
      this.state.recurrenceTime.length > 0
    ) {
      this.state.recurrenceTime.map((timeValue, i) => {
        const time = `time_${i + 1}`;
        return this.setState({
          [time]: timeValue,
        });
      });
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }

    this.setState({
      isDialogOpen: false,
    });
  }

  handleSubmit = (formState) => {
    if (this.state.saving) {
      return;
    }
    this.setState({ saving: true });
    const state = { ...this.state, ...formState };
    const rule = this.getRrule(state);
    const recDescription = rule.toText();
    const toSave = {
      active: true,
      recurrence: rule.toString(),
      recurrenceDescription: recDescription,
      timezone: state.timezone,
      title: state.title,
      text: state.text,
      userId: state.userObject.userId,
    };

    if (state.edit) {
      toSave.eventId = this.props.data.eventData.eventId;
      editGeneralReminder(this.props.dispatch, this.context.apiClient, toSave)
        .then(() =>
          this.setState({ saving: false }, () => {
            this.onClose();
            window.location.reload();
          })
        )
        .catch((err) => {
          console.log('Error during editGeneralReminder form save', err);
          this.setState({ saving: false });
        });
    }

    if (!state.edit) {
      toSave.addToSelf = state.duplicateToCareGiverCalendar;
      addGeneralReminder(this.props.dispatch, this.context.apiClient, toSave)
        .then(() =>
          this.setState({ saving: false }, () => {
            this.onClose();
            window.location.reload();
          })
        )
        .catch((err) => {
          console.log('Error during addGeneralReminder form save', err);
          this.setState({ saving: false });
        });
    }
  };

  getRrule({ recurrence, timezone, startDate, endDate, ongoing, ...others }) {
    let freq = null;
    if (/^(EVERY_DAY)/.test(recurrence)) {
      freq = RRule.DAILY;
    }
    if (/^(EVERY_WEEK)/.test(recurrence)) {
      freq = RRule.WEEKLY;
    }
    if (/^(EVERY_MONTH)/.test(recurrence)) {
      freq = RRule.MONTHLY;
    }

    const dtstart = dateInRruleDateFormat(startDate);
    const until = !ongoing ? dateInRruleDateFormat(endDate, true) : false;
    const byminute = [];

    Reflect.ownKeys(others).forEach((key) => {
      if (/^time_\d$/.test(key)) {
        byminute.push(getMinutes(others[key]));
      }
    });

    const ruleData =
      freq === null
        ? {
            dtstart,
            byminute,
            count: 1,
          }
        : {
            freq,
            dtstart,
            byminute,
          };

    if (until) {
      ruleData.until = until;
    }

    ruleData.tzid = timezone;
    return new RRule(ruleData);
  }

  render() {
    const hideCheckbox = this.state.edit || this.state.isFromCareGiverCalendar;
    const { classes, t } = this.props;

    return (
      <MuiDialog
        open={this.state.isDialogOpen}
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.tooltipTitleContainer}>
          <DialogTitle id="alert-dialog-title">General Reminder</DialogTitle>
          <InfoTooltip
            translationKey={'GeneralReminder.Tooltip'}
            className={classes.infoTooltip}
            translationOptions={{
              name: this.state.userObject.name.first,
            }}
          />
        </div>
        <div className={classes.titlemargin}>
          <Typography variant="subtitle1">
            {t('Reminder.EventWillBeCreatedInCareRecipientTimezone')}
          </Typography>
          <Tooltip
            title={DateTime.local()
              .setZone(this.state.timezone)
              .setLocale('en-US')
              .toFormat('ZZZZZ')}
          >
            <Typography align="center" variant="caption">
              (UTC
              {DateTime.local()
                .setZone(this.state.timezone)
                .setLocale('en-US')
                .toFormat('ZZ z')}{' '}
              )
            </Typography>
          </Tooltip>
        </div>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={() => this.onClose()}
        >
          <CloseIcon />
        </IconButton>
        <Divider />

        <DialogContent>
          <GeneralReminderForm
            preFilledFormState={this.state}
            timezone={this.state.timezone}
            hideCheckbox={hideCheckbox}
            onSubmit={(formState) => this.handleSubmit(formState)}
          />
        </DialogContent>
      </MuiDialog>
    );
  }
}

export default withTranslation()(withStyles(styles)(connect()(Dialog)));
