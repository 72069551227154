import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialog from '@material-ui/core/Dialog';

const noop = () => {};

function Dialog({ data, dialogId }, context) {
  const onDialogCancel = data.onCancel || noop;
  const onDialogOk = data.onOk || noop;

  const [isDialogOpen, setIsDialogOpen] = React.useState(true);

  React.useEffect(() => {
    document.body.style.overflow = '';
  });

  function onClose() {
    context.dialogClose(dialogId);
    setIsDialogOpen(false);
  }

  function onOk() {
    onDialogOk();
    onClose();
  }

  function onCancel() {
    onDialogCancel();
    onClose();
  }

  return (
    <MuiDialog
      aria-labelledby="confirmation-dialog-title"
      open={isDialogOpen}
      onClose={() => onClose()}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="confirmation-dialog-title">
        {data.title || 'Confirm'}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {data.message || 'Are you sure?'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color={data.cancelButtonColor || 'default'}
          onClick={() => onCancel()}
          variant="contained"
        >
          {data.cancelText || 'Cancel'}
        </Button>
        <Button
          color={data.okButtonColor || 'primary'}
          onClick={() => onOk()}
          variant="contained"
        >
          {data.okText || 'Ok'}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}

Dialog.contextTypes = {
  apiClient: PropTypes.object.isRequired,
  dialogPush: PropTypes.func,
  dialogClose: PropTypes.func,
};

export default Dialog;
