import getOrDefault from './safe';

function hasBreakThruCapability(user) {
  if (user === null || user === undefined) {
    return false;
  }

  if (user.deviceTokens === null || user.deviceTokens === undefined) {
    return false;
  }

  const androidPhoneDevices = getOrDefault(() => user.deviceTokens, [])
    .filter((deviceToken) => deviceToken.tokenIsValid === true)
    .filter((deviceToken) => deviceToken.tokenDeviceType === 'phone')
    .filter(
      (deviceToken) => deviceToken.tokenDeviceOperatingSystem === 'android-os'
    );

  const userHasBreakthruCapableDevice = androidPhoneDevices.length === 1;
  const userHasAllowedBreakThruCallsToDevice = getOrDefault(
    () => user.phoneSettings.acceptsBreakthrough,
    false
  );

  return userHasBreakthruCapableDevice && userHasAllowedBreakThruCallsToDevice;
}

export { hasBreakThruCapability };
