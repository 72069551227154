/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, Icon, Typography } from '@material-ui/core';

import set from 'lodash.set';
import get from 'lodash.get';

import AutoComplete from 'components/AutoComplete';
import Button from 'components/Button';
import Field from 'components/DialogField';
import IconButton from 'components/Button/IconButton';
import Title from 'components/Title';

import { updateMedicalInfo } from 'actions/user';
import { searchConditions, searchAllergies } from 'actions/medicine';

import {
  millimeterToFeetInches,
  gramToPounds,
  feetToMillimeters,
  inchesToMillimeters,
  poundsToGrams
} from 'utils/numbers';

import style from './style.module.scss';

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    const currentStep = 1;
    const out = {
      ...props.activeRecipient,
      ...millimeterToFeetInches(props.activeRecipient.height || 0),
      weightPounds: gramToPounds(props.activeRecipient.weight || 0)
    };

    this.state = {
      out,
      currentStep,
      saveError: '',
      saving: false
    };

    this.loadAllergiesOptions = this.loadAllergiesOptions.bind(this);
    this.loadConditionOptions = this.loadConditionOptions.bind(this);
  }

  // componentDidMount() {
  //   getAllAllergies(
  //     this.props.dispatch,
  //     this.context.apiClient
  //   );
  //   getAllConditions(
  //     this.props.dispatch,
  //     this.context.apiClient
  //   );
  // }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onSaveClick() {
    if (!this.state.saving) {
      this.setState({ saving: true });

      const toSave = {
        ...this.state.out
      };

      if (!toSave.allergies) {
        toSave.allergies = [];
      }

      if (!toSave.organDonor) {
        delete toSave.organDonor;
      }

      toSave.height =
        feetToMillimeters(Number(toSave.feet) || 0) +
        inchesToMillimeters(Number(toSave.inches || 0));
      toSave.weight = poundsToGrams(Number(toSave.weightPounds));

      updateMedicalInfo(
        this.props.dispatch,
        this.context.apiClient,
        this.props.activeRecipient.userId,
        toSave
      )
        .then(() => this.setState({ saving: false }, () => this.onClose()))
        .catch(err => this.setState({ saveError: err.error, saving: false }));
    }
  }

  onNextClick() {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep + 1 });
  }

  onBackClick() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  onEditClick(step) {
    this.setState({ currentStep: this.state.currentStep = step });
  }

  setOutValue(key, val) {
    this.setState({ out: set(this.state.out, key, val) });
  }

  loadAllergiesOptions(inputValue) {
    // if (inputValue === '') {
    //   return getAllAllergies(this.props.dispatch, this.context.apiClient)
    //     .then((res => res.data.allergies.map(a => ({ value: a.name, label: a.name }))));
    // }
    return searchAllergies(this.props.dispatch, this.context.apiClient, inputValue).then(res =>
      res.data.allergies.map(a => ({ value: a.name, label: a.name })));
  }

  loadConditionOptions(inputValue) {
    // if (inputValue === '') {
    //   return getAllConditions(this.props.dispatch, this.context.apiClient)
    //     .then((res => res.data.conditions.map(a => ({ value: a.name, label: a.name }))));
    // }
    return searchConditions(this.props.dispatch, this.context.apiClient, inputValue).then(res =>
      res.data.conditions.map(c => ({ value: c.name, label: c.name })));
  }

  renderField(type, outKey, label, props, defaultValue) {
    if (type === 'text') {
      return (
        <div className={style.field} key={`field_${outKey}`}>
          <div className={style.label}>{label}</div>
          <Field
            type={get(props, 'type', 'text')}
            value={get(this.state.out, outKey, defaultValue || '')}
            onChange={event => this.setOutValue(outKey, get(event, 'target.value', event))}
            {...props}
          />
        </div>
      );
    } else if (type === 'list') {
      const values = get(this.state.out, outKey, defaultValue || []);
      const { lists } = this.state;
      const fieldVal = get(lists, outKey, '');
      return (
        <div className={style.field} key={`field_${outKey}`}>
          <div className={style.label}>
            {label} <span className={style.muted}>(press enter key to add)</span>
          </div>
          <Field
            type={get(props, 'type', 'text')}
            value={fieldVal}
            onChange={event => this.setState({ lists: { ...lists, [outKey]: event.target.value } })}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.setOutValue(outKey, [...values, fieldVal]);
                this.setState({ lists: { ...lists, [outKey]: '' } });
              }
            }}
            {...props}
          />
          <ul>
            {values.map((v, index) => (
              <li key={`${outKey}_${index}`} className={style.listItem}>
                <Icon
                  className={style.clearIcon}
                  onClick={() => this.setOutValue(outKey, values.filter(val => val !== v))}
                >
                  clear
                </Icon>
                {v}
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (type === 'autocomplete-text') {
      return (
        <div className={style.field} key={`field_${outKey}`}>
          <AutoComplete
            setOutValue={data => this.setOutValue(outKey, data)}
            label={label}
            placeholder={props.placeholder}
            loadOptions={props.loadOptions}
            suggestions={props.suggestions}
            async={props.async}
            isMulti
          />
        </div>
      );
    } else if (type === 'checkbox') {
      return (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              color="default"
              checked={get(this.state.out, outKey, defaultValue || false)}
              onChange={event => this.setOutValue(outKey, event.target.checked)}
              {...props}
            />
          }
        />
      );
    }
    return null;
  }

  renderStep1() {
    const { saveError } = this.state;
    return (
      <div className={[style.background, style[this.props.transition]].join(' ')}>
        <div className={style.container} onClick={e => e.stopPropagation()}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            <div className={style.content}>
              <Title ltIcon="list" iconColor="#548393">
                Editing Medical Profile
              </Title>
              <div>
                <Typography variant="body2" paragraph gutterBottom>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="//www.leapthru.com/knowledge-base/use_of_medical_information/"
                  >
                    Please Review this important information before entering information into the Medical Profile.
                  </a>
                </Typography>
              </div>
              <div className={style.fields}>
                {this.renderField('text', 'bloodType', 'Blood type')}
                {this.renderField('text', 'weightPounds', 'Weight (in lbs)')}
                {this.renderField('text', 'feet', 'Height (feet)')}
                {this.renderField('text', 'inches', 'Height (inches)')}
                {this.renderField('autocomplete-text', 'allergies', 'Allergies', {
                  placeholder: 'Search for or add allergies',
                  suggestions: this.props.activeRecipient.allergies || false,
                  loadOptions: this.loadAllergiesOptions,
                  async: true
                })}
                {this.renderField('text', 'emergencyInfo', 'Emergency info')}
                {this.renderField('autocomplete-text', 'conditions', 'Medical Conditions', {
                  placeholder: 'Search for or add medical conditions',
                  suggestions: this.props.activeRecipient.conditions || false,
                  loadOptions: this.loadConditionOptions,
                  async: true
                })}
              </div>
              <div>{this.renderField('checkbox', 'organDonor', 'Organ donor')}</div>
              {saveError && <div className={style.saveError}>An error occurred: {saveError}</div>}
            </div>
            <div>
              <Button className={style.nextButton} onClick={() => this.onNextClick()}>
                NEXT
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStep2() {
    return (
      <div>
        <Title icon="assignment" iconColor="#afe67b">
          Details & Confirmation
          <IconButton
            variant="fab"
            mini
            icon="edit"
            className={style.button}
            onClick={() => this.onEditClick(1)}
          />
        </Title>
        <div className={style.fields}>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Blood type:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.bloodType}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Weight (in lbs):
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.weightPounds}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
            Height (feet):
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.feet}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
            Height (inches):
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.inches}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Allergies:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.allergies.length && this.state.out.allergies.join(', ')}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Emergency info:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.emergencyInfo}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Medical Conditions:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.conditions.join(', ')}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Organ donor:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.organDonor ? 'Yes' : 'No'}
            </div>
          </div>

        </div>
      </div>
    );
  }

  render() {
    const { currentStep } = this.state;
    return (
      <div
        className={[style.background, style[this.props.transition]].join(' ')}
      >
        <div className={style.container} onClick={e => e.stopPropagation()}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            {currentStep > 1 && (
              <IconButton
                onClick={() => this.onBackClick()}
                className={style.buttonBack}
                icon="arrow_back"
                variant="fab"
                mini
              />
            )}
            <div className={style.content}>
              {currentStep === 1 && this.renderStep1()}
              {currentStep === 2 && this.renderStep2()}
            </div>
            <div>
              {currentStep === 1 && (
                <Button className={style.nextButton} onClick={() => this.onNextClick()}>
                  NEXT
                </Button>
              )}
              {currentStep === 2 && (
                <Button className={style.nextButton} onClick={() => this.onSaveClick()}>
                  SAVE
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(['carerecipients', 'activeRecipient'], {});
  // const allAlergies = state.getIn(['medicine', 'allAlergies']);
  // const allConditions = state.getIn(['medicine', 'allConditions']);

  return { activeRecipient };
  // return { activeRecipient, allAlergies, allConditions };
};

export default connect(mapStateToProps)(Dialog);
