import React, { Suspense } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import LoadingPage from 'pages/LoadingPage';
import PageNavigationContainer from 'components/PageNavigation/PageNavigationContainer';
import style from 'components/OuterFrame/style.module.scss';
import Invitation from 'components/Invitation';
import logoleapthru from 'assets/brand-logos/logoleapthru.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  poweredByLine: {
    position: 'relative',
    fontSize: 20,
    color: theme.palette.grey[500],
    fontWeight: 'bold',
    paddingLeft: 'calc(50% - 120px)',
  },
  logo: {
    paddingTop: theme.spacing(1),
    margin: '0 5px',
    width: 140,
    float: 'left',
  },
  title: {
    float: 'left',
    position: 'relative',
    top: '-50%',
    transform: 'translateY(50%)',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  subtitles: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const AppLayout = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ width: '100%', minHeight: '100vh' }}
    >
      <Box marginBottom={2}>
        <Suspense fallback={<LoadingPage />}>
          <PageNavigationContainer />
        </Suspense>
      </Box>

      <Box flexGrow={1}>
        <Suspense fallback={<LoadingPage />}>{children}</Suspense>
      </Box>

      <Box marginTop={2} marginBottom={2}>
        <div className={classes.flexColumn}>
          <div className={`${style.poweredBy} ${classes.poweredByLine}`}>
            <span className={classes.title}>{t('Footer.PoweredBy')}</span>
            <img
              className={classes.logo}
              src={logoleapthru}
              alt="Leapthru logo"
            />
          </div>
          <div className={classes.subtitles}>
            <a href="/terms-and-conditions">
              {t('TermsConditionsPrivacyPolicy.TermsConditionsTitle')}
            </a>{' '}
            |{' '}
            <a href="/privacy-policy">
              {t('TermsConditionsPrivacyPolicy.PrivacyPolicyTitle')}
            </a>{' '}
          </div>
        </div>
      </Box>

      <Invitation />
    </Box>
  );
};
