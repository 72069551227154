import React from 'react';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
// import Badge from '@material-ui/core/Badge';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoleapthru from 'assets/brand-logos/logoleapthru.svg';

import TopAppBarDrawer from 'components/PageNavigation/TopAppBarDrawer';
import TopAppBarMenu from 'components/PageNavigation/TopAppBarMenu';

import { useTranslation } from 'react-i18next';
import HelpDropdown from 'components/HelpDropdown';
import CaregiverProfileDropdown from 'components/CaregiverProfileDropdown';
import { Container } from '@material-ui/core';
import { useActiveCareRecipientSelector } from 'reducers/carerecipients';
import TopAppBarLine from 'components/PageNavigation/TopAppBarLine';
import makeHSL from 'styles/makeHSL';
import { shallowEqual, useSelector } from 'react-redux';
import getOrDefault from 'utils/safe';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: makeHSL({ hue: 210, saturation: 0.17, lightness: 0.91 }),
    boxShadow: 'none',
  },

  rootGradientBar: {
    width: '100%',
    height: 10,
    background:
      'linear-gradient(to right, hsla(208,51%,51%,1), hsla(180,60%,46%,1))',
  },

  toolbarItem: {
    display: 'inline-flex',
  },

  spacer: {
    flexGrow: 1,
  },

  leapThruLogoAnchor: {
    display: 'inline-flex',
    position: 'relative',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  leapThruLogoImage: {
    width: 140,
  },

  leapThruMenuItems: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  dividerItem: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    position: 'relative',
    verticalAlign: 'middle',
  },

  divider: {
    display: 'inline-block',
    width: 1,
    backgroundColor: makeHSL({ hue: 210, saturation: 0.17, lightness: 0.91 }),
  },
}));

const TopAppBar = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const activeCareRecipient = useActiveCareRecipientSelector();
  const hasActiveCareRecipient = activeCareRecipient.userId !== null;

  const menuItems = [
    {
      icon: 'home',
      name: t('TopAppBar.dashboard'),
      destination: '/new-dashboard',
    },
    {
      icon: 'stream',
      name: t('TopAppBar.timeline'),
      destination: '/timeline_v2',
    },
    {
      icon: 'calendar',
      name: t('TopAppBar.calendar'),
      destination: '/calendar',
    },
    {
      icon: 'signal-alt-3',
      name: t('TopAppBar.adherence'),
      destination: '/adherence',
    },
    {
      icon: 'alarm-clock',
      name: t('TopAppBar.reminders'),
      destination: '/reminders',
    },
    {
      icon: 'prescription-bottle-alt',
      name: t('TopAppBar.medications'),
      destination: '/medications',
    },
    {
      icon: 'address-book',
      name: t('TopAppBar.contacts'),
      destination: '/contacts',
    },
  ];

  const currentUser = useSelector(
    (state) => state.getIn(['user', 'self']),
    shallowEqual
  );

  const userGroup = getOrDefault(() => currentUser.userGroup, '');

  // Simple whitelabling
  const getLogoUrlForUserGroup = () => {
    switch (userGroup.toLowerCase()) {
      case 'ibx':
        return 'https://image.leapthru.com/image/ibx';
      case 'mountsinai':
        return 'https://image.leapthru.com/image/mountsinai.png';
      case 'tnjh':
        return 'https://image.leapthru.com/image/tnjh.png';
      default:
        return logoleapthru;
    }
  };

  const url = getLogoUrlForUserGroup();

  const TopAppBarLogoView = (props) => {
    return (
      <div {...props}>
        {hasActiveCareRecipient && <TopAppBarDrawer menuItems={menuItems} />}

        <a
          className={classes.leapThruLogoAnchor}
          href="/"
          rel="noopener noreferrer"
        >
          <img className={classes.leapThruLogoImage} src={url} alt="LeapThru" />
        </a>
      </div>
    );
  };

  const TopAppBarMenuView = (props) => {
    return hasActiveCareRecipient ? (
      <TopAppBarMenu {...props} menuItems={menuItems} />
    ) : null;
  };

  const TopAppBarCaregiverView = (props) => {
    return (
      <div {...props}>
        {/* {hasActiveCareRecipient && (
          <IconButton>
            <Badge badgeContent={36} color="secondary">
              <FontAwesomeIcon icon={['fal', 'inbox']} size="sm" />
            </Badge>
          </IconButton>
        )}

        {hasActiveCareRecipient && (
          <IconButton>
            <Badge badgeContent={17} color="secondary">
              <FontAwesomeIcon icon={['fal', 'bell']} size="sm" />
            </Badge>
          </IconButton>
        )} */}

        <CaregiverProfileDropdown />

        <div className={classes.dividerItem}>
          <div className={classes.divider} />
        </div>

        <HelpDropdown />
      </div>
    );
  };

  return (
    <AppBar position="static" color="inherit" className={classes.root}>
      <TopAppBarLine />

      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <TopAppBarLogoView className={classes.toolbarItem} />

          <TopAppBarMenuView
            className={classes.toolbarItem + ' ' + classes.leapThruMenuItems}
          />

          <div className={classes.spacer} />

          <TopAppBarCaregiverView className={classes.toolbarItem} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopAppBar;
