import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, TabContent } from 'components/Tabs';
import { saveCaregiverSettings } from 'actions/user';
import { relationshipOptions } from 'utils/roleOptions';
import {
  accessType,
  excludeAccessTypes,
  notificationGroups,
  tableGroups,
  appendReviewTypesForNotification,
} from 'pages/NotificationSettings/NotificationGroups';
import getOrDefault from 'utils/safe';
import capitalise from 'utils/strings';
import { ServiceRightsGroup } from './ServiceRightsGroup';
import {
  getMobileDeviceFromCareRecipient,
  getPermissionsFromCareRecipient,
} from 'reducers/carerecipients';

import style from './style.module.scss';
import InfoTooltip from '../../components/InfoTooltip';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialog: {
    overflowX: 'hidden',
  },
  dialogContent: {
    overflowX: 'hidden',
  },
  formButton: {
    width: 360,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  tableContainer: {
    maxWidth: '100%',
    overflowX: 'auto',
    padding: theme.spacing(1, 0),
  },
  tableHeaderLabel: {
    color: '#000',
    fontSize: 14,
  },
  tableHeaderLabelSecondary: {
    color: '#000',
    fontSize: 14,
    fontWeight: 600,
  },
  firstTableCell: {
    paddingLeft: 0,
  },
  textBold: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  tabsLink: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  select: {
    minWidth: 260,
  },
  infoTooltipContainer: {
    display: 'flex',
  },
  infoTooltip: {
    padding: theme.spacing(2, 2, 2, 0),
  },
});

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
    hasPermission: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const { caregiver, careRecipient } = props.data;
    if (caregiver.notificationSettings) {
      delete caregiver.notificationSettings.contact_remove;
      delete caregiver.notificationSettings.wellness_reminder;
      delete caregiver.notificationSettings.medicine_reminder;
    }

    const careGiverRights = getPermissionsFromCareRecipient(
      careRecipient,
      caregiver.userId
    );

    if (getMobileDeviceFromCareRecipient(careRecipient) === 'ios') {
      const index = notificationGroups.CALLS.indexOf('emergencyCall');
      if (index >= 0) {
        notificationGroups.CALLS.splice(index, 1);
      }
    }

    this.state = {
      activeTab: 0,
      careGiverRights: careGiverRights,
      careGiverRightsSave: false,
      notificationSettings: caregiver.notificationSettings,
      notificationSettingsSave: false,
      caregiverSettings: {
        roleTitle: caregiver.roleTitle || caregiver.title,
      },
      caregiverSettingsSave: false,
      saving: false,
    };
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onSaveClick() {
    if (!this.state.saving) {
      if (
        this.state.careGiverRightsSave ||
        this.state.notificationSettingsSave ||
        this.state.caregiverSettingsSave
      ) {
        const toSave = {
          CRId: this.props.data.careRecipient.userId,
          CGId: this.props.data.caregiver.userId,
        };

        if (this.state.careGiverRightsSave) {
          toSave.careGiverRights = this.state.careGiverRights;
        }

        if (this.state.notificationSettingsSave) {
          const settings = { ...this.state.notificationSettings };

          for (const key in settings) {
            settings[key].notify = appendReviewTypesForNotification(
              key,
              getOrDefault(() => settings[key].notify, [])
            );
          }

          toSave.notificationSettings = settings;
        }

        if (this.state.caregiverSettingsSave) {
          toSave.caregiverSettings = this.state.caregiverSettings;
        }

        this.setState({ saving: true });

        saveCaregiverSettings(
          this.props.dispatch,
          this.context.apiClient,
          toSave
        )
          .then(() => this.setState({ saving: false }, () => this.onClose()))
          .catch((err) => {
            console.log('err', err);
            this.setState({
              saving: false,
              careGiverRightsSave: false,
              notificationSettingsSave: false,
              caregiverSettingsSave: false,
            });
          });
      }
    }
  }

  renderNotificationCheckbox(key, type, reviewTypes = null) {
    const { notificationSettings: settings } = this.state;
    const hasReviewTypes = reviewTypes !== null;
    let reviewTypesChecked;

    const handleChange = (event) => {
      if (hasReviewTypes) {
        if (event.target.checked) {
          if (reviewTypes.merge) {
            settings[key].notify = [
              ...getOrDefault(() => settings[key].notify, []),
              ...reviewTypes.notify,
            ];

            settings[key].notify = settings[key].notify.filter(
              (e) => e !== 'none'
            );
          } else {
            settings[key].notify = reviewTypes.notify;
          }
        } else {
          if (reviewTypes.merge) {
            settings[key].notify = settings[key].notify.filter(
              (e) => !reviewTypes.notify.includes(e)
            );

            if (!settings[key].notify.length) {
              settings[key].notify = ['none'];
            }
          } else {
            settings[key].notify = ['none'];
          }
        }
      }

      if (!hasReviewTypes) {
        if (settings[key].notify.includes(type)) {
          settings[key].notify = settings[key].notify.filter((notification) => {
            if (notification !== type) {
              return notification;
            }
            return null;
          });
        } else {
          settings[key].notify.push(type);
        }
      }

      this.setState({
        notificationSettings: settings,
        notificationSettingsSave: true,
      });
    };

    if (hasReviewTypes) {
      reviewTypesChecked = reviewTypes.notify.every((reviewType) =>
        settings[key].notify.includes(reviewType)
      );
    }

    const isChecked = !hasReviewTypes
      ? this.state.notificationSettings[key].notify.includes(type)
      : reviewTypesChecked;

    return (
      <Checkbox color="primary" checked={isChecked} onChange={handleChange} />
    );
  }

  renderCheckboxGroup(checkboxGroup) {
    return checkboxGroup.map((checkbox, index) =>
      this.renderCheckbox(index, checkbox, checkboxGroup)
    );
  }

  handleServiceRightUpdate = ({
    key,
    value,
    toggleCorrespondingGroupId,
    valueGroup,
    initialCheckboxGroup: checkboxGroup,
  }) => (event) => {
    const { careGiverRights } = this.state;
    const { checked: newValue } = event.target;
    const handleUpdate = (key, value) => {
      if (careGiverRights[key][value] !== newValue) {
        careGiverRights[key][value] = newValue;
      }

      if (
        toggleCorrespondingGroupId &&
        newValue === true &&
        checkboxGroup.length
      ) {
        checkboxGroup.forEach((group) => {
          if (group.correspondingGroupId !== toggleCorrespondingGroupId) {
            return;
          }

          if (group.valueGroup && group.valueGroup.length) {
            Object.keys(group.valueGroup).forEach((subValueGroup) => {
              const { key, value } = group.valueGroup[subValueGroup];
              careGiverRights[key][value] = newValue;
            });
          } else {
            careGiverRights[group.key][group.value] = newValue;
          }
        });
      }
    };

    if (valueGroup.length) {
      Object.keys(valueGroup).forEach((group) => {
        const { key, value } = valueGroup[group];
        handleUpdate(key, value);
      });
    } else {
      handleUpdate(key, value);
    }

    this.setState({
      careGiverRights,
      careGiverRightsSave: true,
    });
  };

  renderCheckbox(
    uniqueKey,
    {
      label,
      key,
      value,
      subTitle,
      toggleCorrespondingGroupId,
      valueGroup = [],
      checkboxGroup = [],
    },
    initialCheckboxGroup = []
  ) {
    const { careGiverRights } = this.state;
    let isChecked = getOrDefault((_) => careGiverRights[key][value], false);

    if (valueGroup.length) {
      isChecked = Object.keys(valueGroup).every((group) => {
        const { key, value } = valueGroup[group];
        return careGiverRights[key][value];
      });
    }

    return (
      <div
        className={subTitle && style.notificationSetting}
        key={`checkbox-${uniqueKey}-${key}`}
      >
        {subTitle && <div className={style.title}>{subTitle && subTitle}</div>}

        {checkboxGroup.length ? (
          this.renderCheckboxGroup(checkboxGroup)
        ) : (
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                color="primary"
                checked={isChecked}
                onChange={this.handleServiceRightUpdate({
                  key,
                  value,
                  toggleCorrespondingGroupId,
                  valueGroup,
                  initialCheckboxGroup,
                })}
              />
            }
          />
        )}
      </div>
    );
  }

  userHasRequiredServices(services) {
    const { careGiverRights } = this.state;
    return services.every((service) => service in careGiverRights);
  }

  renderServiceRightGroup(key, { title, checkboxGroup, requireServices = [] }) {
    if (!this.userHasRequiredServices(requireServices)) {
      return null;
    }

    return (
      <div className={style.wrapper} key={key}>
        <div className={style.group}>{title}</div>
        <div className={style.divider} />
        {this.renderCheckboxGroup(checkboxGroup)}
      </div>
    );
  }

  render() {
    const { notificationSettings, caregiverSettings } = this.state;
    const { hasPermission } = this.context;
    const { classes, t } = this.props;
    const caregiverName = this.props.data.caregiver.name.full;
    const careRecipientName = this.props.data.careRecipient.name.full;

    const tabs = [
      {
        title: 'General settings',
        content: () => (
          <div className={style.stepContent} key="tab-content-1">
            <FormControl fullWidth>
              <TextField
                select
                label={t('CareGiverSettings.Label.Relationship', {
                  recipient: careRecipientName,
                })}
                className={style.select}
                fullWidth
                value={caregiverSettings.roleTitle}
                onChange={(event) =>
                  this.setState({
                    caregiverSettings: {
                      ...caregiverSettings,
                      roleTitle: event.target.value,
                    },
                    caregiverSettingsSave: true,
                  })
                }
                variant="outlined"
              >
                {relationshipOptions.map((relationship) => (
                  <MenuItem
                    key={`role_setting_${relationship.value}`}
                    value={relationship.value}
                  >
                    {relationship.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        ),
      },
    ];

    if (hasPermission('UserService.setRights')) {
      tabs.push({
        title: 'Rights',
        content: () => {
          const serviceRightsGroupCollection = ServiceRightsGroup(t);

          return (
            <div className={style.stepContent} key="tab-content-2">
              {Object.keys(serviceRightsGroupCollection).map((service) =>
                this.renderServiceRightGroup(
                  service,
                  serviceRightsGroupCollection[service]
                )
              )}
            </div>
          );
        },
      });
    }

    if (hasPermission('UserService.setNotificationSettings')) {
      tabs.push({
        title: 'Notifications',
        content: () => (
          <div className={style.stepContent} key="tab-content-3">
            {Object.keys(notificationGroups).map((group) => (
              <div>
                <div className={style.group}>
                  {t(`NotificationSettings.${group.toLowerCase()}`)}
                </div>
                <div className={style.divider} />
                {notificationGroups[group].map((key) => {
                  if (excludeAccessTypes[key]) {
                    return null;
                  }

                  const type = accessType[key] || accessType.default;
                  const hasGroups = 'group' in type;

                  if (!hasGroups && !notificationSettings[key]) {
                    return null;
                  }

                  const notifyArray = Array.isArray(type.notify);
                  const { reviewTypes } = type;
                  const isReviewTypesArray =
                    reviewTypes && Array.isArray(reviewTypes);

                  return (
                    <div
                      key={`notification_setting_${key}`}
                      className={`${style.notificationSetting} ${
                        !notifyArray && style.singleCheckbox
                      }`}
                    >
                      <div>
                        <span className={classes.textBold}>
                          {t(`NotificationSettings.${key}`) || key}
                        </span>
                      </div>
                      <div>
                        {!hasGroups &&
                          !reviewTypes &&
                          !notifyArray &&
                          this.renderNotificationCheckbox(key, type.notify)}

                        {!hasGroups && reviewTypes && !isReviewTypesArray && (
                          <FormControlLabel
                            label={capitalise(reviewTypes.label || '').replace(
                              'gps',
                              'GPS'
                            )}
                            control={this.renderNotificationCheckbox(
                              key,
                              null,
                              reviewTypes
                            )}
                          />
                        )}

                        {!hasGroups &&
                          isReviewTypesArray &&
                          reviewTypes.map((type, index) => (
                            <FormControlLabel
                              key={`review-type-${index}`}
                              label={capitalise(type.label || '').replace(
                                'gps',
                                'GPS'
                              )}
                              control={this.renderNotificationCheckbox(
                                key,
                                null,
                                type
                              )}
                            />
                          ))}

                        {hasGroups &&
                          Object.keys(type.group).map((groupType, index) => (
                            <FormControlLabel
                              key={`group-type-${index}`}
                              label={capitalise(
                                type.group[groupType].label || ''
                              ).replace('gps', 'GPS')}
                              control={this.renderNotificationCheckbox(
                                groupType,
                                type.group[groupType].notify
                              )}
                            />
                          ))}

                        {!hasGroups &&
                          !reviewTypes &&
                          notifyArray &&
                          type.notify.map((state, index) => {
                            const isObj = typeof state === 'object';
                            const label = isObj
                              ? state.label
                              : capitalise(state);
                            const value = isObj ? state.value : state;
                            return (
                              <FormControlLabel
                                key={`checkbox-${index}`}
                                label={capitalise(label).replace('gps', 'GPS')}
                                control={this.renderNotificationCheckbox(
                                  key,
                                  value
                                )}
                              />
                            );
                          })}

                        {type.filters && (
                          <div>
                            {Object.keys(type.filters).map(
                              (filterKey, index) => {
                                if (Array.isArray(type.filters[filterKey])) {
                                  return (
                                    <FormControl key={`filterKey-${index}`}>
                                      <InputLabel>
                                        {filterKey.charAt(0).toUpperCase() +
                                          filterKey.slice(1)}
                                      </InputLabel>
                                      <Select
                                        placeholder={filterKey}
                                        multiple
                                        className={style.select}
                                        value={
                                          notificationSettings[key].filters[
                                            filterKey
                                          ] || []
                                        }
                                        onChange={(event) =>
                                          this.setState({
                                            notificationSettings: {
                                              ...notificationSettings,
                                              [key]: {
                                                ...notificationSettings[key],
                                                filters: {
                                                  ...notificationSettings[key]
                                                    .filters,
                                                  [filterKey]:
                                                    event.target.value,
                                                },
                                              },
                                            },
                                            notificationSettingsSave: true,
                                          })
                                        }
                                      >
                                        {type.filters[filterKey].map(
                                          (filter) => (
                                            <MenuItem
                                              key={`notification_setting_${filterKey}_${filter.value}`}
                                              value={filter.value}
                                            >
                                              {filter.label}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  );
                                }
                                return null;
                              }
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}

                {tableGroups[group] &&
                  Object.keys(tableGroups[group]).map((tableKey, index) => {
                    const table = tableGroups[group][tableKey];
                    const { columns, rows, header } = table;

                    return (
                      <React.Fragment key={`table-${index}`}>
                        <div className={classes.tableContainer}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  classes={{ root: classes.firstTableCell }}
                                >
                                  <span className={classes.tableHeaderLabel}>
                                    {header}
                                  </span>
                                </TableCell>
                                {columns.map((column, index) => (
                                  <TableCell key={`col-${index}`}>
                                    <span
                                      className={
                                        classes.tableHeaderLabelSecondary
                                      }
                                    >
                                      {column}
                                    </span>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row, index) => {
                                const { label, group: rowGroup } = row;

                                return (
                                  <TableRow key={`row-${index}`}>
                                    <TableCell
                                      classes={{ root: classes.firstTableCell }}
                                    >
                                      <span
                                        className={
                                          classes.tableHeaderLabelSecondary
                                        }
                                      >
                                        {label}
                                      </span>
                                    </TableCell>
                                    {Object.keys(rowGroup).map(
                                      (notificationKey, index) => (
                                        <TableCell key={`row-group-${index}`}>
                                          {this.renderNotificationCheckbox(
                                            notificationKey,
                                            rowGroup[notificationKey].notify,
                                            rowGroup[notificationKey]
                                              .reviewTypes || null
                                          )}
                                        </TableCell>
                                      )
                                    )}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            ))}
          </div>
        ),
      });
    }

    return (
      <MuiDialog
        open={true}
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        className={classes.dialog}
      >
        <div className={classes.infoTooltipContainer}>
          <DialogTitle id="alert-dialog-title">
            {caregiverName} - Settings for {careRecipientName}
          </DialogTitle>
          <InfoTooltip
            translationKey="CareGiverSettings.Tooltip"
            translationOptions={{
              CRName: this.props.data.careRecipient.name.first,
              CRLastname: this.props.data.careRecipient.name.last,
              CGName: this.props.data.caregiver.name.first,
              CGLastname: this.props.data.caregiver.name.last,
            }}
            className={classes.infoTooltip}
          />
        </div>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={() => this.onClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <Tabs
            value={this.state.activeTab}
            onChange={(e, val) => this.setState({ activeTab: val })}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={`tab-${index}`}
                label={tab.title}
                className={classes.tabsLink}
              />
            ))}
          </Tabs>
          <TabContent className={style.tabContent}>
            {tabs.map(
              (tab, index) => this.state.activeTab === index && tab.content()
            )}
          </TabContent>
        </DialogContent>
        <Box display="flex" justifyContent="center" marginY={2} paddingX={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.onSaveClick()}
            className={classes.formButton}
          >
            Save
          </Button>
        </Box>
      </MuiDialog>
    );
  }
}

const MyDialog = withTranslation()(withStyles(styles)(connect()(Dialog)));

function AppDialog(props) {
  React.useEffect(() => {
    document.body.style.overflow = '';
  });

  return (
    <Suspense fallback="loading">
      <MyDialog {...props} />
    </Suspense>
  );
}

export default AppDialog;
