/* eslint-disable */

import RRule from 'rrule';
import get from 'lodash.get';
import { DateTime } from 'luxon';

import { convertRecurrenceToJSDate, rruleDateToLocalTime } from 'utils/rruleWrapper';
import { getCalendar, getEvent } from 'actions/calendar';
import { useDispatch } from 'react-redux';
import { useCalendarFilterSelector } from 'reducers/calendar';


export const whenToTakeOptions = {
  as_needed: { label: 'As needed', alerts: 1, rrule: {} },
  day_1: { label: 'Once a day', alerts: 1, rrule: { freq: RRule.DAILY } },
  day_2: { label: '2 times a day', alerts: 2, rrule: { freq: RRule.DAILY } },
  day_3: { label: '3 times a day', alerts: 3, rrule: { freq: RRule.DAILY } },
  day_4: { label: '4 times a day', alerts: 4, rrule: { freq: RRule.DAILY } },
  week_1: { label: '1x per week', alerts: 1, rrule: { freq: RRule.WEEKLY } },
  month_1: { label: '1x per month', alerts: 1, rrule: { freq: RRule.MONTHLY } }
};
export const whenToTakeKeys = Object.keys(whenToTakeOptions);
export const refillIntervalOptions = {
  week_1: { label: 'Every week', rrule: { freq: RRule.WEEKLY } },
  week_2: { label: 'Every 2 weeks', rrule: { freq: RRule.WEEKLY, interval: 2 } },
  month_1: { label: 'Every month', rrule: { freq: RRule.MONTHLY } },
  month_3: { label: 'Every 90 days', rrule: { freq: RRule.DAILY, interval: 90 } },
  year_1: { label: 'Every year', rrule: { freq: RRule.YEARLY } }
};
export const refillIntervalKeys = Object.keys(refillIntervalOptions);

export const parseEventForEdit = (eventData) => {
  const recurrenceRRule = eventData.recurrence && RRule.fromString(eventData.recurrence);
  // const refillRRule = eventData.lrefil && RRule.fromString(eventData.refill);
  const byminute = get(recurrenceRRule, 'options.byminute', []);
  const whenToTake =
    (recurrenceRRule &&
      whenToTakeKeys.find((key) => {
        const w = whenToTakeOptions[key];
        return w.alerts === byminute.length && w.rrule.freq === recurrenceRRule.options.freq;
      })) ||
    'as_needed';
  const startDate = get(recurrenceRRule, 'options.dtstart');
  const endDate = get(recurrenceRRule, 'options.until', false);
  const alerts = byminute.map(min => convertRecurrenceToJSDate(startDate, min));
  const refillRRuleString = get(eventData, 'data[0].refill');
  const refillRRule = refillRRuleString && RRule.fromString(refillRRuleString);
  let refillCount = null;
  let beginReminders = null;
  let refillReminderTime = null;
  let refillReminderInterval = null;
  let refillFilters = null;
  if (refillRRule) {
    const refillDate = rruleDateToLocalTime(refillRRule.options.dtstart);
    refillCount = `${refillRRule.options.count}`;
    beginReminders = refillDate.startOf('day').toJSDate();
    refillReminderTime = refillDate.toJSDate();
    refillReminderInterval = refillIntervalKeys.find(key =>
      refillIntervalOptions[key].rrule.freq === refillRRule.options.freq);
  }
  refillFilters = get(eventData, 'data[0].refillFilters[0].key', []);
  return {
    ...eventData,
    medicine: [
      {
        ...get(eventData, 'data[0]'),
        whenToTake,
        alerts,
        refillCount,
        beginReminders,
        refillReminderTime
      }
    ],
    startDate: startDate ? DateTime.fromJSDate(startDate).toUTC().setZone('local', { keepLocalTime: true }).toJSDate() : null,
    endDate: endDate ? DateTime.fromJSDate(endDate).toUTC().setZone('local', { keepLocalTime: true }).toJSDate() : null,
    refillReminderInterval,
    refillFilters
  };
};

export const getRefillMedicines = (dispatch, apiClient, medications, events, careRecipientId) => {
  return Promise.all(events.map(event => {
    let medicine = medications.find(function(med) {
      return med.eventId === event.data.calendarId;
    });

    if (medicine) {
      return Promise.resolve(medicine);
    } else {
      return getEvent(dispatch, apiClient, careRecipientId, event.data.calendarId)
        .then(result => result.data)
        .catch(() => null);
    }
  }));
};

export default {};
