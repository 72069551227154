import { InsecureAppLayout } from 'layouts/InsecureAppLayout';
import { AppLayout } from 'layouts/AppLayout';

import React from 'react';
import { Redirect, Route as ReactRouterRoute } from 'react-router-dom';

import {
  useActiveCareRecipientSelector,
  useActiveUserSelector,
} from 'reducers/carerecipients';
import NoCareRecipientEmptyState from 'components/NoCareRecipientEmptyState';

const InsecureAppRoute = ({ component: Component, auth, ...rest }) => (
  <ReactRouterRoute
    {...rest}
    render={(props) => (
      <InsecureAppLayout>
        <Component {...props} />
      </InsecureAppLayout>
    )}
  />
);

export const AppRoute = ({
  component: Component,
  requiresCareRecipient = false,
  ...rest
}) => {
  const user = useActiveUserSelector();
  const activeCareRecipient = useActiveCareRecipientSelector();
  const hasNoCareRecipients = activeCareRecipient.userId === null;

  const shouldShowEmptyState = requiresCareRecipient && hasNoCareRecipients;

  return (
    <ReactRouterRoute
      {...rest}
      render={(props) =>
        user === null ? (
          <Redirect to={{ pathname: '/login' }} />
        ) : (
          <AppLayout>
            {shouldShowEmptyState ? (
              <NoCareRecipientEmptyState />
            ) : (
              <Component {...props} />
            )}
          </AppLayout>
        )
      }
    />
  );
};

export const Route = {
  App: AppRoute,
  Insecure: InsecureAppRoute,
};
