import React from 'react';
import { DateTime } from 'luxon';

function LiveClock(props) {
  let loadInterval = React.useRef(0);
  const timezone = props.timezone;
  const [time, setTime] = React.useState({ time: '00:00:00' });

  const getTime = React.useCallback(() => {
    setInterval(() => {
      let d, t;

      d = DateTime.local().setZone(timezone);
      t = d.setLocale('en-US').toFormat('tt');

      setTime({
        time: t,
      });
    }, 1000);
  }, [timezone]);

  React.useEffect(() => {
    loadInterval.current = setInterval(getTime(), 1000);
  }, [getTime, loadInterval]);

  return `${time.time}`;
}

export default LiveClock;
