import { createMuiTheme } from '@material-ui/core/styles';
import makeHSL from 'styles/makeHSL';
import 'typeface-roboto';
import 'typeface-source-sans-pro';
import 'typeface-poppins';

const defaults = {
  fontFamily: 'Source Sans Pro, arial, sans-serif',
};

const headings = {
  fontFamily: 'Poppins, Source Sans Pro, Arial, sans-serif',
};

export default createMuiTheme({
  palette: {
    primary: {
      main: makeHSL({ hue: 180, saturation: 0.6, lightness: 0.46 }),
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
    },
  },

  typography: {
    fontFamily: defaults.fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 600,

    h1: { ...headings },
    h2: { ...headings },
    h3: { ...headings },
    h4: { ...headings },
    h5: { ...headings },
    h6: { ...headings, fontWeight: 600 },
  },
});
