import { fromJS } from 'immutable';
import { useSelector } from 'react-redux';

const INITIAL_STATE = fromJS({
  self: null,
  pending: false,
  items: {},
  userCaregivers: [],
});

export function setUserSelf(state, user) {
  return state.merge({ pending: false, self: user });
}

export function clearUserSelf(state) {
  return state.set('pending', false).remove('self').set('items', fromJS({}));
}

export function setPendingSelf(state, pending = true) {
  return state.set('pending', !!pending);
}

export function merge(state, item) {
  const arr = Array.isArray(item) ? item : [item];
  return state.mergeIn(
    ['items'],
    fromJS(arr.reduce((obj, i) => ({ ...obj, [i.userId]: i }), {}))
  );
}

export function setUserCaregivers(state, payload) {
  const userCaregivers = payload;
  return state.set('userCaregivers', userCaregivers);
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'USER_CAREGIVERS_SET':
      return setUserCaregivers(state, action.payload);

    case 'USER_SELF_SET':
      return setUserSelf(state, action.user);

    case 'USER_SELF_CLEAR':
      return clearUserSelf(state);

    case 'USER_SELF_PENDING':
      return setPendingSelf(state, action.pending);

    case 'USER_SET':
      return merge(state, action.payload);

    default:
      return state;
  }
};

export const useCareGiversFilterSelector = (filterFunction = () => {}) =>
  useSelector((state) =>
    state.getIn(['user', 'userCaregivers'], []).filter(filterFunction)
  );
