const relationships = {
  friend: 'Friend',
  spouse: 'Spouse',
  'other-relative': 'Other Relative',
  other: 'Other',
  family: 'Family',
  healthProfessional: 'Health Professional',
  physician: 'Physician',
  caregiver: 'Professional Caregiver',
  livingStaff: 'Residential Living Staff',
};

export const relationshipOptions = Object.keys(relationships).map((value) => ({
  value,
  label: relationships[value],
}));

const relationshipOther = 'Other';

export const TranslateRelationships = (relationship = null) => {
  relationships['family-member'] = 'Family';
  relationships['professional-caregiver'] = 'Professional Caregiver';

  return relationships[relationship] || relationshipOther;
};

export { relationships as default };
