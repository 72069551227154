import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 10,
    background:
      'linear-gradient(to right, hsla(208,51%,51%,1), hsla(180,60%,46%,1))',
  },
});

export default function TopAppBarLine() {
  const classes = useStyles();

  return <div className={classes.root} />;
}
