import { fromJS } from 'immutable';
import { useSelector } from 'react-redux';
import getOrDefault from 'utils/safe';

const INITIAL_STATE = fromJS({
  userLocations: {},
});

function setUserLocations(state, inData, userId) {
  return state.setIn(['userLocations', userId], inData);
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOCATION_SET_USERLOCATIONS':
      return setUserLocations(state, action.payload, action.userId);
    default:
      return state;
  }
};

export const useLocationsForUser = (user) => {
  const userId = getOrDefault(() => user.userId, null);
  return useSelector((state) =>
    state.getIn(['location', 'userLocations', userId], [])
  );
};
