import { getMyInvitations, accept, reject } from 'actions/invitation';
import { useDispatch } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  Typography,
} from '@material-ui/core';
import User from 'entities/user';
import Box from '@material-ui/core/Box';
import { usePendingInvitations } from 'reducers/invitation';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },

  buttons: {
    marginLeft: theme.spacing(1),
  },

  acceptInvitation: {
    marginBottom: theme.spacing(1),
  },
}));

function Invitation(_, context) {
  const dispatch = useDispatch();
  const pendingInvitations = usePendingInvitations();
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [askLater, setAskLater] = React.useState(false);

  const { t } = useTranslation();

  async function handleAcceptedInvitation(pendingInvitation) {
    setIsLoading(true);
    await accept(dispatch, context.apiClient, pendingInvitation);
    setIsLoading(false);
    window.location = '/';
  }

  async function handleDeclinedInvitation(pendingInvitation) {
    setIsLoading(true);
    await reject(dispatch, context.apiClient, pendingInvitation);
    setIsLoading(false);
    window.location = '/';
  }

  function handleAskLater() {
    setAskLater(true);
  }

  React.useEffect(() => {
    if (!askLater) {
      getMyInvitations(dispatch, context.apiClient);
    }
  }, [dispatch, context.apiClient, askLater]);

  React.useEffect(() => {
    if (pendingInvitations.length > 0 && askLater === false) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [askLater, pendingInvitations]);

  const invitationItems = pendingInvitations.map((pendingInvitation) => {
    const careRecipients = pendingInvitation.careRecipients.map(
      (careRecipient) => User(careRecipient).name.fullName
    );

    return (
      <ListItem key={pendingInvitation.invitationId}>
        <ListItemText
          primary={t('Invitation.InvitationTitle')}
          secondary={t('Invitation.InvitationForPerson', {
            name: careRecipients,
          })}
        />

        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAcceptedInvitation(pendingInvitation)}
            className={classes.buttons}
            disabled={isLoading}
          >
            {t('Invitation.InvitationAccept')}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeclinedInvitation(pendingInvitation)}
            className={classes.buttons}
            disabled={isLoading}
          >
            {t('Invitation.InvitationDecline')}
          </Button>
        </Box>
      </ListItem>
    );
  });

  return (
    <Drawer anchor="top" open={isOpen} onClose={() => handleAskLater()}>
      <List>
        <ListItem divider color="secondary">
          <ListItemText
            primary={
              <Typography variant="h6">
                {t('Invitation.InvitationTitle')}
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                {t('Invitation.InvitationDescription', {
                  count: pendingInvitations.length,
                })}
              </Typography>
            }
          />

          <ListItemSecondaryAction>
            <Button
              variant="outlined"
              onClick={() => handleAskLater()}
              className={classes.buttons}
              disabled={isLoading}
            >
              {t('Invitation.InvitationAskMeLater')}
            </Button>
          </ListItemSecondaryAction>
        </ListItem>

        {invitationItems}
      </List>
      {isLoading && <LinearProgress color="secondary" />}
    </Drawer>
  );
}

Invitation.contextTypes = {
  apiClient: PropTypes.object.isRequired,
};

export default Invitation;
