import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  lastPulse: null,
});

function setLastPulse(state, payload) {
  const lastPulse = Array.isArray(payload) ? payload[0] : payload;
  return state.set('lastPulse', lastPulse);
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'HEALTH_LAST_PULSE_SET':
      return setLastPulse(state, action.payload);

    case 'HEALTH_LAST_PULSE_RESET':
      return INITIAL_STATE;

    default:
      return state;
  }
};
