import { DateTime } from 'luxon';
import userIcon from 'assets/user-icon.png';
import getOrDefault from 'utils/safe';

export default function User(user) {
  return {
    get id() {
      return getOrDefault(() => user.userId, '');
    },

    get name() {
      return {
        first: getOrDefault(() => user.name.first, ''),
        last: getOrDefault(() => user.name.last, ''),
        fullName: getOrDefault(
          () => user.name.first + ' ' + user.name.last,
          ''
        ),
      };
    },

    get profileImage() {
      const attemptToGetImage = () => {
        // This value is attempted to be retrieved when the user is a care giver.
        if (user.image) {
          return user.image;
        }

        // This value is attempted to be retrieved when the user is a care recipient.
        if (user.profileImage) {
          return user.profileImage;
        }

        return undefined;
      };

      return getOrDefault(attemptToGetImage, userIcon);
    },

    get location() {
      return {
        name: getOrDefault(() => user.location.locationData.data.title, ''),
        eventTime: getOrDefault(
          () => DateTime.fromISO(user.location.eventTime).toLocal().toJSDate(),
          new Date()
        ),
        lat: getOrDefault(() => user.location.lat, ''),
        lng: getOrDefault(() => user.location.lng, ''),
      };
    },

    get heartRate() {
      return {
        lastPulse: getOrDefault(
          () => DateTime.fromISO(user.pulse.eventTime).toLocal().toJSDate(),
          new Date()
        ),
        pulse: getOrDefault(() => user.pulse.pulse, ''),
        thresholds: getOrDefault(() => user.thresholds.heartrate, {}),
      };
    },

    get activity() {
      return {
        lastUpdate: getOrDefault(
          () => DateTime.fromISO(user.steps.lastUpdate).toLocal().toJSDate(),
          new Date()
        ),
        steps: getOrDefault(() => user.steps.steps, ''),
        thresholds: getOrDefault(() => user.thresholds.steps, []),
      };
    },
  };
}
