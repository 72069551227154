/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AsyncCreatable from 'react-select/lib/AsyncCreatable';
import CreatableSelect from 'react-select/lib/Creatable';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import makeHSL from 'styles/makeHSL';

import style from './style.module.scss';

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    margin: 12,
    fontSize: 16,
    cursor: 'text'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 15,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    // height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);

    let suggestions = [];

    if (props.suggestions) {
      suggestions = props.suggestions.map(suggestion => ({
        value: suggestion,
        label: suggestion,
      }));
    }

    this.state = {
      selectedValues: suggestions
    };
  }

  handleChange = name => (value) => {
    this.setState({
      [name]: value,
    }, () => {
      const { selectedValues } = this.state;
      const preFormatedselectedValues = Array.isArray(selectedValues) ?
        selectedValues : [selectedValues];
      let formatedData;
      if (preFormatedselectedValues.length > 0) {
        formatedData = preFormatedselectedValues.map((selectedValue) => {
          if (selectedValue !== null && selectedValue.value) {
            return selectedValue.phoneNumber
              ? { value: selectedValue.value, phoneNumber: selectedValue.phoneNumber }
              : selectedValue.value;
          }
          return false;
        });
      } else {
        formatedData = '';
      }
      return this.props.isMulti
        ? this.props.setOutValue(formatedData)
        : formatedData.length > 0 && !formatedData[0] === false
          ? formatedData[0].phoneNumber
            ? this.props.setOutValue({
              value: formatedData[0].value.trim(),
              phoneNumber: formatedData[0].phoneNumber
            })
            : this.props.setOutValue(formatedData[0].trim())
          : '';
    });
  };

  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        borderColor: 'makeHSL({ hue: 180, saturation: 0.6, lightness: 0.46 }) !important',
        '& input': {
          font: 'inherit',
          borderColor: 'makeHSL({ hue: 180, saturation: 0.6, lightness: 0.46 }) !important',
        },
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <div className={style.label}>
            {this.props.label}
          </div>
          {(this.props.async) &&
            <AsyncCreatable
              cacheOptions
              classes={classes}
              components={components}
              isMulti={this.props.isMulti}
              isSearchable
              noOptionsMessage={() => 'Enter atleast 2 characters to start search or enter wanted option to create a new one.'}
              onChange={this.handleChange('selectedValues')}
              placeholder={this.props.placeholder}
              loadOptions={this.props.loadOptions}
              styles={selectStyles}
              textFieldProps={{
                label: 'Label',
                InputLabelProps: {
                  shrink: true,
                },
              }}
              value={this.state.selectedValues}
            />
          }
          {(!this.props.async) &&
            <CreatableSelect
              classes={classes}
              components={components}
              isMulti={this.props.isMulti}
              noOptionsMessage={() => 'Enter atleast 2 characters to start search or enter wanted option to create a new one.'}
              onChange={this.handleChange('selectedValues')}
              placeholder={this.props.placeholder}
              options={this.props.loadOptions}
              styles={selectStyles}
              textFieldProps={{
                label: 'Label',
                InputLabelProps: {
                  shrink: true,
                },
              }}
              value={this.state.selectedValues}
            />
          }
          <div className={classes.divider} />
        </NoSsr>
      </div>
    );
  }
}

AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
  theme: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(styles, { withTheme: true })(AutoComplete);
