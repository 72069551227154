/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from 'components/Button/IconButton';
import style from './style.module.scss';
import { termsAndConditions } from '../../pages/TermsConditionsPrivacyPolicy.js';
import ReactMarkdown from 'react-markdown';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  content: {
    padding: theme.spacing(2)
  },
});
class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func
  };

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  createSlug(title) {
    return title.toLowerCase().replace(/\W/g, '-');
  }

  getRenders() {
    return {
      heading: ({ level, children }) => {
        const text = children[0].props.value;
        const slug = this.createSlug(text);

        return (
          <Typography id={slug} variant={`h${level}`}>
            {children}
          </Typography>
        );
      },
      listItem: ({ level, children }) => {
        return <ListItem>• {children}</ListItem>;
      },
      list: ({ level, children }) => {
        return <List>{children}</List>;
      },
    };
  }

  render() {
    const { classes } = this.props;

    return (
       <MuiDialog  onClose={() => this.onClose()} aria-labelledby="simple-dialog-title" open={open}>
       <DialogTitle id="simple-dialog-title">Terms & Conditions</DialogTitle>
       <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
        <div className={classes.content}>
          <ReactMarkdown renderers={() => this.getRenders()} source={termsAndConditions} />
        </div>
     </MuiDialog>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(['carerecipients', 'activeRecipient'], {});
  return { activeRecipient };
};

export default withStyles(styles)(connect(mapStateToProps)(Dialog));
