import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { setActiveCareRecipient } from 'actions/carerecipients';
import User from 'entities/user';

import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';

import makeHSL from 'styles/makeHSL';
import CareRecipientSwitcher from 'components/CareRecipientSwitcher';
import CommunicationDropdown from 'components/CommunicationDropdown';
import OrderSomethingDropdown from 'components/OrderSomethingDropdown';
import { useTranslation } from 'react-i18next';

import { NewReminderPicker } from 'components/NewReminderPicker';

import { ReminderTypes } from 'core/EventTypes';
import { Container } from '@material-ui/core';
import LiveClock from './LiveClock';

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #e4e8ec',
    boxShadow: 'none',
  },

  root: {
    flexGrow: 1,
  },

  img: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 30,
    width: 60,
    height: 60,
  },

  spacer: {
    flexGrow: 1,
  },

  spacer2: {
    flexGrow: 0.03,
  },

  careRecipientName: {
    width: 200,
    color: '#333',
  },
  profileButton: {
    marginLeft: theme.spacing(3),
  },

  buttonIconLeft: {
    marginRight: theme.spacing(1),
  },

  dividerContainer: {
    width: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  divider: {
    width: 1,
    height: 30,
    backgroundColor: makeHSL({ hue: 208, saturation: 0.12, lightness: 0.68 }),
  },

  actionBigScreenButton: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  actionSmallScreenButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  timezonebox: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconspace: {
    marginRight: theme.spacing(0.5),
  },
}));

const BottomAppBar = (_, context) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeCareRecipient = useSelector(
    (state) => state.getIn(['carerecipients', 'activeRecipient']),
    shallowEqual
  );
  const currentUser = useSelector(
    (state) => state.getIn(['user', 'self']),
    shallowEqual
  );

  const user = User(currentUser);
  const isRecipient = activeCareRecipient.userId === user.id;
  const crTimezone = activeCareRecipient.timezone;

  const careRecipientSelected = (recipient) => {
    setActiveCareRecipient(dispatch, recipient.userId);
  };

  const newReminderItemSelected = (item) => {
    const dialogNameToOpen = {
      [ReminderTypes.GENERAL_REMINDER]: 'generalReminder',
      [ReminderTypes.MEDICINE_REMINDER]: 'newMedication',
      [ReminderTypes.DESTINATION_CHECK]: 'newLocation',
      [ReminderTypes.WELLNESS_REMINDER]: 'newCheckin',
    };

    if (dialogNameToOpen[item]) {
      return context.dialogPush(dialogNameToOpen[item], {
        userId: activeCareRecipient.userId,
        userObject: activeCareRecipient,
      });
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header} color="inherit">
        <Container maxWidth={false}>
          <Toolbar disableGutters={true}>
            {!isRecipient && (
              <img
                className={classes.img}
                src={
                  activeCareRecipient ? activeCareRecipient.profileImage : ''
                }
                alt="leapthru"
              />
            )}
            <CareRecipientSwitcher
              onCareRecipientChange={careRecipientSelected}
            />
            <div className={classes.spacer2} />

            {isRecipient ? (
              ''
            ) : (
              <div className={classes.dividerContainer}>
                <div className={classes.divider} />
              </div>
            )}
            <div className={classes.spacer2} />

            <div className={classes.timezonebox}>
              <div>
                {isRecipient
                  ? t('BottomAppBar.MyZone')
                  : t('BottomAppBar.CRzone')}
              </div>
              <div>
                <FontAwesomeIcon
                  className={classes.iconspace}
                  size="1x"
                  icon={['far', 'clock']}
                />
                <LiveClock timezone={crTimezone} />{' '}
                <Tooltip
                  title={DateTime.local()
                    .setZone(crTimezone)
                    .setLocale('en-US')
                    .toFormat('ZZZZZ')}
                >
                  <Typography align="center" variant="caption">
                    (UTC
                    {DateTime.local()
                      .setZone(crTimezone)
                      .setLocale('en-US')
                      .toFormat('ZZ z')}{' '}
                    )
                  </Typography>
                </Tooltip>
              </div>
            </div>

            <div className={classes.spacer} />
            <Typography align="center" variant="h6">
              {t('BottomAppBar.GeneralDisclaimer')}
            </Typography>
            <div className={classes.spacer} />

            <CommunicationDropdown />

            <OrderSomethingDropdown />

            <div className={classes.dividerContainer}>
              <div className={classes.divider} />
            </div>
            <NewReminderPicker onItemSelected={newReminderItemSelected} />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

BottomAppBar.contextTypes = {
  dialogPush: PropTypes.func,
  dialogClose: PropTypes.func,
};

export default BottomAppBar;
