/* eslint-disable */

export default () => ({
  selectRoot: {
    // backgroundColor: 'yellow',
    // borderRadius: '4px',
    // overflow: 'hidden'
  },
  inputRoot: {
    borderRadius: '4px',
    overflow: 'hidden',
    border: '1px solid #cccccc',
    backgroundColor: 'white',
    height: 36
    // '&:before': {
    //   borderBottomWidth: '0',
    //   borderBottomColor: '#cccccc'
    // },
    // '&:after': {
    //   borderBottomColor: '#cccccc'
    // },
    // '&:focus': {
    //   borderBottomColor: '#cccccc'
    // }
  },
  select: {
    paddingLeft: '10px'
  }
});
