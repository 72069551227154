import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { useSnackbar } from 'notistack';

import { shallowEqual, useSelector } from 'react-redux';
import User from 'entities/user';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useActiveCareRecipientSelector } from 'reducers/carerecipients';

const useButtonStyles = makeStyles({
  rightIcon: {
    marginLeft: 8,
  },
});

const useStyles = makeStyles({
  root: {
    minWidth: 25,
  },

  profileImage: {
    border: '2px solid hsla(211, 15%, 46%, 1)',
  },

  ListItem: {
    backgroundColor: '#F0F0F0',
    height: 35,
  },

  textAndIconColor: {
    color: 'hsla(211, 15%, 46%, 1)',
  },
  dropDownButton: {
    marginLeft: 10,
  },
});

const CareRecipientSwitcher = ({ onCareRecipientChange = () => {} }) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  const activeRecipient = useSelector(
    (state) => state.getIn(['carerecipients', 'activeRecipient']),
    shallowEqual
  );

  const careRecipients = useSelector(
    (state) => state.getIn(['carerecipients', 'recipients']),
    shallowEqual
  );

  const currentUser = useSelector(
    (state) => state.getIn(['user', 'self']),
    shallowEqual
  );

  const user = User(activeRecipient);
  const activeUser = User(currentUser);
  const recipients = Object.values(careRecipients.toJS());
  const activeCareRecipient = useActiveCareRecipientSelector();
  const isRecipient = activeCareRecipient.userId === activeUser.id;

  const [anchor, setAnchor] = useState(null);
  const { t } = useTranslation();

  function handleClick(event) {
    setAnchor(event.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  function handleCareRecipientChange(recipient) {
    onCareRecipientChange(recipient);
    handleClose();
    window.location.reload();
  }

  const id = 'CareRecipientSwitcher';

  return (
    <React.Fragment>
      {!isRecipient && (
        <Button
          aria-owns={anchor ? id : undefined}
          aria-haspopup="true"
          className={classes.dropDownButton}
          onClick={(e) => handleClick(e)}
        >
          {user.name.fullName}
          <FontAwesomeIcon
            size="xs"
            className={buttonClasses.rightIcon}
            icon={['fal', 'chevron-down']}
          />
        </Button>
      )}
      {!isRecipient && (
        <Menu
          id={id}
          anchorEl={anchor}
          getContentAnchorEl={null}
          variant="menu"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchor)}
          onClose={handleClose}
        >
          <List disablePadding>
            <ListItem
              button
              component={Link}
              to="/cr-settings/medical-profile"
              onClick={() => handleClose()}
            >
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon="id-card"
                  size="1x"
                  className={classes.textAndIconColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('CareRecipientSwitcher.CareProfile')}
                className={classes.textAndIconColor}
              />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/caregivers"
              onClick={() => handleClose()}
            >
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon="users"
                  size="1x"
                  className={classes.textAndIconColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('CareRecipientSwitcher.CareTeam')}
                className={classes.textAndIconColor}
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/chat"
              onClick={() => handleClose()}
            >
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon="comments"
                  size="1x"
                  className={classes.textAndIconColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('CareRecipientSwitcher.GroupChat')}
                className={classes.textAndIconColor}
              />
            </ListItem>

            <Divider />

            <ListItem
              button
              component={Link}
              to="/cr-settings"
              onClick={() => handleClose()}
            >
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon="cog"
                  size="1x"
                  className={classes.textAndIconColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('CareRecipientSwitcher.Settings')}
                className={classes.textAndIconColor}
              />
            </ListItem>
            <Divider />

            <ListItem className={classes.ListItem}>
              <ListItemText
                secondary={t(
                  'CareRecipientSwitcher.SwitchToCareRecipient'
                ).toUpperCase()}
              />
            </ListItem>
            <Divider />

            {(recipients || []).map((recipient, index, array) => {
              const divider = index !== array.length - 1;
              return (
                <ListItem
                  id={'careRecipientSelector' + index}
                  key={index}
                  dense
                  divider={divider}
                  button
                  onClick={() => handleCareRecipientChange(recipient)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="profile-image"
                      src={User(recipient).profileImage}
                      className={classes.profileImage}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={User(recipient).name.fullName} />
                </ListItem>
              );
            })}
          </List>
        </Menu>
      )}
    </React.Fragment>
  );
};

CareRecipientSwitcher.propTypes = {
  onCareRecipientChange: PropTypes.func,
};

export default CareRecipientSwitcher;
