import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  locations: [],
  lastKnownLocation: '',
  coordsAddresses: {},
});

const setLocations = (state, locations) => state.set('locations', locations);
// const setLastKnownLocation = (state, lastKnownLocation) =>
//   state.set('lastKnownLocation', lastKnownLocation);
const setCoordsAddress = (state, coordsAddress) =>
  state.setIn(
    ['coordsAddresses', `${coordsAddress.lat}_${coordsAddress.lng}`],
    coordsAddress
  );

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GEOCODE_SET_LOCATIONS':
      return setLocations(state, action.payload);

    case 'GEOCODE_SET_COORDS_ADDRESS':
      return setCoordsAddress(state, action.payload);

    default:
      return state;
  }
};
