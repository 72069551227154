/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from 'components/Button/IconButton';
import GeneralReminder from './GeneralReminder'
import moment from 'moment-timezone';
import { getEventTimline } from 'actions/event';
import { getCalendar } from 'actions/calendar';
import {
  MenuItem,
  Select,
  FormControl,
} from '@material-ui/core';
import style from './style.module.scss';

import NewMedication from './NewMedication';
import DestinationCheck from './DestinationCheck';
import WellnessCheck from './WellnessCheck';

const REMINDER_TYPES = {
  generalReminder: {
    value: 'general',
    text: 'General reminder',
  },
  medication: {
    value: 'medication',
    text: 'Medication'
  },
  destinationCheck: {
    value: 'location',
    text: 'Destination check'
  },
  wellnessCheck: {
    value: 'wellness',
    text: 'Wellness check'
  }
};

class CreateReminder extends React.Component {
  static contextTypes = {
    dialogClose: PropTypes.func,
    apiClient: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedReminderType: REMINDER_TYPES.generalReminder,
    };

    console.log('PROPS', props);
  }

  componentDidMount() {
    if (this.props.data.eventData) {
      this.setState({
        selectedReminderType: this.getReminderType(this.props.data.eventData.type)
      });
    } else {
      this.setState({
        selectedReminderType: REMINDER_TYPES.generalReminder
      });
    }
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onSave() {
    const from = moment().clone().startOf('week').toDate();
    const to = moment().clone().endOf('week').toDate();
    const { userId } = this.props.activeRecipient;
    getEventTimline(this.props.dispatch, this.context.apiClient, userId, from, to); // eslint-disable-line
    getCalendar(this.props.dispatch, this.context.apiClient, userId);
    this.onClose();
  }

  getReminderType(type) {
    switch (type) {
      case 'general':
        return REMINDER_TYPES.generalReminder;
      case 'medicine':
        return REMINDER_TYPES.medication;
      case 'location':
        return REMINDER_TYPES.destinationCheck;
      case 'wellness':
        return REMINDER_TYPES.wellnessCheck;
      default:
        return '';
    }
  }

  renderReminderForm() {
    switch (this.state.selectedReminderType.value) {
      case REMINDER_TYPES.generalReminder.value:
        return (
          <GeneralReminder
            data={{
              isFromCareGiverCalendar: false,
              userId: this.props.activeRecipient.userId,
              userObject: this.props.activeRecipient,
              eventData: this.props.data.eventData,
              currentStep: this.props.data.currentStep
            }}
            onSave={() => this.onSave()}
          />
        );
      case REMINDER_TYPES.medication.value:
        return (
          <NewMedication
            data={{
              eventData: this.props.data.eventData,
              currentStep: this.props.data.currentStep
            }}
            onSave={() => this.onSave()}
          />
        );
      case REMINDER_TYPES.destinationCheck.value:
        return (
          <DestinationCheck
            data={{
              userId: this.props.activeRecipient.userId,
              eventData: this.props.data.eventData,
              currentStep: this.props.data.currentStep
            }}
            onSave={() => this.onSave()}
          />
        );
      case REMINDER_TYPES.wellnessCheck.value:
        return (
          <WellnessCheck
            data={{
              userId: this.props.activeRecipient.userId,
              eventData: this.props.data.eventData,
              currentStep: this.props.data.currentStep
            }}
            onSave={() => this.onSave()}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className={[style.background]}>
        <div className={style.container}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            <div className={style.spacingTop}>
              <FormControl fullWidth>
                <div className={style.typeLabel}>Reminder type</div>
                <Select
                  placeholder="Reminder type"
                  fullWidth
                  className={style.select}
                  value={this.state.selectedReminderType}
                  onChange={(event) => {
                    console.log('asdasd', this.state.selectedReminderType);
                    this.setState({
                      selectedReminderType: event.target.value
                    });
                  }}
                >
                  {Object.entries(REMINDER_TYPES).map(([key, value]) => (
                    <MenuItem key={`reminder_type_${key}`} value={value}>
                      {value.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              {this.state.selectedReminderType && this.renderReminderForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeRecipient: state.getIn(['carerecipients', 'activeRecipient'])
  };
};

export default connect(mapStateToProps)(CreateReminder);
