import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  callStatus: null,
  callData: null,
});

export function setCallData(state, status, callData) {
  return state.merge({ callStatus: status, callData });
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CALL_RECEIVED':
      return setCallData(state, 'received', action.payload);
    case 'CALL_CLOSE':
      return setCallData(state, 'closed', null);
    default:
      return state;
  }
};
