/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Select from 'components/Select';
import Title from 'components/Title';

import { createCareRecipient } from 'actions/carerecipients';

import { MenuItem, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { getTimezoneOptions } from 'utils/date';

import style from './style.module.scss';

const styles = () => ({
  textFieldRoot: {},
  inputRoot: {
    height: 36,
    backgroundColor: 'white',
    padding: '8px 0 8px 8px',
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: 4
  },
  inputMultiLine: {
    backgroundColor: 'white',
    padding: '8px 0 8px 8px',
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: 4
  },
  inputFocused: {},
  input: {
    padding: 0
  },
  adornment: {
    alignSelf: 'center'
  }
});

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      couponId: '',
      firstName: '',
      lastName: '',
      password: '',
      saving: false,
      saveError: '',
      username: '',
      timezone: moment.tz.guess()
    };
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onNextClick() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  onBackClick() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  onSaveClick() {
    if (!this.state.saving) {
      this.setState({ saving: true });
      const name = {
        first: this.state.firstName,
        last: this.state.lastName,
      };
      const toSave = {
        couponId: this.state.couponId,
        name,
        password: this.state.password,
        timezone: this.state.timezone,
        username: this.state.username,
      };

      createCareRecipient(this.props.dispatch, this.context.apiClient, toSave)
        .then(() => this.setState({ saving: false }, () => this.onClose()))
        .catch((err) => {
          this.setState({ saveError: err.error, saving: false });
        });
    }
  }
  renderStep1() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Title>
          Enter coupon ID
        </Title>
        <div className={style.stepContent}>
          <div className={style.title}>
            <div className={style.field}>
              <div className={style.label}>Coupon ID</div>
              <TextField
                fullWidth
                value={this.state.couponId}
                onChange={event => this.setState({ couponId: event.target.value })}
                classes={{ root: classes.textFieldRoot }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderStep2() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Title>
          Set Care Recipients name
        </Title>
        <div className={style.stepContent}>
          <div className={style.title}>
            <div className={style.field}>
              <div className={style.label}>First name</div>
              <TextField
                fullWidth
                value={this.state.firstName}
                onChange={event => this.setState({ firstName: event.target.value })}
                classes={{ root: classes.textFieldRoot }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
              />
            </div>
          </div>
          <div className={style.title}>
            <div className={style.field}>
              <div className={style.label}>Last name</div>
              <TextField
                fullWidth
                value={this.state.lastName}
                onChange={event => this.setState({ lastName: event.target.value })}
                classes={{ root: classes.textFieldRoot }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className={style.title}>
          <div className={style.field}>
            <div className={style.label}>Care recipients timezone</div>
            <Select
              value={this.state.timezone}
              onChange={event => this.setState({ timezone: event.target.value })}
              className={style.field}
            >
              {getTimezoneOptions().map(t => (
                <MenuItem key={`timezone_${t.value}`} value={t.value}>
                  {t.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderStep3() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Title>
          Set credentials
        </Title>
        <div className={style.stepContent}>
          <div className={style.title}>
            <div className={style.field}>
              <div className={style.label}>Username</div>
              <TextField
                fullWidth
                value={this.state.username}
                onChange={event => this.setState({ username: event.target.value })}
                classes={{ root: classes.textFieldRoot }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
              />
            </div>
          </div>
          <div className={style.title}>
            <div className={style.field}>
              <div className={style.label}>Password</div>
              <TextField
                fullWidth
                value={this.state.password}
                onChange={event => this.setState({ password: event.target.value })}
                classes={{ root: classes.textFieldRoot }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
              />
            </div>
          </div>
        </div>
        {this.state.saveError && (
          <div className={style.saveError}>An error occurred: {this.state.saveError}</div>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div
        className={[style.background, style[this.props.transition]].join(' ')}
      >
        <div className={style.container}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            {this.state.currentStep > 1 && (
              <IconButton
                onClick={() => this.onBackClick()}
                className={style.buttonBack}
                icon="arrow_back"
                variant="fab"
                mini
              />
            )}
            <div className={style.content}>
              {this.state.currentStep === 1 && this.renderStep1()}
              {this.state.currentStep === 2 && this.renderStep2()}
              {this.state.currentStep === 3 && this.renderStep3()}
            </div>
            <div>
              {this.state.currentStep !== 3 && (
                <Button
                  disabled={false}
                  className={style.nextButton}
                  onClick={() => this.onNextClick()}
                >
                  NEXT
                </Button>
              )}
              {this.state.currentStep === 3 && (
                <Button
                  disabled={false}
                  className={style.nextButton}
                  onClick={() => this.onSaveClick()}
                >
                  CREATE
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(['carerecipients', 'activeRecipient'], {});
  return { activeRecipient };
};

export default withStyles(styles)(connect(mapStateToProps)(Dialog));
