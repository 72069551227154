/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RRule from 'rrule';
import moment from 'moment-timezone';
import set from 'lodash.set';
import get from 'lodash.get';
import classNames from 'classnames/bind';

import {
  MenuItem,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  CircularProgress
} from '@material-ui/core';

import AutoComplete from 'components/AutoComplete';
import Button from 'components/Button';
import Field from 'components/DialogField';
import IconButton from 'components/Button/IconButton';
import OptionSelect from 'components/OptionSelect';
import RequiredFieldIndicator from 'components/RequiredFieldIndicator';
import SearchField from 'components/DialogSearchField';
import Select from 'components/Select';
import Switch from 'components/Switch';
import Title from 'components/Title';

import { getContacts } from 'actions/contacts';
import { searchProducts, addMedicineEvent, editMedicineEvent, searchConditions } from 'actions/medicine';
import { filterContacts } from 'reducers/contacts';

import { getMinutes, getTimeByTimezone, toToday } from 'utils/date';
import {
  parseEventForEdit,
  whenToTakeOptions,
  whenToTakeKeys,
  refillIntervalOptions,
  refillIntervalKeys
} from 'utils/medication';

import style from './style.module.scss';

const cx = classNames.bind(style);

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
    hasPermission: PropTypes.func
  };

  constructor(props) {
    super(props);

    const data = this.props.data || {};
    const { currentStep = 1, eventData } = data;
    const edit = !!eventData;
    let out = {
      medicine: [
        {
          Term: 'short',
          alerts: []
        }
      ],
      type: 'medicine',
      recurrenceDescription: whenToTakeOptions.as_needed.label
    };

    let refillCount = 0;

    if (edit) {
      out = parseEventForEdit(eventData);
      if (!out.medicine[0].Pharmacy) {
        out.medicine[0].Pharmacy = '';
      }
      if (!out.medicine[0].Doctor) {
        out.medicine[0].Doctor = '';
      }
      if (out.data[0].refill) {
        const rule = RRule.fromString(out.data[0].refill);
        refillCount = rule.origOptions.count;
      } else {
        out.medicine[0].refillCount = refillCount;
      }
    }

    const isOngoing = !(get(out, 'endDate', false));
    this.state = {
      out,
      currentStep,
      search: '',
      searchResults: [],
      saveError: '',
      saving: false,
      disableRecurrenceDescription: false,
      edit,
      isOngoing,
      refillCount
    };

    if (props && props.activeRecipient) {
      this.state.activeUserId = props.activeRecipient.userId;
    }

    this.loadConditionOptions = this.loadConditionOptions.bind(this);
    // this.loadDoctorOptions = this.loadDoctorOptions.bind(this);
    // this.loadPharmacyOptions = this.loadPharmacyOptions.bind(this);
  }

  componentDidMount() {
    if (this.state.activeUserId) {
      this.fetchContacts();
    }
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onNextClick() {
    const { currentStep } = this.state;
    if (currentStep === 1) {
      const medicine = this.getSelectedMedicine();
      // const { ActiveIngredient } = get(this.state.out, 'medicine[0]', {});
      // if (!ActiveIngredient || ActiveIngredient === medicine.ActiveIngredient) {
      this.setOutValue('medicine[0].ActiveIngredient', medicine.ActiveIngredient);
      // }
    }
    this.setState({ currentStep: currentStep + 1 });
  }

  onBackClick() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  onEditClick(step) {
    this.setState({ currentStep: this.state.currentStep = step });
  }

  onSaveClick() {
    if (!this.state.saving) {
    // if (true) {
      this.setState({ saving: true });
      const { userId, timezone } = this.props.activeRecipient;

      const toSave = {
        userId,
        timezone,
        recurrence: '',
        refill: '',
        ...this.state.out
      };

      const medicine = toSave.medicine[0];
      const { whenToTake } = medicine;
      const refillCount = Number(medicine.refillCount) || 0;

      // setting rest of medicine data
      const medicineResult = this.getSelectedMedicine();
      if (medicineResult) {
        toSave.medicine[0] = { ...medicineResult, ...medicine };
      }

      // setting when to take rrule
      if (whenToTake && whenToTakeOptions[whenToTake]) {
        const selectedWhenToTake = whenToTakeOptions[whenToTake];
        const rruleOpts = {
          ...selectedWhenToTake.rrule,
          tzid: timezone,
          byminute: (medicine.alerts || []).map(time => getMinutes(time))
        };
        if (toSave.startDate) {
          const startDate = moment(toSave.startDate).toDate();
          rruleOpts.dtstart = new Date(Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          ));
        }
        if (toSave.endDate && !this.state.isOngoing) {
          const endDate = moment(toSave.endDate).toDate();
          rruleOpts.until = new Date(Date.UTC(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
            23,
            59
          ));
        }

        const rrule = new RRule(rruleOpts);
        toSave.recurrence = rrule.toString();
      }

      // setting refill rrule
      if (refillCount > 0) {
        const selectedInterval =
          refillIntervalOptions[toSave.refillReminderInterval || refillIntervalKeys[0]];
        const time = moment(medicine.refillReminderTime);
        let dtstart = moment(medicine.beginReminders)
          .second(0)
          .millisecond(0);
        if (time.isValid()) {
          dtstart = dtstart.hour(time.hour()).minute(time.minute());
        }
        const rrule = new RRule({
          ...selectedInterval.rrule,
          count: refillCount,
          tzid: timezone,
          dtstart: dtstart.isValid() ? new Date(Date.UTC(
            dtstart.year(),
            dtstart.month(),
            dtstart.date(),
            dtstart.hours(),
            dtstart.minutes()
          )) : null
        });
        toSave.refill = rrule.toString();

        if (toSave.refillFilters === 'caregiver') {
          toSave.refillFilters = [{
            type: 'userType',
            key: 'caregiver'
          }];
        } else if (toSave.refillFilters === 'carereceiver') {
          toSave.refillFilters = [{
            type: 'userType',
            key: 'carereceiver'
          }];
        } else {
          delete toSave.refillFilters;
        }
      } else {
        delete toSave.refill;
        delete toSave.refillFilters;
      }

      (this.state.edit ? editMedicineEvent : addMedicineEvent)(
        this.props.dispatch,
        this.context.apiClient,
        toSave
      )
        .then(() => this.setState({ saving: false }, () => this.onClose()))
        .catch(err => this.setState({ saveError: err.error, saving: false }));
    }
    this.props.onSave();
  }

  onSearchChange(event) {
    this.setState({ search: event.target.value });
  }

  onNewMedicationClick() {
    this.setOutValue('medicine[0].DrugName', '');
    this.setOutValue('medicine[0].Form', '');
    this.setOutValue('medicine[0].Strength', '');
    this.setOutValue('medicine[0].ActiveIngredient', '');
    this.onNextClick();
  }

  onOngoingChange = name => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  getSelectedMedicine() {
    const [selected] = this.state.out.medicine;
    const selectedResults = this.state.searchResults[selected.DrugName] || [];
    return (
      selectedResults.find(result => result.Form === selected.Form && result.Strength === selected.Strength) || selected // eslint-disable-line max-len
    );
  }

  setRefillreminderAndRefillFiltersValue(key, val) {
    if (key === 'medicine[0].refillCount' && val > 0) {
      if (!this.state.out.refillReminderInterval && !this.state.out.refillFilters) {
        this.setState({
          out: {
            ...set(this.state.out, 'refillReminderInterval', 'week_1'),
            ...set(this.state.out, 'refillFilters', 'both')
          }
        });
      }
    } else if (key === 'medicine[0].refillCount' && val === '0') {
      this.setState({
        out: {
          ...set(this.state.out, 'refillReminderInterval', ''),
          ...set(this.state.out, 'refillFilters', '')
        }
      });
    }
  }

  setOutValue(key, val) {
    this.setState(
      { out: set(this.state.out, key, (val && val.phoneNumber) ? val.value : val) },
      () => {
        if (key === 'medicine[0].Pharmacy' && val && val.phoneNumber) {
          this.setState({
            out: set(this.state.out, 'medicine[0].PharmacyPhone', val.phoneNumber)
          });
        }
        if (key === 'medicine[0].Doctor' && val && val.phoneNumber) {
          this.setState({
            out: set(this.state.out, 'medicine[0].DoctorPhone', val.phoneNumber)
          });
        }
        if (key === 'medicine[0].refillCount' && val) {
          this.setRefillreminderAndRefillFiltersValue(key, val);
        }
      }
    );
  }

  fetchContacts() {
    const { hasPermission } = this.context;
    if (hasPermission('ContactService.getContact') || hasPermission('ContactService.getContacts')) {
      getContacts(this.props.dispatch, this.context.apiClient, this.state.activeUserId);
    }
  }

  loadConditionOptions(inputValue) {
    return searchConditions(this.props.dispatch, this.context.apiClient, inputValue).then(res =>
      res.data.conditions.map(c => ({ value: c.name, label: c.name })));
  }

  searchProducts(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ searching: true });
    searchProducts(this.props.dispatch, this.context.apiClient, this.state.search)
      .then((res) => {
        this.setState({
          searchResults: res.data.reduce((obj, product) => {
            if (!obj[product.DrugName]) {
              obj[product.DrugName] = [];
            }
            obj[product.DrugName].push(product);
            return obj;
          }, {}),
          searching: false
        });
      })
      .catch(() => this.setState({ searching: false }));
    return false;
  }

  renderField(type, outKey, label, props, defaultValue) {
    const showIndicator = get(props, 'required', false);
    const isOngoing = get(props, 'onGoing', false);

    if (type === 'optionSelectLabelValue') {
      return (
        <OptionSelect
          label={label}
          value={get(this.state.out, outKey, defaultValue || '')}
          onChange={val => this.setOutValue(outKey, val)}
          className={style.optionSelect}
          {...props}
          options={props.options.map(o => ({ label: o.label, value: o.value }))}
        />
      );
    } else if (type === 'optionSelect') {
      return (
        <OptionSelect
          label={label}
          value={get(this.state.out, outKey, defaultValue || '')}
          onChange={val => this.setOutValue(outKey, val)}
          className={style.optionSelect}
          {...props}
          options={props.options.map(o => ({ label: o, value: o }))}
        />
      );
    } else if (type === 'text') {
      return (
        <div className={style.field} key={`field_${outKey}`}>
          <div className={style.label}>
            {label} {showIndicator ? <RequiredFieldIndicator /> : false}
          </div>
          <Field
            type={get(props, 'type', 'text')}
            value={get(this.state.out, outKey, defaultValue || '')}
            onChange={event => this.setOutValue(outKey, get(event, 'target.value', event))}
            {...props}
          />
        </div>
      );
    } else if (type === 'autocomplete-text') {
      return (
        <div className={style.field} key={`field_${outKey}`}>
          <AutoComplete
            setOutValue={data => this.setOutValue(outKey, data)}
            label={label}
            placeholder={props.placeholder}
            loadOptions={props.loadOptions}
            suggestions={props.suggestions}
            async={props.async}
          />
        </div>
      );
    } else if (type === 'date') {
      return (
        <div
          className={
            cx(
              style.field,
              { [style.ongoing]: isOngoing }
            )}
          key={`field_${outKey}`}
        >
          <div className={style.label}>
            {label} {showIndicator ? <RequiredFieldIndicator /> : false}
          </div>
          <Field
            type={type}
            value={get(this.state.out, outKey, defaultValue || null)}
            onChange={event => this.setOutValue(outKey, get(event, 'target.value', event))}
            {...props}
          />
        </div>
      );
    } else if (type === 'time') {
      const value = get(this.state.out, outKey, defaultValue || null);
      const time = moment(value);
      const { timezone } = this.props.activeRecipient;
      return (
        <div className={style.field} key={`field_${outKey}`}>
          <div className={style.label}>
            {label} {showIndicator ? <RequiredFieldIndicator /> : false}
          </div>
          <div className={style.timeField}>
            <Field
              type={type}
              value={value}
              onChange={event => this.setOutValue(outKey, get(event, 'target.value', event))}
              {...props}
            />
            {time.isValid() && (
              <div className={style.time}>
                Care recipient
                {"'"}s time zone:{' '}
                <strong>{getTimeByTimezone(toToday(time), timezone).format('h:mm A')}</strong>
              </div>
            )}
          </div>
        </div>
      );
    } else if (type === 'checkbox') {
      return (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              color="default"
              checked={get(this.state.out, outKey, defaultValue || false)}
              onChange={event => this.setOutValue(outKey, event.target.value)}
              {...props}
            />
          }
        />
      );
    } else if (type === 'select') {
      return (
        <div className={`${style.field} ${style.selectField}`} key={`field_${outKey}`}>
          <div className={style.label}>{label}</div>
          <Select
            value={get(this.state.out, outKey, defaultValue || '')}
            onChange={event => this.setOutValue(outKey, event.target.value)}
            className={style.select}
            {...props}
          >
            {props &&
              props.options &&
              props.options.map(option => (
                <MenuItem key={`select_${outKey}_${option.value}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </div>
      );
    } else if (type === 'select-refill') {
      return (
        <div className={`${style.field} ${style.selectField}`} key={`field_${outKey}`}>
          <div className={style.label}>{label}</div>
          <Select
            value={get(this.state.out, outKey, defaultValue || '')}
            onChange={(event) => {
              this.setState({ refillCount: event.target.value });
              return (this.setOutValue(outKey, event.target.value));
            }}
            className={style.select}
            {...props}
          >
            {props &&
              props.options &&
              (() => props.options.map(option => (
                <MenuItem key={`select_${outKey}_${option.value}`} value={option.value}>
                  {option.label}
                </MenuItem>
                )))()}
          </Select>
        </div>
      );
    }
    return null;
  }

  renderStep1() {
    const { out, searchResults, searching } = this.state;
    const searchResultKeys = Object.keys(searchResults);

    const searchResultLabelKeys = [];

    for (let a = 0; a < searchResultKeys.length; a += 1) {
      const key = searchResultKeys[a];
      const item = searchResults[key][0];

      const label = `${item.DrugName} (${item.ActiveIngredient})`;
      searchResultLabelKeys.push({ label, value: key });
    }

    return (
      <div>
        <Title icon="assignment" iconColor="#afe67b">
          Search Drug (1/5)
        </Title>
        <div className={style.searchContainer}>
          <div className={style.fieldContainer}>
            <form onSubmit={event => this.searchProducts(event)}>
              <SearchField
                value={this.state.search}
                onChange={event => this.onSearchChange(event)}
              />
            </form>
          </div>
          <div className={style.or}>OR</div>
          <div className={style.newMedicationContainer}>
            <Button onClick={() => this.onNewMedicationClick()}>New Medication</Button>
          </div>
        </div>
        {searching && <CircularProgress />}
        {!searching && (
          <div>
            {this.renderField(
              'optionSelectLabelValue',
              'medicine[0].DrugName',
              'Select Proprietary Name',
              { options: searchResultLabelKeys }
            )}
            {out.medicine[0].DrugName &&
              this.renderField('optionSelect', 'medicine[0].Form', 'Select Drug formulation', {
                options: [
                  // eslint-disable-next-line
                  ...new Set(
                    (searchResults[out.medicine[0].DrugName] || []).map(result => result.Form))
                ]
              })}
            {out.medicine[0].Form &&
              this.renderField('optionSelect', 'medicine[0].Strength', 'Select Drug strength', {
                options: [
                  ...new Set((searchResults[out.medicine[0].DrugName] || []).reduce(
                      (arr, result) =>
                        result.Form === out.medicine[0].Form ? [...arr, result.Strength] : arr,
                      []
                    ))
                ]
              })}
          </div>
        )}
      </div>
    );
  }

  renderStep2() {
    const selectedMedicine = this.getSelectedMedicine() || {};
    return (
      <div>
        <Title icon="assignment" iconColor="#afe67b">
          Prescription Information (2/5)
        </Title>
        <div className={style.selectedMedication}>
          {selectedMedicine.DrugName} | {selectedMedicine.ActiveIngredient} |{' '}
          {selectedMedicine.Form} | {selectedMedicine.Strength}
        </div>
        <div className={style.fields}>
          {this.renderField('text', 'medicine[0].Prescription', 'Prescription number', { required: true })}
          <div className={style.field}>
            <div className={style.label}>Short or long term medication</div>
            <RadioGroup
              value={get(this.state.out, 'medicine[0].Term')}
              onChange={event => this.setOutValue('medicine[0].Term', event.target.value)}
              row
            >
              <FormControlLabel
                value="short"
                control={<Radio color="default" style={{ padding: '6px 12px' }} />}
                label="Short term"
              />
              <FormControlLabel
                value="long"
                control={<Radio color="default" style={{ padding: '6px 12px' }} />}
                label="Long term"
              />
            </RadioGroup>
          </div>
          {this.renderField('text', 'medicine[0].DrugName', 'Brand name', { required: true })}
          {this.renderField(
            'text',
            'medicine[0].ActiveIngredient',
            'Generic Name',
            { required: true },
            selectedMedicine.ActiveIngredient
          )}
          {this.renderField('text', 'medicine[0].Strength', 'Strength', { required: true })}
          {this.renderField('text', 'medicine[0].Form', 'Drug Formulation', { required: true })}
          {this.renderField('text', 'medicine[0].Dosage', 'Amount to take', { required: true })}
          {this.renderField(
            'select',
            'medicine[0].whenToTake',
            'When to take',
            {
              options: whenToTakeKeys.map(w => ({
                label: whenToTakeOptions[w].label,
                value: w
              })),
              onChange: (event) => {
                const { value } = event.target;
                this.setOutValue('medicine[0].whenToTake', value);

                const { label: description } = whenToTakeOptions[value];
                this.setOutValue('recurrenceDescription', description);
                this.setState({ disableRecurrenceDescription: value !== 'as_needed' });
              }
            },
            whenToTakeKeys[0]
          )}
          {this.renderField('text', 'medicine[0].Duration', 'Duration')}
          {this.renderField('text', 'medicine[0].NumberOfDosages', 'Quantity dispensed', { type: 'number' })}
          {this.renderField('text', 'medicine[0].Instructions', 'Special Instructions')}
          {this.renderField('autocomplete-text', 'medicine[0].Condition', 'Primary Medical Condition', {
            placeholder: 'Search medical conditions',
            suggestions: this.state.edit ? [this.state.out.medicine[0].Condition] : false,
            loadOptions: this.loadConditionOptions,
            async: true
          })}
          {this.renderField('autocomplete-text', 'medicine[0].Pharmacy', 'Pharmacy', {
            placeholder: 'Search contacts',
            suggestions: this.state.edit ? [this.state.out.medicine[0].Pharmacy] : false,
            loadOptions: this.props.contacts ?
              this.props.contacts.map(c => ({
                value: c.name,
                label: c.name,
                phoneNumber: c.phoneNumber ? c.phoneNumber : ''
              })) : [],
            async: false
          })}
          {this.renderField('text', 'medicine[0].PharmacyPhone', 'Pharmacy phone number')}
          {this.props.contacts && this.renderField('autocomplete-text', 'medicine[0].Doctor', 'Doctor', {
            placeholder: 'Search contacts',
            suggestions: this.state.edit ? [this.state.out.medicine[0].Doctor] : false,
            loadOptions: this.props.contacts ?
              this.props.contacts.map(c => ({
                value: c.name,
                label: c.name,
                phoneNumber: c.phoneNumber ? c.phoneNumber : ''
              })) : [],
            async: false,
          })}
          {this.renderField('text', 'medicine[0].DoctorPhone', 'Doctor phone number', {
            type: 'tel'
          })}
        </div>
      </div>
    );
  }

  renderStep3() {
    const { whenToTake: whenToTakeSelected } = this.state.out.medicine[0];
    const { alerts } = get(whenToTakeOptions, whenToTakeSelected, { alerts: 1 });
    return (
      <div>
        <Title icon="assignment" iconColor="#afe67b">
          Medication reminders (3/5)
        </Title>
        <div className={style.dateRangeFields}>
          {this.renderField('date', 'startDate', 'Start date', { placeholder: 'Start date', required: true })}
          {this.renderField('date', 'endDate', 'End date', { placeholder: 'End date', isActive: true, onGoing: this.state.isOngoing })}
          <div className={style.fillAvailable}>
            <Switch
              checked={this.state.isOngoing}
              onChange={this.onOngoingChange('isOngoing')}
            />
            <span className={style.switchLabel}>Ongoing</span>
          </div>
        </div>
        <div className={style.fields}>
          {Array.from(Array(alerts), (_, num) =>
            this.renderField(
              'time',
              `medicine[0].alerts[${num}]`,
              `Time alert ${num + 1}`,
              { required: this.state.out.recurrenceDescription !== 'As needed' },
              null
            ))}
        </div>
        <div className={style.fields}>
          {this.renderField('text', 'recurrenceDescription', 'Frequency description', {
            disabled: this.state.disableRecurrenceDescription
          })}
        </div>
      </div>
    );
  }

  renderStep4() {
    return (
      <div>
        <Title icon="assignment" iconColor="#afe67b">
          Refill reminders (4/5)
        </Title>
        <div>
          {this.renderField(
            'select-refill',
            'medicine[0].refillCount',
            'Number of refills',
            {
              options: Array.from(Array(10), (_, count) => ({
                label: `${count}`,
                value: `${count}`
              }))
            },
            '0'
          )}
          {(this.state.refillCount > 0) &&
          (
            <React.Fragment>
              {this.renderField(
                'date',
                'medicine[0].beginReminders',
                'When would you like the refill reminders to begin?',
                { required: true },
                null
              )}
              {this.renderField(
                'time',
                'medicine[0].refillReminderTime',
                'Set the time for refill reminder',
                { required: true },
                null
              )}
              {this.renderField(
                'select',
                'refillReminderInterval',
                'How long in-between refills would you like this reminder?',
                {
                  options: refillIntervalKeys.map(w => ({
                    label: refillIntervalOptions[w].label,
                    value: w
                  })),
                },
                refillIntervalKeys[0]
              )}
              {this.renderField(
                'select',
                'refillFilters',
                'Visible for',
                {
                  options: [{
                    label: 'Caregiver and Care Recipient',
                    value: 'both',
                  },
                  {
                    label: 'Caregiver',
                    value: 'caregiver',
                  },
                  {
                    label: 'Care Recipient',
                    value: 'carereceiver',
                  }]
                },
                'both'
              )}
            </React.Fragment>
        )}
        </div>
        {this.state.saveError && (
          <div className={style.saveError}>An error occurred: {this.state.saveError}</div>
        )}
      </div>
    );
  }

  renderStep5() {
    const visibleFor = {
      both: { label: 'Caregiver and Care Recipient' },
      caregiver: { label: 'Caregiver' },
      carereceiver: { label: 'Care Recipient' }
    };
    return (
      <div>
        <Title icon="assignment" iconColor="#afe67b">
          Details & Confirmation (5/5)
        </Title>
        <Title>
          Prescription Information
          <IconButton
            variant="fab"
            mini
            icon="edit"
            className={style.button}
            onClick={() => this.onEditClick(2)}
          />
        </Title>
        <div className={style.fields}>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Prescription number:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Prescription ? this.state.out.medicine[0].Prescription : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Short or long term medication:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Term ? this.state.out.medicine[0].Term : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Brand name:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].DrugName ? this.state.out.medicine[0].DrugName : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Generic Name:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].ActiveIngredient ? this.state.out.medicine[0].ActiveIngredient : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Strength:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Strength ? this.state.out.medicine[0].Strength : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Drug Formulation:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Form ? this.state.out.medicine[0].Form : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Amount to take:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Dosage ? this.state.out.medicine[0].Dosage : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              When to take:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.recurrenceDescription ? this.state.out.recurrenceDescription : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Duration:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Duration ? this.state.out.medicine[0].Duration : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Quantity dispensed:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].NumberOfDosages ? this.state.out.medicine[0].NumberOfDosages : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Special Instructions:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Instructions ? this.state.out.medicine[0].NumberOfDosages : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Primary Medical Condition:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Condition ? this.state.out.medicine[0].Condition : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Pharmacy:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Pharmacy ? this.state.out.medicine[0].Pharmacy : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Pharmacy phone number:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].PharmacyPhone ? this.state.out.medicine[0].PharmacyPhone : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Doctor:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].Doctor ? this.state.out.medicine[0].Doctor : <br />}
            </div>
          </div>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Doctor phone number:
            </div>
            <div className={style.confirmation_sub_label}>
              {this.state.out.medicine[0].DoctorPhone ? this.state.out.medicine[0].DoctorPhone : <br />}
            </div>
          </div>

        </div>
        <br />
        <Title>
          Refill reminders
          <IconButton
            variant="fab"
            mini
            icon="edit"
            className={style.button}
            onClick={() => this.onEditClick(4)}
          />
        </Title>
        <div className={style.fields}>

          <div className={style.field}>
            <div className={style.confirmation_label}>
              Number of refills:
            </div>
            <div className={style.confirmation_sub_label}>
              { this.state.out.medicine[0].refillCount ?
                this.state.out.medicine[0].refillCount : this.state.refillCount }
            </div>
          </div>

          { this.state.refillCount > 0 &&
            <div className={style.field}>
              <div className={style.confirmation_label}>
                How long in-between refills would you like this reminder?
              </div>
              <div className={style.confirmation_sub_label}>
                {this.state.out.refillReminderInterval ? refillIntervalOptions[this.state.out.refillReminderInterval].label : <br />}
              </div>
            </div>
          }

          { this.state.refillCount > 0 &&
            <div className={style.field}>
              <div className={style.confirmation_label}>
                Visible for:
              </div>
              <div className={style.confirmation_sub_label}>
                {this.state.out.refillFilters ? visibleFor[this.state.out.refillFilters].label : <br />}
              </div>
            </div>
          }

        </div>
      </div>
    );
  }

  render() {
    const { currentStep } = this.state;
    return (
      <div>
        {currentStep > 1 && (
          <IconButton
            onClick={() => this.onBackClick()}
            className={style.buttonBack}
            icon="arrow_back"
            variant="fab"
            mini
          />
        )}
        <div className={style.content}>
          {currentStep === 1 && this.renderStep1()}
          {currentStep === 2 && this.renderStep2()}
          {currentStep === 3 && this.renderStep3()}
          {currentStep === 4 && this.renderStep4()}
          {currentStep === 5 && this.renderStep5()}
        </div>
        <div>
          {currentStep === 1 && (
            <Button className={style.nextButton} onClick={() => this.onNextClick()}>
              NEXT
            </Button>
          )}
          {currentStep === 2 && (() => {
            const medicine = get(this.state.out, 'medicine[0]', {});
            return (
              <Button
                disabled={
                  !medicine.Prescription ||
                  !medicine.DrugName ||
                  !medicine.Form ||
                  !medicine.Strength ||
                  !medicine.ActiveIngredient ||
                  !medicine.Dosage
                }
                className={style.nextButton}
                onClick={() => this.onNextClick()}
              >
                NEXT
              </Button>
            );
          })()}
          {currentStep === 3 && (() => {
            const medicine = get(this.state.out, 'medicine[0]', {});
            const { whenToTake: whenToTakeSelected } = this.state.out.medicine[0];
            const { alerts } = get(whenToTakeOptions, whenToTakeSelected, { alerts: 1 });
            const asNeeded = this.state.out.recurrenceDescription === 'As needed';
            let isTimeSet = !asNeeded;
            if (!asNeeded) {
              for (let a = 0; a < alerts; a += 1) {
                if (!medicine.alerts[a]) {
                  isTimeSet = true;
                }
                if (medicine.alerts[a]) {
                  isTimeSet = false;
                }
              }
            }

            return (
              <Button
                disabled={
                  !this.state.out.startDate ||
                  isTimeSet
                }
                className={style.nextButton}
                onClick={() => this.onNextClick()}
              >
                NEXT
              </Button>
            );
            })()}
          {currentStep === 4 && (() => {
            const medicine = get(this.state.out, 'medicine[0]', {});
            return (
              <Button
                className={style.nextButton}
                disabled={
                  this.state.refillCount > 0 ?
                    (!medicine.refillReminderTime || !medicine.beginReminders) : false
                }
                onClick={() => this.onSaveClick()}
              >
                SAVE
              </Button>
            );
          })()}
          {currentStep === 5 &&
            <Button
              className={style.nextButton}
              onClick={() => this.onSaveClick()}
            >
              SAVE
            </Button>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(['carerecipients', 'activeRecipient'], {});
  const contacts = activeRecipient ? filterContacts(state, activeRecipient.userId) : null;

  return {
    activeRecipient,
    contacts
  };
};

export default connect(mapStateToProps)(Dialog);
