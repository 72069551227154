import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import Frequency from 'enums/Frequency';
import getOrDefault from 'utils/safe';

const useStyles = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    marginTop: 10,
  },
  centeredGridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  formButton: {
    width: '50%',
  },
  reminderTimeLabel: {
    fontSize: 12,
    paddingTop: 8,
  },
}));

function TimeSelectionComponent({
  recurrence,
  formState,
  handleChange,
  getDefaultDateTime,
}) {
  let repeatTimes = Frequency.toNumber(recurrence);
  const timeSelection = [];

  for (let i = 1; i <= repeatTimes; i += 1) {
    const time = `time_${i}`;
    const label = i === 1 ? 'Reminder time' : `Reminder time ${i}`;
    const timeValue = formState[time] ? formState[time] : getDefaultDateTime();
    if (!formState[time]) {
      handleChange(time, timeValue);
    }

    timeSelection.push(
      <Grid item xs={12} key={`${label}-${i}`}>
        <TimePicker
          fullWidth
          keyboard
          clearable
          label={label}
          name={time}
          onChange={(value) =>
            handleChange(
              time,
              getOrDefault(() => value.toDate(), null)
            )
          }
          value={timeValue}
          mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
          variant="outlined"
        />
      </Grid>
    );
  }

  return timeSelection;
}

function GeneralReminderForm({
  preFilledFormState = {},
  timezone,
  onSubmit,
  hideCheckbox = false,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const today = DateTime.local().startOf('day').toJSDate();

  const initialFormState = {
    saving: false,
    title: '',
    text: '',
    recurrence: Frequency.NEVER,
    startDate: today,
    ongoing: true,
    endDate: null,
    duplicateToCareGiverCalendar: false,
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === 'ongoing') {
      setValues({
        ...values,
        ongoing: value,
        endDate: values.ongoing ? null : values.endDate,
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  const handleDateTimeInputChange = (name, date) => {
    const others = {};
    if (name === 'endDate') {
      others.ongoing = date === null;
    }

    setValues({
      ...values,
      [name]: date,
      ...others,
    });
  };

  const [values, setValues] = useState({
    ...initialFormState,
    ...preFilledFormState,
  });

  const displayEndDateField =
    values.recurrence && values.recurrence !== Frequency.NEVER;

  const onSaveButtonClick = () => {
    onSubmit({
      ...values,
    });
  };

  return (
    <form className={classes.formContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Title"
            value={values.title}
            name="title"
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rowsMax="4"
            label="Description"
            value={values.text}
            name="text"
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            label="Frequency"
            value={values.recurrence}
            name="recurrence"
            onChange={handleInputChange}
            variant="outlined"
          >
            {Frequency.asList().map((frequency) => (
              <MenuItem key={frequency} value={frequency}>
                {t(frequency)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={12}>
          <DatePicker
            fullWidth
            keyboard
            clearable
            autoOk
            disableOpenOnEnter
            label="Start date"
            key="startDate"
            value={values.startDate}
            name="startDate"
            onChange={(value) =>
              handleDateTimeInputChange(
                'startDate',
                getOrDefault(() => value.toDate(), null)
              )
            }
            format="MM/DD/YYYY"
            mask={(value) =>
              value
                ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                : []
            }
            animateYearScrolling={false}
            minDate={today}
            variant="outlined"
          />
        </Grid>
        {displayEndDateField && !values.ongoing && (
          <Grid item xs={12} lg={12}>
            <DatePicker
              fullWidth
              keyboard
              clearable
              autoOk
              disableOpenOnEnter
              label="End date"
              key="endDate"
              value={values.endDate}
              name="endDate"
              onChange={(value) =>
                handleDateTimeInputChange(
                  'endDate',
                  getOrDefault(() => value.toDate(), null)
                )
              }
              format="MM/DD/YYYY"
              mask={(value) =>
                value
                  ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                  : []
              }
              minDate={today}
              animateYearScrolling={false}
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={values.ongoing}
                name="ongoing"
                disabled={values.recurrence === Frequency.NEVER}
                onChange={handleInputChange}
                value={values.ongoing}
                color="primary"
              />
            }
            label="Ongoing reminder"
          />
        </Grid>
        <TimeSelectionComponent
          timezone={timezone}
          recurrence={values.recurrence}
          classes={classes}
          formState={values}
          handleChange={(key, value) => {
            handleDateTimeInputChange(key, value);
          }}
          getDefaultDateTime={() => {
            return DateTime.local().plus({ hours: 1 }).toJSDate();
          }}
        />
        {!hideCheckbox && (
          <Grid item xs={12}>
            <FormControlLabel
              label="Duplicate to caregivers calendar"
              control={
                <Checkbox
                  color="primary"
                  checked={values.duplicateToCareGiverCalendar}
                  name="duplicateToCareGiverCalendar"
                  onChange={handleInputChange}
                  value={String(values.duplicateToCareGiverCalendar)}
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.centeredGridItem}>
          <Button
            disabled={!values.title.trim().length}
            color="primary"
            variant="contained"
            className={classes.formButton}
            onClick={onSaveButtonClick}
            id="general-reminder-form-save-btn"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

GeneralReminderForm.propTypes = {
  timezone: PropTypes.any.isRequired,
  hideCheckbox: PropTypes.bool,
  preFilledFormState: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default GeneralReminderForm;
