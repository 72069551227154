const ReminderTypes = Object.freeze({
  GENERAL_REMINDER: 'GENERAL_REMINDER',
  MEDICINE_REMINDER: 'MEDICINE',
  MEDICINE_REFILL_REMINDER: 'MEDICINE_REFILL',
  DESTINATION_CHECK: 'DESTINATION_CHECK',
  DESTINATION_REMINDER: 'DESTINATION_REMINDER',
  WELLNESS_REMINDER: 'WELLNESS_REMINDER',
  NOT_KNOWN_TYPE: 'NOT_KNOWN_TYPE',
});

const ReminderAvatars = Object.freeze({
  [ReminderTypes.GENERAL_REMINDER]: {
    icon: 'bells',
    iconClass: ReminderTypes.GENERAL_REMINDER,
  },
  [ReminderTypes.WELLNESS_REMINDER]: {
    icon: 'spa',
    iconClass: ReminderTypes.WELLNESS_REMINDER,
  },
  [ReminderTypes.MEDICINE_REMINDER]: {
    icon: 'prescription-bottle-alt',
    iconClass: ReminderTypes.MEDICINE_REMINDER,
  },
  [ReminderTypes.DESTINATION_CHECK]: {
    icon: 'map-marked-alt',
    iconClass: ReminderTypes.DESTINATION_CHECK,
  },
  [ReminderTypes.DESTINATION_REMINDER]: {
    icon: 'map-marked-alt',
    iconClass: ReminderTypes.DESTINATION_CHECK,
  },
  [ReminderTypes.MEDICINE_REFILL_REMINDER]: {
    icon: 'prescription-bottle-alt',
    iconClass: ReminderTypes.MEDICINE_REMINDER,
  },
  [ReminderTypes.NOT_KNOWN_TYPE]: {
    icon: 'bells',
    iconClass: '',
  },
  REMINDER_UPDATE: {
    icon: 'bells',
    iconClass: '',
  },
});

const UserEventTypes = Object.freeze({
  GENERAL_REMINDER: 'general_reminder',
  GENERAL_REMINDER_CHANGE: 'general_reminder_update',
  WELLNESS_REMINDER: 'wellness_reminder',
  WELLNESS_REMINDER_CHANGE: 'wellness_reminder_update',
  MEDICINE_INTAKE_REMINDER: 'medicine_reminder',
  MEDICINE_REFILL_REMINDER: 'medicine_refill',
  MEDICINE_REMINDER_CHANGE: 'medicine_reminder_update',
  DESTINATION_CHECK: 'destination_check',
  DESTINATION_CHECK_CHANGE: 'destination_check_update',
  LOCATION_REMINDER: 'location_reminder',
  LOCATION_REMINDER_CHANGE: 'location_reminder_update',
  HEARTRATE_THRESHOLD: 'heartrate',
  ACTIVITY_THRESHOLD: 'activity',
  TIMEZONE_CHANGE: 'timezone_change',
  HOME_ALL_DAY: 'home_all_day',
  IDLE_DETECTION: 'idle_detection',
  EMERGENCY_CONTACT_PHONE_CALL: 'emergency_contact_phone_call',
  EMERGENCY_PHONE_CALL: 'emergency_phone_call',
  CALL_BREAKTHROUGH: 'call_breakthrough',
  LOW_BATTERY: 'low_battery',
  AT_OR_NEAR_HOSPITAL: 'at_or_near_hospital',
  ADHERENCE: 'adherence',
  USER_LOGOUT_PHONE: 'user_logout_phone',
  ALERT: 'alert',
});

const UserEventAvatars = Object.freeze({
  [UserEventTypes.GENERAL_REMINDER]: {
    icon: 'bells',
    iconClass: UserEventTypes.GENERAL_REMINDER,
  },
  [UserEventTypes.GENERAL_REMINDER_CHANGE]: {
    icon: 'bells',
    iconClass: UserEventTypes.GENERAL_REMINDER,
  },
  [UserEventTypes.WELLNESS_REMINDER]: {
    icon: 'spa',
    iconClass: UserEventTypes.WELLNESS_REMINDER,
  },
  [UserEventTypes.WELLNESS_REMINDER_CHANGE]: {
    icon: 'spa',
    iconClass: UserEventTypes.WELLNESS_REMINDER_CHANGE,
  },
  [UserEventTypes.MEDICINE_INTAKE_REMINDER]: {
    icon: 'prescription-bottle-alt',
    iconClass: UserEventTypes.MEDICINE_INTAKE_REMINDER,
  },
  [UserEventTypes.MEDICINE_REFILL_REMINDER]: {
    icon: 'prescription-bottle-alt',
    iconClass: UserEventTypes.MEDICINE_REFILL_REMINDER,
  },
  [UserEventTypes.MEDICINE_REMINDER_CHANGE]: {
    icon: 'prescription-bottle-alt',
    iconClass: UserEventTypes.MEDICINE_REMINDER_CHANGE,
  },
  [UserEventTypes.DESTINATION_CHECK]: {
    icon: 'map-marked-alt',
    iconClass: UserEventTypes.DESTINATION_CHECK,
  },
  [UserEventTypes.DESTINATION_CHECK_CHANGE]: {
    icon: 'map-marked-alt',
    iconClass: UserEventTypes.DESTINATION_CHECK_CHANGE,
  },
  [UserEventTypes.LOCATION_REMINDER]: {
    icon: 'map-marked-alt',
    iconClass: UserEventTypes.LOCATION_REMINDER,
  },
  [UserEventTypes.LOCATION_REMINDER_CHANGE]: {
    icon: 'map-marked-alt',
    iconClass: UserEventTypes.LOCATION_REMINDER_CHANGE,
  },
  [UserEventTypes.HEARTRATE_THRESHOLD]: {
    icon: 'spa',
    iconClass: UserEventTypes.HEARTRATE_THRESHOLD,
  },
  [UserEventTypes.ACTIVITY_THRESHOLD]: {
    icon: 'spa',
    iconClass: UserEventTypes.ACTIVITY_THRESHOLD,
  },
  [UserEventTypes.TIMEZONE_CHANGE]: {
    icon: 'map-marked-alt',
    iconClass: UserEventTypes.TIMEZONE_CHANGE,
  },
  [UserEventTypes.HOME_ALL_DAY]: {
    icon: 'home-lg-alt',
    iconClass: UserEventTypes.HOME_ALL_DAY,
  },
  [UserEventTypes.IDLE_DETECTION]: {
    icon: 'spa',
    iconClass: UserEventTypes.IDLE_DETECTION,
  },
  [UserEventTypes.EMERGENCY_CONTACT_PHONE_CALL]: {
    icon: 'spa',
    iconClass: UserEventTypes.EMERGENCY_CONTACT_PHONE_CALL,
  },
  [UserEventTypes.EMERGENCY_PHONE_CALL]: {
    icon: 'spa',
    iconClass: UserEventTypes.EMERGENCY_PHONE_CALL,
  },
  [UserEventTypes.CALL_BREAKTHROUGH]: {
    icon: 'spa',
    iconClass: UserEventTypes.CALL_BREAKTHROUGH,
  },
  [UserEventTypes.LOW_BATTERY]: {
    icon: 'bells',
    iconClass: UserEventTypes.LOW_BATTERY,
  },
  [UserEventTypes.AT_OR_NEAR_HOSPITAL]: {
    icon: 'hospital',
    iconClass: UserEventTypes.AT_OR_NEAR_HOSPITAL,
  },
  [UserEventTypes.ADHERENCE]: {
    icon: 'bells',
    iconClass: UserEventTypes.ADHERENCE,
  },
  [UserEventTypes.USER_LOGOUT_PHONE]: {
    icon: 'bells',
    iconClass: UserEventTypes.USER_LOGOUT_PHONE,
  },
  [UserEventTypes.ALERT]: {
    icon: 'exclamation-circle',
    iconClass: UserEventTypes.ALERT,
  },
});

const AlertTypes = Object.freeze({
  GENERAL: 'general',
  WELLNESS: 'wellness',
  LOCATION: 'location',
  MEDICATION: 'medication',
  MEDICINE: 'medicine',
  REFILL: 'refill',
  CALL_BREAKTHROUGH: 'call_breakthrough',
  IDLE5TO5: 'idle5to5',
  PULSE_SYSTEM_LOW_WARNING: 'pulse_system_low_warning',
  PULSE_SYSTEM_LOW_ERROR: 'pulse_system_low_error',
  PULSE_INDIVIDUAL_LOW: 'pulse_individual_low',
  PULSE_INDIVIDUAL_HIGH: 'pulse_individual_high',
  PULSE_SYSTEM_HIGH_WARNING: 'pulse_system_high_warning',
  PULSE_SYSTEM_HIGH_ERROR: 'pulse_system_high_error',
  IDLE_DETECTION_NO_RESPONSE: 'idle_detection_no_response',
  IDLE_DETECTION_RESPONSE_NOK: 'idle_detection_response_nok',
  TIMEZONECHANGE: 'timezoneChange',
  ADHERENCE: 'adherence',
  EMERGENCYCONTACTCALL: 'emergencyContactCall',
  EMERGENCYCALL: 'emergencyCall',
  AT_OR_NEAR_HOSPITAL: 'at_or_near_hospital',
  USER_LOGOUT_PHONE: 'user_logout_phone',
  PHONE_BATTERY_DROPTO: 'phone_battery_dropto',
  WATCH_BATTERY_DROPTO: 'watch_battery_dropto',
  STEP_THRESHOLD_REACHED: 'step_threshold_reached',
  STEP_THRESHOLD_UNREACHED: 'step_threshold_unreached',
  PHONE_BATTERY_RISETO: 'phone_battery_riseto',
  WATCH_BATTERY_RISETO: 'watch_battery_riseto',
  POTENTIAL_SITUATION: 'potential_situation',
  NOT_KNOWN_TYPE: 'not_known_type',
});

const AlertAvatars = Object.freeze({
  [AlertTypes.GENERAL]: {
    icon: 'bells',
    iconClass: AlertTypes.GENERAL,
  },
  [AlertTypes.WELLNESS]: {
    icon: 'spa',
    iconClass: AlertTypes.WELLNESS,
  },
  [AlertTypes.LOCATION]: {
    icon: 'map-marked-alt',
    iconClass: AlertTypes.LOCATION,
  },
  [AlertTypes.MEDICATION]: {
    icon: 'prescription-bottle-alt',
    iconClass: AlertTypes.MEDICATION,
  },
  [AlertTypes.MEDICINE]: {
    icon: 'prescription-bottle-alt',
    iconClass: AlertTypes.MEDICINE,
  },
  [AlertTypes.REFILL]: {
    icon: 'prescription-bottle-alt',
    iconClass: AlertTypes.REFILL,
  },
  [AlertTypes.CALL_BREAKTHROUGH]: {
    icon: 'mobile',
    iconClass: AlertTypes.CALL_BREAKTHROUGH,
  },
  [AlertTypes.IDLE5TO5]: {
    icon: 'home-lg-alt',
    iconClass: AlertTypes.IDLE5TO5,
  },
  [AlertTypes.PULSE_SYSTEM_LOW_WARNING]: {
    icon: 'heartbeat',
    iconClass: AlertTypes.PULSE_SYSTEM_LOW_WARNING,
  },
  [AlertTypes.PULSE_SYSTEM_LOW_ERROR]: {
    icon: 'heartbeat',
    iconClass: AlertTypes.PULSE_SYSTEM_LOW_ERROR,
  },
  [AlertTypes.PULSE_INDIVIDUAL_LOW]: {
    icon: 'heartbeat',
    iconClass: AlertTypes.PULSE_INDIVIDUAL_LOW,
  },
  [AlertTypes.PULSE_INDIVIDUAL_HIGH]: {
    icon: 'heartbeat',
    iconClass: AlertTypes.PULSE_INDIVIDUAL_HIGH,
  },
  [AlertTypes.PULSE_SYSTEM_HIGH_WARNING]: {
    icon: 'heartbeat',
    iconClass: AlertTypes.PULSE_SYSTEM_HIGH_WARNING,
  },
  [AlertTypes.PULSE_SYSTEM_HIGH_ERROR]: {
    icon: 'heartbeat',
    iconClass: AlertTypes.PULSE_SYSTEM_HIGH_ERROR,
  },
  [AlertTypes.IDLE_DETECTION_NO_RESPONSE]: {
    icon: 'spa',
    iconClass: AlertTypes.IDLE_DETECTION_NO_RESPONSE,
  },
  [AlertTypes.IDLE_DETECTION_RESPONSE_NOK]: {
    icon: 'spa',
    iconClass: AlertTypes.IDLE_DETECTION_RESPONSE_NOK,
  },
  [AlertTypes.TIMEZONECHANGE]: {
    icon: 'user-clock',
    iconClass: AlertTypes.TIMEZONECHANGE,
  },
  [AlertTypes.ADHERENCE]: {
    icon: 'handshake',
    iconClass: AlertTypes.ADHERENCE,
  },
  [AlertTypes.EMERGENCYCONTACTCALL]: {
    icon: 'phone-volume',
    iconClass: AlertTypes.EMERGENCYCONTACTCALL,
  },
  [AlertTypes.EMERGENCYCALL]: {
    icon: 'ambulance',
    iconClass: AlertTypes.EMERGENCYCALL,
  },
  [AlertTypes.AT_OR_NEAR_HOSPITAL]: {
    icon: 'hospital',
    iconClass: AlertTypes.AT_OR_NEAR_HOSPITAL,
  },
  [AlertTypes.USER_LOGOUT_PHONE]: {
    icon: 'sign-out-alt',
    iconClass: AlertTypes.USER_LOGOUT_PHONE,
  },
  [AlertTypes.STEP_THRESHOLD_REACHED]: {
    icon: 'shoe-prints',
    iconClass: AlertTypes.STEP_THRESHOLD_REACHED,
  },
  [AlertTypes.STEP_THRESHOLD_UNREACHED]: {
    icon: 'shoe-prints',
    iconClass: AlertTypes.STEP_THRESHOLD_UNREACHED,
  },
  [AlertTypes.PHONE_BATTERY_RISETO]: {
    icon: 'battery-three-quarters',
    iconClass: AlertTypes.PHONE_BATTERY_RISETO,
  },
  [AlertTypes.WATCH_BATTERY_RISETO]: {
    icon: 'battery-three-quarters',
    iconClass: AlertTypes.WATCH_BATTERY_RISETO,
  },
  [AlertTypes.PHONE_BATTERY_DROPTO]: {
    icon: 'battery-quarter',
    iconClass: AlertTypes.PHONE_BATTERY_DROPTO,
  },
  [AlertTypes.WATCH_BATTERY_DROPTO]: {
    icon: 'battery-quarter',
    iconClass: AlertTypes.WATCH_BATTERY_DROPTO,
  },
  [AlertTypes.POTENTIAL_SITUATION]: {
    icon: 'exclamation-circle',
    iconClass: AlertTypes.POTENTIAL_SITUATION,
  },
  [AlertTypes.NOT_KNOWN_TYPE]: {
    icon: 'bells',
    iconClass: AlertTypes.NOT_KNOWN_TYPE,
  },
});

const getEventTypeFromUserEventType = (type) => {
  switch (type) {
    case UserEventTypes.GENERAL_REMINDER:
      return ReminderTypes.GENERAL_REMINDER;
    case UserEventTypes.WELLNESS_REMINDER:
      return ReminderTypes.WELLNESS_REMINDER;
    case UserEventTypes.DESTINATION_CHECK:
      return ReminderTypes.DESTINATION_CHECK;
    case UserEventTypes.LOCATION_REMINDER:
      return ReminderTypes.DESTINATION_REMINDER;
    case UserEventTypes.MEDICINE_INTAKE_REMINDER:
      return ReminderTypes.MEDICINE_REMINDER;
    case UserEventTypes.MEDICINE_REFILL_REMINDER:
      return ReminderTypes.MEDICINE_REFILL_REMINDER;
    case UserEventTypes.GENERAL_REMINDER_CHANGE:
    case UserEventTypes.MEDICINE_REMINDER_CHANGE:
    case UserEventTypes.WELLNESS_REMINDER_CHANGE:
    case UserEventTypes.LOCATION_REMINDER_CHANGE:
    case UserEventTypes.DESTINATION_CHECK_CHANGE:
      return 'REMINDER_UPDATE';
    default:
      return ReminderTypes.NOT_KNOWN_TYPE;
  }
};

const getEventFromLegacyEvent = (legacyEvent) => {
  switch (legacyEvent) {
    case 'general':
      return ReminderTypes.GENERAL_REMINDER;
    case 'wellness':
      return ReminderTypes.WELLNESS_REMINDER;
    case 'location':
      return ReminderTypes.DESTINATION_REMINDER;
    case 'medicine':
      return ReminderTypes.MEDICINE_REMINDER;
    case 'refill':
      return ReminderTypes.MEDICINE_REFILL_REMINDER;

    default:
      return ReminderTypes.NOT_KNOWN_TYPE;
  }
};

const getAlertsFromLegacy = (alertType) => {
  switch (alertType) {
    case 'general':
      return AlertTypes.GENERAL;
    case 'wellness':
      return AlertTypes.WELLNESS;
    case 'location':
      return AlertTypes.LOCATION;
    case 'medication':
      return AlertTypes.MEDICATION;
    case 'medicine':
      return AlertTypes.MEDICINE;
    case 'refill':
      return AlertTypes.REFILL;
    case 'call_breakthrough':
      return AlertTypes.CALL_BREAKTHROUGH;
    case 'idle5to5':
      return AlertTypes.IDLE5TO5;
    case 'pulse_system_low_warning':
      return AlertTypes.PULSE_SYSTEM_LOW_WARNING;
    case 'pulse_system_low_error':
      return AlertTypes.PULSE_SYSTEM_LOW_ERROR;
    case 'pulse_individual_low':
      return AlertTypes.PULSE_INDIVIDUAL_LOW;
    case 'pulse_system_high_warning':
      return AlertTypes.PULSE_SYSTEM_LOW_WARNING;
    case 'pulse_system_high_error':
      return AlertTypes.PULSE_SYSTEM_HIGH_ERROR;
    case 'pulse_individual_high':
      return AlertTypes.PULSE_INDIVIDUAL_HIGH;
    case 'idle_detection_no_response':
      return AlertTypes.IDLE_DETECTION_NO_RESPONSE;
    case 'idle_detection_response_nok':
      return AlertTypes.IDLE_DETECTION_RESPONSE_NOK;
    case 'timezoneChange':
      return AlertTypes.TIMEZONECHANGE;
    case 'adherence':
      return AlertTypes.ADHERENCE;
    case 'emergencyContactCall':
      return AlertTypes.EMERGENCYCONTACTCALL;
    case 'emergencyCall':
      return AlertTypes.EMERGENCYCALL;
    case 'at_or_near_hospital':
      return AlertTypes.AT_OR_NEAR_HOSPITAL;
    case 'user_logout_phone':
      return AlertTypes.USER_LOGOUT_PHONE;
    default:
      if (/step_threshold_.*_unreached/.test(alertType)) {
        return AlertTypes.STEP_THRESHOLD_UNREACHED;
      } else if (/step_threshold_.*_reached/.test(alertType)) {
        return AlertTypes.STEP_THRESHOLD_REACHED;
      } else if (/phone_battery_dropto_.*/.test(alertType)) {
        return AlertTypes.PHONE_BATTERY_DROPTO;
      } else if (/phone_battery_riseto_.*/.test(alertType)) {
        return AlertTypes.PHONE_BATTERY_RISETO;
      } else if (/watch_battery_dropto_.*/.test(alertType)) {
        return AlertTypes.WATCH_BATTERY_DROPTO;
      } else if (/watch_battery_riseto_.*/.test(alertType)) {
        return AlertTypes.WATCH_BATTERY_RISETO;
      }
      return AlertTypes.POTENTIAL_SITUATION;
  }
};

const getLegacyEventFromEvent = (event) => {
  switch (event) {
    case ReminderTypes.GENERAL_REMINDER:
      return 'general';
    case ReminderTypes.WELLNESS_REMINDER:
      return 'wellness';
    case ReminderTypes.MEDICINE_REMINDER:
      return 'medicine';
    case ReminderTypes.DESTINATION_CHECK:
    case ReminderTypes.DESTINATION_REMINDER:
      return 'location';
    case ReminderTypes.MEDICINE_REFILL_REMINDER:
      return 'refill';

    default:
      return ReminderTypes.NOT_KNOWN_TYPE;
  }
};

export {
  ReminderTypes,
  ReminderAvatars,
  AlertTypes,
  AlertAvatars,
  UserEventTypes,
  UserEventAvatars,
  getEventTypeFromUserEventType,
  getEventFromLegacyEvent,
  getLegacyEventFromEvent,
  getAlertsFromLegacy,
};
