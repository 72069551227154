/* eslint-disable */

import React from 'react';
import ReactTooltip from 'react-tooltip';
import uuidv4 from 'uuid/v4';

import style from './style.module.scss';

const RequiredFieldIndicator = () => {
  const tooltipID = uuidv4();
  return (
    <React.Fragment>
      <span
        className={style.required}
        data-tip
        data-for={tooltipID}
      >
        *
      </span>
      <ReactTooltip
        id={tooltipID}
        effect="solid"
        className={style.tooltip}
      >
        Required field
      </ReactTooltip>
    </React.Fragment>
  );
};

export default RequiredFieldIndicator;
