/* eslint-disable */

import React from 'react';
import style from './style.module.scss';

const Face = ({ value }) => (
  <div className={`${style.face} ${style[`face${value}`]}`} />
);

export default Face;
