import React from 'react';
import getOrDefault from 'utils/safe';
import clamp from 'utils/clamp';

const clampBetweenZeroAndInfinity = clamp({ min: 0 });

function useSteps(initialActiveStep = 0) {
  const [activeStep, setActiveStep] = React.useState(initialActiveStep);

  const toNext = () => {
    setActiveStep(activeStep + 1);
  };

  const toPrevious = () => {
    setActiveStep(clampBetweenZeroAndInfinity(activeStep - 1));
  };

  const toActiveStep = (activeStep) => {
    setActiveStep(activeStep);
  };

  return { activeStep, toNext, toPrevious, toActiveStep };
}

const Steps = ({ activeStep = 0, children = [] } = {}) => {
  if (children.length === 0) {
    return null;
  }

  const step = clampBetweenZeroAndInfinity(activeStep);

  return getOrDefault(() => children[step], null);
};

const Steppable = { useSteps, Steps };

export default Steppable;
