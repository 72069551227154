import { combineReducers } from 'redux-immutable';

import calendar from 'reducers/calendar';
import call from 'reducers/call';
import carerecipients from 'reducers/carerecipients';
import contacts from 'reducers/contacts';
import cookie from 'reducers/cookie';
import event from 'reducers/event';
import medicine from 'reducers/medicine';
import messages from 'reducers/messages';
import geocode from 'reducers/geocode';
import user from 'reducers/user';
import invitation from 'reducers/invitation';
import location from 'reducers/location';
import health from 'reducers/health';
import heartRateLevel from 'reducers/heartRateLevel';
import activityLevel from 'reducers/activityLevel';
import { conversations } from 'reducers/conversations';
import userevents from 'reducers/userevents';

const reducers = combineReducers({
  calendar,
  call,
  carerecipients,
  contacts,
  cookie,
  event,
  medicine,
  messages,
  geocode,
  user,
  invitation,
  location,
  health,
  heartRateLevel,
  activityLevel,
  conversations,
  userevents,
});

export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return reducers(state, action);
};
