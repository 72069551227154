/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import UserList from 'components/UserList';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import style from './style.module.scss';

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogClose: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getRecipients() {
    const { filter } = this.props.data;
    const careRecipients = Object.values(this.props.careRecipients);
    if (filter) {
      return careRecipients.filter((recipient) => {
        let found = false;
        if (filter.userIds) {
          found = filter.userIds.includes(recipient.userId);
        }
        return found;
      });
    }
    return careRecipients;
  }

  pick(id) {
    this.props.data.onChange(id);
    this.context.dialogClose(this.props.dialogId);
  }

  render() {
    const careRecipients = this.getRecipients();
    return (
      <div
        className={[style.background, style[this.props.transition]].join(' ')}
        onClick={() => this.context.dialogClose(this.props.dialogId)}
      >
        <div className={style.container} onClick={e => e.stopPropagation()}>
          <Button
            onClick={() => this.context.dialogClose(this.props.dialogId)}
            className={style.buttonClose}
          >
            <Icon>clear</Icon>
          </Button>
          <div style={{ padding: '10px' }}>
            <div>Pick a care recipient</div>
            <div>
              {careRecipients.map(user => (
                <div key={user.userId}>
                  <Button className={style.cta} onClick={() => this.pick(user.userId)}>
                    {`${user.name.first} ${user.name.last}`}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  careRecipients: state.getIn(['carerecipients', 'recipients']).toJS()
});

export default connect(mapStateToProps)(Dialog);
