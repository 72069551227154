
/* eslint-disable */

import createPlayer from 'web-audio-player';

class Audio {
  constructor(url, options) {
    this.url = url;
    this.state = 'init';
    this.audio = null;

    this.stateChange = null;

    if (options) {
      if (options.stateChange) {
        this.stateChange = options.stateChange;
      }
    }
  }

  changeState(state) {
    this.state = state;
    if (this.stateChange) {
      this.stateChange(state);
    }
  }

  play() {
    if (this.state === 'init') {
      this.changeState('loading');

      const options = {
        crossOrigin: 'anonymous'
      };

      this.audio = createPlayer(this.url, options);

      this.audio.on('load', () => {
        this.audio.play();
        this.audio.node.connect(this.audio.context.destination);
        this.changeState('playing');
      });

      this.audio.on('end', () => {
        this.changeState('end');
      });

      this.audio.on('error', () => {
        this.changeState('error');
      });
    } else if (this.state === 'end' || this.state === 'stopped') {
      this.audio.play();
      this.changeState('playing');
    }
  }

  stop() {
    if (this.state === 'playing') {
      this.audio.stop();
      this.changeState('stopped');
    }
  }

  togglePlay() {
    if (this.state === 'init' || this.state === 'end' || this.state === 'stopped') {
      this.play();
    } else if (this.state === 'playing') {
      this.stop();
    }
  }
}

export default Audio;
