import React, { useState } from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import makeHSL from 'styles/makeHSL';
import { useTranslation } from 'react-i18next';

const HelpEventTypes = Object.freeze({
  QUESTION: 'QUESTION',
  QUESTIONS: 'QUESTIONS',
  LIVE_CHAT: 'LIVE_CHAT',
});

const useStyles = makeStyles({
  [HelpEventTypes.QUESTION]: {
    color: makeHSL({ hue: 180, saturation: 0.6, lightness: 0.46 }),
    backgroundColor: 'transparent',
  },
  questionText: {
    color: makeHSL({ hue: 180, saturation: 0.6, lightness: 0.46 }),
    width: 300,
  },
  [HelpEventTypes.QUESTIONS]: {
    color: `#333`,
    backgroundColor: 'transparent',
  },
  [HelpEventTypes.LIVE_CHAT]: {
    color: makeHSL({ hue: 0, saturation: 0, lightness: 1 }),
    backgroundColor: makeHSL({ hue: 353, saturation: 0.98, lightness: 0.41 }),
  },

  noPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  buttonIcon: {
    marginRight: 10,
  },
});

const helpLinks = [
  {
    title: 'Knowledge base',
    link: 'https://leapthru.com/knowledge-base/',
    icon: 'info-square',
  },
  {
    title: 'Features & how to use them',
    link: 'https://leapthru.com/knowledge-base-category/feature-help/',
    icon: 'sliders-h',
  },
  {
    title: 'Registration and setup',
    link:
      'https://leapthru.com/knowledge-base-category/registration-and-setup/',
    icon: 'user-friends',
  },
  {
    title: 'Connect with us',
    link: 'https://leapthru.com/connect-with-us/',
    icon: 'address-card',
  },
];

const HelpDropdown = ({ onFaqNavigationChange = () => {} }) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);
  const { t } = useTranslation();

  function handleClick(event) {
    setAnchor(event.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  const id = 'helpDropdown';

  return (
    <React.Fragment>
      <Button
        aria-owns={anchor ? id : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
      >
        <FontAwesomeIcon
          size="lg"
          icon={['fal', 'question-circle']}
          className={classes.buttonIcon}
        />
        Help
      </Button>

      <Menu
        id={id}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchor)}
        onClose={handleClose}
        classes={{
          list: classes.noPadding,
        }}
      >
        <List disablePadding>
          <ListItem>
            <ListItemText
              primary={t('HelpDropdown.HowToHelpToday')}
              secondary={t('HelpDropdown.PagesOpenInNewTab')}
              primaryTypographyProps={{
                display: 'block',
                align: 'center',
                variant: 'h6',
              }}
              secondaryTypographyProps={{
                display: 'block',
                align: 'center',
                variant: 'body2',
              }}
            />
          </ListItem>

          <Divider />

          {helpLinks.map((helpLink) => {
            return (
              <ListItem
                button
                component="a"
                href={helpLink.link}
                target="_blank"
              >
                <ListItemAvatar>
                  <Avatar className={classes[HelpEventTypes.QUESTIONS]}>
                    <FontAwesomeIcon icon={helpLink.icon} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={helpLink.title} />
                <ListItemSecondaryAction>
                  <FontAwesomeIcon size="lg" icon={['fal', 'chevron-right']} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}

          <Divider />

          <ListItem
            button
            id="crisp-chat-help-button"
            onClick={() => window.$crisp.push(['do', 'chat:open'])}
            className={classes[HelpEventTypes.LIVE_CHAT]}
          >
            <ListItemText
              primary={t('HelpDropdown.HelpWithLiveChat')}
              primaryTypographyProps={{ display: 'block', align: 'center' }}
            />
          </ListItem>
        </List>
      </Menu>
    </React.Fragment>
  );
};

HelpDropdown.propTypes = {
  onFaqNavigationChange: PropTypes.func,
};

export default HelpDropdown;
