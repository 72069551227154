import React from 'react';
import Title from 'components/Title';
import style from './style.module.scss';

export default {
  '/dashboard': (
    <div>
      <Title>Dashboard</Title>
      <div className={style.text}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          tristique mollis rhoncus. Duis ac diam a mi volutpat volutpat. Ut quis
          dui vel est commodo tempor. Suspendisse laoreet commodo efficitur.
          Etiam aliquet, sapien eget dignissim ultricies, eros lorem tempus
          lacus, vel aliquet justo nulla eget libero. Donec convallis efficitur
          diam, finibus dignissim enim egestas bibendum. Pellentesque aliquet
          odio nisi, id vulputate mi congue eget. Sed tempus efficitur nibh,
          eget rhoncus ex dignissim non. Integer in euismod urna, nec fringilla
          magna. In urna neque, porttitor ac faucibus et, gravida et est. Mauris
          eu felis ex. Curabitur aliquet orci non sem varius aliquam. Maecenas
          pulvinar, arcu a consectetur condimentum, odio magna tincidunt ipsum,
          sed varius diam eros vel lorem.
        </p>
        <p>
          Ut efficitur, ante nec lobortis varius, turpis massa finibus metus,
          facilisis fermentum justo dolor id urna. Donec at nisl nunc. Duis
          finibus eleifend lobortis. Quisque ultrices nec dolor dapibus dapibus.
          Nulla non dui porttitor, vestibulum sapien eu, volutpat ipsum.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Vivamus blandit mauris vitae ex dapibus, sit
          amet ultrices purus cursus. Morbi tincidunt mi in euismod fringilla.
          Duis vitae molestie velit. Aliquam pharetra tellus non nulla mattis
          finibus. Cras luctus ante metus, a mollis lectus rhoncus vitae.
          Vestibulum pharetra nulla a tortor volutpat, ac congue lacus mattis.
          Aliquam dapibus vel nunc sed pharetra. Ut quis malesuada sapien.
          Aliquam sed interdum massa, et consectetur tellus. Vestibulum eu
          tellus lacus.
        </p>
      </div>
    </div>
  ),
  '/calendar': (event) => <div>{event && event.title}</div>,
};
