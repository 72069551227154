import { ReminderTypes } from 'core/EventTypes';
import makeColor from 'styles/makeColor';
import theme from '../theme';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const ReminderStyles = {
  [ReminderTypes.GENERAL_REMINDER]: makeColor({ hue: 100 }),
  [ReminderTypes.MEDICINE_REMINDER]: makeColor({ hue: 160 }),
  [ReminderTypes.MEDICINE_REFILL_REMINDER]: makeColor({ hue: 160 }),
  [ReminderTypes.DESTINATION_CHECK]: makeColor({ hue: 308 }),
  [ReminderTypes.DESTINATION_REMINDER]: makeColor({ hue: 308 }),
  [ReminderTypes.WELLNESS_REMINDER]: makeColor({ hue: 360 }),
  contentDiv: {
    width: 400,
    maxHeight: 600,
    minHeight: 300,
  },
  hasBottomSpace: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    padding: 0,
    width: '100%',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    width: '100%',
  },
  iconHeader: {
    color: theme.palette.primary.main,
  },
  topHeader: {
    margin: '1rem 0',
    textTransform: 'capitalize',
  },
  listPanelItem: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderBottom: '1px solid white',
    display: 'block',
  },
  listPanelItemValue: {
    float: 'right',
  },
  panel: {
    padding: 0,
  },
  medicineChip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  chipRight: {
    float: 'right',
  },
  questionlabel: {
    borderTop: '1px solid #cec2c2',
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  answerlabel: {
    backgroundColor: theme.palette.grey[100],
    minHeight: '4em',
    display: 'flex',
    flexDirection: 'row',
  },
  contentText: {
    padding: theme.spacing(2.5),
  },
  iconsize: {
    display: 'flex',
    flexDirection: 'row',
    width: 15,
    marginLeft: 15,
  },
  answerIcons: {
    fontSize: 10,
  },
  noData: {
    margin: 'auto',
    textAlign: 'center',
  },
  destinationContainer: {
    height: '150px',
    width: '100%',
  },
  answer: {
    marginLeft: 'auto',
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    padding: '18px 40px 5px 10px',
    minHeight: '4em',
  },
  answerContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  alertChip: {
    backgroundColor: red[200],
    padding: '5px 15px 5px 10px',
    textAlign: 'center',
  },
  doneChip: {
    backgroundColor: green[200],
    padding: '5px 15px 5px 10px',
    textAlign: 'center',
  },
  pendingChip: {
    backgroundColor: theme.palette.grey[200],
    padding: '5px 15px 5px 10px',
    textAlign: 'center',
  },
  medicineCard: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.5),
    fontSize: 14,
    display: 'flex',
  },
  refillCard: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.5),
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
  },
  medicineBox: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  medicineIcon: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  medicineContent: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.grey[100],
    minHeight: '4em',
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2),
  },
  date: {
    fontSize: 13,
    color: theme.palette.grey[600],
  },
  generalCard: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    padding: theme.spacing(1.5),
  },
  responseStatus: {
    marginLeft: theme.spacing(22),
  },
  reportedlabel: {
    backgroundColor: red[100],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  label: {
    marginRight: theme.spacing(1),
  },
  contentLabel: {
    backgroundColor: theme.palette.grey[100],
    minHeight: '4em',
    padding: theme.spacing(2),
  },
  locationButton: {
    borderRadius: '0',
    borderRight: 'none',
    borderLeft: 'none',
  },
  concerninglabel: {
    borderTop: '1px solid #cec2c2',
    backgroundColor: red[200],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  audio: {
    paddingRight: 'none',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
  },
  audioPlayer: {
    width: 250,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2.5),
    border: `1px solid ${theme.palette.grey[300]}`,
  },
};

export default ReminderStyles;
