import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  allergies: {},
  allAlergies: {},
  conditions: {},
  allConditions: {},
  items: {},
});

export function merge(state, item) {
  const arr = Array.isArray(item) ? item : [item];
  return state.mergeIn(
    ['items'],
    fromJS(arr.reduce((obj, i) => ({ ...obj, [i.ApplNo]: i }), {}))
  );
}

export function mergeConditions(state, item) {
  const arr = Array.isArray(item) ? item : [item];
  return state.mergeIn(
    ['conditions'],
    fromJS(arr.reduce((obj, i) => ({ ...obj, [i.conditionId]: i }), {}))
  );
}

export function mergeAllergies(state, item) {
  const arr = Array.isArray(item) ? item : [item];
  return state.mergeIn(
    ['allergies'],
    fromJS(arr.reduce((obj, i) => ({ ...obj, [i.allergiesId]: i }), {}))
  );
}

export function setAllAllergies(state, payload) {
  return state.set('allAlergies', payload);
}

export function setAllConditions(state, payload) {
  return state.set('allConditions', payload);
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MEDICINE_SET':
      return merge(state, action.payload);

    case 'MEDICINE_ALLERGIES_ALL_SET':
      return setAllAllergies(state, action.payload);

    case 'MEDICINE_CONDITIONS_ALL_SET':
      return setAllConditions(state, action.payload);

    case 'MEDICINE_CONDITIONS_SET':
      return mergeConditions(state, action.payload);

    case 'MEDICINE_ALLERGIES_SET':
      return mergeAllergies(state, action.payload);

    default:
      return state;
  }
};
