import { fromJS } from 'immutable';
import { useSelector } from 'react-redux';

const INITIAL_STATE = fromJS({
  items: {},
  unconfirmedCaregivers: [],
});

export function merge(state, item) {
  const arr = Array.isArray(item) ? item : [item];
  return state.mergeIn(
    ['items'],
    fromJS(arr.reduce((obj, i) => ({ ...obj, [i.invitationId]: i }), {}))
  );
}

export function setUnconfirmedCaregivers(state, payload) {
  console.log('setUnconfirmedCaregivers', payload);
  return state.set('unconfirmedCaregivers', payload);
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INVITATIONS_SET':
      return merge(state, action.payload);
    case 'SET_UNCONFIRMED_CAREGIVERS':
      return setUnconfirmedCaregivers(state, action.payload);
    default:
      return state;
  }
};

export const usePendingInvitations = () =>
  useSelector((state) => {
    return Object.values(
      state.getIn(['invitation', 'items'], {}).toJS()
    ).filter((invitation) => invitation.status === 'pending');
  });
