/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';

import RRule from 'rrule';
import moment from 'moment-timezone';

import { MenuItem, TextField, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core/styles';

import { getMinutes, getTimeByTimezone } from 'utils/date';

import { addWellnessEvent, editWellnessEvent } from 'actions/wellness';

import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Select from 'components/Select';
import Title from 'components/Title';
import {
  convertRecurrenceToJSDate,
  dateInRruleDateFormat,
  rruleDateToLocalTime
} from 'utils/rruleWrapper';

import Question from '../NewCheckin/Question';

import style from './style.module.scss';

const styles = () => ({
  textFieldRoot: {},
  inputRoot: {
    height: 36,
    backgroundColor: 'white',
    padding: '8px 0 8px 8px',
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: 4
  },
  inputFocused: {},
  input: {
    padding: 0
  },
  adornment: {
    alignSelf: 'center'
  }
});

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    const data = this.props.data || {};
    const { currentStep = 1, eventData } = data;
    const edit = !!eventData;
    let out = {};
    let rule = {};
    let convertedRecurrence;
    const recurrenceTime = [];

    if (edit) {
      const checkin = Array.isArray(eventData.data) ? eventData.data : [eventData.data];
      rule = RRule.fromString(eventData.recurrence);
      const freqTranslations = ['YEARLY', 'MONTHLY', 'WEEKLY', 'DAILY', 'HOURLY', 'MINUTELY', 'SECONDLY'];
      const originalRecurrence = freqTranslations[rule.origOptions.freq];

      const origTime = Array.isArray(rule.origOptions.byminute) ?
        rule.origOptions.byminute : [rule.origOptions.byminute];

      if (originalRecurrence === 'DAILY') {
        convertedRecurrence = `${origTime.length}xDay`;
      }

      if (originalRecurrence === 'WEEKLY') {
        convertedRecurrence = '1xWeek';
      }

      if (originalRecurrence === 'MONTH') {
        convertedRecurrence = '1xMonth';
      }

      const startDate = get(rule, 'options.dtstart');

      // Convert rrule time to Date
      origTime.map(time => recurrenceTime.push(convertRecurrenceToJSDate(startDate, time)));

      out = {
        ...eventData,
        wellnessData: checkin
      };
    }

    this.state = {
      currentStep,
      edit,
      endDate: (typeof rule.origOptions !== 'undefined') ? rruleDateToLocalTime(rule.origOptions.until).toJSDate() : moment().add(1, 'day'),
      numberOfQuestions: (typeof out.wellnessData !== 'undefined') ? out.wellnessData.length : 1,
      out,
      recurrence: (typeof convertedRecurrence !== 'undefined') ? convertedRecurrence : 'askOnce',
      recurrenceTime,
      saving: false,
      startDate: (typeof rule.origOptions) !== 'undefined' ? rruleDateToLocalTime(rule.origOptions.dtstart).toJSDate() : moment().toDate(),
      time_1: moment(),
      timezone: this.props.activeRecipient.timezone,
      title: out.title || '',
      sensorReadings: out.sensorReadings || {
        shouldIncludeHeartrateForCheckIn: false,
        shouldIncludeStepCountForCheckIn: false,
      },
    };
  }

  componentDidMount() {
    if (((typeof this.state.recurrenceTime) !== 'undefined') && this.state.recurrenceTime.length > 0) {
      this.state.recurrenceTime.map((timeValue, i) => {
        const time = `time_${i + 1}`;
        return this.setState({
          [time]: timeValue
        });
      });
    }

    if (((typeof this.state.question_1) === 'undefined') && (typeof this.state.out.wellnessData !== 'undefined')) {
      if (this.state.out.wellnessData.length > 0) {
        this.state.out.data.map((questionValue, i) => {
          const question = `question_${i + 1}`;
          return this.setState({ [question]: questionValue });
        });
      }
    }
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onNextClick() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  onBackClick() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  onSaveClick() {
    if (!this.state.saving) {
      // this.setState({ saving: true });

      const questions = [];
      Reflect.ownKeys(this.state).forEach((key) => {
        if (/^question_\d$/.test(key)) {
          const currentQuestion = Object.assign({}, this.state[key]);
          const isQuestionEdited = !!((currentQuestion.valuesForSaving !== undefined) &&
            (currentQuestion.optionsForSaving !== undefined));

          if (isQuestionEdited) {
            if (currentQuestion.type === 'options') {
              currentQuestion.alertCondition = [];
              Reflect.ownKeys(currentQuestion.options).forEach((optionKey) => {
                if (currentQuestion.options[optionKey] === true) {
                  currentQuestion.alertCondition.push(optionKey);
                }
              });
              currentQuestion.options = currentQuestion.optionsForSaving;
              delete currentQuestion.optionsForSaving;
              delete currentQuestion.valuesForSaving;
              delete currentQuestion.values;
            }

            if (currentQuestion.type === 'values') {
              currentQuestion.alertCondition = [];
              Reflect.ownKeys(currentQuestion.values).forEach((valueKey) => {
                if (currentQuestion.values[valueKey] === true) {
                  currentQuestion.alertCondition.push(Number(valueKey));
                }
              });
            }
            currentQuestion.values = currentQuestion.valuesForSaving;
            delete currentQuestion.valuesForSaving;
            delete currentQuestion.optionsForSaving;
            delete currentQuestion.options;
          }
          questions.push(currentQuestion);
        }
      });

      const rule = this.getRrule(this.state.recurrence);
      const recDescription = rule.toText();
      const trimmedQuestions = questions.slice(0, this.state.numberOfQuestions);
      const toSave = {
        active: true,
        recurrence: rule.toString(),
        recurrenceDescription: recDescription,
        timezone: this.state.timezone,
        title: this.state.title,
        userId: this.props.activeRecipient.userId,
        wellnessData: trimmedQuestions,
        sensorReadings: this.state.sensorReadings,
      };

      if (this.state.edit) {
        toSave.eventId = this.props.data.eventData.eventId;
        editWellnessEvent(this.props.dispatch, this.context.apiClient, toSave)
          .then(() => this.setState({ saving: false }, () => this.onClose()))
          .catch((err) => {
            console.log('err', err);
            this.setState({ saveError: err.error, saving: false });
          });
      }

      if (!this.state.edit) {
        addWellnessEvent(this.props.dispatch, this.context.apiClient, toSave)
          .then(() => this.setState({ saving: false }, () => this.onClose()))
          .catch((err) => {
            console.log('err', err);
            this.setState({ saveError: err.error, saving: false });
          });
      }
    }
    this.props.onSave();
  }

  getRrule(recurrence) {
    let freq = null;
    if (/^\dxDay$/.test(recurrence) || recurrence === 'askOnce') {
      freq = RRule.DAILY;
    }
    if (/^\dxWeek$/.test(recurrence)) {
      freq = RRule.WEEKLY;
    }
    if (/^\dxMonth$/.test(recurrence)) {
      freq = RRule.MONTHLY;
    }
    const dtstart = dateInRruleDateFormat(this.state.startDate);
    const until = dateInRruleDateFormat(this.state.endDate);
    const byminute = [];
    Reflect.ownKeys(this.state).forEach((key) => {
      if (/^time_\d$/.test(key)) {
        byminute.push(getMinutes(this.state[key]));
      }
    });
    return new RRule({
      tzid: this.props.activeRecipient.timezone,
      freq,
      dtstart,
      until,
      byminute
    });
  }

  handleSensorReadingOption(sensorReadingOptionName) {
    return (event) => {
      const state = { ...this.state };
      state.sensorReadings[sensorReadingOptionName] = event.target.checked;
      this.setState(state);
    };
  }

  questionUpdate(data, i) {
    this.setState({ [`question_${i}`]: data });
  }

  renderQuestions(repeatTimes) {
    const questions = [];
    for (let i = 1; i <= repeatTimes; i += 1) {
      questions.push( //eslint-disable-line
        <Question
          key={`question_${i}`}
          orderNumber={i}
          questionUpdate={data => this.questionUpdate(data, i)}
          initialData={(typeof this.state.out.wellnessData !== 'undefined') ? // eslint-disable-line
            (typeof this.state.out.wellnessData !== 'undefined' ? this.state.out.wellnessData[i - 1] : false) :
              false}
        />
      ); //eslint-disable-line
    }
    return questions;
  }

  renderTimeSelection() {
    let repeatTimes = 1;
    if (this.state.recurrence === 'askOnce' ||
        this.state.recurrence === '1xDay' ||
        this.state.recurrence === '1xWeek' ||
        this.state.recurrence === '1xMonth') {
      repeatTimes = 1;
    }
    if (this.state.recurrence === '2xDay') {
      repeatTimes = 2;
    }
    if (this.state.recurrence === '3xDay') {
      repeatTimes = 3;
    }
    if (this.state.recurrence === '4xDay') {
      repeatTimes = 4;
    }

    const timeSelection = [];
    const { classes, activeRecipient } = this.props;
    const { timezone } = activeRecipient;
    for (let i = 1; i <= repeatTimes; i += 1) {
      const timeKey = `time_${i}`;
      const label = i === 1 ? 'Reminder time' : `Reminder time ${i}`;
      const value = this.state[timeKey] ? this.state[timeKey] : moment().toDate();
      const time = moment(value);
      timeSelection.push( //eslint-disable-line
        <div className={style.field} key={timeKey}>
          <div className={style.label}>{label}</div>
          <div className={style.timeField}>
            <TimePicker
              keyboard
              fullWidth
              clearable
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  input: classes.input
                }
              }}
              onChange={selectedTime => this.setState({ [timeKey]: selectedTime })}
              value={value}
              mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
            />
            {time.isValid() && (
              <div className={style.time}>
                {'Reminder time for carerecipient: '}
                <strong>{getTimeByTimezone(time, timezone).format('h:mm A Z')}</strong>
              </div>
            )}
          </div>
        </div>);
    }
    return timeSelection;
  }

  renderStep1() {
    const today = new Date();
    const { classes } = this.props;
    return (
      <div>
        <Title icon="assignment" iconColor="#afe67b">
          New Wellness Check (1/3)
        </Title>
        <div className={style.stepContent}>
          <div className={style.title}>
            <div className={style.field}>
              <div className={style.label}>Title</div>
              <TextField
                fullWidth
                value={this.state.title}
                onChange={event => this.setState({ title: event.target.value })}
                classes={{ root: classes.textFieldRoot }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
              />
            </div>
          </div>
          <div className={style.checkinFrequency}>
            <div className={style.field}>
              <div className={style.label}>Frequency</div>
              <Select
                value={this.state.recurrence}
                onChange={event => this.setState({ recurrence: event.target.value })}
                className={style.select}
              >
                <MenuItem key="askOnce" value="askOnce">
                  Ask once
                </MenuItem>
                <MenuItem key="1xDay" value="1xDay">
                  1x a day
                </MenuItem>
                <MenuItem key="2xDay" value="2xDay">
                  2x a day
                </MenuItem>
                <MenuItem key="3xDay" value="3xDay">
                  3x a day
                </MenuItem>
                <MenuItem key="4xDay" value="4xDay">
                  4x a day
                </MenuItem>
                <MenuItem key="1xWeek" value="1xWeek">
                  1x a week
                </MenuItem>
                <MenuItem key="1xMonth" value="1xMonth">
                  1x a month
                </MenuItem>
              </Select>
            </div>
            <div className={style.dateRangeFields}>
              <div className={style.field}>
                <div className={style.label}>Start date</div>
                <DatePicker
                  value={this.state.startDate}
                  onChange={date => this.setState({ startDate: date })}
                  fullWidth
                  keyboard
                  format="MM/DD/YYYY"
                  clearable
                  autoOk
                  mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocused,
                      input: classes.input
                    }
                  }}
                  InputAdornmentProps={{
                    classes: { root: classes.adornment }
                  }}
                />
              </div>
              <div className={style.field}>
                <div className={style.label}>End date</div>
                <DatePicker
                  value={this.state.endDate}
                  onChange={date => this.setState({ endDate: date })}
                  fullWidth
                  keyboard
                  format="MM/DD/YYYY"
                  clearable
                  autoOk
                  mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  minDate={today}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocused,
                      input: classes.input
                    }
                  }}
                  InputAdornmentProps={{
                    classes: { root: classes.adornment }
                  }}
                />
              </div>
            </div>
            <div className={style.timeSelection}>
              {this.renderTimeSelection()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { classes } = this.props;
    return (
      <div>
        <Title icon="assignment" iconColor="#afe67b">
          Questions (2/3)
        </Title>
        <div className={style.numberOfQuestions}>
          <div className={style.field}>
            <div className={style.label}>Number of questions</div>
            <TextField
              fullWidth
              type="number"
              value={this.state.numberOfQuestions}
              onChange={event => this.setState({ numberOfQuestions: event.target.value })}
              classes={{ root: classes.textFieldRoot }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  input: classes.input
                },
              }}
              // eslint-disable-next-line
              inputProps={{
                min: 1,
                step: 1
              }}
            />
          </div>
        </div>
        <div>
          {this.renderQuestions(this.state.numberOfQuestions)}
        </div>
        {this.state.saveError && (
          <div className={style.saveError}>An error occurred: {this.state.saveError}</div>
        )}
      </div>
    );
  }

  renderStep3() {
    return (
      <div>
        <Title icon="assignment" iconColor="#afe67b">
          Include Readings (3/3)
        </Title>
        <div className={style.numberOfQuestions}>
          <div className={style.field}>
            <div className={style.label}>
              Check the additional readings you would like to include in this check-in
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={this.handleSensorReadingOption('shouldIncludeHeartrateForCheckIn')}
                    checked={this.state.sensorReadings.shouldIncludeHeartrateForCheckIn}
                  />
                }
                label="Heart rate sensor reading"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={this.handleSensorReadingOption('shouldIncludeStepCountForCheckIn')}
                    checked={this.state.sensorReadings.shouldIncludeStepCountForCheckIn}
                  />
                }
                label="Gather daily step count"
              />
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { currentStep } = this.state;
    const lastStep = 3;
    return (
      <div>
        {currentStep > 1 && (
          <IconButton
            onClick={() => this.onBackClick()}
            className={style.buttonBack}
            icon="arrow_back"
            variant="fab"
            mini
          />
        )}
        <div className={style.content}>
          {currentStep === 1 && this.renderStep1()}
          {currentStep === 2 && this.renderStep2()}
          {currentStep === lastStep && this.renderStep3()}
        </div>
        <div>
          {currentStep !== lastStep && (
            <Button
              disabled={!!(!this.state.title)} // eslint-disable-line
              className={style.nextButton}
              onClick={() => this.onNextClick()}
            >
              NEXT
            </Button>
          )}
          {currentStep === lastStep && (
            <Button
              className={style.nextButton}
              onClick={() => this.onSaveClick()}
            >
              SAVE
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(['carerecipients', 'activeRecipient'], {});
  return { activeRecipient };
};

export default withStyles(styles)(connect(mapStateToProps)(Dialog));
