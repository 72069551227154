import React, { useState } from 'react';
import {
  TextField,
  Grid,
  Button,
  IconButton,
  Icon,
  Checkbox,
  FormHelperText,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    minHeight: '25em',
    margin: 10,
  },
  formButton: {
    width: '100%',
  },
  reminderTimeLabel: {
    fontSize: 12,
    paddingTop: 8,
  },
  avatar: {
    width: 150,
    height: 150,
    boxShadow: '1px 1px 7px -3px grey',
    cursor: 'pointer',
    left: '50%',
    transform: 'translate(-50%, -10%)',
  },
  fileButton: {
    transform: 'translate(70%, -10%)',
  },
}));

function NewContactForm({ preFilledForm, onSubmit = () => {} }) {
  const initialFormState = {
    name: '',
    phoneNumber: '',
    address: '',
    isFavorite: false,
    isEmergency: false,
    email: '',
    profileImage: [],
  };
  const [values, setState] = useState({
    ...initialFormState,
    ...preFilledForm,
  });
  const classes = useStyles();

  const handleInputChange = (event) => {
    const name = event.target.name;
    var value = event.target.value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    } else if (event.target.type === 'file') {
      value = event.target.files[0];
    }

    setState({ ...values, [name]: value });
  };

  const handleFavoriteOnChange = (value) => {
    setState({ ...values, isFavorite: value });
  };

  function checkValidEmail() {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(values.email).toLowerCase())) {
      return true;
    }
    return false;
  }

  const { t } = useTranslation();

  return (
    <form className={classes.formContainer}>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={12}>
          <label htmlFor="raised-button-file">
            {Array.isArray(values.profileImage) && (
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon size="3x" icon={['fas', 'camera-alt']} />
              </Avatar>
            )}
            {(values.edit && typeof values.profileImage !== 'string') ||
              (values.profileImage.name && (
                <Avatar className={classes.avatar}>
                  <FontAwesomeIcon size="3x" icon={['fas', 'camera-alt']} />
                </Avatar>
              ))}

            {values.edit && typeof values.profileImage === 'string' && (
              <Avatar
                src={
                  values.edit ? values.profileImage : values.profileImage.name
                }
                className={classes.avatar}
              ></Avatar>
            )}
          </label>
          <input
            accept="image/*"
            id="raised-button-file"
            onChange={handleInputChange}
            name="profileImage"
            className={classes.fileButton}
            type="file"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            rowsMax="2"
            required
            label={t('Contacts.Name')}
            value={values.name}
            name="name"
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            required
            rowsMax="4"
            label={t('Contacts.PhoneNumber')}
            value={values.phoneNumber}
            name="phoneNumber"
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            rowsMax="4"
            label={t('Contacts.Address')}
            value={values.address}
            name="address"
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!checkValidEmail() && values.email !== ''}
            fullWidth
            multiline
            rowsMax="4"
            type="email"
            label={t('Contacts.Email')}
            value={values.email}
            name="email"
            onChange={handleInputChange}
            variant="outlined"
          />
          {!checkValidEmail() && values.email && (
            <FormHelperText id="component-error-text">
              {t('Contacts.RequiresValidEmail')}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {values.isFavorite && (
            <IconButton
              color="secondary"
              onClick={() => handleFavoriteOnChange(false)}
            >
              <Icon>star</Icon>
            </IconButton>
          )}
          {!values.isFavorite && (
            <IconButton
              aria-label={t('Contacts.FavoriteAContact')}
              onClick={() => handleFavoriteOnChange(true)}
            >
              <Icon>star_border</Icon>
            </IconButton>
          )}
          <label> Favorite</label>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            checked={values.isEmergency}
            type="checkbox"
            name="isEmergency"
            value={String(values.isEmergency)}
            onChange={handleInputChange}
          ></Checkbox>
          <label>{t('Contacts.EmergencyContact')}</label>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            disabled={
              !checkValidEmail() ||
              !values.name ||
              !values.phoneNumber ||
              (!values.name && !values.phoneNumber)
            }
            className={classes.formButton}
            onClick={() => onSubmit(values)}
            id="general-reminder-form-cancel-btn"
          >
            {!values.edit ? t('Contacts.Create') : t('Contacts.Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

function NewContactFormDialog(props) {
  return (
    <React.Suspense fallback="loading">
      <NewContactForm {...props} />
    </React.Suspense>
  );
}

export default NewContactFormDialog;
