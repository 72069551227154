import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import makeHSL from 'styles/makeHSL';

const useStyles = makeStyles((theme) => ({
  route: {
    ...theme.mixins.toolbar,
    textDecoration: 'none',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    color: makeHSL({ hue: 211, saturation: 0.15, lightness: 0.46 }),
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',

    '&:hover': {
      color: makeHSL({ hue: 210, saturation: 0.55, lightness: 0.19 }),
      borderBottomColor: makeHSL({
        hue: 180,
        saturation: 0.6,
        lightness: 0.46,
      }),
    },
  },

  activeRoute: {
    color: makeHSL({ hue: 210, saturation: 0.55, lightness: 0.19 }),
    borderBottomColor: makeHSL({
      hue: 180,
      saturation: 0.6,
      lightness: 0.46,
    }),
  },
}));

const TopAppBarMenu = ({ menuItems = [], ...props }) => {
  const classes = useStyles();

  const menu = menuItems.map((item, index) => (
    <NavLink
      key={index}
      activeClassName={classes.activeRoute}
      className={classes.route}
      to={item.destination}
    >
      <span>{item.name}</span>
    </NavLink>
  ));

  return <div {...props}>{menu}</div>;
};

export default TopAppBarMenu;
