import { RECEIVE_HEART_RATE_LEVEL } from 'actions/heartRateLevel';

function setHeartRateLevel(payload) {
  return { ...payload };
}

const heartRateLevel = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_HEART_RATE_LEVEL:
      return setHeartRateLevel(action.payload);

    default:
      return state;
  }
};

export default heartRateLevel;
