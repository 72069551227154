import { Actions } from 'actions/conversation';
import { useSelector } from 'react-redux';
import isEqualLength from 'utils/isEqualLength';

export const initialState = {
  conversations: [],
  conversation: {
    conversationId: null,
    name: '',
    participants: [],
    messages: [],
  },
};

function addConversationToConversations(conversations, message) {
  if (!message) {
    return conversations;
  }

  return [] //
    .concat(conversations) //
    .map((conversation) => {
      if (conversation.conversationId === message.conversationId) {
        conversation.messages = [[].concat(message.messages).pop()];
      }

      return conversation;
    });
}

export const conversations = (state = initialState, action) => {
  switch (action.type) {
    case Actions.RECEIVE_CONVERSATIONS:
      return { ...state, conversations: action.payload };

    case Actions.RECEIVE_CONVERSATION:
      const conversation = action.payload;
      const conversations = addConversationToConversations(
        state.conversations,
        conversation
      );

      return { ...state, conversation, conversations };

    default:
      return state;
  }
};

export const useConversationsSelector = () =>
  useSelector(
    (state) => state.getIn(['conversations', 'conversations']),
    (previous, current) => isEqualLength(current, previous)
  );

export const useConversationSelector = () =>
  useSelector(
    (state) => state.getIn(['conversations', 'conversation']),
    (current, previous) => isEqualLength(previous.messages, current.messages)
  );
