import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intersection } from 'utils/sets';
import { create } from 'actions/messages';
import {
  Dialog as MuiDialog,
  IconButton,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import NewMessageForm from './NewMessageForm';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    width: 550,
  },
  send: {
    width: '50%',
    display: 'block',
    margin: 'auto',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
});

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      toUserId: props.data.toUserId || [],
      careRecipientId: props.data.careRecipientId || '',
      subject: props.data.subject || '',
      content: props.data.content || '',
      isDialogOpen: true,
    };

    this.render = this.render.bind(this);
  }

  getCareRecipientIdsFilter() {
    const { toUserId } = this.state;
    if (toUserId.length === 0) {
      return null;
    }
    const users = this.props.users.toJS();
    return Array.from(
      toUserId.reduce((set, userId) => {
        const newSet = new Set(users[userId].careRecipients || []);
        return set.length > 0 ? intersection(set, newSet) : newSet;
      }, new Set())
    );
  }

  submit = (values) => {
    const { t } = this.props;
    const mess = t('SecureMessages.MessageHasBeenSent');
    create(
      this.props.dispatch,
      this.context.apiClient,
      values.toUserId,
      values.careRecipientId,
      values.subject,
      values.content
    )
      .then(() => {
        this.context.dialogClose(this.props.dialogId);
        this.context.dialogPush('message', {
          content: 'Ok',
          subContent: mess,
        });
      })
      .catch((e) =>
        this.context.dialogPush('message', {
          content: 'Oops',
          subContent: e.toString ? e.toString() : 'Unknown error',
        })
      );
  };

  onClose() {
    this.setState({ isDialogOpen: false });
  }

  renderCareRecipient() {
    const careRecipients = Object.values(this.props.careRecipients);
    const rec = careRecipients.find(
      (i) => i.userId === this.state.careRecipientId
    );
    if (!rec) {
      return 'Unknown care recipient';
    }
    return `${rec.name.first} ${rec.name.last}`;
  }

  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  getAllCareGivers = (recipients, activecaregiver) => {
    const allCareGivers = [];
    for (var cr in recipients) {
      for (var cg in recipients[cr].careGivers) {
        if (
          recipients[cr].userId !== recipients[cr].careGivers[cg].userId &&
          recipients[cr].careGivers[cg].userId !== activecaregiver
        ) {
          allCareGivers.push(recipients[cr].careGivers[cg]);
        }
      }
    }
    return this.getUnique(allCareGivers, 'userId');
  };

  render() {
    const { classes, careRecipients, userId, activeRecipient, t } = this.props;
    var othercaregivers = '';
    var isRecipient = '';

    if (activeRecipient.userId !== null) {
      othercaregivers = activeRecipient.careGivers.filter(function (item) {
        return item.userId !== userId && item.userId !== activeRecipient.userId;
      });
      isRecipient = activeRecipient.userId === userId;
    }
    const allCareGivers = this.getAllCareGivers(careRecipients, userId);

    return (
      <MuiDialog
        open={this.state.isDialogOpen}
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('SecureMessages.NewMessage')}
        </DialogTitle>
        <IconButton
          aria-label={t('SecureMessages.Close')}
          className={classes.closeButton}
          onClick={() => this.onClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <NewMessageForm
            careRecipients={careRecipients}
            othercaregivers={othercaregivers}
            allCareGivers={allCareGivers}
            preFilledFormState={this.state}
            onSubmit={this.submit}
            isRecipient={isRecipient}
          ></NewMessageForm>
        </DialogContent>
      </MuiDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  userCaregivers: state.getIn(['user', 'userCaregivers']),
  activeRecipient: state.getIn(['carerecipients', 'activeRecipient']),
  userId: state.getIn(['user', 'self', 'userId']),
  careRecipients: state.getIn(['carerecipients', 'recipients']).toJS(),
  users: state.getIn(['user', 'items']),
});

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(Dialog))
);
