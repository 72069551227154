import React from 'react';
import {
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Edit from '@material-ui/icons/Edit';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    textAlign: 'center',
    marginBottom: 20,
  },
  table: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  titleText: {
    fontWeight: 'bold',
    marginTop: 5,
    width: '100%',
  },
}));

function DisplayResults({
  onEditButtonBlick = () => {},
  onSaveButtonClick = () => {},
  formData,
}) {
  const classes = useStyles();
  const medicineResult = [];
  const reminderResult = [];
  const dataFromHandler = formData;

  function makeTitle(word) {
    let data = word.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g);
    let title = '';
    for (let i = 0; i < data.length; i++) {
      title += data[i] + ' ';
    }
    title = title.charAt(0).toUpperCase() + title.slice(1);
    return title.replace(/\s+$/, '');
  }

  function formResultInformation() {
    for (var category in dataFromHandler) {
      for (var item in dataFromHandler[category]) {
        var title = makeTitle(item);
        if (category === 'medicalInformation') {
          let value =
            dataFromHandler[category][item] !== null
              ? dataFromHandler[category][item].toString()
              : dataFromHandler[category][item];
          if (value !== '' && value !== null && title !== '_id') {
            medicineResult.push({
              label: makeTitle(item),
              value: value,
            });
          }
        }
        if (category === 'reminder') {
          let value = '';
          if (typeof dataFromHandler[category][item] === 'boolean') {
            value = dataFromHandler[category][item] === false ? 'No' : 'Yes';
          } else if (item === 'startDate') {
            reminderResult.push({
              label: title,
              value: DateTime.fromJSDate(
                dataFromHandler[category][item]
              ).toFormat('DDD'),
            });
          } else if (
            dataFromHandler[category][item] !== null &&
            !item.startsWith('reminderTime')
          ) {
            value = dataFromHandler[category][item].toString();
          } else if (item.startsWith('reminderTime')) {
            const nums = parseInt(title.replace(/^\D+/g, '')) + 1;
            title = title.replace(/_/g, ' ').substring(0, title.length - 1);
            reminderResult.push({
              label: title + nums,
              value: DateTime.fromJSDate(
                dataFromHandler[category][item]
              ).toFormat('ttt'),
            });
          } else {
            value = dataFromHandler[category][item];
          }
          if (value !== '' && value !== null) {
            reminderResult.push({
              label: title,
              value: value,
            });
          }
        }
      }
    }
  }

  formResultInformation();

  return (
    <div>
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow>
            <TableCell width="40%" variant="head">
              <Typography className={classes.titleText} variant="subtitle1">
                Medical Information
              </Typography>
            </TableCell>
            <TableCell align="right">
              <IconButton
                className={classes.iconButton}
                onClick={() => onEditButtonBlick(1)}
              >
                <Edit />
              </IconButton>
            </TableCell>
          </TableRow>
          {medicineResult.map((w, key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                <b>{w.label}</b>
              </TableCell>
              <TableCell>{w.value}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell width="40%" variant="head">
              <Typography className={classes.titleText} variant="subtitle1">
                Reminder Information
              </Typography>
            </TableCell>
            <TableCell align="right">
              <IconButton
                className={classes.iconButton}
                onClick={() => onEditButtonBlick(2)}
              >
                <Edit />
              </IconButton>
            </TableCell>
          </TableRow>
          {reminderResult.map((w, key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                <b>{w.label}</b>
              </TableCell>
              <TableCell>{w.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => onSaveButtonClick(formData)}
      >
        SAVE
      </Button>
    </div>
  );
}

export default DisplayResults;
