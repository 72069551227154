import React, { useState } from 'react';
import {
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Grid,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { whenToTakeOptions, whenToTakeKeys } from 'utils/medication';
import { searchConditions } from 'actions/medicine';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AutoComplete from 'components/AutoComplete';

const useStyles = makeStyles((theme) => ({
  radioButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

function FillInMedicalInformation(
  { onChosenMedicalInformation = () => {}, medication, medInfo, prevStep },
  context
) {
  const initialFormState = {
    Prescription: '',
    Term: 'short',
    Dosage: '',
    WhenToTake: '',
    Duration: '',
    NumberOfDosages: '',
    Instructions: '',
    Doctor: '',
    Pharmacy: '',
    PharmacyPhone: '',
    DoctorPhone: '',
    Condition: '',
  };

  const formState = {
    ...medication,
    ...initialFormState,
  };
  const usedState = Object.keys(medInfo).length === 0 ? formState : medInfo;
  const [values, setState] = useState(usedState);
  const dispatch = useDispatch();

  const handleInputOnChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setState({ ...values, [target.name]: value });
  };

  function getMedicalConditions(search) {
    return searchConditions(dispatch, context.apiClient, search).then((res) =>
      res.data.conditions.map((c) => ({ value: c.name, label: c.name }))
    );
  }

  const classes = useStyles();

  const handleConditionSearch = (value) => {
    setState({ ...values, Condition: value });
  };

  return (
    <div>
      <form>
        <Grid container spacing={2} alignItems={'center'} justify={'center'}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={values.Prescription}
              onChange={handleInputOnChange}
              name={'Prescription'}
              type={'text'}
              label={'Prescription Number'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Short or long term medication{' '}
              </FormLabel>
              <RadioGroup
                name="Term"
                value={values.Term}
                onChange={handleInputOnChange}
                className={classes.radioButtons}
              >
                <FormControlLabel
                  value="short"
                  control={<Radio color="primary" />}
                  label="Short Term"
                />
                <FormControlLabel
                  value="long"
                  control={<Radio color="primary" />}
                  label="Long Term"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              value={values.DrugName}
              onChange={handleInputOnChange}
              name={'DrugName'}
              type={'text'}
              label={'Brand name'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              value={values.ActiveIngredient}
              onChange={handleInputOnChange}
              name={'ActiveIngredient'}
              type={'text'}
              label={'Generic name'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              value={values.Strength}
              onChange={handleInputOnChange}
              name={'Strength'}
              type={'text'}
              label={'Strength'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              value={values.Form}
              onChange={handleInputOnChange}
              name={'Form'}
              type={'text'}
              label={'Drug formulation'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              value={values.Dosage}
              placeholder="E.g. 2 tablets"
              onChange={handleInputOnChange}
              name={'Dosage'}
              type={'text'}
              label={'Amount to take'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              required
              label="When to take"
              value={values.WhenToTake}
              name="WhenToTake"
              onChange={handleInputOnChange}
              variant="outlined"
            >
              {whenToTakeKeys.map((w) => (
                <MenuItem key={w} value={w}>
                  {whenToTakeOptions[w].label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={values.Duration}
              onChange={handleInputOnChange}
              placeholder="E.g. 3 months"
              name={'Duration'}
              type={'text'}
              label={'Duration'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={values.NumberOfDosages}
              onChange={handleInputOnChange}
              name={'NumberOfDosages'}
              type={'text'}
              label={'Quantity dispensed'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              multiline
              value={values.Instructions}
              onChange={handleInputOnChange}
              type={'text'}
              name={'Instructions'}
              label={'Special instructions'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={values.Doctor}
              onChange={handleInputOnChange}
              type={'text'}
              name={'Doctor'}
              label={'Doctor'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={values.Pharmacy}
              onChange={handleInputOnChange}
              type={'text'}
              name={'Pharmacy'}
              label={'Pharmacy'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={values.DoctorPhone}
              onChange={handleInputOnChange}
              type={'text'}
              name={'DoctorPhone'}
              label={'Doctor phone number'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={values.PharmacyPhone}
              onChange={handleInputOnChange}
              type={'text'}
              name={'PharmacyPhone'}
              label={'Pharmacy phone number'}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AutoComplete
              loadOptions={(data) => getMedicalConditions(data)}
              placeholder={'Medical Condition'}
              name={'Condition'}
              async={true}
              setOutValue={(data) => handleConditionSearch(data)}
            ></AutoComplete>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={prevStep}
            >
              BACK
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              disabled={
                !values.DrugName ||
                !values.Form ||
                !values.Strength ||
                !values.ActiveIngredient ||
                !values.Dosage ||
                !values.WhenToTake
              }
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => onChosenMedicalInformation(values)}
            >
              NEXT
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

FillInMedicalInformation.contextTypes = {
  apiClient: PropTypes.object.isRequired,
};
export default FillInMedicalInformation;
