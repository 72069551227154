import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { grey } from '@material-ui/core/colors';

function InfoTooltip(props) {
  const { t } = useTranslation();
  const IconWrapper = props.iconWrapper;

  const getTranslationForToolTip = () => {
    const { translationKey, translationOptions } = props;
    const translations = t(translationKey, {
      returnObjects: true,
      ...translationOptions,
    });
    const lastIndex = Object.keys(translations).length - 1;

    return (
      <>
        {Object.keys(translations)
          .sort()
          .map((translationKey, idx) => (
            <Typography
              align="center"
              variant="subtitle2"
              paragraph={idx !== lastIndex}
              gutterBottom={idx !== lastIndex}
              key={translationKey}
            >
              {translations[translationKey]}
            </Typography>
          ))}
      </>
    );
  };

  return (
    <Tooltip
      title={getTranslationForToolTip()}
      placement="bottom"
      className={props.className}
    >
      <IconWrapper>
        <FontAwesomeIcon
          color={props.iconColor}
          size="2x"
          icon={['fas', props.iconType]}
        />
      </IconWrapper>
    </Tooltip>
  );
}

InfoTooltip.defaultProps = {
  iconWrapper: 'div',
  iconColor: grey[600],
  iconType: 'info-circle',
};

export default InfoTooltip;
