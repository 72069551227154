/* eslint-disable */

export const searchProducts = (dispatch, apiClient, query) =>
  apiClient.call({ endpoint: 'medicine/searchProducs', data: { query } }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'MEDICINE_SET', payload: res.data });
    return res;
  });

export const addMedicineEvent = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'medicine/addMedicineEvent',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
      return res;
    });

export const editMedicineEvent = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'medicine/editMedicineEvent',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
      return res;
    });

export const searchConditions = (dispatch, apiClient, query) =>
  apiClient.call({ endpoint: 'medicine/searchConditions', data: { query } }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'MEDICINE_CONDITIONS_SET', payload: res.data.conditions });
    return res;
  });

export const searchAllergies = (dispatch, apiClient, query) =>
  apiClient.call({ endpoint: 'medicine/searchAllergies', data: { query } }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'MEDICINE_ALLERGIES_SET', payload: res.data.allergies });
    return res;
  });

export const getAllAllergies = (dispatch, apiClient) =>
  apiClient.call({ endpoint: 'medicine/getAllAllergies' }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'MEDICINE_ALLERGIES_ALL_SET', payload: res.data.allergies });
    return res;
  });

export const getAllConditions = (dispatch, apiClient) =>
  apiClient.call({ endpoint: 'medicine/getAllConditions' }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'MEDICINE_CONDITIONS_ALL_SET', payload: res.data.conditions });
    return res;
  });

