/* eslint-disable */

export const getMyInvitations = (dispatch, apiClient, status) =>
  apiClient.call({ endpoint: 'invitations/getMyInvitations', data: { status } }).then((res) => {
    if (res.status !== 200) {
      throw res;
    }
    dispatch({ type: 'INVITATIONS_SET', payload: res.data });
    return res;
  });

export const accept = (dispatch, apiClient, { invitationId = null }) =>
  apiClient.call({ endpoint: 'invitations/accept', data: { invitationId } }).then((res) => {
    if (res.status !== 200) {
      throw res;
    }

    return getMyInvitations(dispatch, apiClient);
  });

export const reject = (dispatch, apiClient, { invitationId = null }) =>
  apiClient.call({ endpoint: 'invitations/reject', data: { invitationId } }).then((res) => {
    if (res.status !== 200) {
      throw res;
    }

    return getMyInvitations(dispatch, apiClient);
  });

export const inviteCaregiver = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'invitations/create',
      data
    })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      // dispatch({ type: 'INVITATIONS_SET', payload: res.data });
      return res;
    });


export const getUsersUnconfirmedCareGivers = (dispatch, apiClient, careRecipientsId) => (
  apiClient
    .call({
      endpoint: 'invitations/getUsersUnconfirmedCareGivers',
      data: { careRecipientsId }
    })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      dispatch({ type: 'SET_UNCONFIRMED_CAREGIVERS', payload: res.data });
      return res;
    })
);


export default {};
