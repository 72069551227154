export const intersection = (setA, setB) => {
  const _intersection = new Set();
  setB.forEach((elem) => {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  });
  return _intersection;
};

export default {};
