/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import classNames from 'classnames/bind';

import Button from 'components/Button';
import RequiredFieldIndicator from 'components/RequiredFieldIndicator';
import Select from 'components/Select';
import Switch from 'components/Switch';

import { getMinutes, getTimeByTimezone } from 'utils/date';
import { addGeneralReminder, editGeneralReminder } from 'actions/calendar';

import { DatePicker, TimePicker } from 'material-ui-pickers';

import { Checkbox, FormControlLabel, MenuItem, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import RRule from 'rrule';
import moment from 'moment-timezone';
import { convertRecurrenceToJSDate, dateInRruleDateFormat } from 'utils/rruleWrapper';

import style from './style.module.scss';


const styles = () => ({
  textFieldRoot: {},
  inputRoot: {
    height: 36,
    backgroundColor: 'white',
    padding: '8px 0 8px 8px',
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: 4
  },
  inputMultiLine: {
    backgroundColor: 'white',
    padding: '8px 0 8px 8px',
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: 4
  },
  inputFocused: {},
  input: {
    padding: 0
  },
  adornment: {
    alignSelf: 'center'
  }
});

const cx = classNames.bind(style);

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    const data = this.props.data || {};
    const { eventData, userObject } = data;
    const edit = !!eventData;
    const isFromCareGiverCalendar = !!this.props.data.isFromCareGiverCalendar;
    let out = {};
    let rule = {};
    let convertedRecurrence;
    const recurrenceTime = [];

    if (edit) {
      const reminder = Array.isArray(eventData.data) ? eventData.data : [eventData.data];
      rule = RRule.fromString(eventData.recurrence);
      const freqTranslations = ['YEARLY', 'MONTHLY', 'WEEKLY', 'DAILY', 'HOURLY', 'MINUTELY', 'SECONDLY'];
      const originalRecurrence = freqTranslations[rule.origOptions.freq];

      const origTime = Array.isArray(rule.origOptions.byminute) ?
        rule.origOptions.byminute : [rule.origOptions.byminute];

      if (originalRecurrence === 'DAILY') {
        convertedRecurrence = `${origTime.length}xDay`;
      }

      if (originalRecurrence === 'WEEKLY') {
        convertedRecurrence = '1xWeek';
      }

      if (originalRecurrence === 'MONTH') {
        convertedRecurrence = '1xMonth';
      }

      const startDate = get(rule, 'options.dtstart');

      // Convert rrule time to Date
      origTime.map(time => recurrenceTime.push(convertRecurrenceToJSDate(startDate, time)));

      out = {
        ...eventData,
        wellnessData: reminder
      };
    }

    const today = moment().startOf('day');
    const recurrenceString = get(out, 'recurrence', false);
    const recurrenceRRule = recurrenceString && RRule.fromString(recurrenceString);
    const endDate = get(recurrenceRRule, 'options.until', false);
    const isOngoing = !endDate;

    this.state = {
      duplicateToCareGiverCalendar: false,
      edit,
      endDate: (typeof rule.origOptions !== 'undefined') ? rule.origOptions.until : today.clone().add(1, 'day').toDate(),
      isFromCareGiverCalendar,
      isOngoing,
      out,
      reminder: [],
      recurrence: (typeof convertedRecurrence !== 'undefined') ? convertedRecurrence : 'askOnce',
      recurrenceTime,
      saving: false,
      startDate: (typeof rule.origOptions) !== 'undefined' ? rule.origOptions.dtstart : today.toDate(),
      text: get(out.data, 'text', ''),
      time_1: moment(),
      timezone: userObject.timezone,
      title: out.title || '',
      userObject
    };
  }

  componentDidMount() {
    if (((typeof this.state.recurrenceTime) !== 'undefined') && this.state.recurrenceTime.length > 0) {
      this.state.recurrenceTime.map((timeValue, i) => {
        const time = `time_${i + 1}`;
        return this.setState({
          [time]: timeValue
        });
      });
    }
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onSaveClick() {
    if (!this.state.saving) {
      this.setState({ saving: true });

      const rule = this.getRrule(this.state.recurrence);
      const recDescription = rule.toText();
      const toSave = {
        active: true,
        recurrence: rule.toString(),
        recurrenceDescription: recDescription,
        timezone: this.state.timezone,
        title: this.state.title,
        text: this.state.text,
        userId: this.state.userObject.userId,
      };


      if (this.state.edit) {
        toSave.eventId = this.props.data.eventData.eventId;
        editGeneralReminder(this.props.dispatch, this.context.apiClient, toSave)
          .then(() => this.setState({ saving: false }, () => this.onClose()))
          .catch((err) => {
            console.log('err', err);
            this.setState({ saveError: err.error, saving: false });
          });
      }

      if (!this.state.edit) {
        toSave.addToSelf = this.state.duplicateToCareGiverCalendar;
        addGeneralReminder(this.props.dispatch, this.context.apiClient, toSave)
          .then(() => this.setState({ saving: false }, () => this.onClose()))
          .catch((err) => {
            console.log('err', err);
            this.setState({ saveError: err.error, saving: false });
          });
      }
    }
    this.props.onSave();
  }

  onOngoingChange = name => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  getRrule(recurrence) {
    let freq = null;
    if (recurrence === 'askOnce') {
      freq = null;
    }
    if (/^\dxDay$/.test(recurrence)) {
      freq = RRule.DAILY;
    }
    if (/^\dxWeek$/.test(recurrence)) {
      freq = RRule.WEEKLY;
    }
    if (/^\dxMonth$/.test(recurrence)) {
      freq = RRule.MONTHLY;
    }

    const dtstart = dateInRruleDateFormat(this.state.startDate);
    const until = !this.state.isOngoing ? dateInRruleDateFormat(this.state.endDate, true) : false;
    const byminute = [];
    Reflect.ownKeys(this.state).forEach((key) => {
      if (/^time_\d$/.test(key)) {
        byminute.push(getMinutes(this.state[key]));
      }
    });

    const ruleData = freq === null ?
      {
        dtstart,
        byminute,
        count: 1
      } : {
        freq,
        dtstart,
        byminute
      };

    if (until) {
      ruleData.until = until;
    }

    ruleData.tzid = this.state.timezone;
    return new RRule(ruleData);
  }

  renderTimeSelection() {
    let repeatTimes = 1;
    if (this.state.recurrence === 'askOnce' ||
      this.state.recurrence === '1xDay' ||
      this.state.recurrence === '1xWeek' ||
      this.state.recurrence === '1xMonth') {
      repeatTimes = 1;
    }
    if (this.state.recurrence === '2xDay') {
      repeatTimes = 2;
    }
    if (this.state.recurrence === '3xDay') {
      repeatTimes = 3;
    }
    if (this.state.recurrence === '4xDay') {
      repeatTimes = 4;
    }

    const timeSelection = [];
    const { classes } = this.props;
    for (let i = 1; i <= repeatTimes; i += 1) {
      const time = `time_${i}`;
      const label = i === 1 ? 'Reminder time' : `Reminder time ${i}`;
      const timeValue = this.state[time] ? this.state[time] : moment().toDate();
      timeSelection.push( //eslint-disable-line
        <div className={style.field}>
          <div className={style.label}>{label}</div>
          <div className={style.timeField}>
            <TimePicker
              fullWidth
              keyboard
              clearable
              onChange={selectedTime => this.setState({ [time]: selectedTime })}
              value={timeValue}
              mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  input: classes.input
                }
              }}
            />
            {moment(timeValue).isValid() && (
              <div className={style.time}>
                {'Reminder time for carerecipient: '}
                <strong>{getTimeByTimezone(moment(timeValue), this.state.timezone).format('h:mm A Z')}</strong>
              </div>
            )}
          </div>
        </div>);
    }
    return timeSelection;
  }

  renderStep1() {
    const today = moment().startOf('day').toDate();
    const { classes } = this.props;
    const hideCheckbox = (this.state.edit || this.state.isFromCareGiverCalendar);
    return (
      <div>
        <div className={style.stepContent}>
          <div className={style.title}>
            <div className={style.field}>
              <div className={style.label}>Title <RequiredFieldIndicator /></div>
              <TextField
                fullWidth
                value={this.state.title}
                onChange={event => this.setState({ title: event.target.value })}
                classes={{ root: classes.textFieldRoot }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
              />
            </div>
            <div className={style.field}>
              <div className={style.label}>Description</div>
              <TextField
                fullWidth
                multiline
                rowsMax="4"
                value={this.state.text}
                onChange={event => this.setState({ text: event.target.value })}
                classes={{ root: classes.textFieldRoot }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputMultiLine,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
              />
            </div>
          </div>
          <div className={style.field}>
            <div className={style.label}>Frequency</div>
            <Select
              value={this.state.recurrence}
              onChange={event => this.setState({ recurrence: event.target.value })}
              className={style.select}
            >
              <MenuItem key="askOnce" value="askOnce">
                Ask once
              </MenuItem>
              <MenuItem key="1xDay" value="1xDay">
                1x a day
              </MenuItem>
              <MenuItem key="2xDay§" value="2xDay">
                2x a day
              </MenuItem>
              <MenuItem key="3xDay" value="3xDay">
                3x a day
              </MenuItem>
              <MenuItem key="4xDay" value="4xDay">
                4x a day
              </MenuItem>
              <MenuItem key="1xWeek" value="1xWeek">
                1x a week
              </MenuItem>
              <MenuItem key="1xMonth" value="1xMonth">
                1x a month
              </MenuItem>
            </Select>
          </div>
          <div className={style.dateRangeFields}>
            <div className={style.field}>
              <div className={style.label}>Start date</div>
              <DatePicker
                key="startDate"
                value={this.state.startDate}
                onChange={date => this.setState({ startDate: date })}
                fullWidth
                keyboard
                format="MM/DD/YYYY"
                clearable
                autoOk
                mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                disableOpenOnEnter
                animateYearScrolling={false}
                minDate={today}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
                InputAdornmentProps={{
                  classes: { root: classes.adornment }
                }}
              />
            </div>
            <div
              className={
                cx(
                  style.field,
                  { [style.ongoing]: this.state.isOngoing }
                )}
            >
              <div className={style.label}>End date</div>
              <DatePicker
                key="endDate"
                value={this.state.endDate}
                onChange={date => this.setState({ endDate: date })}
                fullWidth
                keyboard
                format="MM/DD/YYYY"
                clearable
                autoOk
                mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                disableOpenOnEnter
                animateYearScrolling={false}
                minDate={today}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
                InputAdornmentProps={{
                  classes: { root: classes.adornment }
                }}
              />
            </div>
            <div className={style.fillAvailable}>
              <Switch
                checked={this.state.isOngoing}
                onChange={this.onOngoingChange('isOngoing')}
              />
              <span className={style.switchLabel}>Ongoing</span>
            </div>

          </div>
          <div className={style.timeSelection}>
            {this.renderTimeSelection()}
          </div>
        </div>
        {(!hideCheckbox) &&
          <FormControlLabel
            label="Duplicate to caregivers calendar"
            control={
              <Checkbox
                color="default"
                checked={this.state.duplicateToCareGiverCalendar}
                onChange={event =>
                  this.setState({ duplicateToCareGiverCalendar: event.target.checked })}
                value={String(this.state.duplicateToCareGiverCalendar)}
              />
            }
          />
        }
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className={style.content}>
          {this.renderStep1()}
        </div>
        <div>
          <Button
            disabled={!this.state.title}
            className={style.nextButton}
            onClick={() => this.onSaveClick()}
          >
            SAVE
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(connect()(Dialog));
