/* eslint-disable */

const userGetPromise = {};

export const me = (dispatch, apiClient) =>
  apiClient
    .call({ endpoint: 'user/me' })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'USER_SELF_SET', user: res.data });
      return res;
    });

export const auth = (dispatch, apiClient, credentials, options) => {
  dispatch({ type: 'USER_SELF_PENDING' });

  return apiClient
    .handshake(credentials)
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }

      const out = {
        type: 'COOKIE_SET',
        key: 'access_session',
        value: res.data.session,
        options: {}
      };

      if (options) {
        if (options.expires) {
          out.options.expires = options.expires;
        }
      }

      dispatch(out);
    })
    .then(() => {
      apiClient.setProtocol('ws');
    })
    .then(() => me(dispatch, apiClient))
    .then(userRes =>
      apiClient.call({ endpoint: 'user/getMyCareRecipients' }).then((res) => {
        const user = userRes.data;
        // console.log('getMyCareRecipients', res);
        if (res.status !== 200) {
          throw res;
        }

        const recipients = res.data;

        if (options && options.activeCareRecipientId) {
          const gotRecipient = recipients.filter(recipient =>
            recipient.userId === options.activeCareRecipientId);

          if (gotRecipient && gotRecipient.length > 0) {
            dispatch({ type: 'CARE_RECIPIENTS_SET', payload: res.data });
            dispatch({ type: 'CARE_RECIPIENTS_SET_ACTIVE', crId: options.activeCareRecipientId });
          } else if (user.level <= 1000) {
            return apiClient.call({ endpoint: 'user/adminGetCareRecipient', data: { CRId: options.activeCareRecipientId } }).then((adminRes) => {
              dispatch({ type: 'CARE_RECIPIENTS_SET', payload: adminRes.data });
              dispatch({ type: 'CARE_RECIPIENTS_SET_ACTIVE', crId: options.activeCareRecipientId });
              return adminRes.data;
            });
          }
        } else {
          dispatch({ type: 'CARE_RECIPIENTS_SET', payload: res.data });
          if (recipients) {
            const firstCareRecipient = recipients[0];
            if (firstCareRecipient) {
              dispatch({ type: 'CARE_RECIPIENTS_SET_ACTIVE', crId: firstCareRecipient.userId });
            }
          }
        }

        return res.data;
      }))
    .catch((e) => {
      dispatch({ type: 'USER_SELF_PENDING', pending: false });
      throw e;
    });
};

export const clear = (dispatch, apiClient) => {
  dispatch({ type: 'USER_SELF_CLEAR' });
  dispatch({ type: 'COOKIE_REMOVE', key: 'session' });
  apiClient.setProtocol('http');
  return Promise.resolve();
};

export const logout = (dispatch, apiClient) => {
  dispatch({ type: 'USER_LOGOUT' });
  dispatch({ type: 'COOKIE_CLEAR' });
  apiClient.setProtocol('http');
};

export const get = (dispatch, apiClient, id) => {
  if (userGetPromise[id]) {
    return userGetPromise[id];
  }

  userGetPromise[id] = apiClient
    .call({ endpoint: 'user/get', data: { id } })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'USER_SET', payload: res.data });

      userGetPromise[id] = null;
      delete userGetPromise[id];
      return res;
    });

  return userGetPromise[id];
};

const getUserCaregiversPromises = {};

export const getUserCaregivers = (dispatch, apiClient, userId) => {
  if (getUserCaregiversPromises[userId]) {
    return getUserCaregiversPromises[userId];
  }

  const CRId = userId;
  getUserCaregiversPromises[userId] = apiClient
    .call({ endpoint: 'user/getUserCaregivers', data: { CRId } })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      dispatch({ type: 'USER_CAREGIVERS_SET', payload: res.data, userId });
      getUserCaregiversPromises[userId] = null;
      delete getUserCaregiversPromises[userId];
      return res;
    }).catch((err) => {
      getUserCaregiversPromises[userId] = null;
      delete getUserCaregiversPromises[userId];
      throw err;
    });

  return getUserCaregiversPromises[userId];
};

export const saveCaregiverSettings = (dispatch, apiClient, data) => {
  const {
    CRId,
    CGId,
    careGiverRights,
    notificationSettings,
    caregiverSettings
  } = data;

  const runner = [];

  if (careGiverRights) {
    console.log('saveCaregiverSettings careGiverRights');
    runner.push(apiClient.call({
      endpoint: 'user/setRights',
      data: {
        rights: careGiverRights,
        CRId,
        CGId
      }
    }));
  }

  if (notificationSettings) {
    console.log('saveCaregiverSettings notificationSettings');
    runner.push(apiClient.call({
      endpoint: 'user/setNotificationSettings',
      data: {
        settings: notificationSettings,
        CRId,
        CGId
      }
    }));
  }

  if (caregiverSettings) {
    console.log('saveCaregiverSettings caregiverSettings');
    runner.push(apiClient.call({
      endpoint: 'user/setCaregiverSettings',
      data: {
        settings: caregiverSettings,
        CRId,
        CGId
      }
    }));
  }

  return Promise.all(runner);
};

export const signup = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'Registration/signup',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const verify = (dispatch, apiClient, verificationToken) =>
  apiClient
    .call({
      endpoint: 'user/verify',
      data: { verificationToken }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const verifyPhone = (dispatch, apiClient, verificationToken, shortVerificationToken) =>
  apiClient
    .call({
      endpoint: 'user/verifyPhone',
      data: { verificationToken, shortVerificationToken }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const userAuthenticateEdit = (dispatch, apiClient, authEditId) =>
  apiClient
    .call({
      endpoint: 'user/userAuthenticateEdit',
      data: { authEditId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const resendSmsVerification = (dispatch, apiClient, userId) =>
  apiClient
    .call({
      endpoint: 'Registration/sendSmsVerification',
      data: { userId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const resendRegistrationInvite = (apiClient, userId) =>
  apiClient
    .call({
      endpoint: 'Registration/sendRegistrationInvite',
      data: { userId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const resendEmailVerification = (apiClient, userId) =>
  apiClient
    .call({
      endpoint: 'Registration/sendEmailVerification',
      data: { userId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const forgotPassword = (dispatch, apiClient, email) =>
  apiClient
    .call({
      endpoint: 'user/forgotPassword',
      data: { email }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const resetPassword = (dispatch, apiClient, password, token) =>
  apiClient
    .call({
      endpoint: 'user/resetPassword',
      data: { password, token }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const updateMedicalInfo = (dispatch, apiClient, CRId, data) =>
  apiClient
    .call({
      endpoint: 'user/updateMedicalInfo',
      data: { ...data, CRId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const updateUserData = (dispatch, apiClient, CRId, data) =>
  apiClient
    .call({
      endpoint: 'user/updateUserData',
      data: { ...data, CRId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const updateCurrentUserData = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'user/updateCurrentUserData',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const changePassword = (dispatch, apiClient, oldPassword, newPassword) =>
  apiClient
    .call({
      endpoint: 'user/changePassword',
      data: { oldPassword, newPassword }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const changeCareRecipientPassword = (dispatch, apiClient, userId, newPassword) =>
  apiClient
    .call({
      endpoint: 'user/changeCareRecipientPassword',
      data: { userId, newPassword }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const getUploadToken = (apiClient, type, fileName) => {
  const data = {
    type,
    fileName
  };

  return apiClient
    .call({ endpoint: 'user/getUploadToken', data })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }

      return res;
    }).catch((err) => {
      throw err;
    });
};

export const adminGetUsers = (dispatch, apiClient) =>
  apiClient
    .call({ endpoint: 'user/adminGetUsers' })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const adminGetMomentaryCareRecipients = (apiClient) =>
  apiClient
    .call({ endpoint: 'user/adminGetMomentarilyUsers' })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const adminUpdateUserData = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'user/adminUpdateUserData',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const adminUpdateMultiple = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'user/adminUpdateMultiple',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const removeCareGiver = (dispatch, apiClient, careRecipientId, careGiverId) =>
  apiClient
    .call({
      endpoint: 'user/removeCareGiver',
      data: { careRecipientId, careGiverId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const logoutPhone = (dispatch, apiClient, CRId) =>
  apiClient
    .call({
      endpoint: 'user/logoutPhone',
      data: { CRId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const importMembers = (apiClient, fileId) => {
  const data = {
    fileId
  };

  return apiClient
    .call({ endpoint: 'user/importMembers', data })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }

      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const revertImport = (apiClient, importHash) => {
  const data = {
    importHash
  };

  return apiClient
    .call({ endpoint: 'user/revertImport', data })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }

      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const authEdit = (apiClient, userId, settings) => {
  const data = {
    userId,
    settings
  };

  return apiClient
    .call({
      endpoint: 'user/authEdit',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });
};

export const crGDPRremove = (dispatch, apiClient, CRId) =>
  apiClient
    .call({
      endpoint: 'user/crGDPRremove',
      data: { CRId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export const setPosing = (apiClient, sessionId, userId) => {
  let endpoint = 'user/adminSetPosing';
  const data = {
    sessionId,
    userId
  };
  if (!userId) {
    endpoint = 'user/adminUnsetPosing';
  }

  return apiClient
    .call({ endpoint, data })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }

      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const logoutWatch = (dispatch, apiClient, CRId) =>
  apiClient
    .call({
      endpoint: 'user/logoutWatch',
      data: { CRId }
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      return res;
    });

export async function setActiveHours(dispatch, apiClient, careRecipientId, activeHours) {
  const response = await apiClient.call({
    endpoint: 'user/setActiveHours',
    data: { careRecipientId, activeHours }
  });

  if (response.status >= 400) {
    throw response;
  }

  dispatch({
    type: 'CARE_RECIPIENTS_UPDATE_KEY_VALUE',
    userId: response.data.userId,
    key: 'activeHours',
    value: response.data.activeHours
  });
  return response;
}

export const checkIfEmailIsAvailable = (dispatch, apiClient, email) =>
  apiClient
    .call({
      endpoint: 'Registration/checkIfEmailIsValid',
      data: { email }
    })
    .then((res) => {
      return res.data && res.data.isEmailAvailable;
    })
    .catch(err => {
      return false;
    });
