/* eslint-disable */

export const listCouponGoups = (dispatch, apiClient) =>
  apiClient.call({ endpoint: 'coupons/listCouponGoups' }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    return res;
  });

export const create = (dispatch, apiClient, data) =>
  apiClient.call({ endpoint: 'coupons/create', data }).then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    return res;
  });
