/* eslint-disable */

import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import RRule from 'rrule';

export const dateInRruleDateFormat = (date, endOfDay = false) => {
  const asDate = moment(date).toDate();
  if (endOfDay) {
    return new Date(Date.UTC(
      asDate.getFullYear(),
      asDate.getMonth(),
      asDate.getDate(),
      23,
      59,
      59
    ));
  }
  return new Date(Date.UTC(asDate.getFullYear(), asDate.getMonth(), asDate.getDate()));
};

export const rruleDateToLocalTime = date =>
  DateTime.fromJSDate(date).toUTC().setZone('local', { keepLocalTime: true });

export const convertRecurrenceToJSDate = (dtstart, minutes) =>
  rruleDateToLocalTime(dtstart).plus({ minutes })
    .toJSDate();

export const rruleTimesFirstDay = (rrule) => {
  const newRrule = new RRule({
    ...rrule.options,
    count: (rrule.options.byminute && rrule.options.byminute.length) || 1
  });

  return newRrule.all().map(time => rruleDateToLocalTime(time).toJSDate());
};
