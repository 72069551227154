/* eslint-disable */

import React from 'react';
import style from './style.module.scss';

export default ({ className, children, ...props }) => (
  <span
    {...props}
    className={`${style.icon} ${style[`lt-icon-${children}`]} ${className || ''}`}
  />
);
