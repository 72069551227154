export const createGrouper = (grouperFunction = null) => (
  mapper = (id) => id
) => (data) => {
  if (grouperFunction === null) {
    throw Error('Grouping function may not be null');
  }

  const groupings = {};

  data.forEach((o) => {
    const group = JSON.stringify(grouperFunction(o));
    groupings[group] = groupings[group] || [];
    groupings[group].push(o);
  });

  return Object.keys(groupings).map((group) =>
    mapper(groupings[group], JSON.parse(group))
  );
};
