import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Dialog = (props, context) => (
  <div
    className={[style.background, style[props.transition]].join(' ')}
    onClick={() => context.dialogClose(props.dialogId)}
  >
    <div className={style.container} onClick={(e) => e.stopPropagation()}>
      <DialogContent className={style.dialogContent}>
        {props.data.subContent}
        <IconButton
          aria-label="Close"
          className={style.buttonClose}
          onClick={() => context.dialogClose(props.dialogId)}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </div>
  </div>
);
Dialog.contextTypes = {
  dialogClose: PropTypes.func,
};

export default Dialog;
