/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import { adminUpdateMultiple } from 'actions/user';
import Title from 'components/Title';
import Field from 'components/DialogField';
import { CircularProgress } from '@material-ui/core';
import get from 'lodash.get';
import set from 'lodash.set';

import style from './style.module.scss';

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
    hasPermission: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      out: {
        expiresOn: '',
        userGroup: '',
      }
    };
  }

  onEditClick() {
    const { out, saving } = this.state;

    const setSettings = {};
    let gotSettings = false;

    const keys = Object.keys(out);

    for (let a = 0; a < keys.length; a += 1) {
      const key = keys[a];
      const value = out[key] && out[key].trim !== undefined ? out[key].trim() : out[key];

      if (value) {
        switch (key) {
          case 'userGroup':
          case 'tag':
            if (value.length > 0) {
              setSettings[key] = value;
              gotSettings = true;
            }
            break;
          case 'expiresOn':
            if (value.toDate) {
              setSettings[key] = value.toDate();
              gotSettings = true;
            }
            break;
          default:
            console.log('unsuported key', key);
        }
      }
    }

    if (!gotSettings) {
      alert('No settings set'); // eslint-disable-line
      return;
    }

    if (!saving) {
      this.setState({ saving: true });
      adminUpdateMultiple(this.props.dispatch, this.context.apiClient, {
        data: setSettings,
        users: this.props.data.users
      })
        .then(() => this.setState({ saving: false }, () => this.onClose()))
        .catch(err => this.setState({ saveError: err.error, saving: false }));
    }
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  setOutValue(key, val) {
    this.setState({ out: set(this.state.out, key, val) });
  }

  renderField(type, outKey, label, props, defaultValue) {
    return (
      <div className={style.field} key={`field_${outKey}`}>
        <div className={style.label}>{label}</div>
        <Field
          type={type}
          value={get(this.state.out, outKey, defaultValue) || ''}
          onChange={event => this.setOutValue(outKey, get(event, 'target.value', event))}
          {...props}
        />
      </div>
    );
  }

  render() {
    const { saving, saveError } = this.state;

    let editContent = null;
    if (saving) {
      editContent = <CircularProgress />;
    } else {
      editContent = (
        <div>
          {this.renderField('text', 'userGroup', 'User group')}
          {this.renderField('text', 'tag', 'Tag')}
          {this.renderField('date', 'expiresOn', 'Expiry date')}
        </div>
      );
    }

    return (
      <div className={[style.background, style[this.props.transition]].join(' ')}>
        <div className={style.container} onClick={e => e.stopPropagation()}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            <div className={style.content}>
              <Title>Edit users</Title>
              {editContent}
              {saveError && <div className={style.saveError}>An error occurred: {saveError}</div>}
            </div>
            <div>
              {!saving &&
                <Button className={style.nextButton} onClick={() => this.onEditClick()}>
                  EDIT
                </Button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(Dialog);
