export const termsAndConditions = `

#### Introduction

&nbsp;

At LeapThru®, we strive to deliver high quality, reliable software that can deliver invaluable information and assistance.  Still, it is important to use the LeapThru® LeapConnect™ Caregiving Platform with caution and with an understanding of the limitations of technology.  Caregiving, after all, is still first and foremost a human endeavor.

&nbsp;

**PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING LEAPTHRU® AND THE LEAPCONNECT™ PLATFORM, WHICH INCLUDES THIS SITE AS WELL AS ALL LEAPTHRU® SOFTWARE AND LEAPTHRU® SOFTWARE APPLICATIONS.**

&nbsp;

LEAPTHRU® SOFTWARE AND SOFTWARE APPLICATIONS INCLUDE BUT ARE NOT LIMITED TO LEAPTHRU APPLICATIONS THAT RUN ON SERVERS LIKE THIS SITE, SMARTPHONES, SMARTWATCHES, HOME SPEAKERS, MEDICAL DEVICES, WELLNESS-DEVICES, HOME SECURITY DEVICES, SENSOR DEVICES AND OTHER ANY OTHER APPLICATIONS RUNNING ON DEVICES THAT PROVIDE INFORMATION TO OR RECEIVE INFORMATION FROM LEAPTHRU’S LEAPCONNECT PLATFORM.

&nbsp;

“LeapThru®” and all references to LeapThru’s software, including but not limited to “LeapThru”, “LeapConnect”, the “LeapConnect Platform”, “LeapConnect Software”, “LeapThru Software”, “LeapThru Application”, “LeapThru App”, “LeapThru VIP”, “LeapThru Care”, “LeapThru Caregiving”, “LeapThru Site”, “Site”, “LeapThru Platform” and various LeapThru application names are referring to the same overall system that is owned and operated by Leipzig Technology, LLC.  For purposes of this TERMS AND CONDITIONS OF USE as well as the company’s Privacy Policy, these references are referring to the same overall system and solely to Leipzig Technology’s Terms and Conditions of Use Agreement and Privacy Policy Agreement.  All references to LeapThru, LeapThru software and the LeapConnect platform should be construed as references to Leipzig Technology, LLC, doing business under the name LeapThru.

&nbsp;

**By using the LeapConnect platform, LeapThru website or any LEAPTHRU application, you signify your assent to these, Leipzig Technology LLC’s, Terms and Conditions of Use.  If you do not agree to all of these Terms and Conditions, do not use the LeapConnect platform, this site OR ANY LEAPTHRU application!**

&nbsp;

LEIPZIG TECHNOLOGY LLC (also referred to as "LeapThru", “Leipzig Tech”) may revise and update these Terms and Conditions of Use at any time. Your continued usage of the LeapThru website, LeapConnect Platform, or any other LeapThru application will mean you accept those changes.

&nbsp;

**Please read through the full Terms & Conditions of Use Agreement before confirming your agreement.**

&nbsp;

---

&nbsp;

#### Definitions

&nbsp;

Throughout this document, the following terms may be referenced:

&nbsp;

**“Care Recipient”** means any person who receives assistance from a Caregiver.  In the LeapThru system, the Care Recipient user is the person being monitored through various LeapThru features and/or the subject of care by linked Caregiver(s).  In this document and in LeapThru, the Care Recipient may also be referred to as the “VIP” or “LeapThru VIP”. The term “Care Recipient” may be capitalized or not, all or in part, when referenced throughout this document, but refers to the same person either way.

&nbsp;

**“Caregiver”** means any person, professional or non-professional, who helps in any way to assist, monitor and/or in any other way provide help on behalf of another person in either a paid or not paid capacity.  Examples of Caregivers include, but are not limited to: professional caregivers, physical therapists, occupational therapists, skilled nurses, home health workers, physicians, unpaid family members and friends. Caregivers may also be LeapThru employees and/or LeapThru agents providing support to users.  In this document and in the LeapThru platform, Caregivers may sometimes be referred to as “Pals, “LeapThru Pals”, or “Care Team” Members.  The term “Caregiver” may be capitalized or not when referenced throughout this document, but refers to the same person either way.

&nbsp;

**What is LeapThru®?**

&nbsp;

In summary, LEAPTHRU® is a software-enabled system which connects a variety of mobile devices, hardware and other software systems together into a caregiving platform to work together in facilitating a series of caregiving related features and activities for the purpose of enhancing “care management” and/or “care delivery” for care recipients and/or caregivers.

&nbsp;

“Care management” activities of LEAPTHRU® include any and all combination but are not limited to the following features:  secure messaging and other secure communication to relevant parties, service(s) that may be ordered from service providers on behalf of a care recipient, calendar and scheduling activities, to-do lists and task assignments, workflow management, reporting  and software systems management (such as account, subscriptions, permissions management) on behalf of caregivers and care recipients.

&nbsp;

“Care delivery” activities of LEAPTHRU® include but are not limited to any and all combination of the following features that facilitate remote care:  communication among care recipients and / or caregivers, monitoring of care recipients, urgent situation capabilities, including BreakThru™ capabilities, video calling, care recipient data collection and / or presentation of care-related data and / or insights.

&nbsp;

---

&nbsp;

#### Terms and Conditions of Use

&nbsp;

o	 You agree not to use any user data or other information from this site as medical advice.

&nbsp;

**LeapThru Does Not Provide Medical Advice.**  The contents of LeapThru Platform, such as text, graphics, images, and other materials created by LeapThru or obtained from LeapThru’s licensors, and other materials contained on the LeapThru Software (collectively, "Content") are for informational purposes only. The Content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on the LeapThru Platform!

&nbsp;

**If you think you may have a medical emergency, call your doctor or 911 immediately.**  LeapThru does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on the Site. Reliance on any information provided by LeapThru, Leipzig Tech employees, others appearing on the LeapThru platform at the invitation of LeapThru, or other visitors to the LeapThru Platform is solely at your own risk.

&nbsp;

o	 You agree not use LeapThru for emergency purposes or as an emergency response system.  LeapThru is not an emergency response system.

&nbsp;

o	 You understand and agree that LeapThru is not a substitute for calling emergency help on 911 or through an emergency response system.  Do NOT use any LeapThru features, including digital communications (including but not limited to BreakThru Calls, Video Calls, Group Chat, and/or Secure Mail) before or instead of calling 911.

&nbsp;

o	 You understand that the BreakThru feature (also called BreakThru Connect, BreakThru Call, BreakThru Video Call) is a feature that when turned on, specially permissioned caregivers can cause the care recipient’s phone to automatically answer the call with the speakerphone enabled and camera turned on.  The proper functioning of this feature is dependent, in part, on the smartphone or other device through which it is delivered.  It is also dependent on a live, unblocked Internet connection that is beyond LeapThru’s control.  You understand that BreakThru is NOT a substitute for calling 911 or is intended as an emergency response feature or device.  You agree NOT to hold LeapThru liable for the functioning, misuse or malfunctioning of the BreakThru feature.

&nbsp;

o	 You understand that LeapThru is software that runs on top of and in conjunction with third party hardware and software not owned or operated by LeapThru.  Additionally, you understand that the LeapThru system itself may have unforeseen bugs that impact functionality, causing errors or features to not work as intended and/or impacting the accuracy of data.  For these reasons, you agree that LeapThru will not be used for emergency purposes or as the sole source of information.

&nbsp;

o	 You understand that data provided about a user may not be accurate.  This includes but is not limited to the data itself, as well as any related reminders, medical reminders, alerts, care alerts and/or notifications and/or LeapThru features that use the data.  You understand this can be due to a number of reasons including, but not limited to: that data may be collected and/or transmitted by third party hardware and/or software sources that are not controlled by LeapThru; that users on the LeapThru platform enter data that is not subject to LeapThru review or validation; and/or also that LeapThru software may have unforeseen errors in the collection or processing of data.  You agree not hold LeapThru liable for any inaccurate data as well as any inaccuracies or failures in LeapThru features related to that data (such as but not limited to reminders, medical reminders, alerts, care alerts and/or notifications and/or other LeapThru features).  You agree not to hold LeapThru liable for any data or related features that is not delivered and/or that is delayed in its delivery.  This includes but is not limited to any health, wellness and medically-related information in the LeapThru system (such as, but not limited to a medical profile and medical reminders).

&nbsp;

o	 You agree that by using LeapThru, you are taking full responsibility for the use the LeapThru system, including but not limited to any and all features on the LeapThru system and the data obtained, input and/or processed through the LeapThru system.

&nbsp;

o	 You agree that the Care Recipient user is solely, fully responsible for taking their correct medicines and following any and all health, wellness or medical regimens at the correct times as directed by their physician and/or medical professionals.  You further agree to not hold LeapThru liable or responsible for the Care Recipient’s actions, including but not limited to taking and/or not taking medicines, whether at the correct or incorrect times, and/or following or not following medical and/or other advice or directions (medical and non-medical in nature), whether provided or not provided by connected LeapThru Caregiver users and/or the LeapThru System itself and regardless of whether any direction or notification came through the LeapThru system and/or outside of the LeapThru system.   You agree that the Care Recipient user is fully responsible for their own health and agree to NOT hold LeapThru liable or responsible for the health, safety and/or wellness of the Care Recipient user or any other LeapThru user regardless of circumstance.

&nbsp;

o	 You understand that when the Care Recipient or a linked Caregiver is logged into their LeapThru application (such as but not limited to a LeapThru smartphone application and/or smartwatch application and/or Portal application), the Care Recipient’s information, including but not limited to medical information, as well as potentially other information is viewable by anyone with access to that device.  You also understand that care alert notifications and reminders, such as medical reminders, may “pop-up” on a logged-in user’s smartphone and/or smartwatch and/or other connected device on the notification drawer and/or home screen without the user actively selecting the LeapThru application.  You agree to be responsible and maintain the privacy of the information viewable and accessible in your LeapThru account.  You agree not to hold LeapThru responsible or liable for any person who views any information through your logged-in user account or the logged-in user account of a linked Care Recipient user and / or Caregiver user (care team member).

&nbsp;

o	 You agree to not hold LeapThru responsible or liable for any use, misuse, unintended use, lack of use, feature breakage, and / or for features not performing as intended.  You agree to not hold LeapThru responsible or liable for any data, whether accurate or inaccurate, that was collected, distributed and/or accessed through the LeapThru system knowingly or unknowingly about you or other people.  Please refer to LeapThru’s Privacy Policy for more information on data collection, ownership and handling.

&nbsp;

o	 You agree to LeapThru’s Privacy Policy.

&nbsp;

o	 You agree not to hold LeapThru responsible or liable for any outcomes or consequences that result from the use, misuse, unintended use, lack of use, feature breakage, and / or features not performing as intended of the LeapThru system.

&nbsp;

o	 You agree not to hold LeapThru responsible or liable for any outcomes or consequences that result from the sharing of information within or external to the LeapThru System by the Care Recipient user, Primary Caregiver user and/or other Caregiver users on the LeapThru System.

&nbsp;

o	 You understand that LeapThru has a series of monitoring features that can leverage everyday devices like smartphones, smartwatches, home smart speakers, cameras, home security systems, and different kinds of sensor devices along with wellness devices or medical devices including but not limited to blood pressure cuffs, glucometers, medication dispensers, and/or weight scales to both gather information about someone and engagement features to interact with that person through LeapThru and through those devices.  Many of those features can be turned on and off in the LeapThru system by either the person being monitored (“Care Recipient”) or a different permissioned user on the LeapThru system (“Caregiver”) including a LeapThru agent.

&nbsp;

o	 You understand that on smartphones, LeapThru can optionally do the following, based on the permissions of the Smartphone’s Operating System (such as Android or iOS), those granted by the user during application installation and those enabled in the user’s LeapThru settings:

  *	 Access your location through the phone’s GPS,
  *	 Access your steps and movement using the phone’s accelerometer and/or GPS (if the phone has an accelerometer and/or GPS)
  *	 Access your contacts & call log to monitor for certain emergency calls
  *	 Access the phone’s calling system to enable phone calls through the LeapThru application
  *	 Access the phone’s cameras and/or speaker to enable internet audio and/or video calls and/or LeapThru’s BreakThru™ calling functionality
  *	 Remain actively running in the phone’s background, even after the phone is re-booted / re-started, in order to actively monitor location, listen for emergency calls, listen for incoming video or BreakThru™ calls, deliver notifications, deliver reminders, as well as send and receive other kinds of information / data from the LeapConnect platform.
  *	 Use the phone’s reminder and notification system to deliver messages and alerts from the LeapThru system

&nbsp;

o	 You understand that on smartwatches, LeapThru can optionally do the following, in part based on the permissions of the Smartwatch’s Operating System (such as Wear OS), those granted by the user during application installation and those enabled in the user’s LeapThru settings:

  *	 Access your location through the watch’s GPS (if it exists on the watch),
  *	 Access your steps and movement using the watch’s accelerometer and/or GPS (if the watch has an accelerometer and/or GPS)
  *	 Access your heart rate using the watch’s heart rate sensor (if the watch has a heart rate sensor)
  *	 Access your contacts & call log to monitor for certain emergency calls (if the smartwatch is also a smartphone that can make calls)
  *	 Access the watch’s calling system to enable phone calls through the LeapThru application (if the smartwatch is also a smartphone that can make calls and/or can access a paired smartphone’s calling system)
  *	 Access the watch’s cameras and/or speaker to enable internet audio and/or video calls and/or LeapThru’s BreakThru™ calling functionality (if the watch has a camera and/or speaker)
  *	 Remain actively running in the watch’s background, even after the watch is re-booted / re-started, in order to actively monitor location, listen for emergency calls, listen for incoming video or BreakThru™ calls, deliver notifications, deliver reminders, as well as send and receive other kinds of information / data from the LeapConnect platform.
  *	 Use the watch’s reminder and notification system to deliver messages and alerts from the LeapThru system

&nbsp;

o	 By using LeapThru, you agree not to hold LeapThru responsible or liable for any monitoring that takes place through the LeapThru system.

&nbsp;

o	 If you are a Care Recipient user, by using LeapThru you agree to allow those permissioned Caregivers linked to you on LeapThru to use any and all combinations of LeapThru monitoring and (other) care features, including but not limited to those “care management” and “care delivery” features described above in this document.

&nbsp;

o	 If you are a Care Recipient user, you agree that you are solely responsible for ensuring the Primary Caregiver’s identity and the identity of any and all (other Caregiver members linked to your Care Recipient account.  You agree to hold LeapThru harmless and to not hold LeapThru liable or responsible for ensuring the identity of any LeapThru user.

&nbsp;

o	 If you are a Caregiver user, you agree that you are solely responsible for ensuring the identity of any and all Care Recipients and other Caregivers you are linked with.   You agree to hold LeapThru harmless and to not hold LeapThru liable or responsible for ensuring the identity of any LeapThru user.

&nbsp;

o	 You understand and agree that a Care Recipient user on LeapThru will have at least one Caregiver account (sometimes called the “Primary Caregiver”) linked to their Care Recipient account and that the Primary Caregiver has complete administrative authority and access to the Care Recipient’s account equal to the Care Recipient’s own authority and access, allowing the Primary Caregiver to turn on and off all LeapThru features and notifications as well as view information, manage data, edit data and engage with the Care Recipient and other linked Caregiver members on the LeapThru system on behalf of the Care Recipient using any and all of the features provided through the LeapThru system.  This includes but is not limited to the rights to view, add, edit and remove other Caregivers linked to the Care Recipient’s account and the rights to adjust those Caregiver permissions, feature access and notifications on behalf of those other Caregivers that are linked to the Care Recipient’s account.

&nbsp;

o	 You also understand and agree that both the Care Recipient and the Primary Caregiver can each also individually add, remove or edit administrative permissions for other Caregivers to give some of or all of the same full access and capabilities that the Primary Caregiver and Care Recipient each individually have to any number of other Caregivers.

&nbsp;

o	 As a Care Recipient user, you agree to give full permission to both the Primary Caregiver and to any and all other Caregivers that the Care Recipient and/or Primary Caregiver have designated through LeapThru to view, administrate, manage and engage with your LeapThru account, including but not limited to using any or all LeapThru features as well as sharing any and all information contained in your account.  You agree to hold LeapThru harmless and to NOT hold LeapThru liable or responsible for any and all of these or other actions taken and/or not taken (as well as any resulting consequences or outcomes) by you the Care Recipient user, the Primary Caregiver user and/or other Caregiver users linked to your account.

&nbsp;

o	 As a Caregiver user on LeapThru, (any Caregiver user), you understand and agree to hold LeapThru harmless and to NOT hold LeapThru liable or responsible for any and all of actions taken and/or not taken (as well as any resulting consequences or outcomes) by you, by the Care Recipient user and / or by any and all other Caregiver users linked to your account.

&nbsp;

o	 You agree not to hold LeapThru liable or responsible for the access, use, misuse, lack of use of features and any and all other actions taken or not taken (as well as any resulting consequences or outcomes) by your and/or any and all Caregivers that have been granted access to a Care Recipient on the LeapThru system.

&nbsp;

o	 You agree to give true and accurate registration information to the best of your knowledge.  You agree that any information you input into the LeapThru system does not violate any patient confidentiality or other confidentiality agreements.

&nbsp;

o	 You understand that LeapThru can optionally collect health and wellness data about a person that is either manually input and/or collected from connected monitoring devices.  You agree not to hold LeapThru accountable for the accuracy of that data or the accessing of it by permissioned users on the LeapThru system.

&nbsp;

o	 You understand and agree to LeapThru’s Privacy Policy governing, among other things, the use of consumer data.

&nbsp;

o	 You agree to use LeapThru at your own risk AND you agree NOT to hold LeapThru, Leipzig Technology, LLC or any of its members or affiliates liable for the use of this platform and any damages that may result from the use of this platform.

&nbsp;

o	 You have read, understand and agree to the remaining Terms & Conditions of Use documented below, which govern licensing, general use, warranties, and other important topics that require your understanding and consent.

&nbsp;

---
&nbsp;

#### Remaining Terms & Conditions of Use

&nbsp;

##### General

&nbsp;

A.  LeapThru Software, and any third party software, documentation, interfaces, content, fonts and any data accompanying this License whether in read only memory, on any other media or in any other form (collectively the "LeapConnect Platform" or “LeapThru”, “LeapThru Software”, “LeapThru Platform”) are licensed, not sold, to you by Leipzig Technology, LLC (also referred to as "LeapThru”, “Leipzig Technology” or “Leipzig Tech”) for use only under the terms of this License. Leipzig Technology LLC and/or Leipzig Tech’s licensors retain ownership of the LeapConnect Platform software itself and reserve all rights not expressly granted to you.

&nbsp;

B.  Leipzig Tech, at its discretion, may make available future upgrades or updates to LeapThru software for your compatible device(s). The LeapThru software upgrades and updates, if any, may not necessarily include all existing LeapThru software features or new features that Leipzig Tech releases for newer or other models of devices. The terms of this License will govern any LeapThru Software upgrades or updates provided by Leipzig Tech to the original LeapThru Software product, unless such upgrade or update is accompanied by a separate license in which case you agree that the terms of that license will govern such upgrade or update.

&nbsp;

##### Permitted License Uses and Restrictions

&nbsp;

A.  **License.** Subject to the terms and conditions of this License, you are granted a limited, non-transferable, non-exclusive license to install and use the LeapThru software on any compatible device that you own or control. You may not distribute or make the LeapThru Software available over a network where it could be used by multiple devices at the same time. You may not rent, lease, lend, sell, redistribute or sublicense the LeapThru Software.

&nbsp;

B.  **System Requirements.** Leipzig Tech Software is supported on Android and Apple smartphones and WearOS smartwatches that meet certain minimum OS requirements that can be found on www.LeapThru.com.  Leipzig Tech software is also supported on Internet Web Browsers that meet certain minimum requirements and other devices identified on www.LeapThru.com.
&nbsp;

C.  **Content and Digital Materials.** Title and intellectual property rights in and to any content displayed by or accessed through LeapThru Software belong to the respective content owner. Such content may be protected by copyright or other intellectual property laws and treaties, and may be subject to terms of use of the third party providing such content. Except as otherwise provided in this License, (i) this License does not grant you any rights to use such content nor does it guarantee that such content will continue to be available to you and (ii) you may not use, extract or distribute, commercially or otherwise, on a standalone basis, any photographs, images, graphics, artwork, audio, video or similar assets (“Digital Materials”) contained within, or provided as a part of, the LeapThru Software, or otherwise use the Digital Materials outside the context of its intended use as part of the LeapThru Software.

&nbsp;

D.  **No Reverse Engineering.** You may not, and you agree not to or enable others to, copy (except as expressly permitted by this License), decompile, reverse engineer, disassemble, attempt to derive the source code of, decrypt, modify, or create derivative works of the LeapThru Software or any services provided by the LeapThru Software, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of open-sourced components included with the LeapThru Software).

&nbsp;

E.  **Compliance with Laws.** You agree to use the LeapThru Software and the Services (as defined in “Services and Third Party Material” below) in compliance with all applicable laws, including local laws of the country or region in which you reside or in which you download or use the LeapThru Software and Services.

&nbsp;

F.  **Third Party Software.** LeapThru may provide access to certain third party software or services as a convenience. To the extent that the LeapThru Software contains or provides access to any third party software or services, Leipzig Tech has no express or implied obligation to provide any technical or other support for such third party software or services. Please contact the appropriate third party software vendor, manufacturer or service provider directly for technical support and customer service related to its software, service and/or products.

&nbsp;

G. **Automatic Updates.** If you choose to allow automatic app updates, your device will periodically check with for updates and upgrades to the LeapThru Software and, if an update or upgrade is available, the update or upgrade will automatically download and install onto your device and, if applicable, your peripheral devices.

&nbsp;

##### Consent to Use of Data

&nbsp;

By using LeapThru Software, you consent to the collection, use, and sharing of your information as described in LeapThru’s Privacy Policy.

&nbsp;

##### Passwords

&nbsp;

LeapThru has several tools that allow you to record and store information. You are responsible for taking all reasonable steps to ensure that no unauthorized person shall have access to your LeapThru passwords or accounts. It is your sole responsibility to: (1) control the dissemination and use of sign-in name, screen name and passwords; (2) authorize, monitor, and control access to and use of your LeapThru account and password; (3) promptly inform LeapThru if you believe your account or password has been compromised or if there is any other reason you need to deactivate a password. To send us an email, use the "Contact Us" links located throughout our site. You grant LeapThru and all other persons or entities involved in the operation of the Site the right to transmit, monitor, retrieve, store, and use your information in connection with the operation of the Site. LeapThru cannot and does not assume any responsibility or liability for any information you submit, or your or third parties' use or misuse of information transmitted or received using LeapThru tools and services.

&nbsp;

##### Children's Privacy

&nbsp;

We are committed to protecting the privacy of children. You should be aware that the LeapThru Software is not intended or designed to attract children under the age of 13.  We do not collect personally identifiable information from any person we actually know is a child under the age of 13 unless a parent, guardian and/or their authorized caregiver has input that information and has provided consent for the use of LeapThru for the child or is using the LeapThru system on the child’s behalf.

&nbsp;

##### Services and Third Party Materials

&nbsp;

A. General. The LeapThru Software may enable access to other Leipzig Tech and third party services and web sites (collectively and individually, "Services").  Such Service may not be available in all languages or in all countries. Use of these Services requires Internet access and use of certain Services may require you to accept additional terms and may be subject to additional fees. By using the LeapThru Software in connection with another Leipzig Tech or third party service, you agree to the applicable terms of service for that Service.

&nbsp;

B. You understand that by using any of the Services, you may encounter content that may be deemed offensive, indecent, or objectionable, which content may or may not be identified as having explicit language. Nevertheless, you agree to use the Services at your sole risk and that Leipzig Tech shall have no liability to you for content that may be found to be offensive, indecent, or objectionable.

&nbsp;

C. Certain Services may display, include or make available content, data, information, applications or materials from third parties (“Third Party Materials”) or provide links to certain third party web sites. By using the Services, you acknowledge and agree that Leipzig Tech is not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third Party Materials or web sites. Leipzig Tech, its officers, affiliates and subsidiaries do not warrant or endorse and do not assume and will not have any liability or responsibility to you or any other person for any third-party Services, Third Party Materials or web sites, or for any other materials, products, or services of third parties. Third Party Materials and links to other web sites are provided solely as a convenience to you.

&nbsp;

D. In using LeapThru software, you will be asked to provide information, and you must provide accurate information to the best of your knowledge.  To the extent that you input information or upload any content through the use of the Services, you represent that you own all rights in, or have authorization or are otherwise legally permitted to upload, such content and that such content does not violate any terms of service applicable to the Services. You agree that the Services contain proprietary content, information and material, including but not limited to any Digital Materials, that is owned by Leipzig Tech, the site owner and/or their licensors, and is protected by applicable intellectual property and other laws, including but not limited to copyright. You agree that you will not use such proprietary content, information or materials other than for permitted use of the Services or in any manner that is inconsistent with the terms of this License or that infringes any intellectual property rights of a third party or Leipzig Tech. No portion of the Services may be reproduced in any form or by any means. You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Services, in any manner, and you shall not exploit the Services in any unauthorized way whatsoever, including but not limited to, using the Services to transmit any computer viruses, worms, trojan horses or other malware, or by trespass or burdening network capacity. You further agree not to use the Services in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party, and that Leipzig Tech is not in any way responsible for any such use by you, nor for any harassing, threatening, defamatory, offensive, infringing or illegal messages or transmissions that you may receive as a result of using any of the Services.

&nbsp;

E. In addition, Services and Third Party Materials that may be accessed, linked to or displayed through the LeapThru Software are not available in all languages or in all countries or regions. Leipzig Tech makes no representation that such Services and Third Party Materials are appropriate or available for use in any particular location. To the extent you choose to use or access such Services and Third Party Materials, you do so at your own initiative and are responsible for compliance with any applicable laws, including but not limited to applicable local laws and privacy and data collection laws. Leipzig Tech and its licensors reserve the right to change, suspend, remove, or disable access to any Services at any time without notice. In no event will Leipzig Tech be liable for the removal of or disabling of access to any such Services. Leipzig Tech may also impose limits on the use of or access to certain Services, in any case and without notice or liability.

&nbsp;

F.  Advertisements, Searches, and Links to Other Sites.  LeapThru may provide links to third-party web sites. LeapThru does not recommend and does not endorse the content on any third-party websites. LeapThru is not responsible for the content of linked third-party sites, sites framed within the LeapThru Software, third-party sites provided as search results, or third-party advertisements, and does not make any representations regarding their content or accuracy. Your use of third-party websites is at your own risk and subject to the terms and conditions of use for such sites. LeapThru does not endorse any product, service, or treatment advertised on the LeapThru Software.

&nbsp;

##### Termination

&nbsp;

This License is effective until terminated. Your rights under this License will terminate automatically or otherwise cease to be effective without notice from Leipzig Tech if you fail to comply with any term(s) of this License. Upon the termination of this License, you shall cease all use of the LeapThru Software and destroy all copies, full or partial, of the LeapThru Software.  The terms of this License shall survive any such termination.

&nbsp;

##### Disclaimer of Warranties

&nbsp;

A. If you are a customer who is a consumer, you may have legal rights in your country of residence which would prohibit the following limitations from applying to you, and where prohibited they will not apply to you. To find out more about consumer rights, you should contact a local consumer advice organization.

&nbsp;

B. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, USE OF THE LEAPTHRU SOFTWARE AND ANY SERVICES PERFORMED BY OR ACCESSED THROUGH THE LEAPTHRU SOFTWARE IS AT YOUR RISK.

&nbsp;

C. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LEAPTHRU SOFTWARE AND SERVICES ARE PROVIDED "AS IS" AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LEIPZIG TECH AND LEIPZIG TECH’S LICENSORS (COLLECTIVELY REFERRED TO AS "LEAPTHRU" FOR THE PURPOSES OF THIS DISCLAIMER OF WARRANTIES SECTION AND THE LIMITATION OF LIABILITY SECTION) HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LEAPTHRU SOFTWARE AND SERVICES, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, QUIET ENJOYMENT, AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS.

&nbsp;

D. LEAPTHRU DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE LEAPTHRU SOFTWARE AND SERVICES, THAT THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR PROVIDED BY, THE LEAPTHRU SOFTWARE WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE LEAPTHRU SOFTWARE OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY SERVICES WILL CONTINUE TO BE MADE AVAILABLE, THAT THE LEAPTHRU SOFTWARE OR SERVICES WILL BE CORRECTED, OR THAT THE LEAPTHRU SOFTWARE WILL BE COMPATIBLE OR WORK WITH ANY THIRD PARTY SOFTWARE, APPLICATIONS OR THIRD PARTY SERVICES. INSTALLATION OF THIS LEAPTHRU SOFTWARE MAY AFFECT THE AVAILABILITY AND USABILITY OF THIRD PARTY SOFTWARE, APPLICATIONS OR THIRD PARTY SERVICES, AS WELL AS LEAPTHRU PRODUCTS AND SERVICES.

&nbsp;

**E. CAUTION – **THE LEAPTHRU SOFTWARE IS AN INVESTIGATIONAL DEVICE, LIMITED BY FEDERAL (OR UNITED STATES) LAW TO INVESTIGATIONAL USE. YOU FURTHER ACKNOWLEDGE THAT THE LEAPTHRU SOFTWARE AND SERVICES ARE NOT INTENDED OR SUITABLE FOR USE IN SITUATIONS OR ENVIRONMENTS WHERE THE FAILURE OR TIME DELAYS OF, OR ERRORS OR INACCURACIES IN THE CONTENT, DATA OR INFORMATION PROVIDED BY, THE LEAPTHRU SOFTWARE OR SERVICES COULD LEAD TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR ENVIRONMENTAL DAMAGE, INCLUDING WITHOUT LIMITATION THE OPERATION OF NUCLEAR FACILITIES, AIRCRAFT NAVIGATION OR COMMUNICATION SYSTEMS, AIR TRAFFIC CONTROL, LIFE SUPPORT OR WEAPONS SYSTEMS.

&nbsp;

F. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LEAPTHRU OR AN LEAPTHRU AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE LEAPTHRU SOFTWARE OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.

&nbsp;

##### Limitation of Liability

&nbsp;

TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT SHALL LEAPTHRU OR ITS LICENSORS OR THIRD PARTY SERVICES BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, CORRUPTION OR LOSS OF DATA, FAILURE TO TRANSMIT OR RECEIVE ANY DATA, , ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE LEAPTHRU SOFTWARE AND SERVICES OR ANY THIRD PARTY SOFTWARE OR APPLICATIONS IN CONJUNCTION WITH THE LEAPTHRU SOFTWARE, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF LEAPTHRU HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.  Except as otherwise set forth above, in no event shall LeapThru’s total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of money paid by you to LeapThru for the use of the LeapThru software. The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.

&nbsp;

##### Indemnity

&nbsp;

You agree to defend, indemnify, and hold Leipzig Tech, its officers, directors, employees, agents, licensors, and suppliers, harmless from and against any claims, actions or demands, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from: (a) your use of the LeapThru Software, (b) any User Content you post or upload, (c) your use of or reliance on any User Content, or (d) your violation of these Terms and Conditions.

&nbsp;

##### Export Control

&nbsp;

You may not use or otherwise export or re-export the LeapThru Software except as authorized by United States law and the laws of the jurisdiction(s) in which the LeapThru Software was obtained. In particular, but without limitation, the LeapThru Software may not be exported or re-exported (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person’s List or Entity List or any other restricted party lists. By using the LeapThru Software, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not use the LeapThru Software for any purposes prohibited by United States law, including, without limitation, the development, design, manufacture or production of missiles, nuclear, chemical or biological weapons.

&nbsp;

##### Controlling Law and Severability

&nbsp;

This License will be governed by and construed in accordance with the laws of the State of Illinois.  This License shall not be governed by the United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded. If for any reason a court of competent jurisdiction finds any provision, or portion thereof, to be unenforceable, the remainder of this License shall continue in full force and effect.

&nbsp;

##### Complete Agreement; Governing Language

&nbsp;

This License constitutes the entire agreement between you and Leipzig Tech relating to the LeapThru Software, and supersedes all prior or contemporaneous understandings regarding such subject matter. No amendment to or modification of this License will be binding unless in writing and signed by Leipzig Tech.

&nbsp;

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

&nbsp;

**Thank you for your cooperation. We hope you find LeapThru helpful and convenient to use! Questions or comments regarding the LeapThru® LeapConnect™ Platform, including any reports of bugs, mis-functioning features or broken links, should be submitted using our Contact Us Form.**

&nbsp;

Last updated on February 8, 2021

&nbsp;

© 2021 Leipzig Technology, LLC. All rights reserved.
`;

export const privacyPolicy = `

#### Introduction

&nbsp;

Leipzig Technology LLC (“LeapThru®”, “LeapThru Software”, “Leipzig Tech”, “LeapConnect”) is committed to protecting the security and privacy of your personal information since we are keenly aware of the trust you place in us. Therefore, we conduct our business in compliance with all applicable laws concerning data security and privacy. The Privacy Policy (“Policy”) outlined below is created to inform you as to what information we collect from visitors/users/customers and how we treat this information to improve your experience. Please read it carefully.

&nbsp;

This Policy may be changed at any time without prior notice to any visitors/users/customers. So please check this policy periodically for any changes. BY USING LEAPTHRU® (referring to any and all LEAPTHRU SOFTWARE including LEAPTHRU APPLICATIONS, THE LEAPCONNECT™ PLATFORM, AND/OR THIS SITE owned and operated by LEIPZIG TECHNOLOGY, LLC) YOU APPROVE AND AGREE TO THE PRACTICES DESCRIBED IN OUR POLICY.

&nbsp;

If you have any questions about this Privacy Policy, please feel free to contact us:

&nbsp;

**Tel:**	(833)532-7123

&nbsp;

**Email:**	connect@leapthru.com

&nbsp;

---

&nbsp;

#### Informed Consent

To use LeapThru® Software, you must consent to the collection, use, and sharing of your information as described in this Privacy Policy AND agree to the Terms and Conditions of Use. To the extent anything in this Privacy Policy conflicts with the Terms and Conditions of Use, the Terms and Conditions of Use will control.

&nbsp;

#### Definitions

&nbsp;

“LeapThru Software” means any and all software owned and operated by Leipzig Technology, LLC, including but not limited to leapthru.com website, the LeapConnect Platform and related software applications.

&nbsp;

“Care Recipients” means any person who receives assistance from a Caregiver on LeapThru Software.  In the LeapThru Software, the Care Recipient user is the person being monitored through various LeapThru features.  In this document and in LeapThru, the Care Recipient may also be referred to as the “VIP” or “LeapThru VIP”.  The term “Care Recipient” may be capitalized or not, all or in part, when referenced throughout this document, but refers to the same person either way.

&nbsp;

“Caregivers” means any person, professional or non-professional, who helps in any way to assist, monitor and/or in any other way provide help on behalf of another person in either a paid or not paid capacity.  Examples of Caregivers include, but are not limited to: professional caregivers, physical therapists, occupational therapists, skilled nurses, home health workers, physicians, unpaid family members and friends. In this document and in the LeapThru Software, Caregivers may sometimes be referred to as “Pals, “LeapThru Pals”, or “Care Team” members.  Caregivers may also be LeapThru employees and/or LeapThru agents providing support to users.  The term “Caregiver” may be capitalized or not when referenced throughout this document, but refers to the same person either way.

&nbsp;

“Care management” activities of LEAPTHRU® include any and all combination but are not limited to the following features:  secure messaging and other secure communication to relevant parties, service(s) that may be ordered from service providers on behalf of a care recipient, calendar and scheduling activities, to-do lists and task assignments, workflow management, reporting  and software systems management (such as account, subscriptions, permissions management) on behalf of caregivers and care recipients.

&nbsp;

“Care delivery” activities of LEAPTHRU® include but are not limited to any and all combination of the following features that facilitate remote care:  communication among care recipients and / or caregivers, monitoring of care recipients, urgent situation capabilities, including BreakThru™ capabilities, care recipient data collection and / or presentation of care-related data and / or insights.

&nbsp;

#### Collection and Use of Information

&nbsp;

Personal information is data that can be used to identify or contact a single person. Certain personal information and non-personal information, as described below, may be collected from or about you through LeapThru software.

&nbsp;

Leipzig Tech and its affiliates may share the information we collect through LeapThru Software with each other and use it consistent with this Privacy Policy.

&nbsp;

If you decide to use LeapThru software, below are examples of the types of information that we collect and how we use it.

&nbsp;

#### What Information We Collect

&nbsp;

When you download LeapThru Software, the following categories of information (which may include some personal information) may be collected from or about you through the LeapThru Software:

&nbsp;

o	 **Contact information** such as your name, email address, state of residence, and phone number.

&nbsp;

o	 **Demographic information**, such as your age, gender, living environment.

&nbsp;

o	 **Self-reported medical history and information**, such as your height/weight, medical diagnosis (e.g., diagnosis of an irregular heart rhythm), use of certain medications and prescription information (e.g., blood thinning medications), allergies (e.g. penicillin) and emergency contact information.  This information may be collected through the LeapThru software registration process, in the settings of an account profile and/or through the setup and use of certain LeapThru features.

&nbsp;

o	 **Sensor information**, such as heart rate, steps, location, movement (accelerometer information), call log information, or other sensor information from connected 3rd party devices.

&nbsp;

o	 **Self-reported behavioral information**, such as responses to wellness check-ins, medication reminders, destination reminders and general alerts.

&nbsp;

o	 **Technical data**, which in many cases is non-personal information, i.e., data in a form that does not, on its own, permit direct association with any specific individual. Technical data includes, for example, information about your usage of the LeapThru Software (e.g., when the LeapThru Software was first launched), app version and installation ID, and technical data about your device, such as operating system and model. This LeapThru Software does not respond to browser "Do-Not-Track" signals.

&nbsp;

o	 **Adverse event information**, such as a concern, adverse event, or other reportable matter.

&nbsp;

o	 **Non-Adverse event information**, such as whether activity goals were met or heart rate readings were within a set range.

&nbsp;

o	 **Contact information for your physician or emergency contacts**

&nbsp;

o	 **Care team of permissioned caregivers linked to a care recipient**, including the permissions and notifications assigned to each caregiver related to a care recipient.  The terms “Care Recipient” and “Caregivers” are defined in The Terms and Conditions of Use.

&nbsp;

o	 **Session information**, such as the times and durations of your voice or video calls, if applicable.  We receive and store certain types of information that is not personally identifiable whenever you interact with us. For instance, we use “cookies”, like many other websites, and we may document the type of computer operating system you are using or type the internet browser you are operating.

&nbsp;

Other examples of the information we collect and analyze include the Internet protocol (IP) address used to connect your computer to the Internet; login; e-mail address; password; computer and connection information such as browser type, version, and time zone setting, browser plug-in types and versions, operating system, and platform; purchase history; the full Uniform Resource Locator (URL) click stream to, through, and from our Web site, including date and time; cookie number. We may also use browser data such as cookies, Flash cookies (also known as Flash Local Shared Objects), or similar data on certain parts of our Web site for fraud prevention and other purposes.

&nbsp;

During some visits we may use software tools such as JavaScript to measure and collect session information, including (a)page response times, (b)download errors, (c)length of visits to certain pages, (d)page interaction information (such as scrolling, clicks, and mouse-overs), (e)methods used to browse away from the page.

&nbsp;

#### How We Use Your Information

&nbsp;

Your personal information, such as your contact information, may be used for purposes such as:

&nbsp;

o	 To enroll you in the LeapThru Software, including determining your eligibility for participation.

&nbsp;

o	 To conduct and support your use of the LeapThru Software.

&nbsp;

o	 To contact you with surveys or other messages related to the LeapThru Software through in-app notifications, email, or otherwise.

&nbsp;

Your Contact information and any other information that could directly identify you will be separated from the rest of the data collected from you through LeapThru Software and replaced with a random code ("Coded Data") for the purposes described below.  In those cases, LeapThru will only have access to Coded Data and will not have access to any Data that could directly identify.  If you subscribed to LeapThru through any third party affiliates, they may maintain Data in an identifiable form based on their Privacy Policy.

&nbsp;

Your Coded Data may be used for purposes such as:

&nbsp;

o	 To evaluate feature usage across populations and at various activity levels

&nbsp;

o	 To develop health-related features and improvement activities

&nbsp;

o	 For other research studies

&nbsp;

o	 To issue usage findings, research and related reports, which will not identify you

&nbsp;

Your technical data (as described above) may also be used to determine your eligibility for the LeapThru Software.

&nbsp;

#### How Caregivers Use Care Recipient-Related Data

&nbsp;

o	 To learn whether an issue or potential issue may have occurred as well as the details surrounding that event.

&nbsp;

o	 To monitor a Care Recipient for purposes of providing Care Delivery and Care Management as described above.

&nbsp;

o	 To communicate with a Care Recipient and/or Caregiver(s) on LeapThru Software, possibly about particular information collected through the LeapThru Software.

&nbsp;

o	 To manage issues or potential issues about a Care Recipient within the LeapThru Software.

&nbsp;

o	 To identify and manage relevant LeapThru services or features or third party services or features.

&nbsp;

#### Disclosure to Third Parties

&nbsp;

Information about our customers is an important part of our business and we will never sell your information to third parties, unless stated at the time of collection. We only shares personal information with other companies or individuals outside of LeapThru in the following limited circumstances:

&nbsp;

o	 We have your consent. We require opt-in consent for the sharing of any sensitive personal information.

&nbsp;

o	 We provide such information to our affiliated companies or other trusted businesses or persons for the purpose of processing personal information on our behalf. We require that these parties agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.

&nbsp;

o	 We have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to (a) satisfy any applicable law, regulation, legal process or enforceable governmental request, (b) enforce applicable Terms of Service, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical issues, or (d) protect against harm to the rights, property or safety of LeapThru, its users or the public as required or permitted by law.

&nbsp;

o	 We employ other companies and individuals to perform functions on our behalf. Such as fulfilling orders, delivering packages, sending postal mail and email, removing repetitive information from customer lists, analyzing data, providing marketing assistance, provide search results and links (including paid links), processing credit card payment, and providing customer service. They have access to personal information needed to perform their functions, but may not use it for any other purpose.

&nbsp;

o	 We release account and other personal information when we believe that release is appropriate to comply with the law; protect the rights, property, or safety of LeapThru, our users or others. This including exchanging information with other companies and organizations for fraud protection and credit risk reduction. Obviously, however, this does not mean to sell, rent, share or disclose personal identifiable information from customers for commercial purpose in violation of commitments set forth in this Policy.

&nbsp;

If LeapThru becomes involved in a merger, acquisition, or any form of sale of some or all of its assets, we will ensure the confidentiality of any personal information involved in such transactions and provide notice before personal information is transferred and becomes subject to a different privacy policy.

&nbsp;

#### Service Providers

&nbsp;

LeapThru or contracted third party affiliates may share your information with companies who provide services for or on behalf of LeapThru. These companies are obligated to protect your information.

&nbsp;

#### Others

&nbsp;

Your personal information and Coded Data may also be disclosed to the following third parties:

&nbsp;

o	 **Government and Regulatory authorities**, such as the U.S. Department of Health and Human Services, the Food and Drug Administration, and other federal or state government agencies.

&nbsp;

o	 **Law enforcement or other third parties** pursuant to valid legal process such as a subpoena, litigation or court order. We may also disclose information about you if we determine that for purposes of national security, or other issues of public importance, disclosure is necessary or appropriate.

&nbsp;

o	 **Others**, if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or users. Additionally, in the event of a reorganization, merger, or sale we may transfer any and all personal information we collect to the relevant third party.

&nbsp;

#### Protection of Your Information

&nbsp;

LeapThru takes the security of your personal information very seriously.

&nbsp;

Your information is stored on our servers and we treat data as an asset that must be protected and use lots of tools (encryption, passwords, physical security, etc.) to protect your personal information against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.

&nbsp;

However, as you probably know, third parties may unlawfully intercept or access transmissions or private communications, and other users may abuse or misuse your personal information that they collect from the site. Therefore, although we work very hard to protect your privacy, we do not promise, and you should not expect, that your personal information or private communications will always remain private. Despite our security measures, total confidentiality cannot be guaranteed.

&nbsp;

We reveal only the last four digits of your credit card numbers when confirming an order. Of course, we transmit the entire credit card number to the appropriate credit card company during order processing.

&nbsp;

It is also restricted for our contractors and agents who need to know that information in order to process it on our behalf to access to personal information. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution, if they fail to meet these obligations.

&nbsp;

Besides, it is important for you to protect against unauthorized access to your password and to your computer. Be sure to sign off when finished using a shared computer.

&nbsp;

If you have trouble logging in, here are some things to check:

&nbsp;

o	 Incorrect email address: make sure your email address is in the following format: username@domain.com (or .net, .org, etc.). If you have more than one email address, be sure that you’re using the correct email address and password combination for the account you are trying to access. Also, if your computer is set to auto-complete the email, make sure it’s the correct email address listed.

&nbsp;

o	 Incorrect password: if you’re sure you’re using the correct email address, try to reset your password.

&nbsp;

o	 If you are using a public terminal, do not forget to log off, or sign out before you leave the computer. You can click the “Sign out” link at any time to exit your LeapThru account to protect the secure of your information. And if you are not signed into the account, the “Sign out” link will not be available.

&nbsp;

o	 After you sign out, your name will be removed from the home page, and your information will be inaccessible to anyone using the same computer after you.

&nbsp;

#### Retention of Personal Information

&nbsp;

Your participation in LeapThru is voluntary. You may decide not to participate or you may leave LeapThru at any time. If you decide to withdraw, we will delete personally identifiable information and keep non-personally identifiable information (Coded Data) that we have already collected and we may continue to use it, but we will stop receiving any new Data about you and will stop contacting you about LeapThru except in the case of serious, actionable medical need.

&nbsp;

#### Links to Other Websites

&nbsp;

Since LeapThru Software may include third-party advertising and links to other websites, we promise not to disclose any personally identifiable customer information to these advertisers or third-party websites.

&nbsp;

These third-party websites and advertisers, or Internet advertising companies working on their behalf, sometimes use technology to send (or “serve”) the advertisements that appear on our website directly to your browser. They automatically receive your IP address when this happens. They may also use cookies, JavaScript, web beacons (also known as action tags or single-pixel gifs), and other technologies to measure the effectiveness of their ads and to personalize advertising content. We do not have access to or control over cookies or other features that they may use, and the information practices of these advertisers and third-party websites are not covered by this Privacy Policy. Please contact them directly for more information about their privacy practices.

&nbsp;

#### Children

&nbsp;

LeapThru Software is not intended for children under the age of 13.  We do not collect personally identifiable information from any person we actually know is a child under the age of 13 unless a parent, guardian and/or their authorized caregiver has input that information and has provided consent for the use of LeapThru for the child or is using the LeapThru system on the child’s behalf.

&nbsp;

If we learn that we have collected the personal information of a child under 13 without the consent of a parent, guardian and/or their authorized caregiver, we will take steps to delete the information as soon as possible.

&nbsp;

Purchases by children will be declined. But our products can be used by children. If you are under 18, you may use LeapThru only with the involvement of a parent or guardian hereinafter referred to as “guardian”. The website will establish and maintain a reasonable procedure to ensure the confidentiality and safety of children’s personal information.

&nbsp;

o	 Guardians should bear the prior responsibility of protecting children’s privacy and private information.

&nbsp;

o	 Our collection of children’s personal information is intended for use by the guardian(s) and guardian-authorized caregivers in the usage of the LeapThru system.

&nbsp;

o	 We will not use children’s personal information or disclose their identification information to a third party without the permission of the guardians. In order to get the permission, we collect guardian’s name, children’s name and their contact information; if the guardians do not respond in a reasonable time, we will delete the information.

&nbsp;

o	 With the permission of guardians, we will collect personal information of the children and provide the guardians with the following:

&nbsp;

   a) Opportunity to examine the children’s and guardian’s information we collect;

&nbsp;

   b) Opportunity to stop the further utilization of the children’s and guardian’s information we collect;

&nbsp;

   c) Opportunity to change or delete the children’s and guardian’s information we collect.

&nbsp;

o	 Guardians have the right to refuse the further contact between children and our website.

&nbsp;

o	 We collect children’s information to protect their safety when engaging in online activities and for the proper usage of the system but not for any other end. We promise we will never ask children for extra personal information as the prerequisite of their online activities.

&nbsp;

#### Applicable Law

&nbsp;

By visiting LeapThru, you agree that the laws of the state of Illinois, without regard to principles of conflict of laws, will govern this Privacy Policy and Terms and Conditions of Use and any dispute of any sort that might arise between you and LeapThru.

&nbsp;

#### Intellectual Property Rights

&nbsp;

LeapThru Software and all the content available on LeapThru Software are the property of Leipzig Technology, LLC and are protected by copyright, trademark, and other intellectual property laws. You may not use the Website or the materials or services available on LeapThru Software in a manner that constitutes an infringement of our rights or that has not been authorized by us or is otherwise contrary to any applicable laws.

&nbsp;

When you submit or post any content, you are granting LeapThru, and anyone authorized by LeapThru, a royalty-free, perpetual, irrevocable, non-exclusive, unrestricted, fully sub-licensable, worldwide license to use, display, copy, modify, transmit, sell, exploit, create derivative works from, incorporate into other works, distribute, and/or digitally perform or publicly perform or display such content, in whole or in part, in any manner or medium, now known or hereafter developed, for any purpose. This license is non-exclusive, except that you agree that LeapThru shall have the exclusive right to practice this license to the extent of combining your posted content with the posted content of other website users for the purposes of constructing or populating a database for the benefit of any party which may be authorized by LeapThru.

&nbsp;

In connection with the exercise of such rights, you grant LeapThru, and anyone authorized by LeapThru, the right to identify you as the author of any of your postings or submissions by name, e-mail address or screen name, as we deem appropriate. LeapThru is under no obligation to give credit or pay any consideration for your posted content.

&nbsp;

#### Product and Feature Descriptions

&nbsp;

LeapThru works hard to be as accurate as possible. However, we do not warrant that product descriptions or other content of this site or LeapThru Software is accurate, complete, reliable, current, or error-free. If a product or featured offered by LeapThru itself is not as described completely, your sole remedy is to unsubscribe from LeapThru.

&nbsp;

#### Privacy Questions

&nbsp;

If you have any questions or concerns about this privacy policy, please contact us (connect@leapthru.com). If you have questions about LeapThru, please contact us at 1-833-532-7123. To withdraw from LeapThru, please either call us at the number above. Please note that deleting LeapThru Software will not withdraw you as an active user.

&nbsp;

#### Changes to This Policy

&nbsp;

Please refer to this privacy policy periodically for any variations. We may email periodic reminders of our notices and conditions, unless you have instructed us not to, but you should check our website frequently to see recent changes. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you and your account. We stand behind the promises we make, however, and will never materially change our policies and practices to make them less protective of customer information collected in the past without the consent of affected customers.

&nbsp;

#### Disclaimer of Warranties & Limitation of Liability

&nbsp;

All information, content, materials, products (including software) and services included on or otherwise made available to you through this site are provided by LeapThru on an “as is” and “as available” basis, unless otherwise specified in writing. LeapThru makes no representations or warranties of any kind, express or implied, as to the operation of this site or the information, content, materials, products (including software) or services included on or otherwise made available to you through this site, unless otherwise specified in writing. You expressly agree that your use of this site is at your sole risk.

&nbsp;

To the full extent permissible by applicable law, LeapThru disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. LeapThru does not warrant any LeapThru Software, including (but not limited to) this this site; information, content, materials, products (including software) or services included on or otherwise made available to you through this site; their servers; or e-mail sent from LeapThru are free of viruses or other harmful components. LeapThru will not be liable for any damages of any kind arising from the use of LeapThru software or from any information, content, materials, products (including software) or services included on or otherwise made available to you through LeapThru Software, including, but not limited to direct, indirect, incidental, punitive, and consequential damages, unless otherwise specified in writing.

&nbsp;

Certain state laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations may not apply to you, and you might have additional rights.

&nbsp;

The power to interpret and modify this privacy policy shall rest with Leipzig Technology, LLC.

&nbsp;

Last updated on February 8, 2021

&nbsp;

© 2021 Leipzig Technology, LLC. All rights reserved.
`;
