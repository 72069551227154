import React, { useState } from 'react';
import {
  MenuItem,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  send: {
    width: '50%',
    display: 'block',
    margin: 'auto',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
  select: {
    borderRadius: 4,
  },
}));

function NewMessageForm({
  careRecipients,
  othercaregivers,
  allCareGivers,
  preFilledFormState,
  onSubmit,
  isRecipient,
}) {
  const formState = {
    ...preFilledFormState,
    pressed: false,
    toRecipient: '',
  };
  const [values, setState] = useState(formState);

  const onChangeHandleInput = (event) => {
    const target = event.target;
    const value = target.value;

    if (!isRecipient && target.name === 'toUserId' && value.length === 0) {
      setState({ ...values, toUserId: [], careRecipientId: '' });
    } else {
      setState({ ...values, [target.name]: value });
    }
  };

  const classes = useStyles();
  const { t } = useTranslation();

  const saveForm = (values) => {
    if (
      !values.toUserId.includes(values.toRecipient) &&
      values.toRecipient !== ''
    ) {
      let toCaregivers = values.toUserId;
      toCaregivers.push(values.toRecipient);

      setState({ ...values, toUserId: toCaregivers });
    }

    onSubmit(values);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <FormControl color="primary" className={classes.select} fullWidth>
          <InputLabel htmlFor="select-multiple">
            {isRecipient
              ? t('SecureMessages.SendToCaregiver')
              : t('SecureMessages.SendToCaregivers')}
          </InputLabel>
          <Select
            multiple
            value={values.toUserId}
            name="toUserId"
            aria-label={t('SecureMessages.SendTo')}
            onChange={onChangeHandleInput}
            input={<Input id="select-multiple" />}
            color="primary"
          >
            {allCareGivers.map((giv) => (
              <MenuItem
                key={giv.userId}
                value={giv.userId}
                aria-label={`${giv.name.first} ${giv.name.last}`}
                selected={values.toUserId.includes(giv.userId)}
              >
                {`${giv.name.first} ${giv.name.last}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {values.toUserId.length !== 0 && !isRecipient && (
        <Grid item sm={12}>
          <TextField
            fullWidth
            select
            variant="outlined"
            label={t('SecureMessages.WhichRecipientItIsAbout')}
            aria-label={t('SecureMessages.Message')}
            name="careRecipientId"
            value={values.careRecipientId}
            onChange={onChangeHandleInput}
            placeholder={t('SecureMessages.MessagePlaceholder')}
          >
            <MenuItem value="">None</MenuItem>

            {!isRecipient &&
              Object.keys(careRecipients).map((rec) => (
                <MenuItem
                  key={careRecipients[rec].name.first}
                  value={careRecipients[rec].userId}
                  selected={values.toUserId.includes(
                    careRecipients[rec].userId
                  )}
                  aria-label={`${careRecipients[rec].name.first} ${careRecipients[rec].name.last}`}
                >
                  {`${careRecipients[rec].name.first} ${careRecipients[rec].name.last}`}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      )}
      {!isRecipient && (
        <Grid item sm={12}>
          <TextField
            fullWidth
            select
            value={values.toRecipient}
            name="toRecipient"
            variant="outlined"
            label={t('SecureMessages.SendToCareRecipient')}
            aria-label={t('SecureMessages.Message')}
            onChange={onChangeHandleInput}
            placeholder={t('SecureMessages.MessagePlaceholder')}
          >
            <MenuItem value="">None</MenuItem>

            {!isRecipient &&
              Object.keys(careRecipients).map((rec) => (
                <MenuItem
                  key={careRecipients[rec].name.first}
                  value={careRecipients[rec].userId}
                  selected={values.toUserId.includes(
                    careRecipients[rec].userId
                  )}
                  aria-label={`${careRecipients[rec].name.first} ${careRecipients[rec].name.last}`}
                >
                  {`${careRecipients[rec].name.first} ${careRecipients[rec].name.last}`}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      )}
      <Grid item sm={12}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('SecureMessages.Subject')}
          name="subject"
          aria-label={t('SecureMessages.Subject')}
          onChange={onChangeHandleInput}
          value={values.subject}
          placeholder={t('SecureMessages.SubjectPlaceholder')}
        ></TextField>
      </Grid>
      <Grid item sm={12}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('SecureMessages.Message')}
          aria-label={t('SecureMessages.Message')}
          name="content"
          rows={10}
          rowsMax={20}
          multiline
          value={values.content}
          onChange={onChangeHandleInput}
          placeholder={t('SecureMessages.MessagePlaceholder')}
        ></TextField>
      </Grid>
      <Grid item sm={12}>
        <Button
          className={classes.send}
          variant="contained"
          color="primary"
          aria-label={t('SecureMessages.Send')}
          disabled={
            !values.content ||
            (!values.subject &&
              (values.toUserId !== [] || values.careRecipientId !== ''))
          }
          onClick={() => saveForm(values)}
        >
          {t('SecureMessages.Send')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default NewMessageForm;
