import React from 'react';
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme) => ({
  gridSpacing: {
    marginBottom: theme.spacing(2),
  },
  buttonElement: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  boldText: {
    fontWeight: 'bold',
  },
}));

function DestinationCheckSummary({
  destinationCheck,
  onSaveClick = () => {},
  onSummaryBack = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  function handleOnSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    onSaveClick();
  }

  function handleBack(event) {
    event.stopPropagation();
    event.preventDefault();

    onSummaryBack();
  }

  return (
    <>
      <Grid className={classes.gridSpacing} container spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.boldText} variant="subtitle1">
            1. Destination information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Title"
            defaultValue={destinationCheck.location.title}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Location address"
            defaultValue={destinationCheck.location.locationAddress}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.boldText} variant="subtitle1">
            2. Timing
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Frequency"
            defaultValue={t(destinationCheck.reminder.recurrence)}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Start date"
            defaultValue={DateTime.fromJSDate(
              destinationCheck.reminder.time_1
            ).toFormat('fff')}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Ongoing reminder"
            defaultValue={destinationCheck.reminder.ongoing ? 'Yes' : 'No'}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="End date"
            defaultValue={
              destinationCheck.reminder.endDate
                ? DateTime.fromJSDate(
                    destinationCheck.reminder.endDate
                  ).toFormat('fff')
                : 'Not set'
            }
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Reminder"
            defaultValue={
              destinationCheck.reminder.showReminderField
                ? t(destinationCheck.reminder.reminderTime)
                : 'No accompanying Care Recipient reminder setup'
            }
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        {destinationCheck.reminder.showReminderField && (
          <>
            {destinationCheck.reminder.additionalInformation.length > 0 && (
              <Grid item xs={12}>
                <TextField
                  label="Additional reminder information"
                  defaultValue={destinationCheck.reminder.additionalInformation}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={6}>
          <Button
            className={classes.buttonElement}
            variant="contained"
            onClick={handleBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.buttonElement}
            variant="contained"
            color="primary"
            onClick={handleOnSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default DestinationCheckSummary;
