import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  items: {},
  lastRefresh: null,
});

export function merge(state, item) {
  const arr = Array.isArray(item) ? item : [item];
  return state
    .mergeIn(
      ['items'],
      fromJS(arr.reduce((obj, i) => ({ ...obj, [i.id]: i }), {}))
    )
    .set('lastRefresh', Date.now());
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MESSAGES_SET':
      return merge(state, action.payload);

    default:
      return state;
  }
};
