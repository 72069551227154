import moment from 'moment-timezone';

export const accessType = {
  new_note: { notify: 'done' },
  new_message: { notify: 'done' },
  phone_battery_dropto_5: { notify: 'done' },
  phone_battery_dropto_10: { notify: 'alert' },
  phone_battery_dropto_15: { notify: 'done' },
  phone_battery_dropto_30: { notify: 'done' },
  watch_battery_dropto_5: { notify: 'done' },
  watch_battery_dropto_10: { notify: 'done' },
  watch_battery_dropto_15: { notify: 'done' },
  watch_battery_dropto_30: { notify: 'done' },
  adherence: {
    notify: ['red', 'yellow'],
    filters: {
      hours: Array.from(Array(24), (_, i) => ({
        value: i,
        label: moment(0).hour(i).format('h a'),
      })),
    },
  },
  calendar_create: { notify: 'done' },
  calendar_inactivate: { notify: 'done' },
  calendar_remove: { notify: 'done' },
  call_breakthrough: {
    // notify: [
    //   { value: 'fixed', label: 'Alerts Reviewed By Caregiver' },
    //   { value: 'alert', label: 'Call Notification' },
    // ],
    reviewTypes: {
      label: 'Call notification',
      notify: ['alert', 'fixed', 'dismissed', 'handling'],
    },
  },
  contact_update: { notify: 'done' },
  location: {
    // notify: [
    //   {
    //     value: 'alert',
    //     label: 'Did not arrive at destination according to GPS',
    //   },
    //   { value: 'done', label: 'Did arrive at destination according to GPS' },
    //   { value: 'fixed', label: 'Alerts Reviewed By Caregiver' },
    //   { value: 'dismissed', label: 'Alert Dismissed By Caregiver' },
    //   { value: 'handling', label: 'Alert Under Review By Caregiver' },
    // ],
    reviewTypes: [
      {
        label: 'Did not arrive at destination according to GPS',
        notify: ['alert', 'fixed', 'dismissed', 'handling'],
        merge: true,
      },
      {
        label: 'Did arrive at destination according to GPS',
        notify: ['done'],
        merge: true,
      },
    ],
  },
  location_reminder: { notify: ['missed', 'alert', 'done', 'fixed'] },
  medicine: {
    // notify: [
    //   { value: 'missed', label: 'Missed' },
    //   { value: 'alert', label: 'Declined Medications' },
    //   { value: 'done', label: 'Confirmed Taking Medications' },
    //   { value: 'fixed', label: 'Alert Reviewed By Caregiver' },
    //   { value: 'dismissed', label: 'Alert Dismissed By Caregiver' },
    //   { value: 'handling', label: 'Alert Under Review By Caregiver' },
    // ],
    reviewTypes: [
      {
        label: 'Missed',
        notify: ['missed'],
        merge: true,
      },
      {
        label: 'Declined Medications',
        notify: ['alert'],
        merge: true,
      },
      {
        label: 'Confirmed Taking Medications',
        notify: ['done'],
        merge: true,
      },
    ],
  },
  care_giver_new: { notify: 'done' },
  idle5to5: {
    // notify: [
    //   { value: 'alert', label: 'Alert' },
    //   { value: 'fixed', label: 'Alert Reviewed By Caregiver' },
    // ],
    // notify: 'alert',
    reviewTypes: {
      notify: ['alert', 'fixed', 'dismissed', 'handling'],
    },
  },
  calendar_update: { notify: 'done' },
  contact_create: { notify: 'done' },
  refill: {
    // notify: [
    //   { value: 'missed', label: 'Missed' },
    //   { value: 'alert', label: 'Declined Refilling' },
    //   { value: 'done', label: 'Intending to Refill' },
    //   { value: 'fixed', label: 'Alert Reviewed By Caregiver' },
    //   { value: 'dismissed', label: 'Alert Dismissed By Caregiver' },
    //   { value: 'handling', label: 'Alert Under Review By Caregiver' },
    // ],
    reviewTypes: [
      {
        label: 'Missed',
        notify: ['missed'],
        merge: true,
      },
      {
        label: 'Declined Refilling',
        notify: ['alert'],
        merge: true,
      },
      {
        label: 'Intending to Refill',
        notify: ['done'],
        merge: true,
      },
    ],
  },
  general: {
    // notify: [
    //   { value: 'missed', label: 'Missed' },
    //   { value: 'done', label: 'Reminder Confirmed' },
    //   { value: 'fixed', label: 'Resolved' },
    //   { value: 'dismissed', label: 'Alert Dismissed By Caregiver' },
    //   { value: 'handling', label: 'Alert Under Review By Caregiver' },
    // ],
    reviewTypes: [
      {
        label: 'Missed',
        notify: ['missed'],
        merge: true,
      },
      {
        label: 'Reminder Confirmed',
        notify: ['done'],
        merge: true,
      },
    ],
  },
  wellness: {
    // notify: [
    //   { value: 'missed', label: 'Missed' },
    //   { value: 'alert', label: 'Any Concerning Responses' },
    //   { value: 'done', label: 'Check-In Completed' },
    //   { value: 'fixed', label: 'Alerts Reviewed By Caregiver' },
    //   { value: 'dismissed', label: 'Alert Dismissed By Caregiver' },
    //   { value: 'handling', label: 'Alert Under Review By Caregiver' },
    // ],
    reviewTypes: [
      {
        label: 'Missed',
        notify: ['missed'],
        merge: true,
      },
      {
        label: 'Any Concerning Responses',
        notify: ['alert'],
        merge: true,
      },
      {
        label: 'Check-In Completed',
        notify: ['done'],
        merge: true,
      },
    ],
  },
  user_set_rights: { notify: 'done' },
  user_set_notification_settings: { notify: 'done' },
  user_set_caregiver_settings: { notify: 'done' },
  user_logout_phone: { notify: 'done' },
  user_set_phone_settings: { notify: 'done' },
  user_set_medical_data: { notify: 'done' },
  user_set_user_data: { notify: 'done' },
  emergencyContactCall: {
    notify: [
      { value: 'alert', label: 'Alert' },
      { value: 'fixed', label: 'Alert Reviewed By Caregiver' },
    ],
  },
  timezoneChange: {
    // notify: [
    //   { value: 'alert', label: 'Time zone change' },
    //   { value: 'fixed', label: 'Alert Reviewed By Caregiver' },
    // ],
    reviewTypes: {
      notify: ['alert', 'fixed', 'dismissed', 'handling'],
    },
  },
  emergencyCall: {
    // notify: [
    //   { value: 'alert', label: 'Call Notification' },
    //   { value: 'fixed', label: 'Alert Reviewed By Caregiver' },
    // ],
    reviewTypes: {
      label: 'Call notification',
      notify: ['alert', 'fixed', 'dismissed', 'handling'],
    },
  },
  call_init: { notify: 'done' },
  at_or_near_hospital: {
    // notify: 'alert',
    reviewTypes: {
      notify: ['alert', 'fixed', 'dismissed', 'handling'],
    },
  },
  default: { notify: ['alert', 'done', 'missed', 'fixed'] },
  step_threshold_10_reached: { notify: 'alert' },
  step_threshold_14_reached: { notify: 'alert' },
  step_threshold_18_reached: { notify: 'alert' },
  step_threshold_10_unreached: { notify: 'alert' },
  step_threshold_14_unreached: { notify: 'alert' },
  step_threshold_18_unreached: { notify: 'alert' },
  pulse_individual_low: { notify: 'alert' },
  pulse_individual_high: { notify: 'alert' },
  idle_detection_no_response: {
    // notify: [
    //   { value: 'alert', label: 'Alert' },
    //   { value: 'fixed', label: 'Resolved' },
    // ],
    reviewTypes: {
      notify: ['alert', 'fixed', 'dismissed', 'handling'],
    },
  },
  idle_detection_response_nok: {
    // notify: [
    //   { value: 'alert', label: 'Alert' },
    //   { value: 'fixed', label: 'Resolved' },
    // ],
    reviewTypes: {
      notify: ['alert', 'fixed', 'dismissed', 'handling'],
    },
  },
  contact_remove: { notify: 'done' },
  user_settings_heart_rate_updated: { notify: 'done' },
  user_settings_activity_level_updated: { notify: 'done' },
  destination_changes_group: {
    group: {
      destination_check_created: { label: 'Newly created', notify: 'done' },
      destination_check_updated: { label: 'Updated', notify: 'done' },
      destination_check_removed: { label: 'Deleted', notify: 'done' },
    },
  },
  user_location_changes_group: {
    group: {
      user_settings_location_created: {
        label: 'Newly created',
        notify: 'done',
      },
      user_settings_location_updated: { label: 'Updated', notify: 'done' },
      user_settings_location_removed: { label: 'Deleted', notify: 'done' },
    },
  },
};

export const appendReviewTypesForNotification = (
  notification,
  notifyArray = []
) => {
  let shouldAppendReviewTypes = false;
  const reviewTypes = ['fixed', 'dismissed', 'handling'];

  if (notifyArray.length && notifyArray[0] !== 'none') {
    switch (notification) {
      case 'medicine':
      case 'refill':
      case 'wellness':
        shouldAppendReviewTypes =
          notifyArray.includes('missed') || notifyArray.includes('alert');
        break;
      case 'general':
        shouldAppendReviewTypes = notifyArray.includes('missed');
        break;
      default:
    }
  }

  switch (notification) {
    case 'medicine':
    case 'refill':
    case 'general':
    case 'wellness':
      const notifyArrayWithNone = notifyArray.filter(
        (e) => !reviewTypes.includes(e)
      );
      if (!notifyArrayWithNone.length) {
        notifyArrayWithNone.push('none');
      }

      // KEEP BELOW FOR DEBUGGING
      // console.log(
      //   'TCL: notification: ' + notification,
      //   shouldAppendReviewTypes
      //     ? [...new Set([...notifyArray, ...reviewTypes])]
      //     : notifyArrayWithNone
      // );

      return shouldAppendReviewTypes
        ? [...new Set([...notifyArray, ...reviewTypes])]
        : notifyArrayWithNone;
    default:
      return notifyArray;
  }
};

export const tableGroups = {
  LOCATION_FEATURES: {
    activityLevel: {
      header: 'Activity level',
      columns: ['Reached', 'Not Reached'],
      rows: [
        {
          label: '10:00 AM',
          group: {
            step_threshold_10_reached: { notify: 'alert' },
            step_threshold_10_unreached: { notify: 'alert' },
          },
        },
        {
          label: '2:00 PM',
          group: {
            step_threshold_14_reached: { notify: 'alert' },
            step_threshold_14_unreached: { notify: 'alert' },
          },
        },
        {
          label: '6:00 PM',
          group: {
            step_threshold_18_reached: { notify: 'alert' },
            step_threshold_18_unreached: { notify: 'alert' },
          },
        },
      ],
    },
  },
  MEDICAL: {
    heartRate: {
      header: 'Heart rate notifications',
      columns: ['Below lower limit', 'Above upper limit'],
      rows: [
        {
          label: 'Pulse thresholds',
          group: {
            pulse_individual_low: { notify: 'alert' },
            pulse_individual_high: { notify: 'alert' },
          },
        },
      ],
    },
  },
  OTHER_SETTINGS: {
    batteryLevel: {
      header: 'Low battery indicator',
      columns: ['30%', '15%', '10%', '5%'],
      rows: [
        {
          label: 'Phone battery',
          group: {
            phone_battery_dropto_30: { notify: 'done' },
            phone_battery_dropto_15: { notify: 'done' },
            phone_battery_dropto_10: {
              notify: 'alert',
              reviewTypes: {
                notify: ['alert', 'fixed', 'dismissed', 'handling'],
              },
            },
            phone_battery_dropto_5: { notify: 'done' },
          },
        },
        {
          label: 'Watch battery',
          group: {
            watch_battery_dropto_30: { notify: 'done' },
            watch_battery_dropto_15: { notify: 'done' },
            watch_battery_dropto_10: {
              notify: 'alert',
              reviewTypes: {
                notify: ['alert', 'fixed', 'dismissed', 'handling'],
              },
            },
            watch_battery_dropto_5: { notify: 'done' },
          },
        },
      ],
    },
  },
};

export const excludeAccessTypes = {
  phone_battery_riseto_10: true,
  phone_battery_riseto_15: true,
  phone_battery_riseto_30: true,
  watch_battery_riseto_10: true,
  watch_battery_riseto_15: true,
  watch_battery_riseto_30: true,
};

export const notificationGroups = {
  CALLS: ['call_breakthrough', 'emergencyCall'],
  MESSAGES: ['new_note', 'new_message'],
  LOCATION_FEATURES: [
    'location',
    'destination_changes_group',
    'user_location_changes_group',
    'at_or_near_hospital',
    'idle5to5',
    'idle_detection_no_response',
    'idle_detection_response_nok',
    'user_settings_activity_level_updated',
    'timezoneChange',
  ],
  MEDICAL: ['medicine', 'refill', 'user_settings_heart_rate_updated'],
  OTHER_SETTINGS: [
    'contact_remove',
    'general',
    'wellness',
    'adherence',
    'user_logout_phone',
  ],
};
