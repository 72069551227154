/* eslint-disable */

import { withStyles } from '@material-ui/core/styles';
import MuiSwitch from '@material-ui/core/Switch';

export default withStyles(theme => ({
  root: {
    width: 42
  },
  switchBase: {
    width: 42,
    height: 20,
    padding: '0 4px',
    justifyContent: 'flex-start',
    '&$checked': {
      color: theme.palette.common.white,
      '& + $bar': {
        backgroundColor: '#7ed321'
      }
    }
  },
  bar: {
    borderRadius: 13,
    width: 42,
    height: 20,
    marginTop: -10,
    marginLeft: -21,
    border: 'none',
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color'])
  },
  icon: {
    width: 14,
    height: 14,
    boxShadow: 'none',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp
    })
  },
  iconChecked: {
    transform: 'translateX(20px)'
  },
  checked: {
    transform: 'none',
    '& + $bar': {
      opacity: 1,
      border: 'none'
    }
  }
}))(MuiSwitch);
