import getOrDefault from 'utils/safe';

export default function isEqualLength(firstArray = null, secondArray = null) {
  if (firstArray === null || secondArray === null) {
    return false;
  }

  const firstLength = getOrDefault(() => firstArray.length, 0);
  const secondLength = getOrDefault(() => secondArray.length, 0);

  return firstLength === secondLength;
}
