import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  contactsData: {},
});

function clearContactsUser(state, userId) {
  const contactsData = state.get('contactsData');
  const newcontactsData = contactsData.set(userId, []);
  return state.set('contactsData', newcontactsData);
}

function setContactsData(state, inData, userId) {
  let workState = state;

  if (userId) {
    workState = clearContactsUser(workState, userId);
  }

  const contactsData = workState.get('contactsData');
  const newData = inData.reduce((data, element) => {
    if (element && element.userId) {
      let usercontactsData = data.get(element.userId);
      if (!usercontactsData) {
        usercontactsData = [];
      }
      usercontactsData.push(element);
      return data.set(element.userId, usercontactsData);
    }
    return data;
  }, contactsData);

  return workState.set('contactsData', newData);
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CONTACTS_SET':
      return setContactsData(state, action.payload, action.userId);
    case 'CONTACTS_CLEAR_USER':
      return clearContactsUser(state, action.userId);
    default:
      return state;
  }
};

export function filterContacts(state, userId) {
  const outData = state.getIn(['contacts', 'contactsData', userId], null);
  if (!outData) {
    return null;
  }

  return outData;
}
