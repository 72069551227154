import React, { useEffect } from 'react';
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Grid,
  DialogContent,
  IconButton,
  Typography,
  Divider,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  initialDestinationCheckLocationFormValues,
  DestinationCheckLocationForm,
} from './DestinationCheckLocationForm';
import {
  initialDestinationReminderFormValues,
  DestinationReminderForm,
} from './DestinationReminderForm';
import DestinationCheckSummary from './DestinationCheckSummary';
import Frequency from 'enums/Frequency';
import ReminderAlert from 'enums/ReminderAlert';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  addLocationEvent,
  editLocationEvent,
  getUserLocations,
} from 'actions/location';
import RRule from 'rrule';
import get from 'lodash.get';
import { dateInRruleDateFormat } from 'utils/rruleWrapper';
import { getMinutes } from 'utils/date';
import { getAddressByCoords } from 'actions/geocode';
import Steppable from 'components/Steppable';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../components/InfoTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexWrap: 'wrap',
    display: 'flex',
  },
  stepper: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  boldText: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(3),
  },
  titlemargin: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  infoTooltip: {
    margin: theme.spacing(2, 2, 2, 0),
  },
  tooltipTitleContainer: {
    display: 'flex',
  },
}));

const frequencyOptions = {
  one_time: { label: 'One time', rrule: { freq: RRule.YEARLY } },
  day_1: { label: 'Once a day', rrule: { freq: RRule.DAILY } },
  week_1: { label: '1x per week', rrule: { freq: RRule.WEEKLY } },
  month_1: { label: '1x per month', rrule: { freq: RRule.MONTHLY } },
};
const frequencyKeys = Object.keys(frequencyOptions);

const translateDestinationReminderFrequencyFromLegacyFrequency = (
  legacyFrequency
) => {
  switch (legacyFrequency) {
    case 'day_1':
      return Frequency.EVERY_DAY.ONE;
    case 'week_1':
      return Frequency.EVERY_WEEK.ONE;
    case 'month_1':
      return Frequency.EVERY_MONTH.ONE;
    default:
      return Frequency.NEVER;
  }
};

const translateToRecurrenceFromDestinationReminderFrequency = (frequency) => {
  switch (frequency) {
    case Frequency.EVERY_DAY.ONE:
      return RRule.DAILY;
    case Frequency.EVERY_WEEK.ONE:
      return RRule.WEEKLY;
    case Frequency.EVERY_MONTH.ONE:
      return RRule.MONTHLY;
    default:
      return null;
  }
};

function Dialog(props, context) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { apiClient } = context;
  const activeCareRecipient = useSelector(
    (state) => state.getIn(['carerecipients', 'activeRecipient']),
    shallowEqual
  );
  const [open, setOpen] = React.useState(true);
  // eslint-disable-next-line
  const [shouldUpdateForm, setShouldUpdateForm] = React.useState(false);

  const data = props.data || {};
  const { eventData } = data;
  const edit = !!eventData;
  const timezone = activeCareRecipient.timezone;

  const formState = {
    location: { ...initialDestinationCheckLocationFormValues },
    reminder: { ...initialDestinationReminderFormValues },
  };

  if (open) {
    getUserLocations(dispatch, apiClient, activeCareRecipient.userId);
  }

  if (edit) {
    const recurrenceRRule =
      eventData.recurrence && RRule.fromString(eventData.recurrence);
    const foundFrequency =
      recurrenceRRule &&
      frequencyKeys.find((key) => {
        const f = frequencyOptions[key];
        return f.rrule.freq === recurrenceRRule.options.freq;
      });
    const frequency =
      typeof foundFrequency === 'undefined' ? Frequency.NEVER : foundFrequency;
    const startDate = DateTime.fromJSDate(
      get(recurrenceRRule, 'options.dtstart')
    )
      .toUTC()
      .setZone('local', { keepLocalTime: true });
    const endDate = DateTime.fromJSDate(get(recurrenceRRule, 'options.until'))
      .toUTC()
      .setZone('local', { keepLocalTime: true });
    const byminute = get(recurrenceRRule, 'options.byminute[0]');
    const time =
      typeof byminute !== 'undefined'
        ? startDate.plus({ minutes: byminute }).toJSDate()
        : null;
    const remindersEnabled =
      eventData.reminders && eventData.reminders.length > 0;
    const reminder = remindersEnabled && eventData.reminders[0];

    formState.reminder.recurrence = translateDestinationReminderFrequencyFromLegacyFrequency(
      frequency
    );

    formState.reminder.startDate = startDate.toJSDate();
    formState.reminder.endDate = !isNaN(endDate.toJSDate())
      ? endDate.toJSDate()
      : null;
    formState.reminder.time_1 = time;
    formState.reminder.ongoing = isNaN(endDate.ts) ? true : false;
    formState.reminder.additionalInformation = eventData.description;
    formState.reminder.showReminderField = remindersEnabled;
    formState.reminder.reminderTime = ReminderAlert.getReminderAlertFromOffset(
      reminder.offset
    );

    formState.location.title = eventData.title;
    formState.location.locationCoords = {
      lat: eventData.data.latitude,
      lng: eventData.data.longitude,
    };

    getAddressByCoords(
      dispatch,
      apiClient,
      eventData.data.latitude,
      eventData.data.longitude
    ).then((value) => {
      formState.location.locationAddress = value;
      setShouldUpdateForm(true);
    });
  }

  const [destinationCheck, setDestinationCheck] = React.useState(formState);
  const stepper = Steppable.useSteps(0);

  useEffect(() => {
    document.body.style.overflow = 'auto';
  });

  function handleCloseDialog() {
    setOpen(false);
  }

  function handleOnLocationSubmitted(submittedLocation) {
    setDestinationCheck({
      ...destinationCheck,
      location: submittedLocation,
    });
    stepper.toNext();
  }

  function handleOnReminderSubmitted(submittedReminder) {
    setDestinationCheck({ ...destinationCheck, reminder: submittedReminder });
    stepper.toNext();
  }

  function handleOnReminderBack(reminderBack) {
    setDestinationCheck({ ...destinationCheck, reminder: reminderBack });
    stepper.toPrevious();
  }

  function handleOnSummaryBack() {
    stepper.toPrevious();
  }

  function getRrule({ recurrence, startDate, endDate, ongoing, ...others }) {
    const freq = translateToRecurrenceFromDestinationReminderFrequency(
      recurrence
    );
    const dtstart = dateInRruleDateFormat(startDate);
    const until = !ongoing ? dateInRruleDateFormat(endDate, true) : false;
    const byminute = [];

    Reflect.ownKeys(others).forEach((key) => {
      if (/^time_\d$/.test(key)) {
        byminute.push(getMinutes(others[key]));
      }
    });

    const ruleData =
      freq === null
        ? {
            dtstart,
            byminute,
            count: 1,
          }
        : {
            freq,
            dtstart,
            byminute,
          };

    if (until) {
      ruleData.until = until;
    }

    ruleData.tzid = activeCareRecipient.timezone;
    return new RRule(ruleData);
  }

  function handleOnSaveClick() {
    const recurrence = getRrule(destinationCheck.reminder);
    const toSave = {
      title: destinationCheck.location.title,
      location: {
        location: destinationCheck.location.locationAddress,
        latitude: destinationCheck.location.locationCoords.lat,
        longitude: destinationCheck.location.locationCoords.lng,
      },
      description: destinationCheck.reminder.additionalInformation,
      timezone: activeCareRecipient.timezone,
      ongoing: destinationCheck.reminder.ongoing,
      recurrence: recurrence.toString(),
      recurrenceDescription: recurrence.toText(),
      userId: activeCareRecipient.userId,
    };

    if (destinationCheck.reminder.showReminderField) {
      toSave.reminders = [
        {
          offset: ReminderAlert.getOffsetFromReminderAlert(
            destinationCheck.reminder.reminderTime
          ),
        },
      ];
    }

    if (edit) {
      toSave.eventId = eventData.eventId;
      editLocationEvent(dispatch, apiClient, toSave).then(() => {
        setOpen(false);
        window.location.reload();
      });
    } else {
      addLocationEvent(dispatch, apiClient, toSave).then(() => {
        setOpen(false);
        window.location.reload();
      });
    }
  }

  const getSteps = () => {
    return ['Destination', 'Timing', 'Summary'];
  };

  const getStepTitles = () => {
    return ['Identify the destination', 'Timing', 'Summary'];
  };

  const steps = getSteps();
  const stepTitles = getStepTitles();

  return (
    <MuiDialog open={open} onClose={handleCloseDialog}>
      <div className={classes.tooltipTitleContainer}>
        <MuiDialogTitle id="alert-dialog-title">
          Destination Check
        </MuiDialogTitle>
        <InfoTooltip
          translationKey="DestinationCheck.Tooltip"
          className={classes.infoTooltip}
          translationOptions={{
            name: activeCareRecipient.name.first,
          }}
        />
      </div>
      <div className={classes.titlemargin}>
        <Typography variant="subtitle1">
          {t('Reminder.EventWillBeCreatedInCareRecipientTimezone')}
        </Typography>
        <Tooltip
          title={DateTime.local()
            .setZone(timezone)
            .setLocale('en-US')
            .toFormat('ZZZZZ')}
        >
          <Typography align="center" variant="caption">
            (UTC
            {DateTime.local()
              .setZone(timezone)
              .setLocale('en-US')
              .toFormat('ZZ z')}{' '}
            )
          </Typography>
        </Tooltip>
      </div>
      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={handleCloseDialog}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <Typography variant="subtitle1" className={classes.boldText}>
        Step {stepper.activeStep + 1} - {stepTitles[stepper.activeStep]}
      </Typography>
      <DialogContent className={classes.root}>
        <Stepper className={classes.stepper} activeStep={stepper.activeStep}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Grid item xs={12}>
          <Steppable.Steps activeStep={stepper.activeStep}>
            <DestinationCheckLocationForm
              location={destinationCheck.location}
              onLocationSubmitted={handleOnLocationSubmitted}
            />
            <DestinationReminderForm
              reminder={destinationCheck.reminder}
              onReminderSubmitted={handleOnReminderSubmitted}
              onReminderBack={handleOnReminderBack}
            />
            <DestinationCheckSummary
              destinationCheck={destinationCheck}
              onSaveClick={handleOnSaveClick}
              onSummaryBack={handleOnSummaryBack}
            />
          </Steppable.Steps>
        </Grid>
      </DialogContent>
    </MuiDialog>
  );
}

Dialog.contextTypes = {
  apiClient: PropTypes.object.isRequired,
};

export default Dialog;
