/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, CircularProgress } from '@material-ui/core';
import Audio from 'utils/audio';
import IconButton from 'components/Button/IconButton';
import Button from 'components/Button';
import Title from 'components/Title';
import style from './style.module.scss';

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    const audio = new Audio(props.data.source, {
      stateChange: state => this.audioStateChange(state)
    });

    this.state = {
      audio,
      time: 0,
      duration: null,
      state: null
    };
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  formatSeconds(t) {
    const time = Math.floor(t || 0);
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const pad = n => (n.toString().length < 2 ? `0${n}` : n);
    return `${pad(minutes)}:${pad(seconds)}`;
  }

  audioStateChange(state) {
    this.setState({ state }, () => {
      if (state === 'playing' && !this.timer) {
        this.startTimer();
      } else if (state === 'end' && this.timer) {
        this.stopTimer();
      }
    });
  }

  startTimer() {
    this.setState({ time: 0 });
    const { audio } = this.state.audio;
    this.timer = setInterval(
      () =>
        this.setState({
          time: audio.currentTime,
          duration: audio.duration
        }),
      100
    );
  }

  stopTimer() {
    clearInterval(this.timer);
    this.timer = null;
    // this.setState({ time: 0 });
  }

  playAudio() {
    this.state.audio.togglePlay();
  }

  stateIcons = {
    playing: <Icon>stop</Icon>,
    default: <Icon>play_arrow</Icon>,
    loading: <CircularProgress size={30} color="inherit" />
  };

  render() {
    const { state, time, duration } = this.state;
    const { text } = this.props.data || {};
    const stateIcon = this.stateIcons[state] || this.stateIcons.default;
    return (
      <div className={[style.background, style[this.props.transition]].join(' ')}>
        <div className={style.container} onClick={e => e.stopPropagation()}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            <div className={style.content}>
              <Title>Voice recording</Title>
              {text && <div className={style.text}>{text}</div>}
              <div className={style.player}>
                <div className={style.playButton}>
                  <Button onClick={() => this.playAudio()} variant="fab" color="secondary">
                    {stateIcon}
                  </Button>
                </div>
                <div className={style.time}>
                  <span className={style.timeElapsed}>{this.formatSeconds(time)}</span>
                  {duration && (
                    <span>
                      /<span className={style.totalTime}>{this.formatSeconds(duration)}</span>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Button className={style.okButton} onClick={() => this.onClose()}>
                OK
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(['carerecipients', 'activeRecipient'], {});
  return { activeRecipient };
};

export default connect(mapStateToProps)(Dialog);
