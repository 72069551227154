import style from './style.module.scss';
import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

function LoginDisclaimerDialog(props, context) {
  const { t } = useTranslation();

  function onClose() {
    context.dialogClose(props.dialogId);
  }

  function onAccept() {
    context.dialogClose(props.dialogId);
    props.data.onAccept();
  }

  return (
    <div className={[style.background, style[props.transition]].join(' ')}>
      <div className={style.container} onClick={(e) => e.stopPropagation()}>
        <div className={style.modal}>
          <div className={style.content}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom>
                  {t('LoginDisclaimer.UsageAcknowledgement')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom paragraph>
                  {t('LoginDisclaimer.Disclaimer_1')}
                </Typography>
                <Typography variant="body2" gutterBottom paragraph>
                  {t('LoginDisclaimer.Disclaimer_2')}
                </Typography>
                <Typography variant="body2" gutterBottom paragraph>
                  {t('LoginDisclaimer.Disclaimer_3')}
                </Typography>
                <Typography variant="body2" gutterBottom paragraph>
                  {t('LoginDisclaimer.Disclaimer_4')}
                </Typography>
                <Typography variant="body2" gutterBottom paragraph>
                  {t('LoginDisclaimer.Disclaimer_5')}
                </Typography>
                <Typography variant="body2" gutterBottom paragraph>
                  {t('LoginDisclaimer.Disclaimer_6')}
                </Typography>
                <Typography variant="body2" gutterBottom paragraph>
                  {t('LoginDisclaimer.Disclaimer_7')}
                </Typography>
                <Typography variant="body2" gutterBottom paragraph>
                  {t('LoginDisclaimer.Disclaimer_8')}
                </Typography>
                <Typography variant="body2" gutterBottom paragraph>
                  <Trans i18nKey="LoginDisclaimer.Disclaimer_9">
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://leapconnect.leapthru.com/terms-and-conditions"
                    >
                      Terms & Conditions of Use
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://leapconnect.leapthru.com/privacy-policy"
                    >
                      Privacy Policy
                    </a>
                  </Trans>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={style.button}
                  variant="contained"
                  type="submit"
                  fullWidth
                  onClick={() => onClose()}
                >
                  {t('LoginDisclaimer.Logout')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={style.button}
                  variant="contained"
                  type="submit"
                  onClick={() => onAccept()}
                >
                  {t('LoginDisclaimer.IAgree')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

LoginDisclaimerDialog.contextTypes = {
  dialogPush: PropTypes.func,
  dialogClose: PropTypes.func,
};

export default LoginDisclaimerDialog;
