export const Actions = Object.freeze({
  RECEIVE_CONVERSATIONS: 'RECEIVE_CONVERSATIONS',
  RECEIVE_CONVERSATION: 'RECEIVE_CONVERSATION',
  POST_CONVERSATION_MESSAGE: 'POST_CONVERSATION_MESSAGE',
});

const receiveConversations = (payload) => ({
  type: Actions.RECEIVE_CONVERSATIONS,
  payload,
});

const receiveConversation = (conversationId, payload) => ({
  type: Actions.RECEIVE_CONVERSATION,
  conversationId,
  payload,
});

export const getConversations = (dispatch, client) => {
  client.call({ endpoint: 'chat/getConversations' }).then((response) => {
    if (response.status !== 200) {
      console.error(response);
    }

    dispatch(receiveConversations(response.data));
  });
};

export const getConversation = (dispatch, client, conversationId) => {
  if (!conversationId) {
    return;
  }

  client
    .call({
      endpoint: 'chat/getConversation',
      data: {
        conversationId,
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        console.error(response);
      }

      dispatch(receiveConversation(conversationId, response.data));
    });
};

export const postConversationMessage = (
  dispatch,
  client,
  conversationId,
  body
) => {
  if (!conversationId) {
    return;
  }

  if (!body) {
    return;
  }

  client
    .call({
      endpoint: 'chat/sendConversationMessage',
      data: {
        conversationId,
        body,
        type: 'text',
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        console.error(response);
      }

      return getConversation(dispatch, client, conversationId);
    });
};
