/* eslint-disable */

const crypto = require('crypto');

const getEventTimelinePromises = {};
let currentEventTimelineRequests = 0;
let currentEventTimelineQueue = [];

export const list = (dispatch, apiClient, data = {}) => apiClient
  .call({ endpoint: 'event/list', data })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'EVENT_SET', payload: res.data.events });
    return res;
  });

function generateKey(keys) {
  let str = '';
  for (let a = 0; a < keys.length; a += 1) {
    const item = keys[a];
    if (item) {
      if (item.toString) {
        str += item.toString();
      } else {
        str += item;
      }
    }
  }

  return crypto.createHash('md5').update(str).digest('hex');
}

const checkDoGetEventTimelineDone = (dispatch) => {
  if (currentEventTimelineRequests === 0) {
    const toDispath = [];

    for (let a = 0; a < currentEventTimelineQueue.length; a += 1) {
      const item = currentEventTimelineQueue[a];
      const {
        res,
        key,
        resolve,
        reject,
        userId
      } = item;

      if (res.status >= 400) {
        reject(res);
      } else {
        toDispath.push({
          payload: res.data.events,
          userId,
          from: res.data.from,
          to: res.data.to
        });

        resolve(res);
      }

      getEventTimelinePromises[key] = null;
      delete getEventTimelinePromises[key];
    }
    currentEventTimelineQueue = [];

    if (toDispath.length > 0) {
      dispatch({
        type: 'EVENT_SET_TIMELINE_MULTI',
        payload: toDispath
      });
    }
  }
};

export const getEventTimline = (dispatch, apiClient, userId, from, to) => {
  const key = generateKey([userId, from, to]);

  if (getEventTimelinePromises[key]) {
    return getEventTimelinePromises[key];
  }

  const data = {
    CRId: userId,
    from,
    to
  };

  getEventTimelinePromises[key] = new Promise((resolve, reject) => {
    currentEventTimelineRequests += 1;

    getEventTimelinePromises[key] = apiClient
      .call({ endpoint: 'event/listAllEvents', data })
      .then((res) => {
        currentEventTimelineRequests -= 1;
        currentEventTimelineQueue.push({
          res,
          key,
          resolve,
          reject,
          userId
        });
        checkDoGetEventTimelineDone(dispatch);
      }).catch((err) => {
        currentEventTimelineRequests -= 1;
        getEventTimelinePromises[key] = null;
        delete getEventTimelinePromises[key];
        checkDoGetEventTimelineDone(dispatch);
        reject(err);
      });
  });

  return getEventTimelinePromises[key];
};

// @deprecated do not use in new code
export const resolveAlert = (dispatch, apiClient, alertResolveId, eventTime, resolveAction, userId, note) => {
  return (
    apiClient
      .call({ endpoint: 'event/resolveAlert', data: { id: alertResolveId, status: resolveAction, note: note } })
      .then((res) => {
        if (res.status >= 400) {
          throw res;
        }
        if (userId) {
          return getAlerts(dispatch, apiClient, userId);
        }
        return res;
      })
      .catch((err) => {
        console.log('ERROR', err);
      })
  );
};

export const getAlerts = (dispatch, apiClient, userId) => {
  return (
    apiClient
      .call({ endpoint: 'event/getAlerts', data: { careRecipientId: userId, status: ['open', 'attendingto'] } })
      .then((res) => {
        if (res.status !== 200) {
          throw res;
        }
        dispatch({ type: 'ALERT_SET', payload: res.data, userId });
      }).catch((err) => {
        console.log('Failed to get alerts: ', err);
      })
  );
};

// DO NOT REMOVE, MAY NEED IN THE FUTURE
// export const getAllEventsForTimeline = (dispatch, apiClient, userId, to, from) => {
//   const listAllCalendarEventsPromise = (
//     apiClient
//       .call({ endpoint: 'calendar/getEvents', data: { userId } })
//       .then((res) => {
//         if (res.status !== 200) {
//           throw res;
//         } return res.data;
//       })
//   );

//   const listAllUserEventsPromise = (
//     apiClient
//       .call({
//         endpoint: 'event/listUserEvents', data: {
//           crId: userId,
//           from,
//           to,
//         }
//       })
//       .then(res => {
//         if (res.status !== 200) {
//           throw res;
//         } return res;
//       })
//   );

//   const listAllTimelineEventsPromise = (
//     apiClient
//       .call({
//         endpoint: 'event/listAllEvents', data: {
//           CRId: userId,
//           from,
//           to,
//         }
//       })
//       .then(res => {
//         if (res.status !== 200) {
//           throw res;
//         } return res;
//       })
//   );

//   const promises = [listAllCalendarEventsPromise, listAllUserEventsPromise, listAllTimelineEventsPromise];

//   return Promise.all(promises)
//     .then(([listAllCalendarEvents, listAllUserEvents, listAllTimelineEvents]) => {
//       console.log([listAllCalendarEvents, listAllUserEvents, listAllTimelineEvents])
//       dispatch({ type: 'CALENDAR_SET', payload: listAllCalendarEvents, userId });
//       dispatch({ type: 'USER_EVENTS_SET', payload: listAllUserEvents, userId });
//       dispatch({ type: 'EVENT_SET_TIMELINE_MULTI', payload: listAllTimelineEvents, userId });
//     })
// }