const ReminderAlert = {
  ON_TIME: 'ON_TIME',
  MIN_5: 'MIN_5',
  MIN_15: 'MIN_15',
  MIN_30: 'MIN_30',
  HOUR_1: 'HOUR_1',
  HOUR_2: 'HOUR_2',
  DAY_1: 'DAY_1',
};

ReminderAlert.getSecondsFromLegacyReminderAlert = (legacyReminderAlert) => {
  const reminderAlert = ReminderAlert.translateFromLegacyReminderAlert(
    legacyReminderAlert
  );
  return ReminderAlert.getSecondsFromLegacyReminderAlert(reminderAlert);
};

ReminderAlert.getReminderAlertFromOffset = (offset) => {
  switch (offset) {
    case 5 * 60:
      return ReminderAlert.MIN_5;
    case 15 * 60:
      return ReminderAlert.MIN_15;
    case 30 * 60:
      return ReminderAlert.MIN_30;
    case 60 * 60:
      return ReminderAlert.HOUR_1;
    case 60 * 60 * 2:
      return ReminderAlert.HOUR_2;
    case 60 * 60 * 24:
      return ReminderAlert.DAY_1;
    case 0:
    default:
      return ReminderAlert.ON_TIME;
  }
};

ReminderAlert.getOffsetFromReminderAlert = (reminderAlert) => {
  switch (reminderAlert) {
    case ReminderAlert.MIN_5:
      return 5 * 60;
    case ReminderAlert.MIN_15:
      return 15 * 60;
    case ReminderAlert.MIN_30:
      return 30 * 60;
    case ReminderAlert.HOUR_1:
      return 60 * 60;
    case ReminderAlert.HOUR_2:
      return 60 * 60 * 2;
    case ReminderAlert.DAY_1:
      return 60 * 60 * 24;
    case ReminderAlert.ON_TIME:
    default:
      return 0;
  }
};

export default Object.freeze(ReminderAlert);
