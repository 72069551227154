import React, { useState } from 'react';
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@material-ui/core';
import ChooseMedication from './ChooseMedication';
import FillInMedicalInformation from './FillInMedicalInformation';
import CreateReminder from './CreateReminder';
import DisplayResults from './DisplayResults';
import PropTypes from 'prop-types';
import Steppable from 'components/Steppable';
import { whenToTakeOptions } from 'utils/medication';
import { DateTime } from 'luxon';

const useStyles = makeStyles(() => ({
  boldText: {
    fontWeight: 'bold',
  },
}));

function NewMedicalReminder({
  handleSaveButtonClick = () => {},
  onEdit,
  preFilledFormState,
}) {
  const initialFormState = {
    medication: {},
    medicalInformation: {},
    reminder: {},
  };
  const classes = useStyles();
  const formToUse = !onEdit ? initialFormState : preFilledFormState;
  const [form, setForm] = useState(formToUse);
  const steps = getSteps();
  const stepper = Steppable.useSteps(0);

  function getSteps() {
    return [
      'Choose medication',
      'Fill in prescription information',
      'Create reminder',
      'Review information',
    ];
  }

  function handleNext() {
    stepper.toNext();
  }

  function handleBack() {
    stepper.toPrevious();
  }

  function handleEditStep(step) {
    stepper.toActiveStep(step);
  }

  function handleChosenMedication(medication) {
    setForm({ ...form, medication });
    handleNext();
  }

  function handleChosenMedicalInformation(medicalInformation) {
    setForm({ ...form, medicalInformation });
    handleNext();
  }

  function handleChosenReminder(reminder) {
    setForm({ ...form, reminder });
    handleNext();
  }

  function save(values) {
    handleSaveButtonClick(values);
  }

  function HorizontalLinearStepper() {
    function generateTimereminders() {
      const today = DateTime.local().startOf('day');
      const defaultTimes = {};
      const amountOfTimes =
        whenToTakeOptions[form.medicalInformation.WhenToTake] !== undefined
          ? whenToTakeOptions[form.medicalInformation.WhenToTake].alerts
          : 0;

      for (let i = 0; i < amountOfTimes; i++) {
        const name = `reminderTime_${i}`;
        defaultTimes[name] = today.plus({ hours: i + 8 }).toJSDate();
      }

      return defaultTimes;
    }

    const freqTimes = generateTimereminders();
    return (
      <div>
        {/* This typography field should be in the DialogTitle but since the steps are not reachable there, they're put here for now. */}
        <Typography variant="subtitle1" className={classes.boldText}>
          Step {stepper.activeStep + 1} - {steps[stepper.activeStep]}
        </Typography>
        <Stepper activeStep={stepper.activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Steppable.Steps activeStep={stepper.activeStep}>
          <ChooseMedication onChosenMedication={handleChosenMedication} />
          <FillInMedicalInformation
            onChosenMedicalInformation={handleChosenMedicalInformation}
            medication={form.medication}
            medInfo={form.medicalInformation}
            givenData={form.medication}
            prevStep={handleBack}
          />
          <CreateReminder
            onChosenReminder={handleChosenReminder}
            reminderInfo={form.reminder}
            medicalInfo={form.medicalInformation}
            prevStep={handleBack}
            freqTimes={freqTimes}
          />
          <DisplayResults
            formData={form}
            prevStep={handleBack}
            onEditButtonBlick={handleEditStep}
            onSaveButtonClick={save}
          />
        </Steppable.Steps>
      </div>
    );
  }

  return <HorizontalLinearStepper />;
}

NewMedicalReminder.propTypes = {
  handleSaveButtonClick: PropTypes.any.isRequired,
};
export default NewMedicalReminder;
