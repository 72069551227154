/* eslint-disable */

export const getMyCareRecipients = (dispatch, apiClient, activeCareRecipientId, selfUser) =>
  apiClient
    .call({ endpoint: 'user/getMyCareRecipients' })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }

      const recipients = res.data;

      if (activeCareRecipientId) {
        const gotRecipient = recipients.filter(recipient =>
          recipient.userId === activeCareRecipientId);

        if (gotRecipient && gotRecipient.length > 0) {
          dispatch({ type: 'CARE_RECIPIENTS_SET', payload: recipients });
        }
      } else {
        dispatch({ type: 'CARE_RECIPIENTS_SET', payload: recipients });
      }

      return res;
    }).catch((err) => {
      console.log('ERROR', err);
    });

export const setActiveCareRecipient = (dispatch, crId) => {
  dispatch({ type: 'CARE_RECIPIENTS_SET_ACTIVE', crId });
  dispatch({
    type: 'COOKIE_SET',
    key: 'activeCareRecipientId',
    value: crId
  });
};

export const updateCareRecipients = (dispatch, apiClient, activeCareRecipientId, selfUser) =>
  apiClient.call({ endpoint: 'user/getMyCareRecipients' }).then((res) => {
    if (res.status !== 200) {
      throw res;
    }

    const recipients = res.data;

    if (activeCareRecipientId) {
      const gotRecipient = recipients.filter(recipient =>
        recipient.userId === activeCareRecipientId);

      if (gotRecipient && gotRecipient.length > 0) {
        dispatch({ type: 'CARE_RECIPIENTS_SET', payload: recipients });
        dispatch({ type: 'CARE_RECIPIENTS_SET_ACTIVE', crId: activeCareRecipientId });
      } else if (selfUser.level <= 1000) {
        return apiClient.call({ endpoint: 'user/adminGetCareRecipient', data: { CRId: activeCareRecipientId } }).then((adminRes) => {
          dispatch({ type: 'CARE_RECIPIENTS_SET', payload: adminRes.data });
          dispatch({ type: 'CARE_RECIPIENTS_SET_ACTIVE', crId: activeCareRecipientId });
          return adminRes.data;
        });
      }
    } else {
      dispatch({ type: 'CARE_RECIPIENTS_SET', payload: recipients });
    }

    return res;
  });

export const stateUpdatePhone = (dispatch, apiClient, activeCareRecipientId) => {
  dispatch({
    type: 'CARE_RECIPIENTS_UPDATE_KEY_VALUE',
    userId: activeCareRecipientId,
    key: 'statusUpdateDate',
    value: new Date()
  });

  apiClient.call({ endpoint: 'user/stateUpdatePhone', data: { CRId: activeCareRecipientId } }).then((res) => {
    if (res.status !== 200) {
      throw res;
    }

    const dispatchData = {
      type: 'CARE_RECIPIENTS_UPDATE_KEY_VALUE',
      key: 'statusUpdateDate',
      userId: activeCareRecipientId,
      value: res.data.statusUpdateDate
    };

    dispatch(dispatchData);

    return res;
  }).catch((error) => {
    console.log('state update error', error);
  });
};

export const test = (dispatch, activeCareRecipientId) => {
  dispatch({
    type: 'CARE_RECIPIENTS_UPDATE_KEY_VALUE',
    userId: activeCareRecipientId,
    key: 'statusUpdateDate',
    value: new Date()
  });
};

export const getUploadToken = (apiClient, CRId, type, fileName) => {
  const data = {
    CRId,
    type,
    fileName
  };

  return apiClient
    .call({ endpoint: 'user/getCRUploadToken', data })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }

      return res;
    }).catch((err) => {
      throw err;
    });
};

export const updatePhoneSettings = (dispatch, apiClient, userId, settings) => {
  dispatch({ type: 'CARE_RECIPIENTS_SET_PHONESETTINGS', userId, settings });

  return apiClient.call({ endpoint: 'user/setUserPhoneSettings', data: { CRId: userId, settings, notifyPhone: true } })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }

      dispatch({ type: 'CARE_RECIPIENTS_SET_PHONESETTINGS', userId, settings: res.data });
      return res;
    });
};

export const createCareRecipient = (dispatch, apiClient, data) => {
  const {
    username,
    password,
    couponId,
    timezone,
    name
  } = data;
  return apiClient
    .call({
      endpoint: 'user/createCareRecipient',
      data: {
        username,
        password,
        couponId,
        timezone,
        name
      }
    })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      return res;
    }).catch((err) => {
      throw err;
    });
};

export const updateWatchSettings = (dispatch, apiClient, userId, settings) => {
  dispatch({ type: 'CARE_RECIPIENTS_SET_WATCHSETTINGS', userId, settings });

  return apiClient.call({ endpoint: 'user/setWatchSettings', data: { CRId: userId, settings } })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }

      dispatch({ type: 'CARE_RECIPIENTS_SET_WATCHSETTINGS', userId, settings: res.data });
      return res;
    });
};

export const updateGeneralSettings = (dispatch, apiClient, userId, generalSettings) => {
  dispatch({ type: 'CARE_RECIPIENTS_SET_GENERAL_SETTINGS', userId, generalSettings });

  return apiClient.call({ endpoint: 'user/setGeneralSettings', data: { CRId: userId, generalSettings } })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }

      dispatch({ type: 'CARE_RECIPIENTS_SET_GENERAL_SETTINGS', userId, settings: res.data });
      return res;
    });
};

const updateThresholds = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'user/setThresholds',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({
        type: 'CARE_RECIPIENTS_UPDATE_KEY_VALUE',
        userId: data.CRId,
        key: 'thresholds',
        value: res.data
      });
      return res;
    });

export const updateStepThresholds = (dispatch, apiClient, CRId, stepsThresholds) =>
  updateThresholds(dispatch, apiClient, { CRId, steps: stepsThresholds });

export const updateHeartRateLimits = (dispatch, apiClient, CRId, heartrate) =>
  updateThresholds(dispatch, apiClient, { CRId, heartrate });
