export const ServiceRightsGroup = (t = () => {}) => ({
  MANAGE_CARE_RECIPIENT_SETTINGS: {
    title: t('CareGiverSettings.Title.ManageCRSettings'),
    requireServices: [
      'FilesService',
      'UserService',
      'MedicineService',
      'LocationService',
      'StepService',
      'PulseService',
    ],
    checkboxGroup: [
      {
        subTitle: t('CareGiverSettings.SubTitle.ManageCRSettings'),
        label: t('CareGiverSettings.Label.ManageCRSettings'),
        valueGroup: [
          {
            key: 'FilesService',
            value: 'getUploadToken',
          },
          {
            key: 'UserService',
            value: 'setAccountInformationSettings',
          },
        ],
      },
      {
        subTitle: t('CareGiverSettings.SubTitle.WellnessManagement'),
        label: t('CareGiverSettings.Label.WellnessManagement'),
        valueGroup: [
          {
            key: 'UserService',
            value: 'setWellnessInformationSettings',
          },
          {
            key: 'UserService',
            value: 'setActiveHours',
          },
        ],
      },
      {
        subTitle: t('CareGiverSettings.SubTitle.AboutUser'),
        key: 'UserService',
        value: 'setAboutUserInformationSettings',
        label: t('CareGiverSettings.Label.AboutUser'),
      },
      {
        subTitle: t('CareGiverSettings.Title.DeviceSettings'),
        checkboxGroup: [
          {
            key: 'SettingsService',
            value: 'setUserWatchSettings',
            label: t('CareGiverSettings.Label.WatchSettings'),
          },
          {
            key: 'SettingsService',
            value: 'setUserPhoneSettings',
            label: t('CareGiverSettings.Label.PhoneSettings'),
          },
          {
            key: 'BatteryService',
            value: 'getBatteryLevels',
            label: t('CareGiverSettings.Label.Battery'),
          },
        ],
      },
      {
        subTitle: t('CareGiverSettings.SubTitle.Locations'),
        checkboxGroup: [
          {
            label: t('CareGiverSettings.Label.Locations1'),
            correspondingGroupId: 'location-group-1',
            valueGroup: [
              {
                key: 'LocationService',
                value: 'getLocations',
              },
              {
                key: 'LocationService',
                value: 'getEvent',
              },
            ],
          },
          {
            label: t('CareGiverSettings.Label.Locations2'),
            toggleCorrespondingGroupId: 'location-group-1',
            valueGroup: [
              {
                key: 'LocationService',
                value: 'addLocation',
              },
              {
                key: 'LocationService',
                value: 'removeLocation',
              },
              {
                key: 'LocationService',
                value: 'addEvent',
              },
              {
                key: 'LocationService',
                value: 'removeEvent',
              },
              {
                key: 'LocationService',
                value: 'editEvent',
              },
            ],
          },
        ],
      },
      {
        subTitle: t('CareGiverSettings.SubTitle.Medical'),
        checkboxGroup: [
          {
            label: t('CareGiverSettings.Label.Medical1'),
            correspondingGroupId: 'medical-group-1',
            valueGroup: [
              {
                key: 'MedicineService',
                value: 'getMedical',
              },
              {
                key: 'MedicineService',
                value: 'getEvents',
              },
            ],
          },
          {
            label: t('CareGiverSettings.Label.Medical2'),
            toggleCorrespondingGroupId: 'medical-group-1',
            valueGroup: [
              {
                key: 'MedicineService',
                value: 'addEvent',
              },
              {
                key: 'MedicineService',
                value: 'updateMedical',
              },
              {
                key: 'MedicineService',
                value: 'editEvent',
              },
              {
                key: 'MedicineService',
                value: 'removeEvent',
              },
            ],
          },
        ],
      },
      {
        subTitle: t('CareGiverSettings.SubTitle.HeartRateSettings'),
        checkboxGroup: [
          {
            label: t('CareGiverSettings.Label.View'),
            correspondingGroupId: 'heart-rate-group-1',
            valueGroup: [
              {
                key: 'PulseService',
                value: 'getPulses',
              },
              {
                key: 'PulseService',
                value: 'getLastPulse',
              },
              {
                key: 'PulseService',
                value: 'viewHeartRateHistory',
              },
            ],
          },
          {
            label: t('CareGiverSettings.Label.Setup'),
            toggleCorrespondingGroupId: 'heart-rate-group-1',
            valueGroup: [
              {
                key: 'PulseService',
                value: 'addPulse',
              },
              {
                key: 'PulseService',
                value: 'setHeartRateLimits',
              },
            ],
          },
        ],
      },
      {
        subTitle: t('CareGiverSettings.SubTitle.ActivityLevelSettings'),
        checkboxGroup: [
          {
            label: t('CareGiverSettings.Label.View'),
            correspondingGroupId: 'activity-level-group-1',
            valueGroup: [
              {
                key: 'StepService',
                value: 'getSteps',
              },
              {
                key: 'StepService',
                value: 'getLastSteps',
              },
              {
                key: 'StepService',
                value: 'viewStepHistory',
              },
            ],
          },
          {
            label: t('CareGiverSettings.Label.Setup'),
            toggleCorrespondingGroupId: 'activity-level-group-1',
            valueGroup: [
              {
                key: 'StepService',
                value: 'addSteps',
              },
              {
                key: 'StepService',
                value: 'setStepGoalLimits',
              },
            ],
          },
        ],
      },
    ],
  },
  CARE_TEAM: {
    title: t('CareGiverSettings.Title.CareTeam'),
    requireServices: ['UserService'],
    checkboxGroup: [
      {
        label: t('CareGiverSettings.Label.CareTeam'),
        valueGroup: [
          {
            key: 'UserService',
            value: 'setRights',
          },
          {
            key: 'UserService',
            value: 'addCareGiver',
          },
          {
            key: 'UserService',
            value: 'removeCareGiver',
          },
          {
            key: 'UserService',
            value: 'userUpdate',
          },
          {
            key: 'UserService',
            value: 'setNotificationSettings',
          },
        ],
      },
    ],
  },
  CONTACTS: {
    title: t('CareGiverSettings.Title.Contacts'),
    requireServices: ['ContactService'],
    checkboxGroup: [
      {
        label: t('CareGiverSettings.Label.Contacts'),
        valueGroup: [
          {
            key: 'ContactService',
            value: 'getContact',
          },
          {
            key: 'ContactService',
            value: 'getContacts',
          },
          {
            key: 'ContactService',
            value: 'addContact',
          },
          {
            key: 'ContactService',
            value: 'editContact',
          },
          {
            key: 'ContactService',
            value: 'removeContact',
          },
          {
            key: 'ContactService',
            value: 'setImage',
          },
        ],
      },
    ],
  },
  CALLS: {
    title: t('CareGiverSettings.Title.Calls'),
    requireServices: ['CallService'],
    checkboxGroup: [
      {
        label: t('CareGiverSettings.Label.Calls'),
        valueGroup: [
          {
            key: 'CallService',
            value: 'callVideo',
          },
          {
            key: 'CallService',
            value: 'callVideoBreakthrough',
          },
          {
            key: 'CallService',
            value: 'callAudio',
          },
          {
            key: 'CallService',
            value: 'callAudioBreakthrough',
          },
        ],
      },
    ],
  },
  EVENTS: {
    title: t('CareGiverSettings.Title.Events'),
    requireServices: ['EventService'],
    checkboxGroup: [
      {
        label: t('CareGiverSettings.Label.Events1'),
        correspondingGroupId: 'event-service-group-1',
        key: 'EventService',
        value: 'getEvents',
      },
      {
        label: t('CareGiverSettings.Label.Events2'),
        toggleCorrespondingGroupId: 'event-service-group-1',
        key: 'EventService',
        value: 'resolveAlerts',
      },
    ],
  },
  GROUP_CHAT: {
    title: t('CareGiverSettings.Title.GroupChat'),
    requireServices: ['NotesService'],
    checkboxGroup: [
      {
        label: t('CareGiverSettings.Label.GroupChat'),
        key: 'NotesService',
        value: 'notes',
      },
    ],
  },
  CALENDAR: {
    title: t('CareGiverSettings.Title.Calendar'),
    requireServices: ['CalenderService'],
    checkboxGroup: [
      {
        label: t('CareGiverSettings.Label.Calendar1'),
        key: 'CalenderService',
        value: 'getEvents',
      },
      {
        label: t('CareGiverSettings.Label.Calendar2'),
        valueGroup: [
          {
            key: 'CalenderService',
            value: 'addGeneralEvent',
          },
          {
            key: 'CalenderService',
            value: 'getGeneralEvent',
          },
          {
            key: 'CalenderService',
            value: 'removeGeneralEvent',
          },
          {
            key: 'CalenderService',
            value: 'editGeneralEvent',
          },
        ],
      },
    ],
  },
  WELLNESS_CHECKS: {
    title: t('CareGiverSettings.Title.WellnessChecks'),
    requireServices: ['WellnessService'],
    checkboxGroup: [
      {
        label: t('CareGiverSettings.Label.WellnessChecks1'),
        key: 'WellnessService',
        value: 'getEvent',
        correspondingGroupId: 'wellness-checks-group-1',
      },
      {
        label: t('CareGiverSettings.Label.WellnessChecks2'),
        toggleCorrespondingGroupId: 'wellness-checks-group-1',
        valueGroup: [
          {
            key: 'WellnessService',
            value: 'addEvent',
          },
          {
            key: 'WellnessService',
            value: 'editEvent',
          },
          {
            key: 'WellnessService',
            value: 'removeEvent',
          },
        ],
      },
    ],
  },
});
