import React, { useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import User from 'entities/user';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import makeHSL from 'styles/makeHSL';
import { logout } from 'actions/user';
import { useActiveCareRecipientSelector } from 'reducers/carerecipients';

const useStyles = makeStyles({
  root: {
    minWidth: 25,
  },

  profileImage: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: 'hsl(184, 55%, 57%)',
    width: 50,
    height: 50,
  },

  ListItem: {
    backgroundColor: '#F0F0F0',
    height: 35,
  },

  textAndIconColor: {
    color: makeHSL({ hue: 211, saturation: 0.15, lightness: 0.46 }),
  },

  userCircleIcon: {
    marginRight: 10,
  },
});

const CaregiverProfileDropdown = (_, context) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);
  const dispatch = useDispatch();

  const currentUser = useSelector(
    (state) => state.getIn(['user', 'self']),
    shallowEqual
  );

  const user = User(currentUser);
  const activeCareRecipient = useActiveCareRecipientSelector();
  const isRecipient = activeCareRecipient.userId === user.id;

  function handleClick(event) {
    setAnchor(event.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  function handeCaregiverLogout() {
    logout(dispatch, context.apiClient);
  }

  const id = 'CaregiverProfileDropdown';

  return (
    <React.Fragment>
      <Button
        aria-owns={anchor ? id : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
      >
        <FontAwesomeIcon
          size="lg"
          icon={['fal', 'user-circle']}
          className={classes.userCircleIcon}
        />
        <FontAwesomeIcon size="sm" icon={['fal', 'chevron-down']} />
      </Button>

      <Menu
        id={id}
        anchorEl={anchor}
        getContentAnchorEl={null}
        variant="menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        <List disablePadding>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="profile-image"
                src={user.profileImage}
                className={classes.profileImage}
              />
            </ListItemAvatar>
            <ListItemText
              primary={user.name.fullName}
              secondary={
                isRecipient ? 'Care Recipient' : 'Professional Caregiver'
              }
            />
          </ListItem>

          <Divider />
          <ListItem
            button
            component={Link}
            to="/inbox"
            onClick={() => handleClose()}
          >
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon="inbox"
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>
            <ListItemText
              primary="Inbox"
              className={classes.textAndIconColor}
            />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/settings"
            onClick={() => handleClose()}
          >
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon="id-card"
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>
            <ListItemText
              primary="Profile Settings"
              className={classes.textAndIconColor}
            />
          </ListItem>

          <Divider />
          {isRecipient && (
            <ListItem
              button
              component={Link}
              to="/cr-settings"
              onClick={() => handleClose()}
            >
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon="cog"
                  size="1x"
                  className={classes.textAndIconColor}
                />
              </ListItemIcon>
              <ListItemText
                primary="Settings & Medical Profile"
                className={classes.textAndIconColor}
              />
            </ListItem>
          )}
          <ListItem
            button
            component={Link}
            to="/notification-settings"
            onClick={() => handleClose()}
          >
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon="bells"
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>
            <ListItemText
              primary="Notification Preferences"
              className={classes.textAndIconColor}
            />
          </ListItem>
          {isRecipient && (
            <ListItem
              button
              component={Link}
              to="/caregivers"
              onClick={() => handleClose()}
            >
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon="users"
                  size="1x"
                  className={classes.textAndIconColor}
                />
              </ListItemIcon>
              <ListItemText
                primary="My Care Team"
                className={classes.textAndIconColor}
              />
            </ListItem>
          )}

          <Divider />

          <ListItem button onClick={() => handeCaregiverLogout()}>
            <ListItemIcon className={classes.root}>
              <FontAwesomeIcon
                icon="sign-out-alt"
                size="1x"
                className={classes.textAndIconColor}
              />
            </ListItemIcon>
            <ListItemText
              primary="Sign out"
              className={classes.textAndIconColor}
            />
          </ListItem>
        </List>
      </Menu>
    </React.Fragment>
  );
};

CaregiverProfileDropdown.contextTypes = {
  apiClient: PropTypes.object.isRequired,
};

CaregiverProfileDropdown.propTypes = {
  onCaregiverProfileNavigationChange: PropTypes.func,
};

export default CaregiverProfileDropdown;
