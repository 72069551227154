import {
  faInbox as fasInbox,
  faInfoCircle as fasInfoCircle,
  faHeart as fasFaHeart,
  faBatteryFull as fasFaBatteryFull,
  faBatterySlash as fasFaBatterySlash,
  faBatteryEmpty as fasFaBatteryEmpty,
  faBatteryQuarter as fasFaBatteryQuarter,
  faBatteryHalf as fasFaBatteryHalf,
  faBatteryThreeQuarters as fasFaBatteryThreeQuarters,
  faBolt as fasFaBolt,
  faForward as fasForward,
  faChevronDown as fasFaChevronDown,
  faChevronLeft as fasFaChevronLeft,
  faChevronRight as fasFaChevronRight,
  faConciergeBell as fasFaConciergeBell,
  faExclamationCircle as fasFaExclamationCircle,
  faExclamationTriangle as fasFaExclamationTriangle,
  faBell as fasFaBell,
  faPlus as fasFaPlus,
  faCalendar as fasFaCalendar,
  faCalendarAlt as fasFaCalendarAlt,
  faHome as fasFaHome,
  faCalendarDay as fasFaCalendarDay,
  faCalendarWeek as fasFaCalendarWeek,
  faMapMarkerCheck as fasFaMapMarkerCheck,
  faMapMarkerAlt as fasFaMapMarkerAlt,
  faSignalAlt3 as fasFaSignalAlt3,
  faAlarmClock as fasFaAlarmClock,
  faAddressBook as fasFaAddressBook,
  faUserNurse as fasFaUserNurse,
  faUserTimes as fasFaUserTimes,
  faCameraAlt as fasCameraAlt,
  faCog as fasFaCog,
  faCommentCheck as fasFaCommentCheck,
  faCommentTimes as fasFaCommentTimes,
  faCommentDots as fasFaCommentDots,
  faCommentAlt as fasFaCommentAlt,
  faComments as fasFaComment,
  faIdCard as fasFaIdCard,
  faUsers as fasFaUsers,
  faBells as fasFaBells,
  faTaxi as fasFaTaxi,
  faLifeRing as fasFaLifeRing,
  faSignOutAlt as fasFaSignOutAlt,
  faLightbulbOn as fasFaLightbulbOn,
  faStream as fasFaStream,
  faListUl as fasFaListUl,
  faTable as fasFaTable,
  faFilter as fasFaFilter,
  faPhoneAlt as fasPhoneAlt,
  faReply as fasReply,
  faShieldAlt as fasShieldAlt,
  faSearchLocation as fasSearchLocation,
  faPrescriptionBottleAlt as fasFaPrescriptionBottleAlt,
  faLayerGroup as fasFaLayerGroup,
  faPaperPlane as fasPaperPlane,
  faPen as fasPen,
  faMailBulk as fasMailBulk,
  faFrown as fasFrown,
  faCircle as fasCircle,
  faClock as fasClock,
  faPrescriptionBottleAlt as fasPrescriptionBottleAlt,
  faShoePrints as fasShoePrints,
  faEnvelopeOpenText as fasEnvelopeOpenText,
  faUserFriends as fasFaUserFriends,
  faTshirt as fasFaTshirt,
  faUtensils as fasFaUtensils,
  faCar as fasFaCar,
  faPrescriptionBottle as fasFaPrescriptionBottle,
  faShoppingBag as fasFaShoppingBag,
  faCheckCircle as fasFaCheckCircle,
  faInfoSquare as fasInfoSquare,
  faHandReceiving as fasHandReceiving,
  faAddressCard as fasAddressCard,
  faSlidersH as fasSlidersH,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCapsules as farCapsules,
  faCompass as farFaCompass,
  faCircle as farCircle,
  faClock as farClock,
  faUserLock as farFaUserLock,
  faHeart as farFaHeart,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faPhone as falFaPhone,
  faVideo as falFaVideo,
  faAddressBook as falFaAddressBook,
  faInbox as falFaInbox,
  faBell as falFaBell,
  faCalendar as falFaCalendarAlt,
  faBells as falFaBells,
  faFont as falFaFont,
  faHeartbeat as falFaHeartbeat,
  faHandshake as falFaHandshake,
  faHomeHeart as falFaHomeHeart,
  faLifeRing as falFaLifeRing,
  faMapMarkerAlt as falFaMapMarkerAlt,
  faMapMarkerAltSlash as falFaMapMarkerAltSlash,
  faMapMarkedAlt as falFaMapMarkedAlt,
  faUserCircle as falFaUserCircle,
  faQuestionCircle as falFaQuestionCircle,
  faChevronDown as falFaChevronDown,
  faBars as falFaBars,
  faChevronRight as falFaChevronRight,
  faShoePrints as falFaShoePrints,
  faSpa as falFaSpa,
  faStream as falFaStream,
  faPrescriptionBottleAlt as falFaPrescriptionBottleAlt,
  faInfoCircle as falFaInfoCircle,
  faLaughBeam as falFaLaughBeam,
  faLayerGroup as falFaLayerGroup,
  faPhoneLaptop as falFaPhoneLaptop,
  faExclamationCircle as falFaExclamationCircle,
  faExclamationTriangle as falFaExclamationTriangle,
  faHomeLgAlt as falFaHomeLgAlt,
  faWalking as falFaWalking,
  faSmileBeam as falFaSmileBeam,
  faFrown as falFaFrown,
  faMeh as falFaMeh,
  faMobile as falMobile,
  faPhoneVolume as falPhoneVolume,
  faUserClock as falUserClock,
  faAmbulance as falAmbulance,
  faCapsules as falCapsules,
  faBatteryQuarter as falBatteryQuarter,
  faBatteryThreeQuarters as falBatteryThreeQuarters,
  faSignOutAlt as falSignOutAlt,
  faHospital as falHospital,
  faWatch as falWatch,
} from '@fortawesome/pro-light-svg-icons';

export default {
  // 'pro solid icons'
  fasFaBell,
  fasInbox,
  fasInfoCircle,
  fasFaHeart,
  fasFaBatteryEmpty,
  fasFaBatteryQuarter,
  fasFaBatteryHalf,
  fasFaBatteryThreeQuarters,
  fasFaBatteryFull,
  fasFaBatterySlash,
  fasFaBolt,
  fasFaChevronDown,
  fasFaChevronLeft,
  fasFaChevronRight,
  fasFaConciergeBell,
  fasFaExclamationCircle,
  fasFaExclamationTriangle,
  fasForward,
  fasFaCommentCheck,
  fasFaCommentTimes,
  fasFaCommentDots,
  fasFaCommentAlt,
  fasFaComment,
  fasFaPlus,
  fasFaHome,
  fasFaSignalAlt3,
  fasFaAlarmClock,
  fasFaAddressBook,
  fasFaUserNurse,
  fasFaUserTimes,
  fasFaMapMarkerCheck,
  fasFaMapMarkerAlt,
  fasFaUsers,
  fasCameraAlt,
  fasFaCog,
  fasFaIdCard,
  fasFaCalendar,
  fasFaCalendarAlt,
  fasFaCalendarDay,
  fasFaCalendarWeek,
  fasFaBells,
  fasFaTaxi,
  fasFaLifeRing,
  fasFaSignOutAlt,
  fasFaLightbulbOn,
  fasFaStream,
  fasFaListUl,
  fasFaTable,
  fasFaFilter,
  fasPhoneAlt,
  fasShieldAlt,
  fasSearchLocation,
  fasReply,
  fasFaPrescriptionBottleAlt,
  fasFaLayerGroup,
  fasPaperPlane,
  fasPen,
  fasMailBulk,
  fasFrown,
  fasCircle,
  fasClock,
  fasPrescriptionBottleAlt,
  fasShoePrints,
  fasEnvelopeOpenText,
  fasFaUserFriends,
  fasFaTshirt,
  fasFaUtensils,
  fasFaCar,
  fasFaPrescriptionBottle,
  fasFaShoppingBag,
  fasFaCheckCircle,
  fasInfoSquare,
  fasHandReceiving,
  fasAddressCard,
  fasSlidersH,

  // 'pro regular icons'
  farCapsules,
  farFaCompass,
  farCircle,
  farClock,
  farFaUserLock,
  farFaHeart,

  // 'pro light icons'
  falFaPhone,
  falFaVideo,
  falFaAddressBook,
  falFaInbox,
  falFaBell,
  falFaCalendarAlt,
  falFaBells,
  falFaFont,
  falFaHeartbeat,
  falFaHandshake,
  falFaHomeHeart,
  falFaLifeRing,
  falFaMapMarkerAlt,
  falFaMapMarkerAltSlash,
  falFaMapMarkedAlt,
  falFaUserCircle,
  falFaQuestionCircle,
  falFaChevronDown,
  falFaBars,
  falFaChevronRight,
  falFaShoePrints,
  falFaSpa,
  falFaStream,
  falFaPrescriptionBottleAlt,
  falFaInfoCircle,
  falFaLaughBeam,
  falFaLayerGroup,
  falFaPhoneLaptop,
  falFaExclamationCircle,
  falFaExclamationTriangle,
  falFaHomeLgAlt,
  falFaWalking,
  falFaSmileBeam,
  falFaFrown,
  falFaMeh,
  falMobile,
  falPhoneVolume,
  falUserClock,
  falAmbulance,
  falCapsules,
  falBatteryQuarter,
  falBatteryThreeQuarters,
  falSignOutAlt,
  falHospital,
  falWatch,
};
