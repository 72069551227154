/* eslint-disable */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import style from './style.module.scss';

export const Tabs = ({ children, ...props }) => (
  <MuiTabs
    indicatorColor="primary"
    textColor="primary"
    variant="fullWidth"
    {...props}
  >
    {children}
  </MuiTabs>
);

export const Tab = ({ children, ...props }) => (<MuiTab {...props}>{children}</MuiTab>);

// export const Tabs = withStyles(() => ({
//   root: {
//     backgroundColor: '#c6c6c6'
//   },
//   indicator: {
//     backgroundColor: '#ffffff',
//     height: '100%'
//   }
// }))(MuiTabs);

// export const Tab = withStyles(() => ({
//   root: {
//     zIndex: 1,
//     color: '#ffffff',
//     textTransform: 'none',
//     flex: 1,
//     maxWidth: '100%',
//     transition: 'color ease 0.3s'
//   },
//   selected: {
//     color: '#777777'
//   }
// }))(MuiTab);

export const TabContent = props => (
  <div {...props} className={`${style.tabContent} ${props.className || ''}`}>
    {props.children}
  </div>
);

export default {};
