import React from 'react';
import { setConfig } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import { I18nextProvider } from 'react-i18next';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import 'isomorphic-fetch';
import AppFrame from './AppFrame';
import i18next from './i18n';

import leapthruMuiTheme from './theme';

window.$crisp = [];
window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_ID;
var d = document;
var s = d.createElement('script');

s.src = 'https://client.crisp.chat/l.js';
s.async = 1;
d.getElementsByTagName('head')[0].appendChild(s);

const App = () => {
  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={leapthruMuiTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <AppFrame />
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
};

if (process.env.NODE_ENV === 'development') {
  setConfig({
    reloadHooks: false,
  });
}

// eslint-disable-next-line
export default process.env.NODE_ENV === 'development' ? hot(App) : App;
