const Frequency = {
  NEVER: 'NEVER',
  EVERY_DAY: Object.freeze({
    ONE: 'EVERY_DAY.ONE',
    TWO: 'EVERY_DAY.TWO',
    THREE: 'EVERY_DAY.THREE',
    FOUR: 'EVERY_DAY.FOUR',
  }),
  EVERY_WEEK: Object.freeze({
    ONE: 'EVERY_WEEK.ONE',
  }),
  EVERY_MONTH: Object.freeze({
    ONE: 'EVERY_MONTH.ONE',
  }),
};

Frequency.asList = () =>
  [Frequency.NEVER]
    .concat(Object.values(Frequency.EVERY_DAY))
    .concat(Object.values(Frequency.EVERY_WEEK))
    .concat(Object.values(Frequency.EVERY_MONTH));

Frequency.toNumber = (frequency) => {
  switch (frequency) {
    case Frequency.EVERY_DAY.ONE:
    case Frequency.EVERY_WEEK.ONE:
    case Frequency.EVERY_MONTH.ONE:
      return 1;
    case Frequency.EVERY_DAY.TWO:
      return 2;
    case Frequency.EVERY_DAY.THREE:
      return 3;
    case Frequency.EVERY_DAY.FOUR:
      return 4;
    default:
      return 1;
  }
};

Frequency.fromRecurrenceNumber = (recurrence, number = 0) => {
  switch (recurrence.toUpperCase()) {
    case 'DAILY': {
      switch (number) {
        case 1:
          return Frequency.EVERY_DAY.ONE;
        case 2:
          return Frequency.EVERY_DAY.TWO;
        case 3:
          return Frequency.EVERY_DAY.THREE;
        case 4:
          return Frequency.EVERY_DAY.FOUR;
        default:
          return Frequency.NEVER;
      }
    }
    case 'WEEKLY':
      return Frequency.EVERY_WEEK.ONE;
    case 'MONTHLY':
      return Frequency.EVERY_MONTH.ONE;
    default:
      return Frequency.NEVER;
  }
};

export default Object.freeze(Frequency);
