// reference for more length converters: https://www.w3schools.com/howto/howto_js_length_converter.asp
export const millimeterToFeet = (mm) => mm * 0.0032808398950131;
export const millimeterToInches = (mm) => Math.round(mm * 0.039370078740157);
export const millimeterToFeetInches = (mm) => {
  let inches = millimeterToInches(mm);
  const feet = Math.floor(inches / 12);
  inches %= 12;
  return { feet: Math.round(feet), inches: Math.round(inches) };
};
export const millimeterToFeetFormatted = (mm) => {
  const measure = millimeterToFeetInches(mm);
  return `${measure.feet}' ${measure.inches}"`;
};
export const feetToMillimeters = (feet) => feet / 0.0032808398950131;
export const inchesToMillimeters = (inches) => inches / 0.039370078740157;

// reference for more weight converters: https://www.w3schools.com/howto/howto_js_weight_converter.asp
export const gramToPounds = (g) => Math.round(g * 0.0022046 * 100) / 100;
export const poundsToGrams = (lbs) => lbs / 0.0022046;

export const toPercentage = (number) => Math.round(Number(number) * 100);

export default {};
