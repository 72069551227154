import React, { useState } from 'react';
import {
  MenuItem,
  FormControlLabel,
  TextField,
  Button,
  Grid,
  Switch,
} from '@material-ui/core';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import { DateTime } from 'luxon';
import {
  refillIntervalOptions,
  refillIntervalKeys,
  whenToTakeOptions,
} from 'utils/medication';
import getOrDefault from 'utils/safe';

function CreateReminder({
  onChosenReminder = () => {},
  prevStep,
  reminderInfo,
  medicalInfo,
  freqTimes,
}) {
  const today = DateTime.local().startOf('day');

  const formState = {
    ...freqTimes,
    startDate: today.toJSDate(),
    endDate: null,
    ongoing: true,
    refills: false,
    refillAmount: 0,
    refillReminderDate: null,
    refillReminderTime: null,
    refillFrequency: '',
  };

  const usedState =
    Object.keys(reminderInfo).length === 0 ? formState : reminderInfo;
  const [values, setValues] = useState(usedState);
  const refillAmounts = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ];

  const getMinimumEndDateStartingFromDate = (date) => {
    const minimumEndDate = date ? DateTime.fromJSDate(date) : today;
    return minimumEndDate.plus({ days: 1 }).toJSDate();
  };

  const handleInputOnChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setValues({ ...values, [name]: value });
  };

  const handleOnOngoingReminderChange = (event) => {
    const value = {
      ...values,
      [event.target.name]: event.target.checked,
    };

    if (event.target.checked) {
      return setValues({
        ...value,
        endDate: null,
      });
    }

    return setValues(value);
  };

  const handleEndDateInputChange = (date) => {
    const value = DateTime.fromJSDate(date).startOf('day').toJSDate();

    setValues({ ...values, endDate: value });
  };

  const getAllReminderTimes = (values) => {
    const reminderTimes = {};

    for (var item in values) {
      if (item.startsWith('reminderTime')) {
        reminderTimes[item] = values[item];
      }
    }

    return reminderTimes;
  };

  const handleDateTimeInputChange = ({ dateKey, date, allTimes }) => {
    const dateValue = DateTime.fromJSDate(date).startOf('day');
    const changedTimes = {};

    if (allTimes !== []) {
      for (var item in allTimes) {
        const { hour, minute } = DateTime.fromJSDate(allTimes[item]);
        const timeValue = dateValue //
          .set({ hour, minute }) //
          .toJSDate();
        changedTimes[item] = timeValue;
      }
    }
    const stateCopy = {
      [dateKey]: dateValue.toJSDate(),
      ...changedTimes,
    };
    setValues({ ...values, ...stateCopy });
  };

  const handleTimeInputChange = ({ date, dateKey, timeKey, time }) => {
    const safeTime = time === null ? DateTime.local().toJSDate() : time;
    const { hour, minute } = DateTime.fromJSDate(safeTime);
    const dateValue = DateTime.fromJSDate(date);
    const timeValue = dateValue //
      .set({ hour, minute }) //
      .toJSDate();

    setValues({
      ...values,
      [timeKey]: timeValue,
      [dateKey]: dateValue.startOf('day').toJSDate(),
    });
  };

  const handleReminderDateInputChange = (date) => {
    const allTimes = getAllReminderTimes(values);
    handleDateTimeInputChange({
      dateKey: 'startDate',
      date,
      allTimes,
    });
  };

  const handleRefillReminderDateInputChange = (date) =>
    handleDateTimeInputChange({
      dateKey: 'refillReminderDate',
      date,
      timeKey: 'refillReminderTime',
      time: values.refillReminderTime,
    });

  const handleReminderTimeInputChange = (name, value) => {
    const time = value;
    handleTimeInputChange({
      dateKey: 'startDate',
      date: values.startDate,
      timeKey: name,
      time,
    });
  };

  const handleRefillReminderTimeInputChange = (time) =>
    handleTimeInputChange({
      dateKey: 'refillReminderDate',
      date: values.refillReminderDate,
      timeKey: 'refillReminderTime',
      time,
    });

  function RenderAmountOfReminderTimes({ frequency }) {
    const amountOfTimes = whenToTakeOptions[frequency].alerts;
    const timeFields = [];

    for (let i = 0; i < amountOfTimes; i++) {
      const name = `reminderTime_${i}`;
      timeFields.push(
        <Grid item xs={12} sm={12} key={i}>
          <TimePicker
            fullWidth
            keyboard
            clearable
            required
            disable={values.startDate === null}
            label={i === 1 ? 'Reminder time' : `Reminder time ${i + 1}`}
            name={name}
            onChange={(value) =>
              handleReminderTimeInputChange(
                name,
                getOrDefault(() => value.toDate(), null)
              )
            }
            value={values[name]}
            mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
            variant="outlined"
          />
        </Grid>
      );
    }
    return timeFields;
  }

  function checkDisabled(allTimes, frequency) {
    const amountOfTimes = whenToTakeOptions[frequency].alerts;

    for (let i = 0; i < Object.keys(allTimes).length; i++) {
      if (i + 1 === amountOfTimes) {
        return true;
      }
    }
    return false;
  }

  return (
    <div>
      <form>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <DatePicker
              fullWidth
              keyboard
              clearable
              autoOk
              required
              disableOpenOnEnter
              label="Start date"
              key="startDate"
              value={values.startDate}
              name="startDate"
              onChange={(value) =>
                handleReminderDateInputChange(
                  getOrDefault(() => value.toDate(), today.toJSDate())
                )
              }
              format="MM/DD/YYYY"
              mask={(value) =>
                value
                  ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                  : []
              }
              animateYearScrolling={false}
              minDate={today.toJSDate()}
              variant="outlined"
            />
          </Grid>
          {values.ongoing ||
            (values.startDate !== null && (
              <Grid item xs={12} sm={12}>
                <DatePicker
                  fullWidth
                  keyboard
                  clearable
                  autoOk
                  disableOpenOnEnter
                  label="End date"
                  key="endDate"
                  value={values.endDate}
                  name="endDate"
                  required={!values.ongoing}
                  onChange={(value) =>
                    handleEndDateInputChange(
                      getOrDefault(
                        () => value.toDate(),
                        getMinimumEndDateStartingFromDate()
                      )
                    )
                  }
                  format="MM/DD/YYYY"
                  mask={(value) =>
                    value
                      ? [
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]
                      : []
                  }
                  animateYearScrolling={false}
                  minDate={getMinimumEndDateStartingFromDate(values.startDate)}
                  variant="outlined"
                />
              </Grid>
            ))}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.ongoing}
                  name="ongoing"
                  onChange={handleOnOngoingReminderChange}
                  value={String(formState.ongoing)}
                  color="primary"
                />
              }
              label="Ongoing reminder"
            />
          </Grid>
          <RenderAmountOfReminderTimes frequency={medicalInfo.WhenToTake} />
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.refills}
                  name="refills"
                  onChange={handleInputOnChange}
                  value={String(formState.refills)}
                  color="primary"
                />
              }
              label="Medicine refill reminder"
            />
          </Grid>
          {values.refills && (
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Refill Amount"
                  value={values.refillAmount}
                  name="refillAmount"
                  defaultValue={1}
                  required={values.refills ? true : false}
                  onChange={handleInputOnChange}
                  variant="outlined"
                >
                  <MenuItem key={0} value={0}>
                    None
                  </MenuItem>
                  {refillAmounts.map((w) => (
                    <MenuItem key={w.label} value={w.value}>
                      {w.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  required={values.refills ? true : false}
                  label="Refill frequency"
                  value={values.refillFrequency}
                  name="refillFrequency"
                  onChange={handleInputOnChange}
                  variant="outlined"
                >
                  {refillIntervalKeys.map((o) => (
                    <MenuItem key={o} value={o}>
                      {refillIntervalOptions[o].label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  fullWidth
                  keyboard
                  clearable
                  autoOk
                  required={values.refills ? true : false}
                  disableOpenOnEnter
                  label="Refill start date"
                  key="refillReminderDate"
                  value={values.refillReminderDate}
                  name="refillReminderDate"
                  disabled={values.ongoing ? false : false}
                  onChange={(value) =>
                    handleRefillReminderDateInputChange(
                      getOrDefault(() => value.toDate(), today.toJSDate())
                    )
                  }
                  format="MM/DD/YYYY"
                  mask={(value) =>
                    value
                      ? [
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]
                      : []
                  }
                  animateYearScrolling={false}
                  minDate={today.toJSDate()}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimePicker
                  fullWidth
                  keyboard
                  clearable
                  required={!!values.refills}
                  label="Refill time"
                  name="refillReminderTime"
                  disabled={values.refillReminderDate === null}
                  onChange={(value) =>
                    handleRefillReminderTimeInputChange(
                      getOrDefault(() => value.toDate(), null)
                    )
                  }
                  value={values.refillReminderTime}
                  mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={prevStep}
            >
              BACK
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              disabled={
                !values.startDate ||
                (!values.ongoing && !values.endDate) ||
                !checkDisabled(
                  getAllReminderTimes(values),
                  medicalInfo.WhenToTake
                ) ||
                (values.refills && !values.refillAmount) ||
                (values.refills && !values.refillReminderDate) ||
                (values.refills && !values.refillFrequency) ||
                (values.refills && !values.refillReminderDate)
              }
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => onChosenReminder(values)}
            >
              NEXT
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default CreateReminder;
