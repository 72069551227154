import { createStore, applyMiddleware } from 'redux';
import { Iterable } from 'immutable';

import { rootReducer } from 'reducers/rootReducer';
import { createLogger } from 'redux-logger';

const configureLogger = () => {
  const stateTransformer = (state) =>
    Iterable.isIterable(state) ? state.toJS() : state;
  const collapsed = (getState, action, logEntry) => !logEntry.error;

  return createLogger({
    stateTransformer,
    collapsed,
  });
};

const createStoreFromInitialState = (initialState) => {
  if (process.env.NODE_ENV !== 'production') {
    const logger = configureLogger();
    return createStore(rootReducer, initialState, applyMiddleware(logger));
  }

  return createStore(rootReducer, initialState);
};

export const configureStore = (initialState) => {
  const store = createStoreFromInitialState(initialState);

  if (module.hot) {
    module.hot.accept('./reducers/rootReducer', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};
