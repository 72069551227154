import { DateTime } from 'luxon';

export const RECEIVE_ACTIVITY_LEVEL = 'RECEIVE_ACTIVITY_LEVEL';
function receiveActivityLevels(payload) {
  return {
    type: RECEIVE_ACTIVITY_LEVEL,
    payload,
  };
}

export function fetchActivityLevels(dispatch, apiClient, userId, from, to) {
  return function () {
    apiClient
      .call({
        endpoint: 'steps/getSteps',
        data: {
          userId,
          from,
          to,
        },
      })
      .then((res) => {
        if (res.status >= 400) {
          throw res;
        }
        const data = res.data
          .map((datum) => ({
            steps: datum.steps,
            eventTime: DateTime.fromISO(datum.slotEnd).toFormat('x'),
            device: datum.source,
          }))
          .reduce((previous, current) => {
            if (previous.length === 0) {
              previous.push(current);
            } else {
              previous.push({
                ...current,
                steps: current.steps + previous[previous.length - 1].steps,
              });
            }
            return previous;
          }, []);

        dispatch(receiveActivityLevels(data));
      });
  };
}

export function getStepsTimeline(dispatch, apiClient, userId, from, to) {
  return apiClient
    .call({
      endpoint: 'steps/getSteps',
      data: {
        userId,
        from,
        to,
      },
    })
    .then((res) => {
      if (res.status >= 400) {
        console.log('Error: ', res);
        return null;
      }

      return res.data;
    })
    .catch((error) => {
      console.log('Error: ', error);
      return null;
    });
}
