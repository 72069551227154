import { DateTime } from 'luxon';

export const RECEIVE_HEART_RATE_LEVEL = 'RECEIVE_HEART_RATE_LEVEL';

function receiveHeartRateLevels(payload) {
  return {
    type: RECEIVE_HEART_RATE_LEVEL,
    payload,
  };
}

export function fetchHeartRateLevels(dispatch, apiClient, userId, from, to) {
  return function () {
    apiClient
      .call({
        endpoint: 'pulses/getPulses',
        data: {
          userId,
          from,
          to,
        },
      })
      .then((res) => {
        if (res.status >= 400) {
          throw res;
        }

        const data = res.data.map((datum) => ({
          eventTime: DateTime.fromISO(datum.eventTime).toFormat('x'),
          pulse: datum.pulse,
        }));

        dispatch(receiveHeartRateLevels(data));
      })
      .catch((error) => {
        console.error('Error: ', error);
        return null;
      });
  };
}
export function getHeartRatesTimeline(dispatch, apiClient, userId, from, to) {
  return apiClient
    .call({
      endpoint: 'pulses/getPulses',
      data: {
        userId,
        from,
        to,
      },
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }

      return res.data;
    })
    .catch((error) => {
      console.error('Error: ', error);
      return null;
    });
}
