/* eslint-disable */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Icon, Button as MuiButton } from '@material-ui/core';

const Button = withStyles(() => ({
  root: {
    color: '#777777',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none'
    }
  }
}))(MuiButton);

const IconButton = ({ color, ...props }) => (
  <Button {...props}>
    <Icon {...(color ? { style: { color } } : {})}>{props.icon}</Icon>
    {props.children}
  </Button>
);

export default IconButton;
