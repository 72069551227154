import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RRule from 'rrule';
import { DateTime } from 'luxon';
import get from 'lodash.get';
import {
  addMedicineEvent,
  editMedicineEvent,
  searchConditions,
} from 'actions/medicine';
import { filterContacts } from 'reducers/contacts';
import NewMedicalReminder from './NewMedicalReminder';
import getOrDefault from 'utils/safe';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import {
  DialogContent,
  Divider,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import {
  parseEventForEdit,
  whenToTakeOptions,
  refillIntervalOptions,
} from 'utils/medication';
import { dateInRruleDateFormat } from 'utils/rruleWrapper';
import { withTranslation } from 'react-i18next';

import InfoTooltip from '../../components/InfoTooltip';
const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titlemargin: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  infoTooltip: {
    padding: theme.spacing(2, 2, 2, 0),
  },
  infoToolTipContainer: {
    display: 'flex',
  },
});

function convertLegacyReminderData(data = []) {
  const convertedData = {
    medication: {},
    medicalInformation: {},
    reminder: {},
  };

  if (data.length === 0) {
    return convertedData;
  }

  const legacyMedData = data.medicine[0];
  const legacyData = data;
  const boolToYesOrNo = {
    refills: legacyMedData.refill === '' ? false : true,
    ongoing: !legacyData.endDate ? true : false,
  };

  var refillCount;
  if (legacyMedData.refill) {
    const rule = RRule.fromString(legacyData.data[0].refill);
    refillCount = rule.origOptions.freq;
  }

  const medication = {
    DrugName: getOrDefault(() => legacyMedData.DrugName, ''),
    ActiveIngredient: getOrDefault(() => legacyMedData.ActiveIngredient, ''),
    Form: getOrDefault(() => legacyMedData.Form, ''),
    Strength: getOrDefault(() => legacyMedData.Strength, ''),
  };

  function fetchReminders(alerts) {
    const allReminders = {};

    if (alerts !== undefined) {
      for (let i = 0; i < alerts.length; i++) {
        const name = `reminderTime_${i}`;

        allReminders[name] = alerts[i];
      }
    }

    return allReminders;
  }

  const medicalInformation = {
    ...medication,
    Prescription: getOrDefault(() => legacyMedData.Prescription, ''),
    Term: getOrDefault(() => legacyMedData.Term, ''),
    Dosage: getOrDefault(() => legacyMedData.Dosage, ''),
    WhenToTake: getOrDefault(() => legacyMedData.whenToTake, ''),
    Duration: getOrDefault(() => legacyMedData.Duration, ''),
    NumberOfDosages: getOrDefault(() => legacyMedData.NumberOfDosages, ''),
    Instructions: getOrDefault(() => legacyMedData.Instructions, ''),
    Doctor: getOrDefault(() => legacyMedData.Doctor, ''),
    Pharmacy: getOrDefault(() => legacyMedData.Pharmacy, ''),
    PharmacyPhone: getOrDefault(() => legacyMedData.PharmacyPhone, ''),
    DoctorPhone: getOrDefault(() => legacyMedData.DoctorPhone, ''),
    Condition: getOrDefault(() => legacyMedData.Condition, ''),
  };

  const reminder = {
    startDate: getOrDefault(() => legacyData.startDate, ''),
    endDate: getOrDefault(() => legacyData.endDate, 'None'),
    ongoing: boolToYesOrNo.ongoing,
    ...fetchReminders(legacyMedData.alerts),
    refills: getOrDefault(() => boolToYesOrNo.refills, ''),
    refillAmount: getOrDefault(() => refillCount, 0),
    refillReminderDate: getOrDefault(
      () => legacyMedData.refillReminderTime,
      null
    ),
    refillReminderTime: getOrDefault(
      () => legacyMedData.refillReminderTime,
      null
    ),
    refillFrequency: getOrDefault(() => legacyData.refillReminderInterval, ''),
  };

  return {
    medication,
    medicalInformation,
    reminder,
  };
}

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
    hasPermission: PropTypes.func,
  };
  constructor(props) {
    super(props);

    const data = this.props.data || {};
    const { currentStep = 1, eventData } = data;
    const { timezone } = this.props.activeRecipient;

    const edit = !!eventData;
    let out = {
      medicine: [
        {
          Term: 'short',
          alerts: [],
        },
      ],
      refillFilters: [],
      type: 'medicine',
    };

    if (edit) {
      out = parseEventForEdit(eventData);
    }

    const isOngoing = !get(out, 'endDate', false);
    this.state = {
      out,
      currentStep,
      search: '',
      searchResults: [],
      saveError: '',
      saving: false,
      disableRecurrenceDescription: false,
      edit,
      isOngoing,
      timezone: timezone,
      isDialogOpen: true,
    };

    if (props && props.activeRecipient) {
      this.state.activeUserId = props.activeRecipient.userId;
      this.state.activeRecipient = props.activeRecipient;
    }

    this.loadConditionOptions = this.loadConditionOptions.bind(this);
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  onClose(updatedMedication = {}) {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose(updatedMedication);
    }
    this.setState({ isDialogOpen: false });
  }

  getAllReminderTimes(values) {
    const reminderTimes = [];

    for (var item in values) {
      if (item.startsWith('reminderTime')) {
        reminderTimes.push(values[item]);
      }
    }

    return reminderTimes;
  }

  onSaveClick(formValues) {
    if (!this.state.saving) {
      this.setState({ saving: true });
      const { userId, timezone } = this.props.activeRecipient;
      const valuesToSave = {
        userId,
        timezone,
        medicine: [],
        recurrence: '',
        recurrenceDescription: '',
        refill: '',
        ...this.state.out,
      };

      const reminder = formValues.reminder;
      const medicalInformation = formValues.medicalInformation;
      const hasRefills = reminder.refills;
      const refillCount = Number(reminder.refillAmount) || 0;
      const WhenToTake = medicalInformation.WhenToTake;
      const selectedWhenToTake = whenToTakeOptions[WhenToTake];

      const getMinutesSinceStartOfDayForTime = (time) => {
        const currentTime = DateTime.fromJSDate(time);
        const midnight = currentTime.startOf('day');
        return currentTime.diff(midnight, 'minutes').toObject().minutes;
      };

      const listOfReminders = this.getAllReminderTimes(reminder);
      valuesToSave.recurrenceDescription = whenToTakeOptions[WhenToTake].label;
      const rruleOpts = {
        ...selectedWhenToTake.rrule,
        tzid: timezone,
        byminute: (listOfReminders || []).map((time) =>
          getMinutesSinceStartOfDayForTime(time)
        ),
      };

      if (reminder.startDate) {
        rruleOpts.dtstart = dateInRruleDateFormat(reminder.startDate);
      }

      if (reminder.endDate && !reminder.ongoing) {
        const endDate = DateTime.fromJSDate(reminder.endDate).toJSDate();
        rruleOpts.until = new Date(
          Date.UTC(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
            23,
            59
          )
        );
      }
      const rrule = new RRule(rruleOpts);
      valuesToSave.recurrence = rrule.toString();
      if (
        hasRefills &&
        refillCount > 0 &&
        reminder.refillReminderDate &&
        reminder.refillReminderTime
      ) {
        const selectedInterval =
          refillIntervalOptions[reminder.refillFrequency];

        const rrule = new RRule({
          ...selectedInterval.rrule,
          count: reminder.refillAmount,
          tzid: timezone,
          dtstart: new Date(
            Date.UTC(
              reminder.refillReminderDate.getFullYear(),
              reminder.refillReminderDate.getMonth(),
              reminder.refillReminderDate.getDate(),
              reminder.refillReminderTime.getHours(),
              reminder.refillReminderTime.getMinutes()
            )
          ),
        });

        valuesToSave.refill = rrule.toString();
      }

      valuesToSave.refillFilters = [];

      valuesToSave.refillFilters.push({
        type: 'userType',
        key: 'caregiver',
      });

      valuesToSave.refillFilters.push({
        type: 'userType',
        key: 'carereceiver',
      });

      valuesToSave.medicine[0] = { ...medicalInformation };
      (this.state.edit ? editMedicineEvent : addMedicineEvent)(
        this.props.dispatch,
        this.context.apiClient,
        valuesToSave
      )
        .then((res) =>
          this.setState({ saving: false }, () => {
            this.onClose(res.data);
            window.location.reload();
          })
        )
        .catch((err) => {
          this.setState({ saveError: err.error, saving: false });
        });
    }
  }

  loadConditionOptions(inputValue) {
    return searchConditions(
      this.props.dispatch,
      this.context.apiClient,
      inputValue
    ).then((res) =>
      res.data.conditions.map((c) => ({ value: c.name, label: c.name }))
    );
  }

  render() {
    const { t, classes } = this.props;
    return (
      <MuiDialog open={this.state.isDialogOpen} onClose={() => this.onClose()}>
        <div className={classes.infoToolTipContainer}>
          <DialogTitle>Medical Reminder</DialogTitle>
          <InfoTooltip
            translationKey={'MedicationReminder.Tooltip'}
            className={classes.infoTooltip}
            translationOptions={{
              name: this.state.activeRecipient.name.first,
            }}
          />
        </div>
        <div className={classes.titlemargin}>
          <Typography variant={'body1'}>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="//www.leapthru.com/knowledge-base/use_of_medical_information/"
            >
              {t('Reminder.DisclaimerTextLink')}
            </a>
          </Typography>
        </div>
        <div className={classes.titlemargin}>
          <Typography variant="subtitle1">
            {t('Reminder.EventWillBeCreatedInCareRecipientTimezone')}
          </Typography>
          <Tooltip
            title={DateTime.local()
              .setZone(this.state.timezone)
              .setLocale('en-US')
              .toFormat('ZZZZZ')}
          >
            <Typography align="center" variant="caption">
              (UTC
              {DateTime.local()
                .setZone(this.state.timezone)
                .setLocale('en-US')
                .toFormat('ZZ z')}{' '}
              )
            </Typography>
          </Tooltip>
        </div>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={() => this.onClose()}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent>
          <NewMedicalReminder
            handleSaveButtonClick={(formValues) => this.onSaveClick(formValues)}
            onEdit={this.state.edit}
            preFilledFormState={convertLegacyReminderData(this.state.out)}
          />
        </DialogContent>
      </MuiDialog>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(
    ['carerecipients', 'activeRecipient'],
    {}
  );
  const contacts = activeRecipient
    ? filterContacts(state, activeRecipient.userId)
    : null;

  return {
    activeRecipient,
    contacts,
  };
};

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(Dialog))
);
