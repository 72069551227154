import i18next from 'i18next';
import I18nextXhrBackend from 'i18next-xhr-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next as I18nextReactInitializer } from 'react-i18next';

const debug = process.env.REACT_APP_DEBUG_TRANSLATIONS === 'true';

i18next
  .use(I18nextReactInitializer)
  .use(I18nextBrowserLanguageDetector)
  .use(I18nextXhrBackend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug,
    saveMissing: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18next;
