import { OrderedMap } from 'immutable';
import { useSelector } from 'react-redux';

const INITIAL_STATE = {
  activeRecipient: { userId: null },
  recipients: new OrderedMap(),
  upload: null,
};

export function setCareRecipients(state, payload) {
  const recipients = new OrderedMap(
    payload.reduce((s, v) => ({ ...s, [v.userId]: v }), {})
  );

  const hasCareRecipients = recipients.size !== 0;
  return { ...state, hasCareRecipients, recipients };
}

export function setActive(state, crId) {
  const activeRecipient = state.recipients.get(crId);

  return {
    ...state,
    activeRecipient,
  };
}

export function setUpload(state, data) {
  return { ...state, upload: data };
}

export function setCRData(state, userId, key, data) {
  const careRecipient = state.recipients.get(userId);
  const newUser = { ...careRecipient, [key]: data };
  return {
    ...state,
    activeRecipient: newUser,
  };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CARE_RECIPIENTS_SET':
      return setCareRecipients(state, action.payload);

    case 'CARE_RECIPIENTS_SET_ACTIVE':
      return setActive(state, action.crId);

    case 'CARE_RECIPIENTS_UPLOAD':
      return setUpload(state, action.payload);

    case 'CARE_RECIPIENTS_SET_PHONESETTINGS':
      return setCRData(state, action.userId, 'phoneSettings', action.settings);

    case 'CARE_RECIPIENTS_UPDATE_KEY_VALUE':
      return setCRData(state, action.userId, action.key, action.value);

    case 'CARE_RECIPIENTS_SET_WATCHSETTINGS':
      return setCRData(state, action.userId, 'watchSettings', action.settings);

    default:
      return state;
  }
};

export const useActiveUserSelector = () =>
  useSelector((state) => state.getIn(['user', 'self']));

export const useActiveCareRecipientSelector = () =>
  useSelector((state) => state.getIn(['carerecipients', 'activeRecipient']));

export const getPermissionsFromCareRecipient = (recipient, careGiverUserId) => {
  let careGiverRights = recipient.careGiverRights || {};

  if (
    recipient.careGiverRightsCollection &&
    recipient.careGiverRightsCollection.length
  ) {
    const careGiverRightsFound = recipient.careGiverRightsCollection.filter(
      (cg) => cg.careGiverId === careGiverUserId
    );

    if (careGiverRightsFound.length) {
      careGiverRights = careGiverRightsFound[0].services;
    }
  }

  return careGiverRights;
};

export const getMobileDeviceFromCareRecipient = (recipient) => {
  const validTokens = recipient.deviceTokens.filter(
    (item) => item.tokenDeviceType === 'phone' && item.tokenIsValid
  );

  if (
    validTokens.includes((item) =>
      item.tokenDeviceOperatingSystem.contains('android')
    )
  ) {
    return 'android';
  }
  return 'ios';
};
