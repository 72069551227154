import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Face from 'components/Face';

const styles = (theme) => ({
  concerningResponseLabel: {
    marginRight: theme.spacing(2),
    display: 'block',
  },
  checkboxContainer: {
    marginRight: theme.spacing(2),
    display: 'inline-block',
  },
});

class Question extends React.Component {
  constructor(props) {
    super(props);

    const { alertCondition = [] } = props.initialData || {};

    this.state = {
      type: props.initialData ? props.initialData.type : 'text',
      text: props.initialData ? props.initialData.text : '',
      optionsForSaving: [
        { icon: 'thumbsup', id: 'yes' },
        { icon: 'thumbsdown', id: 'no' },
      ],
      valuesForSaving: [
        { icon: 'face1', value: 1 },
        { icon: 'face2', value: 2 },
        { icon: 'face3', value: 3 },
        { icon: 'face4', value: 4 },
        { icon: 'face5', value: 5 },
      ],
      options: {
        yes: props.initialData ? alertCondition.includes('yes') : false,
        no: props.initialData ? alertCondition.includes('no') : false,
      },
      values: Array.from(Array(5)).reduce((obj, _, index) => {
        const propKey = index + 1;
        return { ...obj, [propKey]: alertCondition.includes(propKey) };
      }, {}),
    };
  }

  handleOptionsChange(name, event) {
    this.setState(
      {
        options: {
          ...this.state.options,
          [name]: event.target.checked,
        },
      },
      () => this.props.questionUpdate(this.state)
    );
  }

  handleValueChange(name, event) {
    this.setState(
      {
        values: {
          ...this.state.values,
          [name]: event.target.checked,
        },
      },
      () => this.props.questionUpdate(this.state)
    );
  }

  handleFieldValueChange(event) {
    this.setState({ text: event.target.value }, () =>
      this.props.questionUpdate(this.state)
    );
  }

  handleSelectChange(event) {
    this.setState({ type: event.target.value }, () =>
      this.props.questionUpdate(this.state)
    );
  }

  renderCheckBoxScale(times, classes) {
    const checkboxes = [];
    for (let i = 1; i <= times; i += 1) {
      checkboxes.push(
        <div className={classes.checkboxContainer} key={i}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.values[i]}
                onChange={(e) => this.handleValueChange(i, e)}
                value={String(i)}
                color="primary"
              />
            }
            label={<Face value={i} />}
          />
        </div>
      );
    }
    return checkboxes;
  }

  render() {
    const { classes } = this.props;
    const { text, type, options } = this.state;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <TextField
            fullWidth
            multiline
            required
            rowsMax="4"
            label={`Question ${this.props.orderNumber}`}
            value={text}
            onChange={(e) => this.handleFieldValueChange(e)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            select
            fullWidth
            label="User Response Type"
            value={type}
            onChange={(e) => this.handleSelectChange(e)}
            variant="outlined"
          >
            <MenuItem key="text" value="text">
              Text
            </MenuItem>
            <MenuItem key="options" value="options">
              Yes/No
            </MenuItem>
            <MenuItem key="values" value="values">
              Rating
            </MenuItem>
          </TextField>
        </Grid>
        {type === 'options' && (
          <Grid item xs={12}>
            <span className={classes.concerningResponseLabel}>
              Select Any Concerning Response:
            </span>
            <div className={classes.checkboxContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.yes}
                    onChange={(e) => this.handleOptionsChange('yes', e)}
                    value="yes"
                    color="primary"
                  />
                }
                label="Yes"
              />
            </div>
            <div className={classes.checkboxContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.no}
                    onChange={(e) => this.handleOptionsChange('no', e)}
                    value="no"
                    color="primary"
                  />
                }
                label="No"
              />
            </div>
          </Grid>
        )}
        {type === 'values' && (
          <Grid item xs={12}>
            <div>Select Any Concerning Response:</div>
            {this.renderCheckBoxScale(5, classes)}
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(Question);
