/* eslint-disable */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

const BackButton = ({ color, ...props }) => {
  const allClasses = {
    primary: { root: props.classes.buttonRoot },
    default: { root: props.classes.buttonDefaultRoot },
    transparent: { root: props.classes.buttonTransparentRoot },
  };
  const classes = allClasses[color || 'primary'];
  return (
    <MuiButton {...props} classes={classes} className={props.className}>
      {props.children}
    </MuiButton>
  );
};

export default withStyles(() => ({
  buttonRoot: {
    backgroundColor: '#4a90e2',
    color: 'white',
    textTransform: 'none',
    padding: '8px 14px',
    '&:hover': {
      backgroundColor: '#3483de'
    },
    '&:active': {
      backgroundColor: '#2376d6'
    },
    '&:focus': {
      backgroundColor: '#3483de'
    },
    '&:disabled': {
      opacity: 0.6,
      color: 'white'
    }
  },
  buttonDefaultRoot: {
    backgroundColor: '#c6c6c6',
    color: 'white',
    textTransform: 'none',
    padding: '8px 14px',
    '&:hover': {
      backgroundColor: '#c7c7c7'
    },
    '&:active': {
      backgroundColor: '#c8c8c8'
    },
    '&:focus': {
      backgroundColor: '#c7c7c7'
    }
  },
  buttonTransparentRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: '#4a90e2',
    textTransform: 'none',
    padding: '8px 14px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)'
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  }
}))(BackButton);
