/* eslint-disable */
/* global FormData */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import { getUploadToken, importMembers, revertImport } from 'actions/user';
import Title from 'components/Title';
import { post } from 'axios';
import { CircularProgress } from '@material-ui/core';

import style from './style.module.scss';

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
    hasPermission: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      error: null,
      result: null,
      loading: false
    };

    this.problems = {
      timezone: 'Invalid timezone',
      email_username: 'Missing email and username',
      password: 'Missing password',
      name: 'Missing name',
      primarycaregiver_missing: 'Primary caregiver missing',
      caregiver_missing: 'Caregiver missing',
      caregiver_exists: 'Caregiver exists',
      carerecipient_exists: 'Care recipient exists'
    };
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onChangeFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ file: event.target.files[0] });
    }
  }

  onUploadClick() {
    this.setState(
      { loading: true, result: null, error: null },
      () => this.state.file && getUploadToken(
        this.context.apiClient,
        'importFile',
        this.state.file.name
      ).then((res) => {
        const upload = res.data;
        const uri = `${process.env.REACT_APP_URL_SERVER_BACKEND}/upload/up/${upload.fileId}`;

        const formData = new FormData();
        formData.append('file', this.state.file);

        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        };

        return post(uri, formData, config).then((response) => {
          if (response.status < 300) {
            return importMembers(this.context.apiClient, upload.fileId);
          }
          return Promise.reject(new Error('Upload failed'));
        });
      })
        .then((res) => {
          const problems = [];
          console.log('ASDFASDF', typeof res.data);
          if (typeof res.data === 'object') {
            while (res.data && res.data.length) {
              const problem = res.data.shift();
              const problemStr = problem.errors.map((item) => {
                let str = this.problems[item.code] || item.code;
                if (item.info) {
                  str += `: ${item.info.join(', ')}`;
                }
                return str;
              }).join(', ');
              problems.push(<li key={`problem${res.data.length}`}>Row {problem.importRow}: {problemStr}</li>);
            }
          }


          if (problems.length) {
            this.setState({
              loading: false,
              result: (
                <div className={style.importResult}>
                  <Title>There were some problems with your import file</Title>
                  <ul>{problems}</ul>
                </div>
              )
            });
          } else {
            const result = (
              <div>
                <span>Import successful</span>
                <Button className={style.nextButton} onClick={() => this.revertImport(res.data)}>
                  REVERT IMPORT
                </Button>
              </div>
            );
            this.setState({ loading: false, result });
          }
        })
        .catch(error => this.setState({ loading: false, error: error.error }))
    );
  }

  revertImport(hash) {
    revertImport(this.context.apiClient, hash).then((res) => {
      if (res && res.data && res.data.ok) {
        this.setState({ result: <span>Import reverted</span> });
      } else {
        this.setState({ result: <span>Could not revert</span> });
      }
    });
  }

  render() {
    const { loading, result, error } = this.state;

    return (
      <div className={[style.background, style[this.props.transition]].join(' ')}>
        <div className={style.container} onClick={e => e.stopPropagation()}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            <div className={style.content}>
              <Title>CSV import file</Title>
              <input type="file" onChange={event => this.onChangeFile(event)} />
            </div>
            <div className={style.importResult}>
              {loading && <CircularProgress />}
              {result}
            </div>
            {error && <div className={style.saveError}>An error occurred: {error}</div>}
            <div>
              {!loading &&
                <Button className={style.nextButton} onClick={() => this.onUploadClick()}>
                  UPLOAD
                </Button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(Dialog);
