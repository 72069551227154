/* eslint-disable */

export const addWellnessEvent = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'Wellness/addWellnessEvent',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
      return res;
    });


export const editWellnessEvent = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'Wellness/editWellnessEvent',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
      return res;
    });

