/* eslint-disable */

const calendarPromise = {};

export const getCalendar = (dispatch, apiClient, userId) => {
  if (calendarPromise[userId]) {
    return calendarPromise[userId];
  }
  calendarPromise[userId] = apiClient
    .call({ endpoint: 'calendar/getEvents', data: { userId } })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      // dispatch({ type: 'CALENDAR_CLEAR_USER', userId });
      dispatch({ type: 'CALENDAR_SET', payload: res.data, userId });
      calendarPromise[userId] = null;
      delete calendarPromise[userId];
      return res;
    })
    .catch((err) => {
      console.log('ERROR', err);
      calendarPromise[userId] = null;
      delete calendarPromise[userId];
    });

  return calendarPromise[userId];
};

export const getEvent = (dispatch, apiClient, userId, eventId) => apiClient
  .call({ endpoint: 'calendar/getEvent', data: { userId, eventId } })
  .then((res) => {
    if (res.status !== 200) {
      throw res;
    }
    return res;
  })
  .catch((err) => {
    console.log('ERROR', err);
    throw err;
  });

export const removeEvent = (dispatch, apiClient, userId, eventId) => apiClient
  .call({ endpoint: 'calendar/removeEvent', data: { userId, eventId } })
  .then((res) => {
    if (res.status !== 200) {
      throw res;
    }
    dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
    return res;
  })
  .catch((err) => {
    console.log('ERROR', err);
  });

export const inactivateEvent = (dispatch, apiClient, userId, eventId) => apiClient
  .call({ endpoint: 'calendar/inactivateEvent', data: { userId, eventId } })
  .then((res) => {
    if (res.status !== 200) {
      throw res;
    }
    dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
    return res;
  })
  .catch((err) => {
    console.log('ERROR', err);
  });

export const addGeneralReminder = (dispatch, apiClient, data) => apiClient
  .call({
    endpoint: 'calendar/addGeneralReminder',
    data
  })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
    return res;
  })
  .catch((err) => {
    console.log('ERROR', err);
  });

export const editGeneralReminder = (dispatch, apiClient, data) => apiClient
  .call({
    endpoint: 'calendar/editGeneralEvent',
    data
  })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
    return res;
  });

export const editEvent = (dispatch, apiClient, data) => apiClient
  .call({
    endpoint: 'calendar/editEvent',
    data
  })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }
    return res;
  })
  .catch((err) => {
    console.log('ERROR', err);
  });
