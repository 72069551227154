/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import set from 'lodash.set';
import get from 'lodash.get';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Tabs, Tab, TabContent } from 'components/Tabs';
import { adminUpdateUserData, authEdit, crGDPRremove } from 'actions/user';

import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Title from 'components/Title';
import Field from 'components/DialogField';
// import roles from 'utils/roleOptions';

import style from './style.module.scss';

// eslint-disable-next-line
const emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
// const roleOptions = Object.keys(roles).map(value => ({ value, label: roles[value] }));

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func,
    hasPermission: PropTypes.func
  };

  constructor(props) {
    super(props);
    const { user } = props.data;

    this.state = {
      activeTab: 0,
      sendauthentication: 'send',
      out: {
        ...user,
        firstName: user.name.first,
        lastName: user.name.last,
        expire: user.expiresOn,
        title: user.roleTitle || user.title,
      },
      authData: {
        username: user.username,
        email: user.email
      }
    };
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onSaveClick() {
    const { out, saving } = this.state;
    const { user } = this.props.data;
    const defaultRoleValue = out.roleTitle || (user.type === 'Care Giver' ? 'caregiver' : 'careRecipient');
    if (!saving) {
      this.setState({ saving: true });
      if (out.title === 'undefined') {
        this.setOutValue('title', defaultRoleValue);
      }
      adminUpdateUserData(this.props.dispatch, this.context.apiClient, { ...out, CRId: out.userId })
        .then(() => this.setState({ saving: false }, () => this.onClose()))
        .catch(err => this.setState({ saveError: err.error, saving: false }));
    }
  }

  onAuthSaveClick() {
    const newSettings = {};
    let gotNewSettings = false;

    const authDataKeys = Object.keys(this.state.authData);
    for (let a = 0; a < authDataKeys.length; a += 1) {
      const key = authDataKeys[a];

      switch (key) {
        case 'username':
        case 'email':
          if (this.props.data.user[key] !== this.state.authData[key]) {
            newSettings[key] = this.state.authData[key];
            gotNewSettings = true;
          }
          break;
        default:
          console.log('invalid key', key);
      }
    }

    if (gotNewSettings) {
      this.setState({ saving: true });
      if (this.state.sendauthentication === 'send') {
        authEdit(this.context.apiClient, this.props.data.user.userId, newSettings)
          .then(() => this.setState({ saving: false }, () => this.onClose()))
          .catch(err => this.setState({ saveError: err.error, saving: false }));
      } else {
        adminUpdateUserData(this.props.dispatch, this.context.apiClient, {
          ...newSettings,
          CRId: this.props.data.user.userId
        })
          .then(() => this.setState({ saving: false }, () => this.onClose()))
          .catch(err => this.setState({ saveError: err.error, saving: false }));
      }
    } else {
      alert('There are no changes'); // eslint-disable-line
    }
  }

  onGDPRRemoveClick() {
    const crId = this.state.out.userId;
    this.context.dialogPush('confirmation', {
      onOk: () => {
        crGDPRremove(
          this.props.dispatch,
          this.context.apiClient,
          crId,
        ).then(() => this.onSaveClick());
      }
    });
  }

  setOutValue(key, val) {
    this.setState({ out: set(this.state.out, key, val) });
  }

  setAuthValue(key, val) {
    this.setState({ authData: set(this.state.authData, key, val) });
  }

  setStateValue(key, val) {
    if (key === 'sendauthentication' && val === 'dontsend') {
      // eslint-disable-next-line
      const con = confirm('Are you sure you want to set these without confirming it with the old adress? this is a security risk');
      if (con) {
        const setKeys = {};
        setKeys[key] = val;
        this.setState(setKeys);
      }
    } else {
      const setKeys = {};
      setKeys[key] = val;
      this.setState(setKeys);
    }
  }

  renderField(key, label, type = 'text', props, defaultValue) {
    return (
      <div key={`field_${key}`} className={style.textSetting}>
        <div className={style.label}>{label}</div>
        <Field
          className={style.field}
          placeholder={label}
          type={type}
          value={get(this.state.out, key, defaultValue)}
          onChange={event => this.setOutValue(key, get(event, 'target.value', event))}
          {...props}
        />
      </div>
    );
  }

  renderAuthField(type, outKey, label, props, defaultValue) {
    return (
      <div className={style.field} key={`field_${outKey}`}>
        <div className={style.label}>{label}</div>
        <Field
          type={type}
          value={get(this.state.authData, outKey, defaultValue) || ''}
          onChange={event => this.setAuthValue(outKey, get(event, 'target.value', event))}
          {...props}
        />
      </div>
    );
  }


  render() {
    const { saveError } = this.state;
    const tabs = [];

    const { user } = this.props.data;

    const userType = user.type;

    const defaultRoleValue = this.state.out.roleTitle || (user.type === 'Care Giver' ? 'caregiver' : 'careRecipient');
    let authSendButton = 'Send';

    let sendToText = null;
    if (this.state.sendauthentication === 'send') {
      if (userType === 'Care Giver') {
        const email = user.email && user.email.length > 0 ? user.email : user.username;
        if (!email || !email.match(emailRegex)) {
          sendToText = (<div>No email to send to</div>);
        } else {
          sendToText = (<div>Confirmation will be sent to {email}</div>);
        }
      } else if (!user.primaryCareGiverEmail || !user.primaryCareGiverEmail.match(emailRegex)) {
        sendToText = (
          <div>Primary caregiver {user.primaryCareGiverName} has no email to send to</div>
        );
      } else {
        sendToText = (
          <div>
            Confirmation will be sent to primary caregiver {user.primaryCareGiverName} (
            {user.primaryCareGiverEmail})
          </div>
        );
      }

      authSendButton = 'Send';
    } else {
      sendToText = (<div>No confirmation will be sent</div>);
      authSendButton = 'Save';
    }

    tabs.push({
      title: 'General settings',
      content: () => (
        <div>
          <div className={style.content}>
            <Title>Member edit</Title>
            {this.renderField('firstName', 'First name', 'text')}
            {this.renderField('lastName', 'Last name', 'text')}
            {this.renderField('userGroup', 'User group', 'text')}
            {this.renderField('tag', 'Tag', 'text')}
            {this.renderField('expire', 'Expiry date', 'date')}
            {this.renderField('phoneNumber', 'Phone number', 'text')}
            {this.renderField('adress', 'Adress', 'text')}
            {this.renderField(
              'title',
              'Title',
              'select',
              {
                options: [
                  { value: 'family', label: 'Family' },
                  { value: 'friend', label: 'Friend' },
                  { value: 'healthProfessional', label: 'Health Professional' },
                  { value: 'physician', label: 'Physician' },
                  { value: 'caregiver', label: 'Professional Caregiver' },
                  { value: 'livingStaff', label: 'Residential Living Staff' },
                  { value: 'careRecipient', label: 'Care Recipient' },
                ]
              },
              defaultRoleValue
            )}
            <RadioGroup
              value={get(this.state.out, 'userLevel')}
              onChange={e => this.setOutValue('userLevel', e.target.value)}
              row
            >
              <FormControlLabel
                value="user"
                control={<Radio color="primary" />}
                label="User"
              // labelPlacement="start"
              />
              <FormControlLabel
                value="admin"
                control={<Radio color="primary" />}
                label="Admin"
              // labelPlacement="start"
              />
            </RadioGroup>
            <RadioGroup
              value={get(this.state.out, 'status')}
              onChange={e => this.setOutValue('status', e.target.value)}
              row
            >
              <FormControlLabel
                value="active"
                control={<Radio color="primary" />}
                label="Active"
              />
              <FormControlLabel
                value="inactive"
                control={<Radio color="primary" />}
                label="Inactive"
              />
            </RadioGroup>
            <Button onClick={() => this.onGDPRRemoveClick()}>
              Remove all personal information (Anonomize user)
            </Button>
            {saveError && <div className={style.saveError}>An error occurred: {saveError}</div>}
          </div>
          <div>
            <Button className={style.nextButton} onClick={() => this.onSaveClick()}>
              SAVE
            </Button>
          </div>
        </div>
      )
    });

    tabs.push({
      title: 'Authenticate settings',
      content: () => (
        <div>
          <div className={style.content}>
            <Title>Authentication edit</Title>
            {this.renderAuthField('text', 'username', 'Username')}
            {this.renderAuthField('text', 'email', 'Email')}

            <RadioGroup
              value={get(this.state, 'sendauthentication')}
              onChange={e => this.setStateValue('sendauthentication', e.target.value)}
              row
            >
              <FormControlLabel
                value="send"
                control={<Radio color="primary" />}
                label="Send confirmation mail"
              // labelPlacement="start"
              />
              <FormControlLabel
                value="dontsend"
                control={<Radio color="primary" />}
                label="Set immediately. Dont send confirmation mail"
              // labelPlacement="start"
              />
            </RadioGroup>
            {sendToText}
            {saveError && <div className={style.saveError}>An error occurred: {saveError}</div>}
          </div>
          <div>
            <Button className={style.nextButton} onClick={() => this.onAuthSaveClick()}>
              {authSendButton}
            </Button>
          </div>
        </div>
      )
    });

    return (
      <div className={[style.background, style[this.props.transition]].join(' ')}>
        <div className={style.container} onClick={e => e.stopPropagation()}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            <br />
            <br />
            <br />
            <div>
              <Tabs
                value={this.state.activeTab}
                onChange={(e, val) => this.setState({ activeTab: val })}
              >
                {tabs.map(tab => (
                  <Tab label={tab.title} />
                ))}
              </Tabs>
              <TabContent className={style.tabContent}>
                {tabs.map((tab, index) => this.state.activeTab === index && tab.content())}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Dialog);
