import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  TextField,
  Button,
  InputAdornment,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import AvatarMapMarker from 'components/GoogleMap/AvatarMapMarker';
import { getCoordsByAddress } from 'actions/geocode';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useDebounce } from 'hooks/useDebounce';
import User from 'entities/user';

const useStyles = makeStyles((theme) => ({
  listItem: {
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(0.5),
  },
  centeredGridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonElement: {
    width: '50%',
  },
  searchIcon: {
    fontSize: '1.5rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

function UserLocationForm(
  { location, onSetUserLocation = () => {}, onLocationSave = () => {} },
  context
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [values, setValues] = useState(location);
  const [shouldMapUpdate, setShouldMapUpdate] = useState(false); // eslint-disable-line

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setValues((state) => ({ ...state, [name]: value }));
  };

  const [searchedLocation, setSearchedLocation] = React.useState(
    values.locationAddress
  );
  const debouncedSearchLocation = useDebounce(searchedLocation, 1000);

  React.useEffect(() => {
    if (debouncedSearchLocation.length >= 3) {
      getCoordsByAddress(
        dispatch,
        context.apiClient,
        debouncedSearchLocation
      ).then((value) => {
        if (!value || !value.geometry) {
          return;
        }
        const { geometry } = value;

        setValues((state) => ({
          ...state,
          locationAddress: debouncedSearchLocation,
          locationAddressFull: value.address,
          locationCoords: geometry.location,
        }));
        setShouldMapUpdate((state) => ({
          ...state,
          shouldMapUpdate: true,
        }));
      });
    }
  }, [debouncedSearchLocation, dispatch, context.apiClient]);

  React.useEffect(() => {
    setSearchedLocation(values.locationAddress);
  }, [values.locationAddress]);

  const handleLocationChange = (event) => {
    setSearchedLocation(event.target.value);
  };

  function handleSetUserLocation(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    onSetUserLocation(values);
  }

  function handleSave(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    onLocationSave();
  }

  function isUserLocationFormValid() {
    return values.locationAddress.length > 1 && values.locationName.length > 0;
  }

  return (
    <form onSubmit={handleSave}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            label="Location name"
            value={values.locationName}
            name="locationName"
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Location Address"
            placeholder="Type to search..."
            value={searchedLocation}
            name="locationAddress"
            onChange={handleLocationChange}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    className={classes.searchIcon}
                    icon={['fas', 'search-location']}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: '200px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
              defaultZoom={13}
              center={
                values.locationCoords.lat !== 0
                  ? { ...values.locationCoords }
                  : { ...values.center }
              }
              draggable={true}
            >
              {values.locationCoords.lat !== 0 && (
                <AvatarMapMarker
                  lat={values.locationCoords.lat}
                  lng={values.locationCoords.lng}
                />
              )}
            </GoogleMapReact>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.centeredGridItem}>
          <Button
            className={classes.buttonElement}
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isUserLocationFormValid()}
            onClick={() => {
              handleSetUserLocation();
              handleSave();
            }}
          >
            Save Location
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

UserLocationForm.contextTypes = {
  apiClient: PropTypes.object.isRequired,
};

const activeCareRecipient = (state) =>
  state.getIn(['carerecipients', 'activeRecipient']);
const user = User(activeCareRecipient);
let lat = 56.1822097;
let lng = 15.6033117;

if (user.location.lat.length > 0) {
  lat = user.location.lat;
  lng = user.location.lng;
}

const initialUserLocationFormValues = Object.freeze({
  locationName: '',
  locationAddress: '',
  locationAddressFull: '',
  locationCoords: {
    lat: 0,
    lng: 0,
  },
  center: {
    lat: lat,
    lng: lng,
  },
});

export { initialUserLocationFormValues, UserLocationForm };
