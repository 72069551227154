import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '../../components/Button/IconButton';
import style from './style.module.scss';
import PropTypes from 'prop-types';
import Title from '../../components/Title';
import Field from '../../components/DialogField';
import { Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resendSmsVerification, resendEmailVerification } from 'actions/user';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

function Dialog(props, context) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [phoneVerificationCode, setPhoneVerificationCode] = React.useState(
    props.data.user.phoneVerificationCode
  );
  const [
    phoneVerificationExpiresAt,
    setPhoneVerificationExpiresAt,
  ] = React.useState(props.data.user.phoneVerificationExpiresAt);

  function onClose() {
    context.dialogClose(props.dialogId);
    props.data.onClose();
  }

  function activeIcon(activeStatus) {
    return activeStatus ? 'check-circle' : 'circle';
  }

  function onClickResendSmsVerification() {
    const { userId } = props.data.user;
    resendSmsVerification(null, context.apiClient, userId)
      .then((res) => {
        setPhoneVerificationCode(res.data.phoneVerificationCode);
        setPhoneVerificationExpiresAt(res.data.phoneVerificationExpiresAt);
        enqueueSnackbar(t('Admin.SuccessfullySentPhoneVerification'), {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(t('Admin.ErrorSendingPhoneVerification'), {
          variant: 'error',
        });
        console.log(error);
      });
  }

  function onClickResendEmailVerification() {
    const { userId } = props.data.user;
    resendEmailVerification(context.apiClient, userId)
      .then(() => {
        enqueueSnackbar(t('Admin.SuccessfullySentRegistrationEmail'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(t('Admin.ErrorSendingRegistrationEmail'), {
          variant: 'error',
        });
      });
  }

  return (
    <div className={[style.background, style[props.transition]].join(' ')}>
      <div className={style.container} onClick={(e) => e.stopPropagation()}>
        <div className={style.modal}>
          <IconButton
            onClick={() => onClose()}
            className={style.buttonClose}
            icon="clear"
            variant="fab"
            mini="true"
          />
          <div className={style.content}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <Title>Verification</Title>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body1">
                  <FontAwesomeIcon
                    size="lg"
                    className={style.verificationIcon}
                    icon={['fa', activeIcon(props.data.user.emailActive)]}
                  />
                  Email status
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body1">
                  <FontAwesomeIcon
                    size="lg"
                    className={style.verificationIcon}
                    icon={['fa', activeIcon(props.data.user.phoneActive)]}
                  />
                  Phone status
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body1">
                  <FontAwesomeIcon
                    size="lg"
                    className={style.verificationIcon}
                    icon={['fa', activeIcon(props.data.user.status)]}
                  />
                  Account status
                </Typography>
              </Grid>

              <Grid item xs={10}>
                <Field
                  disabled
                  className={style.field}
                  type="text"
                  label="Email"
                  value={props.data.user.email}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <Button
                  onClick={() => onClickResendEmailVerification()}
                  style={{ height: '100%' }}
                  variant="contained"
                  size="large"
                  disabled={props.data.user.emailActive}
                >
                  Resend
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Field
                  disabled
                  className={style.field}
                  type="text"
                  label="Phone verification code"
                  value={phoneVerificationCode}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  disabled
                  className={style.field}
                  type="text"
                  label="Expires at"
                  value={phoneVerificationExpiresAt}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <Button
                  onClick={() => onClickResendSmsVerification()}
                  style={{ height: '100%' }}
                  variant="contained"
                  size="large"
                  disabled={props.data.user.phoneActive}
                >
                  Resend
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

Dialog.contextTypes = {
  apiClient: PropTypes.object.isRequired,
  dialogPush: PropTypes.func,
  dialogClose: PropTypes.func,
};

export default Dialog;
