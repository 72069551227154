/* eslint-disable */

export const addLocationEvent = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'locations/addLocationEvent',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
      return res;
    }).catch(error => {
      console.error('Error: ', error);
      return null;
    });

export const editLocationEvent = (dispatch, apiClient, data) =>
  apiClient
    .call({
      endpoint: 'locations/editLocationEvent',
      data
    })
    .then((res) => {
      if (res.status >= 400) {
        throw res;
      }
      dispatch({ type: 'FETCH_CALENDAR_DATA', payload: res.data });
      return res;
    }).catch(error => {
      console.error('Error: ', error);
      return null;
    });

export const getUserLocations = (dispatch, apiClient, userId) => apiClient
  .call({
    endpoint: 'locations/getUserLocations',
    data: { userId }
  })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }

    dispatch({ type: 'LOCATION_SET_USERLOCATIONS', payload: res.data, userId });

    return res;
  })
  .catch((error) => {
    console.error('getUserLocations got user locations error', error);
    throw error;
  });

export const addUserLocation = (dispatch, apiClient, data) => apiClient
  .call({
    endpoint: 'locations/addUserLocation',
    data
  })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }

    dispatch({ type: 'LOCATION_SET_USERLOCATIONS', payload: res.data, userId: data.userId });

    return res;
  })
  .catch((error) => {
    console.error('addUserLocation got user locations error', error);
    throw error;
  });

export const removeUserLocation = (dispatch, apiClient, userId, userLocationId) => apiClient
  .call({
    endpoint: 'locations/removeUserLocation',
    data: {
      userId,
      userLocationId
    }
  })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }

    dispatch({ type: 'LOCATION_SET_USERLOCATIONS', payload: res.data, userId });

    return res;
  })
  .catch((error) => {
    console.error('removeUserLocation got user locations error', error);
    throw error;
  });

export const updateUserLocation = (dispatch, apiClient, data) => apiClient
  .call({
    endpoint: 'locations/updateUserLocation',
    data
  })
  .then((res) => {
    if (res.status >= 400) {
      throw res;
    }

    dispatch({ type: 'LOCATION_SET_USERLOCATIONS', payload: res.data, userId: data.userId });

    return res;
  })
  .catch((error) => {
    console.error('addUserLocation got user locations error', error);
    throw error;
  });

export function getLocationsTimeline(dispatch, apiClient, userId, from, to) {
  return apiClient
    .call({
      endpoint: 'locations/getLocations',
      data: {
        userId,
        from,
        to,
      },
    })
    .then(res => {
      if (res.status >= 400) {
        console.error('Error: ', res);
        return null;
      }

      return res.data;
    })
    .catch(error => {
      console.error('Error: ', error);
      return null;
    });
}

export default {};
