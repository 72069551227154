import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles({
  avatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: pink[500],
  },
});

export default function AvatarMapMarker() {
  const classes = useStyles();

  return (
    <Avatar className={classes.avatar}>
      <FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
    </Avatar>
  );
}
