/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Title from 'components/Title';

import { inviteCaregiver } from 'actions/invitation';

import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import style from './style.module.scss';

const styles = () => ({
  textFieldRoot: {},
  inputRoot: {
    height: 36,
    backgroundColor: 'white',
    padding: '8px 0 8px 8px',
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: 4
  },
  inputMultiLine: {
    backgroundColor: 'white',
    padding: '8px 0 8px 8px',
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: 4
  },
  inputFocused: {},
  input: {
    padding: 0
  },
  adornment: {
    alignSelf: 'center'
  }
});

class Dialog extends React.Component {
  static contextTypes = {
    apiClient: PropTypes.object.isRequired,
    dialogPush: PropTypes.func,
    dialogClose: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      saving: false,
      saveError: '',
    };
  }

  onClose() {
    this.context.dialogClose(this.props.dialogId);
    if (this.props.data && this.props.data.onClose) {
      this.props.data.onClose();
    }
  }

  onSaveClick() {
    if (!this.state.saving) {
      this.setState({ saving: true });
      const careRecipientsIds = [this.props.data.userId];
      const toSave = {
        careRecipientsIds,
        inviteeMail: this.state.email,
      };

      inviteCaregiver(this.props.dispatch, this.context.apiClient, toSave)
        .then(() => this.setState({ saving: false }, () => this.onClose()))
        .catch((err) => {
          this.setState({ saveError: err.error, saving: false });
        });
    }
  }
  renderStep1() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Title>
          {'Enter caregiver\'s e-mail'}
        </Title>
        <div className={style.stepContent}>
          <div className={style.title}>
            <div className={style.field}>
              <div className={style.label}>E-mail</div>
              <TextField
                fullWidth
                required
                value={this.state.email}
                type="email"
                onChange={event => this.setState({ email: event.target.value })}
                classes={{ root: classes.textFieldRoot }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    input: classes.input
                  }
                }}
              />
            </div>
          </div>
        </div>
        {this.state.saveError && (
          <div className={style.saveError}>An error occurred: {this.state.saveError}</div>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div
        className={[style.background, style[this.props.transition]].join(' ')}
      >
        <div className={style.container}>
          <div className={style.modal}>
            <IconButton
              onClick={() => this.onClose()}
              className={style.buttonClose}
              icon="clear"
              variant="fab"
              mini
            />
            <div className={style.content}>
              {this.renderStep1()}
            </div>
            <div>
              <Button
                disabled={false}
                className={style.nextButton}
                onClick={() => this.onSaveClick()}
              >
                SEND INVITE
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const activeRecipient = state.getIn(['carerecipients', 'activeRecipient'], {});
  return { activeRecipient };
};

export default withStyles(styles)(connect(mapStateToProps)(Dialog));
