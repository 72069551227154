import { RECEIVE_ACTIVITY_LEVEL } from 'actions/activityLevel';

function setActivityLevel(payload) {
  return { ...payload };
}

const ActivityLevel = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_ACTIVITY_LEVEL:
      return setActivityLevel(action.payload);

    default:
      return state;
  }
};

export default ActivityLevel;
